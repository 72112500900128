<template>
  <div class="bly-link-button" :class="type">
    <div class="bly-link-button-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: String,
  },

  data() {
    return {
    };
  },
};
</script>

<style scoped src="./LinkButton.css"></style>
