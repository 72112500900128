<template>
  <!-- -------------------------------------------------------------------------- -->
  <!-- RESCHEDULE EVENT DIALOG -------------------------------------------------- -->
  <!-- -------------------------------------------------------------------------- -->
  <el-dialog
    title="Reschedule booking"
    :visible.sync="rescheduleEventDialog"
    :close-on-click-modal="false"
    width="370px"
  >
    <div class="dialog-form">
      <el-row :gutter="36">
        <el-col :span="24">
          <!-- BASIC INFORMATIONS -->
          <el-collapse-transition>
            <el-alert type="warning" v-if="isCloseToServiceStartTime">
              Late Event. This event is close to start time needs to be started manually
            </el-alert>
          </el-collapse-transition>
          <el-collapse-transition>
            <el-alert type="warning" v-if="doesEventStarted">
              Late Event. This event is after start time needs to be started manually
            </el-alert>
          </el-collapse-transition>
          <el-form ref="rescheduleForm" :model="rescheduleForm" label-position="top">
            <el-row :gutter="16">
              <el-col :span="12">
                <el-form-item size="small" label="Line-up date (GMT)" prop="lineupDate" required>
                  <el-date-picker
                    v-model="rescheduleForm.lineupDate"
                    :picker-options="datePickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="Select line-up date"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="Line-up time (GMT)" prop="lineupTime" required>
                  <el-time-picker
                    value-format="HH:mm:ss"
                    v-model="rescheduleForm.lineupTime"
                    type="datetime"
                    format="HH:mm"
                    placeholder="Select line-up Time"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="Start date (GMT)" prop="startDate" required>
                  <el-date-picker
                    v-model="rescheduleForm.startDate"
                    :picker-options="datePickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="Select start date"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="Start Time (GMT)" prop="startTime" required>
                  <el-time-picker
                    v-model="rescheduleForm.startTime"
                    value-format="HH:mm:ss"
                    format="HH:mm"
                    placeholder="Select start time"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="End date (GMT)" prop="endDate" required>
                  <el-date-picker
                    v-model="rescheduleForm.endDate"
                    :picker-options="datePickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="Select end date"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="End time (GMT)" prop="endTime" required>
                  <el-time-picker
                    v-model="rescheduleForm.endTime"
                    value-format="HH:mm:ss"
                    format="HH:mm"
                    placeholder="Select end time"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="rescheduleEventDialog = false">Cancel</el-button>
      <el-button type="primary" @click="rescheduleBooking()" v-loading="loading">Save</el-button>
    </span>
  </el-dialog>
</template>
<script>
import moment from 'moment';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';

export default {
  data() {
    return {
      isMobileOnly,
      rescheduleForm: {
        startDate: null,
        lineupDate: null,
        endDate: null,
        endTime: null,
        startTime: null,
        eventId: null,
        lineupTime: null,
      },

      datePickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < new Date(new Date().setDate(new Date().getDate() - 1))
          );
        },
      },
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.userAccount.userId,
      aclEx: (state) => state.userAccount.userPermissions,
      isAdmin: (state) => state.userAccount.isAdmin,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      acl: (state) => state.userAccount.aclCurrent,
      userPermissions: (state) => state.userAccount.userPermissions,
      client: (state) => state.userAccount.client,

      selectedEvent: (state) => state.schedule.selectedEvent,
      loading: (state) => state.schedule.loading,
      loadings: (state) => state.schedule.loadings,
      showRescheduleEventDialog: (state) => state.schedule.showRescheduleEventDialog,
    }),

    eventAccount() {
      return this.selectedEvent ? this.selectedEvent.account : { name: '' };
    },

    eventClient() {
      return this.selectedEvent ? this.selectedEvent.customer : { name: '' };
    },

    serviceStartTimeOffsetMin() {
      return moment(this.selectedEvent.lineupTime).diff(this.selectedEvent.serviceStartTime, 'minutes');
    },

    isStartTimeModified() {
      if (!this.rescheduleForm || !this.rescheduleForm.startTime || !this.rescheduleForm.startDate) return false;
      return !(moment.utc(`${this.rescheduleForm.startDate} ${this.rescheduleForm.startTime}`).isSame(moment(this.selectedEvent.startTime)));
    },

    isEndTimeModified() {
      if (!this.rescheduleForm || !this.rescheduleForm.endTime || !this.rescheduleForm.endDate) return false;
      return !(moment.utc(`${this.rescheduleForm.endDate} ${this.rescheduleForm.endTime}`).isSame(moment(this.selectedEvent.endTime)));
    },

    isCloseToServiceStartTime() {
      const format = 'HH:mm:ss';
      const now = moment.utc();
      const before = moment.utc(this.rescheduleForm.startTime, format).subtract('1', 'hours');
      const currentStartTime = moment.utc(this.rescheduleForm.startTime, format);
      const isCurrentDate = moment.utc(this.rescheduleForm.startDate).isSame(new Date(), 'day');
      if (!isCurrentDate) return false;
      return now.isBetween(before, currentStartTime, '[]');
    },

    doesEventStarted() {
      const format = 'HH:mm:ss';
      const now = moment.utc();
      const currentStartTime = moment.utc(this.rescheduleForm.startTime, format);
      const currentEndTime = moment.utc(this.rescheduleForm.endTime, format);
      const isCurrentDate = moment.utc(this.rescheduleForm.startDate).isSame(new Date(), 'day');

      if (!isCurrentDate) return false;
      return now
        .isBetween(currentStartTime, currentEndTime, '[]');
    },

    rescheduleEventDialog: {
      set(value) {
        this.setRescheduleEventDialog(value);
      },
      get() {
        return this.showRescheduleEventDialog;
      },
    },
  },

  methods: {
    ...mapActions({
      rescheduleEvent: 'schedule/rescheduleAgendaEvent',
    }),
    ...mapMutations({
      setRescheduleEventDialog: 'schedule/setRescheduleEventDialog',
    }),

    async rescheduleBooking() {
      const {
        lineupTime,
        lineupDate,
        startTime,
        startDate,
        endTime,
        endDate,
        eventId,
        expectedVersion,
        accountId,
        clientId,
      } = this.rescheduleForm;
      const {
        headTimeMin,
        tailTimeMin,
      } = this.selectedEvent;

      const head = this.isStartTimeModified ? 0 : headTimeMin;
      const tail = this.isEndTimeModified ? 0 : tailTimeMin;

      const payload = {
        lineupTime: moment.utc(`${lineupDate} ${lineupTime}`).format(),
        startTime: moment.utc(`${startDate} ${startTime}`).format(),
        endTime: moment.utc(`${endDate} ${endTime}`).format(),
        headTimeMin: head,
        tailTimeMin: tail,
        eventId,
        expectedVersion,
        accountId,
        clientId,
        serviceStartTimeOffsetMin: this.serviceStartTimeOffsetMin,
      };
      console.log('ddd', payload);
      const result = await this.rescheduleEvent(payload);
      const hasErrors = result.error;
      if (!hasErrors) this.rescheduleEventDialog = false;
    },
  },

  watch: {
    selectedEvent(newVal) {
      if (!newVal) return;
      this.rescheduleForm = {
        endDate: newVal.endTime.split('T')[0],
        startDate: newVal.startTime.split('T')[0],
        lineupDate: newVal.lineupTime.split('T')[0],
        eventId: newVal.id,
        endTime: newVal.endTime.split('T')[1],
        startTime: newVal.startTime.split('T')[1],
        lineupTime: newVal.lineupTime.split('T')[1],
        expectedVersion: newVal.version,
        accountId: this.eventAccount.code,
        clientId: this.eventClient.code,
        tailTimeMin: newVal.tailTimeMin,
        headTimeMin: newVal.headTimeMin,
      };
    },
  },
};
</script>
