<template>
  <div>
    <button @click="showModal = true" class="p-2  hover:text-yellow-500">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-5 h-5 relative -top-0.5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25" />
      </svg>
    </button>

    <div v-if="showModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full min-h-[80vh] relative pb-11">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2" id="modal-title">
                  Import Updates
                </h3>
                <button class="absolute top-4 right-2 hover:text-red-500"
                  @click="showModal = false">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>

                </button>
                <div class="flex mb-2">
                  <button
                    v-for="(info, name) in importInfo"
                    :key="name"
                    @click="changeTab(name)"
                    class="uppercase"
                    :class="['tab px-2 py-0.5 rounded-sm', { 'font-bold text-black bg-yellow-500': activeTab === name }]"
                  >
                    {{ name }}
                  </button>
                </div>
                <div class="flex gap-5">
                  <div>
                    <div class="text-xs uppercase font-bold mb-1">Last Import </div>
                    <div v-for="(info, name) in importInfo" :key="name" >
                      <div v-if="activeTab === name"
                        class="mr-2 py-1 rounded-sm mb-2 text-sm sm:flex gap-3 mt-[5px]">
                        <div>
                          Imported By: {{ info.userName }}
                        </div>
                        <div>
                          Start Date: <strong>{{moment.utc(info.startDate).format('DD-MM-yyyy HH:mm')}}</strong>
                        </div>
                        <div>
                          End Date: <strong>{{moment.utc(info.endDate).format('DD-MM-yyyy HH:mm')}}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>

                    <div class="text-xs uppercase font-bold mb-1">Date Range</div>
                    <div class="flex items-center">
                      <date-range-picker
                        ref="picker"
                        :opens="'center'"
                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                        :singleDatePicker="false"
                        :ranges="false"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="true"
                        v-model="dateRange"
                        @start-selection="handleStartDateSelection"
                        @finish-selection="handleEndDateSelection"
                        :linkedCalendars="true"
                        >
                        <template v-slot:input="picker" style="min-width: 150px;" class="bg-black font-bold relative">
                            <div class="text-sm">{{ moment.utc(picker.startDate).format('DD/MM/yyyy')}} <span class="hidden sm:inline"> - {{ moment.utc(picker.endDate).format('DD/MM/yyyy') }}</span></div>
                        </template>
                      </date-range-picker>
                      
                      <button @click="sync('syncDazn')" v-if="activeTab === 'dazn'" type="button" class="mt-3 w-full text-xs inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-1 bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Start Import
                      </button>
                      <button @click="sync('syncPlp')" v-if="activeTab === 'plp'" type="button" class="mt-3 w-full text-xs inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-1 bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Start Import
                      </button>
                      <button @click="sync('syncUrbanZoo')" v-if="activeTab === 'urbanzoo'" type="button" class="mt-3 w-full text-xs inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-1 bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Start Import
                      </button>
                      <button @click="sync('syncMatchroom')" v-if="activeTab === 'matchroom'" type="button" class="mt-3 w-full text-xs inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-1 bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Start Import
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-2 max-h-[50vh] overflow-y-auto">
                  <ul>
                    <li v-for="(message, index) in messages" :key="index"
                      class="px-2 py-1 rounded-md mb-2 text-black w-full"
                      :class="{
                        'bg-green-200': message.status === 'ok',
                        'bg-blue-300': message.status === 'end',
                        'bg-red-200': message.status === 'error',
                        'bg-blue-200': message.status === 'start',
                        }">
                        <span>{{ message.createdAt }}</span>
                      {{ message.message }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-between absolute bottom-0 right-0 left-0">
            <!-- <button @click="showModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-sm border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Close
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImportService from '../../services/Import.js';
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';

import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  components: { DateRangePicker },
  data() {
    return {
      importInfo: {
        dazn: {},
        plp: {},
        urbanzoo: {},
        matchroom: {}
      },
      messages: [],
      showModal: false,
      activeTab: 'dazn',
      moment,
      dateRange: {
        startDate: moment.utc().subtract(7, 'days').startOf('day').format(),
        endDate: moment.utc().add(1, 'days').endOf('day').format(),
      },
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.userAccount.userId,
      userEmail: (state) => state.userAccount.userEmail,
    })
  },
  async mounted() {
    this.importInfo = {
      dazn: {...await ImportService.getLastImportInfo('dazn')},
      plp: {...await ImportService.getLastImportInfo('plp')},
      urbanzoo: {...await ImportService.getLastImportInfo('urbanzoo')},
      matchroom: {...await ImportService.getLastImportInfo('matchroom')},
    }
    ImportService.connectToWs((event) => {
      console.log('JSON.stringify(event.data),', JSON.parse(event.data))
      this.messages.push({
        ...JSON.parse(event.data),
        createdAt: moment.utc(new Date()).format('HH:mm')
      });
    });
  },
  methods: {
    async changeTab(name) {
      this.activeTab = name;
      ImportService.websocket.close();
      this.messages = [];
      ImportService.connectToWs((event) => {
        console.log('JSON.stringify(event.data),', JSON.parse(event.data))
        this.messages.push({
          ...JSON.parse(event.data),
          createdAt: moment.utc(new Date()).format('HH:mm')
        });
      });
      this.importInfo = {
        dazn: {...await ImportService.getLastImportInfo('dazn')},
        plp: {...await ImportService.getLastImportInfo('plp')},
        urbanzoo: {...await ImportService.getLastImportInfo('urbanzoo')},
        matchroom: {...await ImportService.getLastImportInfo('matchroom')},
      }
    },
    sync(service) {
      // Call your sync function here
      console.log(`Syncing ${service}`, this.dateRange);
      ImportService.send({
        ...this.dateRange,
        task: service,
        userId: this.userId,
        userName: this.userEmail,
      })
    },
  }
};
</script>
