<template>
  <div id="router-mini" class="router-mini">
    <div class="pane-wrapper">
      <div class="pane-title blue-light">
        <i class="bi bly-output"></i>
        Sources
        <span class="loading" v-show="loadings.sources">
          <i class="bi bly-loading red bi-is-spinning"></i>
        </span>
        <div class="title-actions">
          <el-select
            size="small"
            placeholder="Select group"
            clearable
            @change="sourceGroupChanged"
            v-model="selectedSourceGroup" value-key="id">
            <el-option
              v-for="group in sourceGroups"
              :key="group.id"
              :label="group.name"
              :value="group">
            </el-option>
          </el-select>
          <el-divider direction="vertical" v-if="!isMobileOnly"></el-divider>
          <div class="search" v-if="!isMobileOnly">
            <el-input
              placeholder="Search sources..."
              size="small"
              clearable
              @change="searchSourceChanged"
              v-model="searchSource">
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
      </div>
      <div v-bar>
        <div class="pane-bar-wrapper sources">
          <div class="item-group">
            <!--<el-divider content-position="left">{{key}}</el-divider>-->
            <div class="item-wrapper"
              v-for="(source, index) in filteredSources"
              :key="source.id">
              <div class="item"
                @click="routSource(source, selectedDestination)"
                :class="{
                  active: selectedSource === source,
                  connected: activeDestinationSource.id === source.id,
                  disabled: !activeDestinationSource
                    && (activeDestinationSource.originRouterId
                    !== source.originRouterId),
                  'show-take': showTakeId === source.id,
                  'show-loading': loadings.source === source.id,
                }">
                <div class="changing-route">
                  <i class="bi bly-loading red bi-is-spinning"></i>
                </div>
                <div class="take-action"
                  @click="sendRouteCommand(source, selectedDestination)">
                  TAKE
                </div>
                <div class="item-avatar"
                  :title="source.originRouterId">
                  <el-avatar shape="square" size="medium"
                  :style="{ background: originRouterColor(source.originRouterId, 'source'),}"
                  >{{index + 1}}</el-avatar>
                </div>
                <div>
                  <div class="item-name">
                    {{source.name}}
                  </div>
                  <div class="item-desc">
                    <div v-if="source.routedDestinations.items">
                      <div
                        class="not-connected"
                        v-if="source.routedDestinations.items.length === 0">
                        not routed
                      </div>
                    </div>
                    <div v-if="source.routedDestinations.items.length > 0">
                      <el-tooltip>
                        <div slot="content">
                          <div
                            class="routed"
                            v-for="d in source.routedDestinations.items"
                            :key="d.id"
                            >{{d.name}}</div>
                        </div>
                        <div>
                          <span class="routed">
                            {{source.routedDestinations.items[0].name}}
                          </span>
                          <span
                            class="routed"
                            v-show="source.routedDestinations.items.length > 0"
                            >... ({{source.routedDestinations.items.length}})
                          </span>
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pane-wrapper">
      <div class="pane-title green">
        <i class="bi bly-input"></i>
        Destinations
        <span class="loading" v-show="loadings.destinations">
          <i class="bi bly-loading red bi-is-spinning"></i>
        </span>
        <div class="title-actions">
          <el-select
            size="small"
            placeholder="Select group"
            clearable
            @change="destGroupChanged"
            v-model="selectedDestGroup" value-key="id">
            <el-option
              v-for="group in destinationGroups"
              :key="group.id"
              :label="group.name"
              :value="group">
            </el-option>
          </el-select>
          <el-divider direction="vertical" v-if="!isMobileOnly"></el-divider>
          <div class="search" v-if="!isMobileOnly">
            <el-input
              placeholder="Search destination..."
              size="small"
              clearable
              @change="searchDestinationChanged"
              v-model="searchDestination">
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
      </div>
      <div v-bar>
        <div class="pane-bar-wrapper destinations">
          <div class="item-group">
            <!--<el-divider content-position="left">{{key}}</el-divider>-->
            <div class="item-wrapper"
              v-for="(dest, index) in filteredDestinations"
              :key="dest.id">
              <div class="item destination"
                @click="toggleDest(dest)"
                :class="{
                  active: selectedDestinationId === dest.id,
                  connected: activeSourceDestinations.indexOf(dest.id) !== -1,
                  disabled: !activeSourceDestinations
                    && (activeSourceDestinations.originRouterId
                    !== dest.originRouterId),
                }"
                >
                <div class="item-avatar"
                  :title="dest.originRouterId"
                  >
                  <el-avatar shape="square" size="medium"
                  :style="{ background: originRouterColor(dest.originRouterId, 'dest'),}">{{index + 1}}</el-avatar>
                </div>
                <div>
                  <div class="item-name">
                    {{dest.name}}
                  </div>
                  <div class="item-desc">
                    {{dest.routedSource.name}}
                  </div>
                </div>
                <div class="item-actions"
                  v-show="false" v-if="isAdmin || acl.ro">
                  <el-tooltip
                    v-if="selectedSource
                      && activeSourceDestinations.indexOf(dest.id) === -1">
                    <div slot="content">
                      Route <strong class="blue-light">{{selectedSource.name}}</strong> here
                    </div>
                    <i class="bi bly-route icon-btn blue-light"
                      @click="sendRouteCommand(selectedSource, dest)"></i>
                  </el-tooltip>
                  <el-tooltip
                    v-show="false"
                    v-if="selectedSource
                      && activeSourceDestinations.indexOf(dest.id) !== -1">
                    <div slot="content">
                      Unassign <strong class="blue-light"> {{selectedSource.name}}</strong>
                    </div>
                    <i class="bi bly-close icon-btn red"
                      @click="sendRouteCommand(selectedSource, dest)"></i>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import _ from 'lodash';
import { Splitpanes, Pane } from 'splitpanes';
import ColorGenerator from '@/services/ColorGenerator';
import 'splitpanes/dist/splitpanes.css';

export default {
  name: 'LiveOpsRouter',
  components: { Splitpanes, Pane },
  data() {
    return {
      isMobileOnly: true,
      activeSource: null,
      loadingInfo: null,
      loadingRoute: null,
      hoveredDest: null,
      hoveredSource: null,
      hoverDest: null,
      useTake: true,
      showTakeId: null,

      seletedRouterGroup: null,
      selectedDestGroup: null,
      selectedSourceGroup: null,

      // routing
      selectedSource: null,
      selectedDestination: null,

      // dialogs
      createRouterDialog: false,
      createRouterSourceDialog: false,
      createRouterDestinationDialog: false,
      createRouterGroupDialog: false,

      // forms
      routerForm: {},
      routerGroupForm: {},
      routerSourceForm: {},
      routerDestinationForm: {},

      keys: {},
      searchDestination: '',
      searchSource: '',
    };
  },

  created() {
    this.init();
    this.initInterval();
    window.addEventListener('keyup', this.keyUp);
    window.addEventListener('keydown', this.keyDown);
  },

  beforeDestroy() {
    window.clearInterval(this.interval);
    this.interval = null;
    window.removeEventListener('keyup', this.keyUp);
    window.removeEventListener('keydown', this.keyDown);
  },

  computed: {
    ...mapState({
      aclEx: (state) => state.userAccount.userPermissions,
      acl: (state) => state.userAccount.aclCurrent,
      isAdmin: (state) => state.userAccount.isAdmin,
      client: (state) => state.userAccount.client,
      account: (state) => state.userAccount.account,
      clients: (state) => state.userAccount.clients,
      siteList: (state) => state.router.siteList,
      selection: (state) => state.router.selectedSite,
      loadings: (state) => state.router.loadings,

      routers: (state) => state.router.routers,
      routerSources: (state) => state.router.routerSources,
      routerDestinations: (state) => state.router.routerDestinations,
      routerGroups: (state) => state.router.routerGroups,

      streamInformation: (state) => state.router.streamInformation,
    }),

    paneMinimalSize() {
      return this.isMobileOnly ? 50 : 25;
    },

    activeSourceDestinations() {
      if (this.hoveredSource) {
        return this.hoveredSource.routedDestinations.items.map((dest) => dest.id);
      }
      if (this.selectedSource) {
        return this.selectedSource.routedDestinations.items.map((dest) => dest.id);
      }
      return [];
    },

    activeDestinationSource() {
      if (this.hoveredDest) {
        return this.hoveredDest.routedSource;
      }
      if (this.selectedDestination) {
        return this.selectedDestination.routedSource;
      }
      return [];
    },

    filteredSources() {
      const filterBySearchString = this.searchSource.length > 2;
      if (filterBySearchString) {
        const seachString = this.searchSource.toLowerCase();
        return this.routerSources
          .filter((item) => item.name.toLowerCase().indexOf(seachString) !== -1)
          .sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      if (!this.selectedSourceGroup) {
        return this.routerSources
          .slice(0).sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      return this.routerSources
        .filter((item) => {
          const groupIds = item.routerGroups.items.map((group) => group.routerGroup.id);
          return groupIds.indexOf(this.selectedSourceGroup.id) !== -1;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    filteredDestinations() {
      const filterBySearchString = this.searchDestination.length > 2;
      if (filterBySearchString) {
        const seachString = this.searchDestination.toLowerCase();
        return this.routerDestinations
          .filter((item) => item.name.toLowerCase().indexOf(seachString) !== -1)
          .sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      if (!this.selectedDestGroup) {
        return this.routerDestinations
          .slice(0).sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      return this.routerDestinations
        .filter((item) => {
          const groupIds = item.routerGroups.items.map((group) => group.routerGroup.id);
          return groupIds.indexOf(this.selectedDestGroup.id) !== -1;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    sourceGroups() {
      if (!this.routerGroups) return [];
      return this.routerGroups.items.filter((item) => item.sources.items.length > 0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    destinationGroups() {
      if (!this.routerGroups) return [];
      return this.routerGroups.items.filter((item) => item.destinations.items.length > 0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    groupedSources() {
      const mappedSources = this.routerSources.map((item) => ({
        source: item,
        groups: item.routerGroups.items.map((group) => group.routerGroup.name),
      }));
      return _.groupBy(mappedSources, (item) => item.groups);
    },

    groupedDestinations() {
      const mappedDest = this.routerDestinations.map((item) => ({
        dest: item,
        groups: item.routerGroups.items.map((group) => group.routerGroup.name),
      }));
      return _.groupBy(mappedDest, (item) => item.groups);
    },

    selectedDestinationId() {
      return this.selectedDestination ? this.selectedDestination.id : null;
    },

    accountId() {
      return this.account.code;
    },

    clientId() {
      return this.client.code;
    },

    claimsCanEdit() {
      return [
        `ro.${this.accountId}.${this.clientId}`,
      ];
    },

    claimsCanAccess() {
      return [
        `rv.${this.accountId}.${this.clientId}`,
        `ro.${this.accountId}.${this.clientId}`,
      ];
    },

    originRouterColor() {
      return (id, type) => ColorGenerator.generateNewColor(id + type);
    },
  },

  watch: {
    seletedRouterGroup() {
      this.selectedSource = null;
      this.selectedDestination = null;
    },
  },

  methods: {
    ...mapActions({
      fetchRouterGroups: 'router/fetchRouterGroups',
      fetchRouterDestinations: 'router/fetchRouterDestinations',
      fetchRouterSources: 'router/fetchRouterSources',
      fetchRouters: 'router/fetchRouters',

      fetchRouter: 'router/fetchRouter',
      fetchRouterDestination: 'router/fetchRouterDestination',
      fetchRouterGroup: 'router/fetchRouterGroup',
      fetchRouterSource: 'router/fetchRouterSource',

      deleteRouter: 'router/deleteRouter',
      deleteRouterDestination: 'router/deleteRouterDestination',
      deleteRouterGroup: 'router/deleteRouterGroup',
      deleteRouterSource: 'router/deleteRouterSource',

      updateRouter: 'router/updateRouter',
      updateRouterDestination: 'router/updateRouterDestination',
      updateRouterGroup: 'router/updateRouterGroup',
      updateRouterSource: 'router/updateRouterSource',

      createRouter: 'router/createRouter',
      createRouterDestination: 'router/createRouterDestination',
      createRouterGroup: 'router/createRouterGroup',
      createRouterSource: 'router/createRouterSource',

      changeRoute: 'router/changeRoute',
    }),
    ...mapMutations({
      setSelectedSite: 'router/setSelectedSite',
    }),

    keyDown(e) {
      this.keys[e.key] = true;
    },

    keyUp(e) {
      this.keys[e.key] = false;
    },

    showTake(source) {
      this.showTakeId = source.id;
    },

    toggleSource(source) {
      if (this.selectedSource === source) {
        this.selectedSource = null;
        return;
      }
      this.selectedSource = source;
      // this.selectedDestination = null;
      if (this.keys.Shift) return;

      this.$forceUpdate();
      setTimeout(() => {
        document.querySelector('.destinations').scroll({
          top: document.querySelector('.destinations .connected').offsetTop - 10,
          behavior: 'smooth',
        });
      }, 100);
    },

    toggleDest(dest) {
      this.showTakeId = null;
      if (this.selectedDestinationId === dest.id) {
        this.selectedDestination = null;
        return;
      }
      this.selectedDestination = dest;
      this.selectedSource = null;
      this.$forceUpdate();

      if (this.keys.Shift) return;
      setTimeout(() => {
        document.querySelector('.sources').scroll({
          top: document.querySelector('.sources .connected').offsetTop - 10,
          behavior: 'smooth',
        });
      }, 100);
    },

    initInterval() {
      this.interval = setInterval(async () => {
        await this.fetchRouterDestinations();
        if (this.selectedDestination) {
          this.filteredDestinations.forEach((dest) => {
            if (dest.id !== this.selectedDestination.id) return;
            this.selectedDestination.routedSource = dest.routedSource;
          });
        }
        await this.fetchRouterSources();
        this.$forceUpdate();
      }, 15000);
    },

    async init() {
      // this.searchDestination = localStorage.getItem('router_searchDestination') || '';
      // this.searchSource = localStorage.getItem('router_searchSource') || '';

      this.selectedSourceGroup = JSON.parse(localStorage.getItem('router_selectedSourceGroup')) || null;
      this.selectedDestGroup = JSON.parse(localStorage.getItem('router_selectedDestGroup')) || null;
      try {
        await this.fetchRouters();
        await this.fetchRouterDestinations();
        await this.fetchRouterSources();
        await this.fetchRouterGroups();
      } catch (error) {
        this.$message({
          showClose: true,
          message: 'Oops, something went wrong.',
          type: 'error',
        });
      }
    },

    routSource(source, destination) {
      const hasPermission = this.isAdmin || this.acl.ro;
      if (!hasPermission) return;
      this.showTakeId = null;
      if (!destination) return;
      if (this.useTake) {
        this.showTakeId = source.id;
        return;
      }

      this.sendRouteCommand(source, destination);
    },

    async sendRouteCommand(source, dest) {
      const command = {
        clientId: this.clientId,
        accountId: this.accountId,
        sourceId: source.id,
        destinationIds: [dest.id],
        // eventId: ID
        claimsCanAccess: this.claimsCanAccess,
        claimsCanEdit: this.claimsCanEdit,
      };
      await this.changeRoute(command);
      this.showTakeId = null;
    },

    // OLD STUFF
    async deleteRouterPrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouter(router);
      return null;
    },
    async deleteRouterSourcePrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouterSource(router.id);
      return null;
    },
    async deleteRouterDestinationPrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouterDestination(router.id);
      return null;
    },

    async showPrompt(confirmationMessage) {
      const confirmation = await this.$confirm(confirmationMessage, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        center: true,
        dangerouslyUseHTMLString: true,
      });
      return confirmation === 'confirm';
    },

    searchDestinationChanged() {
      localStorage.setItem('router_searchDestination', this.searchDestination);
    },
    searchSourceChanged() {
      localStorage.setItem('router_searchSource', this.searchSource);
    },
    sourceGroupChanged() {
      localStorage.setItem('router_selectedSourceGroup', JSON.stringify(this.selectedSourceGroup));
    },
    destGroupChanged() {
      localStorage.setItem('router_selectedDestGroup', JSON.stringify(this.selectedDestGroup));
    },
  },
};
</script>

<style >
#main #nav .nav-profile {
  user-select: none;
}



.el-loading-spinner svg {
  margin: 0 auto;
}

#main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px;
  margin: 0 -20px;
  overflow-x: hidden;
  min-height: 100vh;
  max-width: 100vw;
}
#main #nav {
  z-index: 99;
}
#main #nav .bly-button {
  margin-top: 10px;
  margin-right: 24px;
}
#main #nav .nav-profile {
  text-transform: none;
  line-height: 18px;
  vertical-align: middle;
  font-weight: bold;
  text-align: right;
  font-weight: 600;
  display: inline-block;
  margin-right: 11px;
  cursor: pointer;
}
#main #nav .nav-profile-name {
  color: #CDD6EE;
  font-size: 14px;
}
#main #nav .nav-profile-account {
  font-size: 12px;
}
#main #right-nav .el-avatar {
  position: relative;
  top: 10px;
  cursor: pointer;
}
#main #right-nav .separator {
  margin-right: 10px;
  margin-left: 10px;
  display: inline-block;
}
#main #logo {
  cursor: pointer;
}
#main #logo img {
  max-width: 200px;
  max-height: 50px;
  margin-bottom: -16px;
}

.main-push-nav .el-drawer__body {
  margin-top: -20px;
}
.main-push-nav .el-dropdown-menu__item .dd-account-name {
  font-size: 16px;
  color: #000;
}
.main-push-nav .el-dropdown-menu__item .dd-account-client {
  font-size: 12px;
  margin-top: -18px;
  display: block;
}

.mobile .main-push-nav .el-dropdown-menu__item .dd-account-name {
  font-size: 16px;
  color: #000;
}
.mobile .main-push-nav .el-dropdown-menu__item .dd-account-client {
  font-size: 12px;
  margin-top: 0px;
  display: block;
}

.drawer-wrapper {
  height: calc(100vh - 57px);
}

.dd-client-account-list {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto auto auto;
}
.dd-client-account-list .dd-account-item {
  padding: 4px 8px;
  border-radius: 3px;
  color: #000;
  cursor: pointer;
  font-weight: 600;
}
.dd-client-account-list .dd-account-item.active {
  background: #FEF5EB;
  color: #ff9f31;
}
.dd-client-account-list .el-divider {
  background-color: #dae5ff;
}

.el-popover .el-divider__text.is-left {
  left: 0;
  padding-left: 0px;
}
.el-popover .el-divider__text {
  color: #babdc7;
}
.el-popover .el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0 13px 0;
}
.el-popover .el-divider {
  background-color: rgba(179, 189, 214, 0.48);
  position: relative;
}
.el-popover .nav-user-info {
  background: #f1f2f7;
  border-radius: 4px;
  padding: 0 16px;
  font-weight: bold;
  position: relative;
  line-height: 49px;
  margin: -12px -12px 0 -12px;
  z-index: 22;
}
.el-popover .nav-user-info .nav-profile-name {
  cursor: pointer;
  color: #000;
  font-weight: 600;
  font-size: 16px;
}
.el-popover .nav-user-info .nav-profile-name:hover {
  color: #ff9f31;
}
.el-popover .nav-user-info .user-actions {
  position: absolute;
  top: 2px;
  right: 6px;
}
</style>
