<template>
    <div>
      <div id="multiview-intro" class="home" v-if="showInitialSelection">
        <div class="welcome-screen">
          <div class="support-message" v-show="showSupportMessage">
            <i class="bi bly-warning"></i>
            <h1>This browser is not supported.</h1>
            <p class="lead">
              Multiviewer is optimised for Chrome and Firefox browser.
              It may be unstable within other browsers.
                For best experience please use Google Chrome or Firefox.
            </p>
            <el-button @click="showSupportMessage = false">
              I understand and want to use it
            </el-button>
          </div>
          <div class="config-select" v-show="!showSupportMessage">
            <h1>Select configuration</h1>
            <el-select
              v-model="sourcePickerValue"
              value-key="id"
              placeholder="Select config"
            >
              <el-option
                v-for="item in sortedMultiviers"
                :key="item.name"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-button @click="loadMultiview(sourcePickerValue)">
              <i class="bi bly-arrow-right-long" />
            </el-button>
          </div>
        </div>
      </div>
      <div class="source-select"  v-if="!showInitialSelection && isMobileOnly">
        <el-select
          v-if="isMobileOnly"
          v-model="sourcePickerValue"
          placeholder="Select config"
          @change="loadMultiview"
        >
          <el-option
            v-for="item in sortedMultiviers"
            :key="item.name"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <bly-mv v-if="selectedMultiviewer && !showInitialSelection && selectedMultiviewer.type === 'ROUTABLE'"
        :key="selectedMultiviewer.id + (Math.random() + 1).toString(36).substring(7)"
        :sources="selectedMultiviewer.streamSources"
        :id="selectedMultiviewer.audioControlId"
        :mv="selectedMultiviewer"></bly-mv>
      <div
        id="multiview"
        class="fullscreen"
        v-hotkey="keymap"
        :class="gridClass"
        :key="selectedMultiviewer.id + (Math.random() + 1).toString(36).substring(7)"
        v-if="selectedMultiviewer && !showInitialSelection && selectedMultiviewer.type !== 'ROUTABLE'"
      >
        <div
          v-for="(source, index) in sources"
          :key="source.title + index + (Math.random() + 1).toString(36).substring(7)"
          class="player-wrapper"
          :ref="'pw-'+ source.id"
          :class="[{
            // active : activeViewPort === source.id,
            buffering: source.loading,
            'active-fullscreen': activeFullScreenViewPort === index
          }, 'pw-'+ source.id]"
        >

          <div class="inner-wrapper">
            <div v-if="source.streamSource?.address.startsWith('wss://')"
              :id="'vp' + source.id"
              :ref="'vp' + source.id"
              :data-index="source.id"
              class="video-active h-[80%]"
              @click="activateViewPort(source.id)"
              :key="'vpk' + source.id">

            </div>
            <video
              v-else
              :id="'vp' + source.id"
              :ref="'vp' + source.id"
              :data-index="source.id"
              class="video-active"
              :key="'vpk' + source.id"
              @click="activateViewPort(source.id)"
              autoplay="true"
            >
            </video>
            <div class="overlay" :class="{open : activeOverlay === source.id }">
              <div class="tiny"></div>
              <div class="videoInfo" :ref="'vp' + source.id + '-meta'">
                Video Resolution: <strong>{{getResolution(source)}}</strong> <br>
                Bitrate: <strong v-if="source.levels">{{getBitrate(source)}} k</strong>
              </div>
            </div>
            <el-button
              class="video-fullscreen-btn"
              type="primary"
              size="mini" @click="toogleFullscreenForViewPort(source.id)">
              <i class="bi bly-fullscreen"></i>
            </el-button>
            <div class="title">
              {{source.streamSource.name}}
              <!-- {{source}} -->
              <div class="source-options">
                <div class="ma-popover">
                  <div class="ma-popover-content">
                    <span class="p-title">Quality</span>
                    <div class="link" @click="setLevel(index,-1)"
                      :class="{'active': getLvl(source) === -1}" >auto </div>
                    <div class="link"
                      v-for="(quality, i) in source.levels"
                      :key="quality.attrs.RESOLUTION"
                      @click="setLevel(index, i)"
                      :class="{'active': getLvl(source) === i}" >
                        {{quality.height}}p
                      </div>
                  </div>
                  <el-button slot="reference" size="mini" class="video-options" >
                    <i class="bi bly-more " v-if="!isMobileOnly"></i>
                  </el-button>
                </div>
                <i class="bi bly-info icon-btn" @click="toggleOverlay(source.id)"></i>
              </div>
            </div>
            <div class="shortcut">{{getKeyByValue(keys,source.id)}}</div>
            <span class="buffering" v-show="source.loading">Buffering</span>
            <div class="peak-meter" :ref="'vp' + source.id + '-meter'">
            </div>
          </div>
        </div>
      </div>
      <div class="config mv-config" v-if="!showInitialSelection">
        <div class="cursor-pointer  icon-btn" v-if="userEmail === 'adrian@mediaatmos.com'" @click="test">lll</div>
        <i class="bi bly-fullscreen icon-btn"
          v-if="!isMobileOnly"
          @click="toggleFullscreen"></i>
        <i class="bi bly-peakmeter icon-btn"
          v-if="!isMobileOnly"
          @click="showPPM = !showPPM"></i>
        <el-select
          v-if="!isMobileOnly"
          v-model="sourcePickerValue"
              value-key="id"
          placeholder="Select config"
          @change="loadMultiview"
        >
          <el-option
            v-for="item in sortedMultiviers"
            :key="item.name"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>

        <span v-show="hlsMediaIssue" class="issue-tooltip">
          <el-tooltip content="HLS Media error">
            <i class="bi bly-warning"></i>
          </el-tooltip>
        </span>
        <span v-show="hlsIssue" class="issue-tooltip">
          <el-tooltip content="HLS Uknown Error">
            <i class="bi bly-warning"></i>
          </el-tooltip>
        </span>
      </div>
    </div>
</template>
<script>
import Vue from 'vue';
import shaka from 'shaka-player';
import Hls from 'hls.js';
import { detect } from 'detect-browser';
import { mapState, mapMutations, mapActions } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';

import WebAudioPeakMeter from '../components/peakMeter';

export default {
  data() {
    return {
      isMobileOnly,
      activeViewPort: null,
      activeFullScreenViewPort: null,
      shakaPlayers: {},
      sldpPlayers: {},
      hideGrid: false,
      hlsconfig: {
        capLevelToPlayerSize: true,
      },
      // gridColumns: 4,
      // gridRows: 4,
      // sources: [],
      ppms: {}, // peak meters resources
      isFullscreen: false,
      showPPM: true,
      keys: {
        1: 0,
        2: 1,
        3: 2,
        4: 3,
        5: 4,
        6: 5,
        7: 6,
        8: 7,
        q: 8,
        w: 9,
        e: 10,
        r: 11,
        t: 12,
        y: 13,
        u: 14,
        i: 15,
      },

      // TODO implement this in proper way
      source: null,
      selectedConfig: null,

      // errors
      hlsMediaIssue: false,
      hlsIssue: false,

      // ui
      activeOverlay: null,
      showSupportMessage: false,
      showInitialSelection: true,
      sourcePickerValue: null,

      tempDisable: true,
      sourcesConfig: {},
    };
  },

  computed: {
    ...mapState({
      selectedMultiviewer: (state) => state.multiview.selectedMultiviewer,
      multiviewers: (state) => state.multiview.multiviewers,
      sources: (state) => state.multiview.sources,
      gridColumns: (state) => state.multiview.gridColumns,
      gridRows: (state) => state.multiview.gridRows,
      userEmail: (state) => state.userAccount.userEmail,
    }),
    sortedMultiviers() {
      return this.multiviewers
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },
    gridClass() {
      const ppm = this.showPPM ? 'show-ppm' : '';
      // if (this.isMobileOnly) return 'grid-mobile';
      return `grid-${this.gridColumns}x${this.gridRows} ${ppm}`;
    },

    getResolution() {
      return (source) => {
        console.log();
        return source.player ? `${source.player.videoWidth}x${source.player.videoHeight}` : '';
      };
    },

    getLvl() {
      return (source) => {
        const isHls = source.type === 'hls';
        if (!isHls) return '-';
        return source.obj ? source.obj.currentLevel : '';
      };
    },

    isOverlayVisible() {
      return (index) => this.activeOverlay === index;
    },

    getBitrate() {
      return (source) => {
        const isHls = source.type === 'hls';
        if (!isHls) return '-';
        const level = source.levels[this.getLvl(source)];
        return level ? Math.round(level.bitrate / 1024) : '';
      };
    },

    isHttps() {
      return window.location.protocol === 'https:';
    },

    currentProtocol() {
      return window.location.protocol;
    },

    currentLocation() {
      return window.location.href.substring(window.location.protocol.length);
    },

    keymap() {
      return {
        1: this.keyActions,
        2: this.keyActions,
        3: this.keyActions,
        4: this.keyActions,
        5: this.keyActions,
        6: this.keyActions,
        7: this.keyActions,
        8: this.keyActions,
        q: this.keyActions,
        w: this.keyActions,
        e: this.keyActions,
        r: this.keyActions,
        t: this.keyActions,
        y: this.keyActions,
        u: this.keyActions,
        i: this.keyActions,
        'ctrl+shift+f': {
          keyup: this.toggleFullscreen,
        },
        'ctrl+alt+2': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+3': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+4': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+5': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+6': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+7': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+8': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+q': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+w': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+e': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+r': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+t': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+y': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+u': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+i': {
          keyup: this.keySourceFullscreen,
        },
      };
    },
  },

  watch: {

  },

  created() {
    // this.listMultiviewers();
    this.initMultiview();
  },

  beforeDestroy() {
    this.setSelectedSourceConfig(null);
    this.disposePlayers();
  },

  mounted() {
    if (document.addEventListener) {
      document.addEventListener('webkitfullscreenchange', this.refreshPPM, false);
      document.addEventListener('mozfullscreenchange', this.refreshPPM, false);
      document.addEventListener('fullscreenchange', this.refreshPPM, false);
      document.addEventListener('MSFullscreenChange', this.refreshPPM, false);
    }
  },

  methods: {
    ...mapActions({
      fetchConfiguration: 'multiview/fetchConfiguration',
      listMultiviewers: 'multiview/listMultiviewers',
    }),

    ...mapMutations({
      setSelectedSourceConfig: 'multiview/setSelectedSourceConfig',
    }),

    async getSources() {
      this.selectedConfig = this.selectedSourceConfig;
      this.source = this.selectedSourceConfig;
    },

    async initMultiview() {
      const urlString = window.location.href;
      const url = new URL(urlString);
      const urlSource = url.searchParams.get('source');
      const isSSLReload = !!urlSource;


      await this.listMultiviewers();
      if (isSSLReload) {
        await this.$confirm(`You've been successfully switched to ${urlSource}`,
          '', {
            center: true,
            showCancelButton: false,
            confirmButtonText: 'OK',
            type: 'info',
          });
        this.showInitialSelection = false;
        // await this.fetchConfiguration();
        // await this.listMultiviewers();
        // this.loadMultiview(urlSource);
        return;
      }
      // await this.fetchConfiguration();
      // await this.listMultiviewers();
      this.sourcePickerValue = this.selectedSourceConfig;

      this.showInitialSelection = true;
      const browser = detect();
      const isBrowserSupported = browser.name === 'firefox' || browser.name === 'chrome';
      const savedMV = localStorage.getItem('source');
      if (savedMV) {
        const source = JSON.parse(savedMV);
        const matchingSource = this.multiviewers.find((el) => el.id === source.id)
        if (!matchingSource) return;
        this.setSelectedSourceConfig(matchingSource);
        this.sourcePickerValue = matchingSource;
        this.$forceUpdate();
      }
      if (!isBrowserSupported) this.showSupportMessage = true;
    },

    loadMultiview(newSource) {
      // this.tempDisable = false;
      localStorage.setItem('latestMV', newSource.id);

      this.$forceUpdate();
      this.disposePlayers();
      this.$forceUpdate();

      this.hideGrid = true;
      setTimeout(() => this.hideGrid = false, 1000);
      this.$forceUpdate();
      this.showInitialSelection = false;
      localStorage.setItem('source', JSON.stringify(newSource));
      this.setSelectedSourceConfig(newSource);
      this.$forceUpdate();
      this.tempDisable = true;
      this.$nextTick(() => {
        // const doReload = this.selectedConfig.protocol !== this.currentProtocol;
        // if (doReload) this.handleSSL(this.selectedConfig.protocol, newSource);
        shaka.polyfill.installAll();
        this.sources.forEach((source, index) => {
          this.initPlayer(source, source.id);
        });
      });
      //  this.$nextTick(() => {
      //   shaka.polyfill.installAll();
      //   this.sources.forEach((source, index) => {
      //     this.initPlayer(source, index);
      //   });
      // });
    },

    disposePlayers() {
      document.removeEventListener('webkitfullscreenchange', this.refreshPPM, false);
      document.removeEventListener('mozfullscreenchange', this.refreshPPM, false);
      document.removeEventListener('fullscreenchange', this.refreshPPM, false);
      document.removeEventListener('MSFullscreenChange', this.refreshPPM, false);

      this.sources.forEach((source, key) => {
        const doesPlayerExist = !!source.player;

        if (source.type === 'hls' && source.obj) source.obj.stopLoad();
        this.sources[key].obj = null;
        if (doesPlayerExist) {
          source.player.pause();
          source.player.removeEventListener('playing', this.onPlaing);
          source.player.removeEventListener('canplay', this.onCanPlay);
          source.player.removeEventListener('waiting', this.onWaiting);
        }
        this.$forceUpdate();
      });
      this.$forceUpdate();
    },

    async handleSSL(protocol, source) {
      window.history.replaceState(null, null, window.location.pathname);
      const newUrl = `${protocol}${this.currentLocation}?source=${source}`;
      console.log('handleSSL', protocol, source, newUrl);
      const confirmation = await this.$confirm(
        `To show you ${source} we need to switch protocols. You may need to login again after the switch. Do you want to continue?`,
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          center: true,
        },
      ).catch((error) => {
        console.log('error', error);
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      window.location.href = newUrl;
    },

    toggleOverlay(index) {
      const isCurrent = this.activeOverlay === index;

      if (isCurrent) {
        this.activeOverlay = null;
        return;
      }

      this.activeOverlay = index;
    },

    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;

      if ((document.fullScreenElement && document.fullScreenElement !== null)
        || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
          return;
        }
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
          return;
        }
        if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

    toogleFullscreenForViewPort(index) {
      const element = this.$refs[`pw-${index}`][0];
      if (
        document.fullscreenElement
        || document.webkitFullscreenElement
        || document.mozFullScreenElement
        || document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        }
        if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        }
        if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
        if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
        const isActive = this.activeViąewPort === index;
        if (!isActive) this.activateViewPort(index);
      }
      return false;
    },

    refreshPPM() {
      setTimeout(() => {
        this.sources.forEach((source, index) => {
          this.ppms[source.id].wap.updateMeretSize();
        });
      }, 200);
    },

    setLevel(index, lvl) {
      this.sourcesConfig[index].obj.currentLevel = lvl;
    },

    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },

    keyActions(event) {
      this.activateViewPort(this.keys[event.key]);
    },

    keySourceFullscreen(event) {
      this.toogleFullscreenForViewPort(this.keys[event.key]);
    },

    initHlsPlayer(conf, id) {
      const hls = new Hls(this.hlsconfig);
      const videoelem = this.$refs[`vp${id}`][0];
      hls.attachMedia(videoelem);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(conf.streamSource.address);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // videoelem.muted = true;
          videoelem.play();
          this.onPlayerLoaded(videoelem, 'hls', id, hls);
        });
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              this.hlsMediaIssue = true;
              hls.recoverMediaError();
              break;
            default:
              // cannot recover
              this.hlsIssue = true;
              hls.destroy();
              break;
          }
        }
      });
      // TODO: Store in list
      hls.on(Hls.Events.LEVEL_SWITCH, (event, data) => {
        const level = hls.levels[data.level];
        const metaelem = this.$refs[`${hls.media.id}-meta`];
        metaelem.innerHTML = `${(level.bitrate / 1000).toFixed(0)}kbps`;
      });
    },

    initDashPlayer(conf, index) {
      const videoelem = this.$refs[`vp${index}`][0];
      const shakap = new shaka.Player(videoelem);
      this.shakaPlayers[index] = shakap;
      videoelem.addEventListener('progress', this.onProgress);

      shakap.load(conf.address).then(() => {
        // videoelem.muted = true;
        shakap.setMaxHardwareResolution(600, 600);
        videoelem.play();
        this.onPlayerLoaded(videoelem, 'shaka', index, shakap);
      }).catch((e) => { console.log('Error: ', e); });
    },

    initSLDPPlayer(conf, id){
      console.log('initSLDPPlayer', conf, id);
      this.sldpPlayers[id] = SLDP.init({
        container: 'vp' + id,
        stream_url: conf.streamSource.address,
        adaptive_bitrate: {
          initial_rendition: '240p'
        },
        buffering:           500,
        height: 'parent',
        width: 'parent',
        autoplay: true,
      });
    },

    onProgress(ev) {
      // console.log('onProgress EV', ev);
      const { index } = ev.target.dataset;
      if (!index) return;
      const p = this.shakaPlayers[index];
      if (!p) return;
      const stats = p.getStats();
      this.sourcesConfig[index].progress = `${(stats.streamBandwidth / 1000).toFixed(0)}kbps`;
    },

    initPlayer(conf, id) {
      console.log('==== initPlayer : conf sss', conf);
      if (conf.streamSource?.address.startsWith('wss://')) {
        console.log('initSLDPPlayer', conf);
        this.initSLDPPlayer(conf, id);
        return;
      }
      if (conf.streamSource.type === 'HLS') this.initHlsPlayer(conf, id);
      if (conf.streamSource.type === 'DASH') this.initDashPlayer(conf, id);
    },

    activateViewPort(id) {
      const activeEl = document.querySelector('.player-wrapper.active');
      if (activeEl) activeEl.classList.remove('active');
      const isActive = this.activeViewPort === id;
      const isActiveSet = !!this.activeViewPort || this.activeViewPort === 0;

      if (isActiveSet) this.muteVideo(this.activeViewPort);

      if (isActive) {
        this.activeViewPort = null;
        return;
      }

      this.sources.forEach((source, i) => {
        console.log('this.sources.forEach', source);
        if (id === source.id) {
          console.log('this.sources.forEach 2', source);
          // source.player.muted = false;
          this.ppms[id].sourceNode.connect(this.ppms[id].audioCtx.destination);
        }
      });
      this.activeViewPort = id;
      document.querySelector('.' + 'pw-' + id).classList.add('active');;
    },

    togglePlayback(videoelem) {
      if (videoelem.paused) {
        videoelem.play();
      } else {
        videoelem.pause();
      }
    },

    togglePlaybackOnAllViewPorts() {
      this.sources.forEach((source) => this.togglePlayback(source.player));
    },

    onPlayerLoaded(videoelem, type, index, obj) {
      this.sourcesConfig[index] = {};
      Vue.set(this.sourcesConfig[index], 'player', videoelem);
      Vue.set(this.sourcesConfig[index], 'playerType', type);
      this.sourcesConfig[index].obj = obj;
      this.sourcesConfig[index].levels = [];
      this.sourcesConfig[index].audioTracks = [];
      const isHls = type === 'hls';
      if (isHls) {
        this.sourcesConfig[index].obj.currentLevel = this.indexOfSmallestQuality(obj.levels);
        this.sourcesConfig[index].levels = obj.levels;
        this.sourcesConfig[index].audioTracks = obj.audioTracks;
      }

      this.$nextTick(() => {
        videoelem.addEventListener('playing', this.onPlaing);
        videoelem.addEventListener('canplay', this.onCanPlay);
        videoelem.addEventListener('waiting', this.onWaiting);
        // videoelem.addEventListener('loadedmetadata', this.onLoadedMetadata);
        this.initPeakMeter(index);
      });
    },

    onCanPlay(event) {
      const { index } = event.target.dataset;
      this.sourcesConfig[index].loading = false;
    },

    onWaiting(ev) {
      const { index } = ev.target.dataset;
      this.sourcesConfig[index].loading = true;
    },

    onPlaying(ev) {
      const { index } = ev.target.dataset;
      this.sourcesConfig[index].loading = false;
      // ev.target.className = ev.target.className.replace("video-buffering", "");
    },

    initPeakMeter(id) {
      const myMeterElement = this.$refs[`vp${id}-meter`][0];
      const myAudio = this.sourcesConfig[id].player;

      const AudioContext = window.AudioContext || window.webkitAudioContext || false;
      if (!AudioContext) {
        // when Audiocontext can't be initialized mute video and return
        this.sourcesConfig[id].player.muted = true;
        return;
      }
      const audioCtx = new AudioContext();
      const wap = WebAudioPeakMeter();
      const sourceNode = audioCtx.createMediaElementSource(myAudio);
      const dest = audioCtx.createMediaStreamDestination();
      sourceNode.connect(dest);
      // sourceNode.connect(audioCtx.destination);
      const meterNode = wap.createMeterNode(sourceNode, audioCtx);
      if (!this.isMobileOnly) wap.createMeter(myMeterElement, meterNode, {});

      this.ppms[id] = {
        myMeterElement,
        myAudio,
        audioCtx,
        wap,
        sourceNode,
        dest,
        meterNode,
      };
    },

    muteVideo(index) {
      this.ppms[index].sourceNode.disconnect(this.ppms[index].audioCtx.destination);
    },

    indexOfSmallestQuality(a) {
      return a.reduce((lowest, next, index) => {
        console.log();
        return next.height < a[lowest].height ? index : lowest;
      }, 0);
    },
    test() {
      console.log('test');
      console.log(this.sources);
    },
  },
};
</script>

<style >
#multiview-intro {
  text-align: center;
}
#multiview-intro .home-logo {
  margin: 0 auto;
  padding: 100px;
  display: block;
}
#multiview-intro .el-button {
  border: 0px;
  color: #000;
  background: #ff9f31;
  margin-left: -4px;
  position: relative;
  top: 1px;
  z-index: 0;
  border-radius: 0 4px 4px 0;
}
#multiview-intro .welcome-screen {
  padding-top: 10vh;
}
#multiview-intro .welcome-screen h1 {
  color: #fff;
}
#multiview-intro .welcome-screen .support-message {
  max-width: 500px;
  margin: 0 auto;
}
#multiview-intro .welcome-screen .support-message .el-button {
  border-radius: 4px;
  min-width: 100px;
}
#multiview-intro .welcome-screen .support-message i {
  font-size: 33px;
  color: orange;
}
#multiview-intro .el-select .el-input__inner {
  background: #373e46;
  border-color: #373e46;
  color: #fff;
}

#multiview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 0px;
  grid-gap: 1px;
  margin: 0;
  overflow-x: hidden;
  min-height: calc(100vh - 50px);
}
#multiview .top-nav-space {
  grid-column: span 4;
}
#multiview > div {
  opacity: 1;
  position: relative;
  display: grid;
  grid-template-rows: 1fr 30px;
}
#multiview > div video {
  width: 100%;
}
#multiview > div .peak-meter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 56px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#multiview > div .overlay {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  padding: 15px;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#multiview > div .overlay.open {
  opacity: 1;
}
#multiview > div .buffering {
  color: red;
  text-transform: uppercase;
  position: absolute;
  right: 50%;
  /* bottom: 1px; */
  background: #fffdfd;
  font-weight: bold;
  padding: 0 10px;
  top: 5px;
  margin: 0 -50px;
  border-radius: 3px;
}
#multiview > div .title {
  text-align: center;
  font-weight: bold;
  color: #fff;
  line-height: 28px;
}
#multiview > div .shortcut {
  padding: 0 10px;
  margin-right: 10px;
  background: rgba(0, 0, 0, 0.4);
  float: right;
  top: 1px;
  line-height: 27px;
  position: absolute;
  z-index: 12;
  left: 2px;
  border-radius: 3px;
}
#multiview > div .video-fullscreen-btn {
  position: absolute;
  top: 2px;
  right: 50px;
  background: transparent;
  border: 0px;
  z-index: 2;
}
#multiview .peak-meter {
  display: none;
}
#multiview.show-ppm .peak-meter {
  display: block;
}
#multiview.show-ppm .overlay {
  margin-right: 50px;
}
#multiview.fullscreen {
  min-height: calc(100vh - 50px);
  overflow: hidden;
}
#multiview.fullscreen .title {
  text-align: center;
  font-weight: bold;
  color: #fff;
  line-height: 28px;
  /* margin-top: -77px; */
  position: absolute;
  bottom: 0;
  width: 100%;
}
#multiview.fullscreen video {
  height: 100%;
  padding-bottom: 30px;
  position: absolute;
}
#multiview .player-wrapper {
  border: 1px solid #000;
}
#multiview .player-wrapper.active {
  outline: 2px solid greenyellow;
  z-index: 2;
}
#multiview.grid-mobile {
  grid-template-columns: 1fr;
  grid-template-rows: auto 0px;
}
#multiview.grid-mobile > div {
  height: calc(100vh - 100px);
}
#multiview.grid-1x1 {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 0px;
}
#multiview.grid-1x1 .top-nav-space {
  grid-column: span 3;
}
#multiview.grid-2x1 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 0px;
}
#multiview.grid-2x1 .top-nav-space {
  grid-column: span 3;
}
#multiview.grid-3x3 {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 0px;
}
#multiview.grid-3x3 .top-nav-space {
  grid-column: span 3;
}
#multiview.grid-3x4 {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 0px;
}
#multiview.grid-3x4 .top-nav-space {
  grid-column: span 3;
}
#multiview.grid-3x2 {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 0px;
}
#multiview.grid-3x2 .top-nav-space {
  grid-column: span 3;
}
#multiview.grid-2x4 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 0px;
}
#multiview.grid-2x4 .top-nav-space {
  grid-column: span 2;
}
#multiview.grid-2x3 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 0px;
}
#multiview.grid-2x3 .top-nav-space {
  grid-column: span 2;
}
#multiview.grid-2x2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 0px;
}
#multiview.grid-2x2 .top-nav-space {
  grid-column: span 2;
}
#multiview.grid-5x5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 0px;
}
#multiview.grid-5x5 .top-nav-space {
  grid-column: span 5;
}

.mv-config.config {
  position: fixed;
  top: 6px;
  z-index: 100;
  left: 250px;
}
.mv-config.config button {
  margin-right: 16px;
}
.mv-config.config .issue-tooltip {
  color: #F44336;
  font-size: 18px;
  position: relative;
  top: 3px;
  margin-left: 10px;
}
.mv-config.config .el-input__inner {
  -webkit-appearance: none;
  background-color: transparent;
  background-image: none;
  border-radius: 4px;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.mv-config.config .el-select .el-input__inner {
  cursor: pointer;
  padding-right: 35px;
}

.el-select:hover .el-input__inner {
  border-color: transparent;
  background: #000;
}

.source-options {
  position: absolute;
  left: 0;
  bottom: -2px;
}
.source-options button {
  background: transparent;
  color: #fff;
  font-size: 14px;
  border: 0px;
  border-radius: 0px;
}
.source-options button:hover {
  color: #ffffff;
  border-color: transparent;
  background-color: #ecf5ff;
  border-radius: 0;
  background: #ff9f31;
}

.ma-popover {
  background: #fff;
  min-width: 150px;
  border-radius: 4px;
  text-align: left;
}
.ma-popover .ma-popover-content {
  display: none;
}
.ma-popover:hover .ma-popover-content {
  display: block;
  padding: 5px 15px;
}
.ma-popover .p-title {
  margin-bottom: 5px;
  opacity: 0.5;
  color: #000;
}
.ma-popover .link {
  color: #3F51B5;
  cursor: pointer;
  line-height: 30px;
  padding: 0 10px;
  margin: 0 -10px;
  border-radius: 3px;
  text-align: left;
  font-weight: normal;
}
.ma-popover .link:hover {
  background: #FF9800;
  color: #000;
}
.ma-popover .link.active {
  color: #000;
  font-weight: bold;
}

.video-options {
  position: absolute;
  bottom: -1px;
  left: 4px;
  line-height: 40px;
  padding: 0px;
  width: 40px;
}

.mobile .source-select .el-select {
  width: 100%;
}
.mobile .source-select .el-select .el-input__inner {
  border-radius: 0px;
  background: #373e46;
  border-color: #373e46;
  color: #fff;
}
.mobile #multiview .player-wrapper {
  overflow: hidden;
}
.mobile #multiview .player-wrapper .title {
  font-size: 10px;
}
.mobile #multiview .player-wrapper .shortcut {
  background: transparent;
  z-index: 0;
  display: none;
}
.mobile #multiview .player-wrapper .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  font-size: 11px;
  border-radius: 0;
}
.mobile #multiview .player-wrapper .video-fullscreen-btn {
  right: auto;
  left: -6px;
}
.mobile #multiview .player-wrapper .source-options .bly-info {
  position: absolute;
  left: -5px;
  bottom: -5px;
}
.mobile #multiview .player-wrapper .source-options .bly-info:hover {
  background: transparent;
  color: #ff6600;
}
</style>
