import { API, graphqlOperation, Auth } from 'aws-amplify';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import * as download from 'downloadjs';
import Vue from 'vue';
import moment from 'moment';
import * as qlqueries from '../graphql/queries';
import * as scheduleQlmutations from '../graphql/scheduleMutations';


// import * as qlsubscriptions from '../graphql/subscriptions';
function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function fetchData(input, nextToken = null) {
  const auth = await Auth.currentSession();
  const isSysAdmin = auth.accessToken.payload['cognito:groups'].indexOf('SysAdmin') !== -1;
  console.log('fetchEventsByDate', isSysAdmin, auth);
  input.nextToken = nextToken;
  const query = isSysAdmin ? qlqueries.eventsByStartTimeAdmin : qlqueries.eventsByStartTime;
  console.log('fetchData', qlqueries.eventsByStartTime);
  const result = await API.graphql(
    graphqlOperation(query, input),
  );
  return result.data;
}

function handleError(error) {
  const hasData = !!error.data && !!error.data.eventsByStartTime;
  let eventsByStartTime = null;

  if (hasData) {
    const { data } = error;
    eventsByStartTime = {
      ...data.eventsByStartTime,
      items: data.eventsByStartTime.items.filter(Boolean),
      nextToken: data.eventsByStartTime.nextToken,
    };
  }
  console.log('handleError', error, eventsByStartTime);
  const errorTypes = [...new Set(error.errors.map((el) => el.errorType))];
  const isUnauthorizedOnly = errorTypes.length === 1 && errorTypes.includes('Unauthorized');

  if (!isUnauthorizedOnly) {
    this._vm.$message.error(
      { message: 'Oops something went wrong. Can\'t retrieve some of the bookings' },
    );
  }

  return { error: true, message: error.errors, eventsByStartTime, nextToken: eventsByStartTime.nextToken };
}

const state = {
  name: 'Schedule',
  listEvents: {
    items: [],
  },
  events: [],
  inputs: {},
  outputs: {},
  outputProfiles: {},
  audioProfiles: {},
  showAllEvents: false,
  ShowAllActiveEvents: false,
  showCanceled: false,

  showCreateEventDialog: false,
  showRescheduleEventDialog: false,

  selectedEvent: null,

  eventStatuses: [
    'APPROVED',
    'COMPLETED',
    'IN_PROGRESS',
    'LINEUP',
    'SCHEDULED',
  ],

  eventTypes: [
    'OU',
    'SYSTEM',
  ],

  OTTPlatformType: [
    'FACEBOOK',
    'GENERIC',
    'HLS',
    'RTMP',
    'TWITCH',
    'TWITTER',
    'YOUTUBE',
  ],

  eventStartType: [
    'EVENT_NORMAL_START_STOP',
    'EVENT_MANUAL_START',
    'EVENT_MANUAL_STOP',
    'EVENT_MANUAL_START_STOP',
  ],

  eventReports: {

  },

  scheduleUIConfig: {
    eventColorsSimple: {
      COMPLETED: '#0033ff',
      CONFIRMED: 'lightgreen',
      CLIENT_CONFIRMED: '#ff9000',
      RECEIVED: '#EA3CF7',
      LIVE: '#0f0 ',
      PENDING: '#FFFE54',
      CANCELLED: '#999999',
      LATE_CANCELLED: '#8b0000',
      POSTPONED: '#999999',
      FAILED: '#f00',
      AWAITING_SIGNAL: '#6cdf6c',
    },
    eventColors: {
      APPROVED: '#caab51',
      COMPLETED: '#0033ff',
      COMPLETE: '#4caf50',
      CONFIRMED: 'lightgreen',
      RECEIVED: '#EA3CF7',
      CLIENT_CONFIRMED: '#ff9000',
      IN_PROGRESS: '#ff9800',
      LIVE: '#0f0 ',
      LINEUP: '#ff9800',
      SCHEDULED: '#2196F3',
      SUBMITTED: '#2196F3',
      PENDING: '#FFFE54',
      CANCELLED: '#999999',
      CANCELED: '#999999',
      REJECTED: '#999999',
      TX_COMMENT: '#FF9800',
      POSTPONED: '#999999',
      LATE_CANCELLED: '#8b0000',
      FAILED: '#f00',
      AWAITING_SIGNAL: '#6cdf6c',
    },
  },
  statusDisplayName: {
    PENDING: 'PENCIL BOOKING',
    CLIENT_CONFIRMED: 'CLIENT CONFIRMED',
    AWAITING_SIGNAL: 'AWAITING SIGNAL',
    RECEIVED: 'RECEIVED',
    CONFIRMED: 'CONFIRMED',
    LIVE: 'LIVE',
    COMPLETED: 'COMPLETED',
    POSTPONED: 'POSTPONED',
    CANCELLED: 'CANCELLED',
    LATE_CANCELLED: 'LATE CANCELLED',
    FAILED: 'FAILED',
  },

  viewsSettings: {
    listWeek: {
      titleFormat: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      },
    },
    timeGrid: {
      slotDuration: '00:30:00',
      allDaySlot: false,
      nowIndicator: true,
      displayEventEnd: true,
      titleFormat: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hourCycle: 'h23',
      },
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'short',
      },
      columnHeaderFormat: {
        weekday: 'long',
        month: '2-digit',
        day: '2-digit',
        omitCommas: true,
      },
    },
    timeGrid3Day: {
      type: 'timeGridDay',
      duration: { days: 3 },
      buttonText: '3 day',
      nowIndicator: true,
      titleFormat: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hourCycle: 'h23',
      },
    },
  },
  loading: false,
  loadings: {
    calendar: false,
    save: false,
    actionOne: false,
    actionTwo: false,
    delete: false,
  },
  bookingClone: null,
  scheduleDateRange: {},
  eventsWithTheSameOTT: [],
  eventsStats: {},
  listEventsAgenda: [],
  listEventsAgendaExtended: [],
};

const getters = {
};

const mutations = {
  setStats: (state, payload) => {
    Vue.set(state, 'eventsStats', payload);
  },
  setDateRange: (state, payload) => {
    Vue.set(state, 'scheduleDateRange', payload);
  },
  setEventsWithTheSameOTT: (state, payload) => {
    Vue.set(state, 'eventsWithTheSameOTT', payload);
  },
  setEventListAgenda: (state, payload) => {
    const sortedList = payload.items.map((event) => {
      const newEvent = {
        ...event,
      };
      newEvent.audioMapping.items = event.audioMapping.items.sort((a, b) => {
        if (a.channelNo < b.channelNo) { return -1; }
        if (a.channelNo > b.channelNo) { return 1; }
        return 0;
      });
      return newEvent;
    });
    const mappedEvents = sortedList
      .map((event) => {
        const nowUtc = moment().utc();
        const isCanceled = event.status === 'CANCELLED'
            || event.status === 'DELETED'
            || event.status === 'REJECTED';
        const isActive = event.status !== 'COMPLETED'
          && event.status !== 'FAILED'
          && event.status !== 'POSTPONED';
        const isToday = moment(nowUtc).isSame(moment.utc(event.startTime), 'day');
        const highlight = isToday && (event.status === 'CLIENT_CONFIRMED' || event.status === 'RECIVED' || event.status === 'AWAINTING_SIGNAL');
        const isOptus = 'otu' === event.customer.code && 'otu' === event.account.code;
        const isDownlink = event.input && event.input.type === 'DOWNLINK';
        const showRemap = isOptus && isDownlink;
        let inputName = event.input ? event.input.name : '';
        const inputOriginalName = event.input ? event.input.name : '';
        inputName += event.inputDetails ? `<div class="muted">${event.inputDetails}</div>` : '';
        const overrideInputName = event.inputOverride ? event.inputOverride.name : '';

        const newEvent = {
          ...event,
          originalEventObj: event,
          isActive,
          isCanceled,
          isToday,
          isOptus,
          isDownlink,
          highlight,
          showRemap,
          inputName,
          inputOriginalName,
          overrideInputName,
          isPastServiceTime: moment(nowUtc).isAfter(moment(event.serviceEndTime)),
        }
      });
    Vue.set(state, 'listEventsAgenda', sortedList);
    Vue.set(state, 'listEventsAgendaExtended', mappedEvents);
  },
  setEventList: (state, payload) => {
    const sortedList = payload.items.map((event) => {
      const newEvent = {
        ...event,
      };
      newEvent.audioMapping.items = event.audioMapping.items.sort((a, b) => {
        if (a.channelNo < b.channelNo) { return -1; }
        if (a.channelNo > b.channelNo) { return 1; }
        return 0;
      });
      return newEvent;
    });

    const list = {
      ...payload,
      items: sortedList,
    };
    Vue.set(state, 'listEvents', list);
  },
  refreshEvents: (state, payload) => {
    let numberOfNewBookings = 0;
    payload.items.forEach((item) => {
      const index = state.listEvents.items.findIndex((el) => item.id === el.id);
      if (index >= 0) {
        if (state.listEvents.items[index].version !== item.version) state.listEvents.items[index] = item;
      } else {
        state.listEvents.items.push(item);
        numberOfNewBookings += 1;
      }
    });

    // if (numberOfNewBookings > 0) this._vm.$message({ message: `They are ${numberOfNewBookings} new bookings` });
  },

  setSelectedEventProperty: (state, payload) => {
    Vue.set(state.selectedEvent, payload.property, payload.value);
  },

  addEvent: (state, payload) => {
    console.log('addEvent', payload);
    Vue.set(state.events, payload.id, payload);
    if (!state.listEvents.items) state.listEvents.items = [];
    state.listEvents.items.push(payload);
    if (!state.listEventsAgenda) state.listEventsAgenda = [];
    state.listEventsAgenda.push(payload);

    console.log('addEvent 2', payload);
  },

  updateEvent: (state, payload) => {
    state.events[payload.id] = payload;
    console.log('updateEvent');
    Vue.set(state.events, payload.id, payload);
    state.listEvents.items.forEach((event, index) => {
      const shouldUpdate = event.id === payload.id;
      console.log('updateEvent', shouldUpdate, event);
      if (shouldUpdate) state.listEvents.items[index] = payload;
    });

    state.listEventsAgenda.forEach((event, index) => {
      const shouldUpdate = event.id === payload.id;
      console.log('updateEvent', shouldUpdate, event);
      if (shouldUpdate) state.listEventsAgenda[index] = payload;
    });

    // update selected event if it is the same as updated
    const isSelectedEvent = payload.id === state.selectedEvent.id;
    if (isSelectedEvent) Vue.set(state, 'selectedEvent', payload);

    state.listEvents.items = state.listEvents.items.map((event) => {
      const shouldUpdate = event.id === payload.id;
      if (!shouldUpdate) return event;
      return payload;
    });
    state.listEventsAgenda = state.listEventsAgenda.map((event) => {
      const shouldUpdate = event.id === payload.id;
      if (!shouldUpdate) return event;
      return payload;
    });
  },

  updateEventAgendaMutation: (state, payload) => {
    state.events[payload.id] = payload;
    console.log('updateEventAgenda', payload, state.selectedEvent);
    Vue.set(state.events, payload.id, payload);
    state.listEventsAgenda.forEach((event, index) => {
      const shouldUpdate = event.id === payload.id;
      console.log('updateEventAgenda', shouldUpdate, event);
      if (shouldUpdate) state.listEventsAgenda[index] = payload;
    });

    // update selected event if it is the same as updated
    
    const isSelectedEvent = payload.id === state.selectedEvent.id;
    if (isSelectedEvent) Vue.set(state, 'selectedEvent', payload);

    state.listEventsAgenda = state.listEventsAgenda.map((event) => {
      const shouldUpdate = event.id === payload.id;
      if (!shouldUpdate) return event;
      return payload;
    });
  },

  setShowAllEvents: (state, payload) => {
    Vue.set(state, 'showAllEvents', payload);
  },
  setShowAllActiveEvents: (state, payload) => {
    Vue.set(state, 'showAllActiveEvents', payload);
  },

  setShowCreateEventDialog: (state, payload) => {
    console.log('setShowCreateEventDialog', payload);
    Vue.set(state, 'showCreateEventDialog', payload);
  },
  setBookingClone: (state, payload) => {
    Vue.set(state, 'bookingClone', JSON.parse(JSON.stringify(payload)));
  },

  setRescheduleEventDialog: (state, payload) => {
    console.log('setRescheduleEventDialog', payload);
    Vue.set(state, 'showRescheduleEventDialog', payload);
  },

  setShowCanceled: (state, payload) => {
    Vue.set(state, 'showCanceled', payload);
  },

  deleteEvent: (state, id) => {
    Vue.delete(state.events, id);
    let index = -1;
    state.listEvents.items.forEach((event, i) => {
      if (event.id === id) index = i;
    });
    state.listEvents.items.splice(index, 1);
  },

  deleteEventAudioMapping: (state, payload) => {
    state.selectedEvent.audioMapping.items = state.selectedEvent.audioMapping.items
      .filter((channel) => {
        const shouldDelete = channel.id === payload.id;
        return !shouldDelete;
      });
  },

  updateEventAudioChannel: (state, payload) => {
    state.selectedEvent.audioMapping.items = state.selectedEvent.audioMapping.items
      .map((channel) => {
        const shouldUpdate = channel.id === payload.id;
        if (shouldUpdate) return payload;
        return channel;
      });
  },

  createEventAudioChannel: (state, payload) => {
    state.selectedEvent.audioMapping.items.push(payload);
  },

  setActiveEvent: (state, payload) => {
    Vue.set(state, 'selectedEvent', payload);
  },

  setInputs: (state, payload) => {
    Vue.set(state, 'inputs', payload);
  },

  setOutputs: (state, payload) => {
    Vue.set(state, 'outputs', payload);
  },

  setOutputProfiles: (state, payload) => {
    Vue.set(state, 'outputProfiles', payload);
  },

  setAudioProfiles: (state, payload) => {
    Vue.set(state, 'audioProfiles', payload);
  },

  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },

  setReports: (state, payload) => {
    Vue.set(state, 'eventReports', payload);
  },

  toggleLoading: (state, payload) => {
    state.loadings[payload] = !state.loadings[payload];
  },
  setDummy: (state, payload) => {
    console.log('DUMMY', payload, state);
  },
};

const actions = {
  async fetchEvents({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listEvents,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setEventList', data.listEvents);
      return data.listEvents;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get events' },
      );
      return { error: true, message: error.errors };
    }
  },

  async fetchEventsByDate({ commit }, payload) {
    commit('toggleLoading', 'calendar');
    let nextToken = null;
    let allEvents = [];

    const input = {
      type: 'OU',
      limit: 401,
      sortDirection: 'ASC',
      startTime: {
        between: [payload.start, payload.end],
      },
      filter: payload.showCompleted ? null : { status: { ne: 'COMPLETED' } }
    };
    try {
      do {
        const data = await fetchData(input, nextToken);
        allEvents = allEvents.concat(data.eventsByStartTime.items);
        nextToken = data.eventsByStartTime.nextToken;
      } while (nextToken !== null);
      commit('setEventList', {items: allEvents});
      return allEvents;
    } catch (error) {
      const { error: isError, message, eventsByStartTime, nextToken } = handleError(error);

      if (eventsByStartTime) {
        allEvents = allEvents.concat(eventsByStartTime.items);
        if (nextToken) {
         try {
          const data = await fetchData(input, nextToken);
          allEvents = allEvents.concat(data.eventsByStartTime.items);
         } catch (error) {
          const { error: isError, message, eventsByStartTime, nextToken } = handleError(error);
            if (eventsByStartTime) {
              allEvents = allEvents.concat(eventsByStartTime.items);
            }
         }
        }
        commit('setEventList', {items: allEvents});
      }

      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get bookings' },
      );

      return { error: isError, message };
    } finally {
      commit('toggleLoading', 'calendar');
    }
  },
  async fetchEventsByDateLazyLoading({ commit }, payload) {
    console.log('fetchEventsByDateLazyLoading', payload);

    commit('toggleLoading', 'calendar');
    try {
      let events = [];
      let nextToken = null;
      do {
        const input = {
          type: 'OU',
          limit: 250,
          sortDirection: 'ASC',
          startTime: {
            between: [moment.utc(payload.startDate).subtract(1, 'day'), moment.utc(payload.endDate).add(1, 'day')],
          },
          nextToken,
        };

        console.log('getEvents 2', payload.showCompleted);
        if (!payload.showCompleted) {
          input.filter = {
            status: {
              ne: 'COMPLETED'
            }
          }
        }
        console.log('getEvents 3', payload);
        // if (!payload.hideCompleted) input.filter = {status: { ne: 'COMPLETED'}}
        const result = await API.graphql(
          graphqlOperation(qlqueries.eventsByStartTimeExtended, input)
        );

        const { data } = result;
        nextToken = data.eventsByStartTime.nextToken;
        events = events.concat(data.eventsByStartTime.items);
        console.log('fetchEventsByDate 1', data.eventsByStartTime);
      } while (nextToken)
      console.log('fetchEventsByDate 2', { items: events });
      commit('toggleLoading', 'calendar');
      commit('setEventListAgenda', {items: events});
      return events;
    } catch (error) {

      console.log('fetchEventsByDateLazyLoading error', error);
      const hasData = !!error.data && !!error.data.eventsByStartTime;

      if (hasData) {
        const { data } = error;
        const eventsByStartTime = {
          ...data.eventsByStartTime,
          items: data.eventsByStartTime.items.filter((el) => el),
        };

        console.debug('Oops something went wrong. Can\'t retrieve some of the booking info', data.eventsByStartTime.items.filter((el) => el), data.eventsByStartTime);
        commit('setEventListAgenda', {items: eventsByStartTime.items });
        console.log('fetchEventsByDateLazyLoading 3', { items: eventsByStartTime.items });
        const errorTypes = [...new Set(error.errors.map((el) => el.errorType))];
        const isUnauthorizedOnly = errorTypes.length === 1 && errorTypes.indexOf('Unauthorized') !== -1;
        if (!isUnauthorizedOnly) {
          this._vm.$message.error(
            { message: 'Oops something went wrong. Can\'t retrieve some of the bookings' },
          );
        }
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get bookings' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('toggleLoading', 'calendar');
    }
  },


  async fetchEventsByDateAgenda({ commit }, payload) {
    console.log('fetchEventsByDateAgenda', payload);
    commit('toggleLoading', 'calendar');
    try {
      const input = {
        type: 'OU',
        limit: 400,
        sortDirection: 'ASC',
        startTime: {
          between: [payload.start, payload.end],
        },
      }
      if (!payload.showCompleted) {
        input.filter = {
          status: {
            ne: 'COMPLETED'
          }
        }
      }
      const result = await API.graphql(
        graphqlOperation(qlqueries.eventsByStartTime,
          {
            type: 'OU',
            limit: 400,
            sortDirection: 'ASC',
            startTime: {
              between: [payload.start, payload.end],
            },
          }),
      );
      const { data } = result;
      commit('setEventListAgenda', data.eventsByStartTime);
      return data.eventsByStartTime;
    } catch (error) {
      const hasData = !!error.data && !!error.data.eventsByStartTime;

      if (hasData) {
        const { data } = error;
        const eventsByStartTime = {
          ...data.eventsByStartTime,
          items: data.eventsByStartTime.items.filter((el) => el),
        };

        console.debug('Oops something went wrong. Can\'t get some of the booking info', data.eventsByStartTime.items.filter((el) => el), data.eventsByStartTime);
        commit('setEventListAgenda', eventsByStartTime);
        const errorTypes = [...new Set(error.errors.map((el) => el.errorType))];
        const isUnauthorizedOnly = errorTypes.length === 1 && errorTypes.indexOf('Unauthorized') !== -1;
        if (!isUnauthorizedOnly) {
          this._vm.$message.error(
            { message: 'Oops something went wrong. Can\'t retrieve some of the bookings' },
          );
        }
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get bookings' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('toggleLoading', 'calendar');
    }
  },

  async fetchEventsByDateExtended({ commit }, payload) {
    console.log('fetchEventsByDateExtended', payload);
    commit('toggleLoading', 'calendar');
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.eventsByStartTimeExtended,
          {
            type: 'OU',
            limit: 200,
            sortDirection: 'ASC',
            startTime: {
              between: [payload.start, payload.end],
            },
          }),
      );
      const { data } = result;
      commit('setEventList', data.eventsByStartTime);
      return data.eventsByStartTime;
    } catch (error) {
      const hasData = !!error.data && !!error.data.eventsByStartTime;
      if (hasData) {
        const { data } = error;
        commit('setEventList', data.eventsByStartTime);
        console.debug('Oops something went wrong. Can\'t get some of the booking info', data.eventsByStartTime);
        const errorTypes = [...new Set(error.errors.map((el) => el.errorType))];
        const isUnauthorizedOnly = errorTypes.length === 1 && errorTypes.indexOf('Unauthorized') !== -1;
        if (!isUnauthorizedOnly) {
          this._vm.$message.error(
            { message: 'Oops something went wrong. Can\'t retrieve some of the bookings' },
          );
        }
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get bookings' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('toggleLoading', 'calendar');
    }
  },

  async refreshEvents({ commit }, payload) {
    console.log('refreshEvents', payload);
    commit('toggleLoading', 'refresh');
    try {
      const input = {
        type: 'OU',
        limit: 400,
        sortDirection: 'ASC',
        startTime: {
          between: [payload.start, payload.end],
        },
      }
      if (!payload.showCompleted) {
        input.filter = {
          status: {
            ne: 'COMPLETED'
          }
        }
      }
      const result = await API.graphql(
        graphqlOperation(qlqueries.eventsByStartTime,
          input),
      );
      const { data } = result;
      commit('refreshEvents', data.eventsByStartTime);
      return data.eventsByStartTime;
    } catch (error) {
      const hasData = !!error.data && !!error.data.eventsByStartTime;
      if (hasData) {
        const { data } = error;
        commit('refreshEvents', data.eventsByStartTime);
        console.debug('Oops something went wrong. Can\'t get some of the booking info', data.eventsByStartTime);
        const errorTypes = [...new Set(error.errors.map((el) => el.errorType))];
        const isUnauthorizedOnly = errorTypes.length === 1 && errorTypes.indexOf('Unauthorized') !== -1;
        if (!isUnauthorizedOnly) {
          this._vm.$message.error(
            { message: 'Oops something went wrong. Can\'t retrieve some of the bookings' },
          );
        }
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get bookings' },
      );

    console.log('refreshEvents error', error);
      return { error: true, message: error.errors };
    } finally {
      commit('toggleLoading', 'refresh');
    }
  },

  async fetchInputs({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listInputs,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setInputs', data.listInputs);
      return data.listInputs;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get inputs' },
      );
      return { error: true, message: error.errors };
    }
  },

  async fetchOutputs({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listOutputs,
          {
            limit: 1500,
          }),
      );

      const resultOutputProfiles = await API.graphql(
        graphqlOperation(qlqueries.listOutputProfiles,
          {
            limit: 1000,
          }),
      );

      const { data } = result;

      commit('setOutputs', data.listOutputs);

      const smEndpoints = data.listOutputs.items.flatMap(output => JSON.parse(output.smEndpoints) || []);
      console.log('smEndpoints', smEndpoints);
      commit('setOutputProfiles', resultOutputProfiles.data.listOutputProfiles);
      return data.listOutputs;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listOutputProfiles;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listOutputProfiles, property: 'outputProfiles' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the output profiles' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get outputs' },
      );
      return { error: true, message: error.errors };
    }
  },

  async listEventReports({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listEventReports,
          {
            limit: 2000,
          }),
      );

      const { data } = result;

      const filterData = data.listEventReports.items.filter((report) => !!report.event);
      console.log('listEventReports', data, filterData);
      commit('setReports', filterData);
      return filterData;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get event reports' },
      );
      return { error: true, message: error.errors };
    }
  },

  async listAudioMappingProfiles({ commit }) {
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listAudioMappingProfiles),
      );
      const { data } = result;
      commit('setAudioProfiles', data.listAudioMappingProfiles);
      return data.listAudioMappingProfiles;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get audio profiles' },
      );
      return { error: true, message: error.errors };
    }
  },

  async addBooking({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.addBooking,
          { input: payload },
        ),
      );

      const { data } = result;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: data.addBooking.id },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('addEvent', eventData);
      this._vm.$message({ message: 'Booking added' });
      return data.addBooking;
    } catch (error) {
      console.log('error add booking', error);
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t create booking' },
      );
      return { error: true, message: error };
    } finally {
      commit('setLoading', false);
    }
  },

  async createEvent({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.createEvent,
          { input: payload },
        ),
      );

      const { data } = result;

      payload.audioMappings.forEach(async (audio) => {
        const newAudioChannel = {
          ...audio,
          eventAudioMappingId: data.createEvent.id,
        };
        await API.graphql(
          graphqlOperation(
            scheduleQlmutations.createEvent,
            { input: newAudioChannel },
          ),
        );
      });
      commit('addEvent', data.createEvent);
      this._vm.$message({ message: 'Event added' });
      return data.createEvent;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t create event' },
      );
      return { error: true, message: error.errors[0] };
    }
  },

  async createDownlinkConfig({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.createInputDownlinkConfig,
          { input: payload },
        ),
      );

      const { data } = result;

      commit('dummyCommit', data.createInputDownlinkConfig);
      // this._vm.$message({ message: 'Event added' });
      return data.createInputDownlinkConfig;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t update event' },
      );
      return { error: true, message: error.errors[0] };
    }
  },

  async deleteEvent({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.deleteEvent,
          {
            input: {
              id: payload.id,
              expectedVersion: payload.version,
            },
          },
        ),
      );
      const { data } = result;
      commit('deleteEvent', payload);
      this._vm.$message({ message: 'Booking deleted' });
      return data.deleteEvent;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t delete the booking' },
      );
      return { error: true, message: error.errors };
    }
  },

  async getEvent({ commit }, payload) {
    commit('setLoading', true);
    try {

      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      // this._vm.$message(
      //   { message: 'Booking updated' },
      // );
      return eventData;
    } catch (error) {
      const hasData = !!error.data && !!error.data.getEvent;
      console.log('hasData', hasData);
      if (hasData) {
        const { data } = error;
        // this._vm.$message.error(
        //   { message: 'Oops something went wrong. Can\'t update booking' },
        // );
        return data.getEvent;
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get booking' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateEvent({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.updateEvent,
          { input: payload },
        ),
      );
      const { data } = result;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: data.updateEvent.id },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      this._vm.$message(
        { message: 'Booking updated' },
      );
      return eventData;
    } catch (error) {
      console.log('error update booking', error);
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t update booking' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async updateEventAgenda({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.updateEvent,
          { input: payload },
        ),
      );
      const { data } = result;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: data.updateEvent.id },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEventAgendaMutation', eventData);
      this._vm.$message(
        { message: 'Booking updated' },
      );
      return eventData;
    } catch (error) {
      console.log('error update booking', error);
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t update booking' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async eventChangeStatus({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.eventChangeStatus,
          { input: payload },
        ),
      );
      const { data } = result;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: data.eventChangeStatus.id },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      this._vm.$message(
        { message: 'Booking updated' },
      );
      return data.eventChangeStatus;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t update booking' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateEventClean({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.updateEvent,
          { input: payload },
        ),
      );
      const { data } = result;
      // commit('updateEvent', eventData);
      this._vm.$message(
        { message: 'Booking updated' },
      );
      return data.updateEvent;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t update booking' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async eventInputChange({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.eventInputChange,
          { input: payload },
        ),
      );
      const { data } = result;
      await timeout(3000);
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      this._vm.$message(
        { message: 'Booking updated' },
      );
      return data.eventInputChange;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t update booking' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async eventOutputAdd({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.eventOutputAdd,
          { input: payload },
        ),
      );
      const { data } = result;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      this._vm.$message(
        { message: 'Output added' },
      );
      return data.eventOutputAdd;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t add output' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async eventOutputProfileChange({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.eventOutputProfileChange,
          { input: payload },
        ),
      );
      const { data } = result;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      this._vm.$message(
        { message: 'Output added' },
      );
      return eventData;
    } catch (error) {
      console.log('eventOutputProfileChange error', error);
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t add output' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async eventOutputProfileChangeAgenda({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.eventOutputProfileChange,
          { input: payload },
        ),
      );
      const { data } = result;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEventAgendaMutation', eventData);
      this._vm.$message(
        { message: 'Output added' },
      );
      return eventData;
    } catch (error) {
      console.log('eventOutputProfileChange error', error);
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t add output' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async eventOutputRemove({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.eventOutputRemove,
          { input: payload.input },
        ),
      );
      const { data } = result;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      this._vm.$message(
        { message: 'Output deleted' },
      );
      return data.eventOutputRemove;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t delete output' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async rescheduleAgendaEvent({ commit }, payload) {
    commit('setLoading', true);
    try {
      console.log('rescheduleEvent agenda', qlqueries.getEvent);
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.eventReschedule,
          { input: payload },
        ),
      );
      const { data } = result;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;

      console.log('rescheduleEvent agenda updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      this._vm.$message(
        { message: 'Booking updated' },
      );
      return eventData;
    } catch (error) {
      // this._vm.$message.error(
      //   { message: 'Oops something went wrong. Can\'t update booking' },
      // );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async rescheduleEvent({ commit }, payload) {
    commit('setLoading', true);
    try {
      console.log('rescheduleEvent', payload);
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.eventReschedule,
          { input: payload },
        ),
      );
      const { data } = result;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;

      console.log('rescheduleEvent updateEvent', eventData);
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      this._vm.$message(
        { message: 'Booking updated' },
      );
      return eventData;
    } catch (error) {
      // this._vm.$message.error(
      //   { message: 'Oops something went wrong. Can\'t update booking' },
      // );
      console.log('rescheduleEvent error', error);
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteEventAudioChannel({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.deleteEventAudioChannel,
          { input: { id: payload } },
        ),
      );
      const { data } = result;
      commit('deleteEventAudioMapping', data.deleteEventAudioChannel);
      return data.deleteEventAudioChannel;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t delete the audio channel' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createEventAudioChannel({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.createEventAudioChannel,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('createEventAudioChannel', data.createEventAudioChannel);
      this._vm.$message(
        { message: 'Event updated' },
      );
      return data.createEventAudioChannel;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t create audio channel' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateEventAudioChannel({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.updateEventAudioChannel,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateEventAudioChannel', data.updateEventAudioChannel);
      this._vm.$message(
        { message: 'Event updated' },
      );
      return data.updateEventAudioChannel;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t update audio channel' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateWowzaTarget({ commit }, {payload, id}) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.updateWowzaTarget,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('updateWowzaTarget', data.updateWowzaTarget);
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      this._vm.$message(
        { message: 'Item saved' },
      );
      return data.updateWowzaTarget;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t save item' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateWowzaConfig({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.updateEventActionConfigWowza,
          { input: payload.input },
        ),
      );
      const { data } = result;
      commit('updateEventActionConfigWowza', data.updateEventActionConfigWowza);
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);

      this._vm.$message(
        { message: 'Autostart set' },
      );
      return data.updateEventActionConfigWowza;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t enable autostart' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async setStreamKey({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.setStreamKey,
          payload,
        ),
      );
      const { data } = result;
      // commit('setStreamKey', data.setStreamKey);
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);

      this._vm.$message(
        { message: 'Stream key saved' },
      );
      return eventData;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t save stream key' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async setEventOutputMapOverride({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.updateEventOutputMap,
          {
            input: {
              id: payload.id,
              outputOttApplicationOverride: payload.override,
            },
          },
        ),
      );
      const { data } = result;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);

      this._vm.$message(
        { message: 'Output overrided' },
      );
      return data.setStreamKey;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t save ovverride' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createAudioMappingProfile({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.createAudioMappingProfile,
          {
            input: payload,
          },
        ),
      );
      const { createAudioMappingProfile } = result.data;
      return createAudioMappingProfile.id;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t create profile' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createAudioMapItem({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.createAudioMapItem,
          {
            input: payload,
          },
        ),
      );
      const { createAudioMapItem } = result.data;
      return createAudioMapItem.id;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t create profile' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async eventManualStart({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.eventManualStart,
          { eventId: payload },
        ),
      );
      const { data } = result;
      commit('eventManualStart', data.eventManualStart);
      this._vm.$message({ message: 'Event started' });
      return data.eventManualStart;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t start the event' },
      );
      return { error: true, message: error.errors };
    }
  },
  async eventManualStop({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.stopEvent,
          { eventId: payload },
        ),
      );
      const { data } = result;
      commit('eventManualStart', data.eventManualStart);
      this._vm.$message({ message: 'Event stopped' });
      return data.eventManualStart;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t stop the event' },
      );
      return { error: true, message: error.errors };
    }
  },

  async startStreamTarget({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.startStreamTarget,
          { streamTargetId: payload.streamTargetId, eventId: payload.eventId },
        ),
      );
      const { startStreamTarget } = result.data;

      if (startStreamTarget.success) {
        this._vm.$message(
          { message: 'Stream started' },
        );
      }
      if (!startStreamTarget.success) {
        this._vm.$message.error(
          { message: startStreamTarget.message },
        );
      }
      commit('setLoading', false);
      return startStreamTarget;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t start stream' },
      );
      return { error: true, message: error.errors };
    }
  },

  async stopStreamTarget({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.stopStreamTarget,
          { streamTargetId: payload.streamTargetId, eventId: payload.eventId },
        ),
      );
      const { data } = result;
      const { stopStreamTarget } = result.data;

      if (stopStreamTarget.success) {
        this._vm.$message(
          { message: 'Stream stopped' },
        );
      }
      if (!stopStreamTarget.success) {
        this._vm.$message.error(
          { message: stopStreamTarget.message },
        );
      }
      commit('setLoading', false);
      return data.stopStreamTarget;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t stop stream target' },
      );
      return { error: true, message: error.errors };
    }
  },

  async restartEvent({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.restartEvent,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('restartEvent', data.restartEvent);
      this._vm.$message({ message: 'Stream restarted' });
      return data.restartEvent;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t restart the stream' },
      );
      return { error: true, message: error.errors };
    }
  },

  async restartIncomingStream({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.restartIncomingStream,
          { eventId: payload },
        ),
      );
      const { data } = result;
      commit('restartIncomingStream', data.restartIncomingStream);
      this._vm.$message({ message: 'Incoming stream restarted' });
      return data.restartIncomingStream;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t restart the Incoming stream' },
      );
      return { error: true, message: error.errors };
    }
  },

  async signDownloadUrl({ commit }, payload) {
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.signDownloadUrl,
          { attachmentPath: payload },
        ),
      );
      const { data } = result;
      commit('setDummy', data.signDownloadUrl);
      return data.signDownloadUrl;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get the file' },
      );
      return { error: true, message: error.errors };
    }
  },
  async addEventReport({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.addEventReport,
          { input: payload },
        ),
      );
      const { data } = result;
      // commit('addEventReport', data.addEventReport);
      this._vm.$message({ message: 'Report sent' });
      return data.addEventReport;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong.' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async createPdf({ commit }, payload) {
    commit('setLoading', true);
    const formUrl = '/static/booking-template.pdf';
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Get all fields in the PDF by their names
    const fixture = form.getTextField('fixture');
    const description = form.getTextField('description');
    const status = form.getTextField('status');
    const client = form.getTextField('client');
    const sport = form.getTextField('sport');
    const property = form.getTextField('property');
    const input = form.getTextField('input');
    const outputs = form.getTextField('outputs');
    const audio = form.getTextField('audio');

    const startDate = form.getTextField('kickoffDate');
    const lineupDate = form.getTextField('lineupDate');
    const lineupTime = form.getTextField('lineupTime');
    const startTime = form.getTextField('kickoffTime');
    const endTime = form.getTextField('endTime');
    const serviceEndTime = form.getTextField('serviceEndTime');
    const serviceStartTime = form.getTextField('serviceStartTime');
    console.log('payload', payload);
    // Fill in the basic info fields
    let fixtureString = payload.fixture ? payload.fixture : 'not-set';
    if (payload.commentaryLanguage) fixtureString += `\r[COMMENTARY LANGUAGE: ${payload.commentaryLanguage}]`;

    let inputString = payload.inputPDF;
    if (payload.inputFrcEnabled) inputString += '\r[FRC Enabled]';

    fixture.setText(fixtureString);
    description.setText(payload.description ? payload.description : 'not-set');
    status.setText(payload.status.replace('_', ' '));
    client.setText(payload.customerName);
    sport.setText(payload.sport ? payload.sport : 'not-set');
    property.setText(payload.property ? payload.property : 'not-set');
    endTime.setText(moment.utc(payload.endTime).format('HH:mm'));
    startTime.setText(moment.utc(payload.startTime).format('HH:mm'));

    serviceEndTime.setText(moment.utc(payload.serviceEndTime).format('HH:mm'));
    serviceStartTime.setText(moment.utc(payload.serviceStartTime).format('HH:mm'));

    startDate.setText(moment.utc(payload.startTime).format('DD-MM-YYYY'));
    lineupDate.setText(moment.utc(payload.lineupTime).format('DD-MM-YYYY'));
    lineupTime.setText(moment.utc(payload.lineupTime).format('HH:mm'));

    input.setText(inputString);
    outputs.setText(payload.outputPDF.replace(/.{33}/g, '$&\n'));
    audio.setText(payload.audioPDF);
    // Fill in the traits field
    // traitsField.setText(
    //   [
    //     `Mario can use three basic three power-ups:`,
    //     `  • the Super Mushroom, which causes Mario to grow larger`,
    //     `  • the Fire Flower, which allows Mario to throw fireballs`,
    //     `  • the Starman, which gives Mario temporary invincibility`,
    //   ].join('\n'),
    // )

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Trigger the browser to download the PDF document
    download(pdfBytes, `${moment.utc(payload.startTime).format('DD-MM-YYYY')}-${payload.fixture}.pdf`, 'application/pdf');

    commit('setLoading', false);
  },

  async mapEventOutputStream({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.createEventOutputStreamsMap,
          {
            input: payload,
          },
        ),
      );
      const { createEventOutputStreamsMap } = result.data;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventOutputStreamsMapEventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      return createEventOutputStreamsMap.id;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t map output stream' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async mapEventInputStream({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.createEventInputStreamsMap,
          {
            input: payload,
          },
        ),
      );
      const { createEventInputStreamsMap } = result.data;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: payload.eventInputStreamsMapEventId },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      return createEventInputStreamsMap.id;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t map input stream' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async unmapEventOutputStream({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.deleteEventOutputStreamsMap,
          {
            input: payload,
          },
        ),
      );
      const { deleteEventOutputStreamsMap } = result.data;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: deleteEventOutputStreamsMap.event.id },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      return deleteEventOutputStreamsMap.id;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t map output stream' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async unmapEventInputStream({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          scheduleQlmutations.deleteEventInputStreamsMap,
          {
            input: payload,
          },
        ),
      );
      const { deleteEventInputStreamsMap } = result.data;
      const getEventResult = await API.graphql(
        graphqlOperation(
          qlqueries.getEvent,
          { id: deleteEventInputStreamsMap.event.id },
        ),
      );
      const eventData = getEventResult.data.getEvent;
      commit('updateEvent', eventData);
      commit('updateEventAgendaMutation', eventData);
      return deleteEventInputStreamsMap.id;
    } catch (error) {
      console.log('unamp input error', error);
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t map input stream' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
