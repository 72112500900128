<template>
  <div>
    sdasdasd
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import Vue from 'vue';
import { Amplify, Storage } from 'aws-amplify'
import config, { statusColors } from '../config';

export default {
  name: 'AgendaAside',
  data() {
    return {
      isMobileOnly,
      previewPushPanel: true,
      // dialogs
      outputReportDialog: false,
      // forms
      eventAudioMapping: [
        {
          active: false,
          channelNo: 0,
          nameFieldL: 'Left',
          nameFieldR: 'Right',
        },
      ],
      searchInputOverride: '',
      showAudioMappingForm: false,
      showAudioMappingEditForm: false,
      audioMappingForm: {
        active: false,
        channelNo: 1,
        nameFieldL: null,
        nameFieldR: null,
        stereo: true,
      },

      colors: statusColors,
      config,

      // popups
      showPopups: {
        fixturePopup: false,
        propertyPopup: false,
        sportPopup: false,
        descriptionPopup: false,
        editBackupValue: null,
        addOutput: false,
        downlinkConfigPopup: false,
        inputAudioPidPopup: false,
        inputVideoPidPopup: false,
        commentaryUserPopup: false,
        inputDetailsPopup: false,
      },

      outputProfileId: null,

      fixture: '',
      sport: '',
      property: '',
      description: '',
      clientName: '',
      lineupTime: '',
      inputVideoPid: '',
      inputInfo: '',
      inputAudioPid: '',
      audoMappingPopups: [],
      tempAudioMapping: {
        channelNo: 0,
        nameFieldL: '',
        nameFieldR: '',
        active: false,
      },

      selectedEvent: null,
      selectedOutput: {},
      showAdvance: false,

      addOutputDialog: false,
      setStreamKeyDialog: false,
      setTranscoderDialog: false,
      setOverrideOutputDialog: false,
      tempTarget: null,
      reportEventDialog: false,

      tempWowzaConfig: null,
      setAutostartDialog: false,

      rescheduleEventDialog: false,
      rescheduleForm: {
        startDate: null,
        lineupDate: null,
        endDate: null,
        endTime: null,
        startTime: null,
        eventId: null,
        lineupTime: null,
      },
      statusTagStyles: {
        REJECTED: 'danger',
        CANCALED: 'danger',
        APPROVED: 'success',
        'CLIENT CONFIRMED': 'clientconfirmed',
        MOS_CONFIRMED: 'mosconfirmed',
        CONFIRMED: 'success',
        COMPLETED: 'info',
        IN_PROGRESS: 'warning',
        LINEUP: '',
        SCHEDULED: '',
        SUBMITTED: 'info',
      },

      reportForm: {
        ntr: false,
        eventId: null,
        description: null,
      },
      downlinkConfigForm: {
        satelliteName: null,
        satelliteFrequency: null,
        satellitePolarization: null,
        symbolRate: null,
        BISS: null,
        service: null,
      },
      editorOption: {
        theme: 'bubble',
        placeholder: 'Describe the issue',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
          ],
        },
      },
      fileToUpload: null,
      datePickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date(new Date().setDate(new Date().getDate() - 1));
        },
      },
      uploadFileLoading: false,
      savedStreamKeys: [],
      shouldCopyStreamKeys: false,
    };
  },

  computed: {
    ...mapState({
      userId: (state) => state.userAccount.userId,
      aclEx: (state) => state.userAccount.userPermissions,
      isAdmin: (state) => state.userAccount.isAdmin,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      acl: (state) => state.userAccount.aclCurrent,
      userPermissions: (state) => state.userAccount.userPermissions,
      account: (state) => state.userAccount.account,
      client: (state) => state.userAccount.client,
      clients: (state) => state.userAccount.clients,

      event: (state) => state.schedule.selectedEvent,
      listEvents: (state) => state.schedule.listEvents,
      loading: (state) => state.schedule.loading,
      loadings: (state) => state.schedule.loadings,
      events: (state) => state.schedule.events,
      eventStatuses: (state) => state.schedule.eventStatuses,
      eventColorsSimple: (state) => state.schedule.scheduleUIConfig.eventColorsSimple,
      statusDisplayName: (state) => state.schedule.statusDisplayName,
      eventTypes: (state) => state.schedule.eventTypes,
      inputs: (state) => state.schedule.inputs,
      outputs: (state) => state.schedule.outputs,
      outputProfiles: (state) => state.schedule.outputProfiles,
      eventStartType: (state) => state.schedule.eventStartType,
      eventsStats: (state) => state.schedule.eventsStats,
      awsExports: (state) => state.awsExports,

      appSettings: (state) => state.appSettings.settings,

      streamDestinations: (state) => state.stream.streamDestinations,
      streamSources: (state) => state.stream.streamSources,
      pictureFormats: (state) => state.input.pictureFormats,
      transcoderProfiles: (state) => state.permissions.transcoderProfiles,
    }),

    hardDisableEdit() {
      const { code } = this.selectedEvent.customer;
      const accountCode = this.selectedEvent.account.code;
      return !this.isSysAdmin && `${code}.${accountCode}` === 'plp.ots';
    },

    shouldChangeToClientConfirmed() {
      const hasStatus = this.eventStatus === 'RECEIVED' || this.eventStatus === 'CONFIRMED' || this.eventStatus === 'AWAITING_SIGNAL';
      const isBeforeServiceTime = moment.utc(this.selectedEvent.serviceStartTime).subtract(1, 'hour').isAfter(moment.utc());
      console.log('shouldChangeToClientConfirmed', hasStatus, isBeforeServiceTime, this.eventStatus)
      return hasStatus && isBeforeServiceTime;
    },

    defaultInputPictureFormat() {
      return this.pictureFormats.filter((el) => el.name === '1080i29.97')[0];
    },

    defaultOutputPictureFormat() {
      return this.pictureFormats.filter((el) => el.name === '1080i25')[0];
    },
    eventInputStreams() {
      return this.selectedEvent && this.selectedEvent.inputStreams ? this.selectedEvent.inputStreams.items : [];
    },
    eventOutputStreams() {
      return this.selectedEvent && this.selectedEvent.outputStreams ? this.selectedEvent.outputStreams.items : [];
    },

    clientUsers() {
      if (!this.clients) return [];
      const users = this.clients[this.eventClient.id].users.items.map((el) => el.user);
      const usersIds = users.map((user) => user.id);
      const filtered = users.filter(({ id }, index) => !usersIds.includes(id, index + 1));
      return filtered;
    },
    drawerSize() {
      console.log('window.screen.width;', window.screen.width);
      if (window.screen.width < 355) return '320px';
      return '360px';
    },

    eventAcl() {
      if (!this.selectedEvent || !this.clients) return [];
      const { code } = this.selectedEvent.customer;
      const accountCode = this.selectedEvent.account.code;
      // const client = this.clients[code];
      const aclCurrent = {};

      if (this.isAdmin) {
        aclCurrent.admin = true;
        return aclCurrent;
      }
      const isClientAdmin = !!this.userPermissions[code]
        && this.userPermissions[code].accounts[code]
        && this.userPermissions[code].accounts[code].permissions.indexOf('ca') > -1;
      const iseventCoordinator = !!this.userPermissions[code]
        && this.userPermissions[code].accounts[code]
        && this.userPermissions[code].accounts[code].permissions.indexOf('ec') > -1;
      if (isClientAdmin) {
        aclCurrent.clientAdmin = true;
        return aclCurrent;
      }
      if (iseventCoordinator) {
        aclCurrent.eventCoordinator = true;
        aclCurrent.ec = true;
        return aclCurrent;
      }

      this.userPermissions[code].accounts[accountCode].permissions.forEach(
        (item) => {
          aclCurrent[item] = true;
        },
      );
      return aclCurrent;
    },

    eventDate() {
      return this.selectedEvent ? this.selectedEvent.startTime.split('T')[0] : '';
    },

    eventAccount() {
      return this.selectedEvent ? this.selectedEvent.account : { name: '' };
    },

    eventClient() {
      return this.selectedEvent ? this.selectedEvent.customer : { name: '' };
    },

    eventTitle() {
      return this.selectedEvent ? this.selectedEvent.fixture : '';
    },

    eventStatus() {
      return this.selectedEvent ? this.selectedEvent.status : null;
    },

    eventAttachments() {
      return this.selectedEvent
        && this.selectedEvent.attachments ? this.selectedEvent.attachments.split(',') : null;
    },

    eventStatusDisplayName() {
      return this.selectedEvent ? this.statusDisplayName[this.selectedEvent.status] : '';
    },

    eventServiceTimeRange() {
      return this.selectedEvent
        ? {
          start: this.selectedEvent.serviceStartTime,
          end: this.selectedEvent.serviceEndTime,
        } : null;
    },

    serviceStartDuration() {
      const duration = moment.duration(
        moment(this.selectedEvent.startTime).diff(moment(this.selectedEvent.serviceStartTime)),
      );
      return Math.floor(duration.asMinutes());
    },

    hasServiceTimeStarted() {
      const nowUtc = moment().utc();
      const { start, end } = this.eventServiceTimeRange;
      return moment(nowUtc)
        .isBetween(moment(start), moment(end), null, '[]');
    },

    isPastServiceTime() {
      const nowUtc = moment().utc();
      const { end } = this.eventServiceTimeRange;
      return moment(nowUtc).isAfter(moment(end));
    },

    isLive() {
      return this.selectedEvent.status === 'LIVE';
    },

    isBeforeServiceTimeStart() {
      // return this.selectedEvent.status === 'LIVE';
      const nowUtc = moment().utc();
      const { start } = this.eventServiceTimeRange;
      return moment(nowUtc).isBefore(moment(start));
    },

    claimsCanEdit() {
      const accountId = this.eventAccount.code;
      const clientId = this.eventClient.code;
      return [`ee.${accountId}.${clientId}`, `eo.${accountId}.${clientId}`];
    },

    claimsCanAccess() {
      const accountId = this.eventAccount.code;
      const clientId = this.eventClient.code;
      return [
        `ev.${accountId}.${clientId}`,
        `ee.${accountId}.${clientId}`,
        `eo.${accountId}.${clientId}`,
      ];
    },

    audioClaimsCanAccess() {
      const accountId = this.eventAccount.code;
      const clientId = this.eventClient.code;
      return [
        `ev.${accountId}.${clientId}`,
        `ee.${accountId}.${clientId}`,
        `eo.${accountId}.${clientId}`,
        `ca.${clientId}.${clientId}`,
        `aa.${accountId}.${clientId}`,
        `ec.${clientId}.${clientId}`,
      ];
    },

    audioClaimsCanEdit() {
      const accountId = this.eventAccount.code;
      const clientId = this.eventClient.code;
      return [
        `ee.${accountId}.${clientId}`,
        `eo.${accountId}.${clientId}`,
        `ca.${clientId}.${clientId}`,
        `aa.${accountId}.${clientId}`,
        `ec.${clientId}.${clientId}`,
      ];
    },

    unusedOutputs() {
      return this.accountOutputs
        .filter((item) => {
          const isAssigned = this.sortedActions
            .filter((action) => item.id === action.assignedOutput.id);
          return isAssigned.length === 0;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        })
        .map((item) => ({
          id: item.id,
          name: item.name,
          selected: false,
          description: item.description,
        }));
    },

    accountTranscodeProfiles() {
      const accessClaim = `usr.${this.eventAccount.code}.${this.eventClient.code}`;
      const hasItems = !!this.transcoderProfiles;
      if (!hasItems) return [];
      return this.transcoderProfiles
        .filter((item) => {
          const hasPermission = item.claimsCanAccess.indexOf(accessClaim) !== -1;
          return hasPermission;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },
    

    accountOutputs() {
      const accessClaim = `usr.${this.eventAccount.code}.${this.eventClient.code}`;
      const hasItems = !!this.outputs.items;
      if (!hasItems) return [];
      return this.outputs.items
        .filter((item) => {
          const hasPermission = item.claimsCanAccess.indexOf(accessClaim) !== -1;
          return hasPermission;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    allInputsFiltered() {
      return this.allInputs
        .filter((item) => {
          if(this.searchInputOverride === '' || this.searchInputOverride.length < 3) return true;
          return item.name.toLowerCase().includes(this.searchInputOverride.toLowerCase());
        });
    },

    allInputs() {
      console.log('%c inputs', 'color: red', this.inputs);
      const hasItems = !!this.inputs.items;
      if (!hasItems) return [];
      return this.inputs.items
        .slice(0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    accountInputs() {
      const accessClaim = `usr.${this.eventClient.code}`;
      const hasItems = !!this.inputs.items;
      if (!hasItems) return [];
      return this.inputs.items.filter((item) => {
        const hasPermission = item.claimsCanAccess.indexOf(accessClaim) !== -1 || item.claimsCanAccess.indexOf(this.accountAccessClaim) !== -1;
        return hasPermission;
      })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    inputsById() {
      const elements = {};

      this.accountInputs.forEach((item) => {
        elements[item.id] = item;
      });

      return elements;
    },

    basicOutputs() {
      if (!this.selectedEvent) return [];
      const outputs = this.selectedEvent.outputs.items;
      const actions = this.selectedEvent.actions.items;

      const basicOutputs = outputs ? outputs.filter((output) => {
        console.log(output);
        if (!output) return false;
        const { id } = output.output;
        const result = actions.filter((action) => {
          const hasAction = id === action.assignedOutput.id;
          return hasAction;
        });

        return result.length === 0;
      }) : [];
      return basicOutputs.sort();
    },

    actions() {
      if (!this.selectedEvent) return [];
      const actions = this.selectedEvent.actions.items
        .map((action) => {
          const actionOutputMap = this.selectedEvent.outputs.items
            .find((el) => action.assignedOutput.id === el.output.id);

          return {
            ...action,
            actionOutputMap,
          };
        });
      console.log('%cActions', 'color:lime', actions);
      return actions;
    },

    sortedActions() {
      if (!this.selectedEvent) return [];
      const sorted = this.actions
        .slice(0)
        .sort((a, b) => {
          if (a.assignedOutput.name < b.assignedOutput.name) { return -1; }
          if (a.assignedOutput.name > b.assignedOutput.name) { return 1; }
          return 0;
        });
      return sorted;
    },

    sortedAudioMapping() {
      const sorted = this.selectedEvent.audioMapping.items
        .slice(0)
        .sort((a, b) => {
          if (a.channelNo < b.channelNo) { return -1; }
          if (a.channelNo > b.channelNo) { return 1; }
          return 0;
        });
      return sorted;
    },

    inputName() {
      const { input } = this.selectedEvent;
      return input && input.name ? input.name : '--- not set ---';
    },

    inputStatus() {
      return (input) => (input && input.status === 'OFFLINE' ? input.status : '');
    },
    hasInputDetailsEnabled() {
      const { input } = this.selectedEvent;
      return input ? input.requireDetails : false;
    },

    overrideInputName() {
      const { inputOverride } = this.selectedEvent;
      return inputOverride && inputOverride.name ? inputOverride.name : 'Override input';
    },

    inputDetails() {
      const { input } = this.selectedEvent;
      return input && input.name ? input : {};
    },

    downLinkConfig() {
      const eventHasDownlinkConfig = this.selectedEvent && this.selectedEvent.inputDownlinkConfig;
      if (eventHasDownlinkConfig) return this.selectedEvent.inputDownlinkConfig;
      if (this.inputDetails.type === 'DOWNLINK' && this.inputDetails.downlinkConfig) return this.inputDetails.downlinkConfig;
      return null;
    },

    accountAccessClaim() {
      if (!this.selectedEvent) return '';
      return `usr.${this.selectedEvent.account.code}.${this.selectedEvent.customer.code}`;
    },

    hasOutputs() {
      return this.sortedActions.length !== 0 || this.basicOutputs.length !== 0;
    },

    hasOttOutputs() {
      // if(!this.hasOutputs) return false;
      const actions = this.sortedActions.filter((action) => action.assignedOutput.outputType === 'OTT');
      const outputs = this.basicOutputs.filter((output) => output.output.outputType === 'OTT');
      return actions.length > 0 || outputs.length > 0;
    },
    serviceStartTimeOffsetMin() {
      return moment(this.selectedEvent.lineupTime).diff(this.selectedEvent.serviceStartTime, 'minutes');
    },
    outputProfilesSorted() {
      if (!this.outputProfiles.items) return [];
      const profiles = this.outputProfiles.items
        .filter((item) => {
          const hasPermission = item.claimsCanAccess.indexOf(this.accountAccessClaim) !== -1;
          return hasPermission;
        }).sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      if (this.isMobileOnly) profiles.unshift({ name: 'None', id: -1 });
      return profiles;
    },
    selectedOutputProfile() {
      return this.outputProfilesSorted.filter((profile) => {
          return profile.id === this.outputProfileId;
      })[0] || {};
    },
    hasSelectedOutputProfileOTT() {
      const { outputs } = this.selectedOutputProfile;
      if (!outputs) return false;

      return outputs.items.filter((map) => {
        return map.output.outputType === 'OTT';
      }).length > 0;
    },
  },

  mounted() {
    this.previewPushPanel = !this.isMobileOnly;
  },

  watch: {
    event(newVal) {
      if (!newVal) return;
      Vue.set(this, 'selectedEvent', newVal);
      this.fixture = newVal.fixture;
      this.sport = newVal.sport;
      this.property = newVal.property;
      this.description = newVal.description;
      this.lineupTime = newVal.lineupTime;
      this.inputAudioPid = newVal.inputAudioPid;
      this.inputVideoPid = newVal.inputVideoPid;
      // this.clientName = newVal.customer.name;
    },
  },

  methods: {
    ...mapActions({
      createEvent: 'schedule/createEvent',
      deleteEvent: 'schedule/deleteEvent',
      updateEvent: 'schedule/updateEvent',
      rescheduleEvent: 'schedule/rescheduleEvent',
      deleteEventAudioChannel: 'schedule/deleteEventAudioChannel',
      createEventAudioChannel: 'schedule/createEventAudioChannel',
      updateEventAudioChannel: 'schedule/updateEventAudioChannel',

      startStreamTarget: 'schedule/startStreamTarget',
      stopStreamTarget: 'schedule/stopStreamTarget',
      restartIncomingStream: 'schedule/restartIncomingStream',
      setStreamKey: 'schedule/setStreamKey',
      setEventOutputMapOverride: 'schedule/setEventOutputMapOverride',
      eventManualStart: 'schedule/eventManualStart',
      eventManualStop: 'schedule/eventManualStop',
      eventInputChange: 'schedule/eventInputChange',
      eventOutputRemove: 'schedule/eventOutputRemove',
      addEventReport: 'schedule/addEventReport',
      eventOutputProfileChange: 'schedule/eventOutputProfileChange',
      updateWowzaConfig: 'schedule/updateWowzaConfig',
      createDownlinkConfig: 'schedule/createDownlinkConfig',
      signDownloadUrl: 'schedule/signDownloadUrl',

      updateAppSetting: 'appSettings/updateAppSetting',
      createPdf: 'schedule/createPdf',

      mapEventInputStream: 'schedule/mapEventInputStream',
      mapEventOutputStream: 'schedule/mapEventOutputStream',

      unmapEventInputStream: 'schedule/unmapEventInputStream',
      unmapEventOutputStream: 'schedule/unmapEventOutputStream',
      updateWowzaTarget: 'schedule/updateWowzaTarget',
    }),
    ...mapMutations({
      setActiveEvent: 'schedule/setActiveEvent',
      setSelectedEventProperty: 'schedule/setSelectedEventProperty',
      setRescheduleEventDialog: 'schedule/setRescheduleEventDialog',
      setShowCreateEventDialog: 'schedule/setShowCreateEventDialog',
      setBookingClone: 'schedule/setBookingClone',
    }),

    openAddOutputDialog() {
      this.savedStreamKeys = this.sortedActions
        .filter((action) => {
          return action.assignedOutput.outputType === 'OTT';
        })
        .map((action) => {
          const [target] = action.configWowza.targets.items;
          return {
            streamKey: target.streamKey,
            target: target.target,
          };
        })
        .filter((action) => {
          return action.streamKey;
        });
      this.addOutputDialog = true;
    },

    duplicateBooking(booking) {
      this.setBookingClone(booking);
      this.setShowCreateEventDialog(true);
    },

    clearSelectedEvent() {
      this.setActiveEvent(null);
      this.selectedEvent = null;
    },

    edit(property, value) {
      this.showPopups[property] = true;
      this.editBackupValue = value;
    },

    cancel(property) {
      this.editBackupValue = null;
      this.showPopups[property] = false;
    },

    async save(property, value) {
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      newEvent[property] = value;
      if (property === 'fixture') {
        newEvent.name = value;
      }
      console.log('xxxxxx', newEvent, property, value);
      await this.updateEvent(newEvent);
      if (property !== 'inputDetails' && property !== 'fixture' && property !== 'description') {
        this.editBackupValue = null;
        this.showPopups[`${property}Popup`] = false;
        return;
      }
      console.log('shouldChangeToClientConfirmed: upload event', this.shouldChangeToClientConfirmed);
      if (this.shouldChangeToClientConfirmed) await this.save('status', 'CLIENT_CONFIRMED')

      this.editBackupValue = null;
      this.showPopups[`${property}Popup`] = false;
    },

    async toggleFRC(property, value) {
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      newEvent[property] = value;
      const input = this.defaultInputPictureFormat.id;
      const output = this.defaultOutputPictureFormat.id;
      newEvent.eventInputFrcInputFormatId = input;
      newEvent.eventInputFrcOututFormatId = output;

      await this.updateEvent(newEvent);
      this.editBackupValue = null;
      this.showPopups[`${property}Popup`] = false;
    },

    async updateDownlinkConfig() {
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      this.downlinkConfigForm.claimsCanEdit = newEvent.claimsCanEdit;
      this.downlinkConfigForm.claimsCanAccess = newEvent.claimsCanAccess;
      this.downlinkConfigForm.version = 1;
      this.downlinkConfigForm.inputDownlinkConfigLastUpdatedById = this.userId;

      const downlinkConfigId = await this.createDownlinkConfig(this.downlinkConfigForm);
      newEvent.eventInputDownlinkConfigId = downlinkConfigId.id;
      await this.updateEvent(newEvent);
      // if (this.appSettings.symbolRate.indexOf(this.downlinkConfigForm.symbolRate) === -1) {
      //   this.appSettings.symbolRate.push(this.downlinkConfigForm.symbolRate);
      //   console.log(this.appSettings.symbolRate);
      //   this.updateAppSetting({ id: 'symbolRate', key: 'symbolRate', value: this.appSettings.symbolRate.join(',') });
      // }
      this.editBackupValue = null;
      this.showPopups.downlinkConfigPopup = false;
      console.log('shouldChangeToClientConfirmed: upload event', this.shouldChangeToClientConfirmed);
      if (this.shouldChangeToClientConfirmed) await this.save('status', 'CLIENT_CONFIRMED')
    },

    async cancelWithComment() {
      const result = await this.$prompt('Please provide comment/reason for cancellation', 'Canceling booking', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      });

      const isConfirmed = result.action === 'confirm';
      if (!isConfirmed) return;

      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      newEvent.cancellationMsg = result.value;
      newEvent.status = 'CANCELLED';
      console.log('test cancelWithComment', result, newEvent);
      await this.updateEvent(newEvent);
    },

    editAudioChannel(mapping, index) {
      this.tempAudioMapping = {
        ...mapping,
      };
      this.audoMappingPopups[index] = true;
    },

    cancelAudioEdit(index) {
      this.clearAudioPopup();
      this.audoMappingPopups[index] = false;
    },

    async saveAudioMapping(index) {
      const newMapping = {
        ...this.tempAudioMapping,
        eventAudioChannelLastUpdatedById: this.userId,
        // eventAudioMappingId: this.selectedEvent.id,
        claimsCanAccess: this.claimsCanAccess,
        claimsCanEdit: this.claimsCanEdit,
      };

      await this.updateEventAudioChannel(newMapping);
      this.clearAudioPopup();
      this.audoMappingPopups[index] = false;
      this.$forceUpdate();
    },

    clearAudioPopup() {
      this.tempAudioMapping = {
        channelNo: 0,
        nameFieldL: '',
        nameFieldR: '',
        active: false,
        stereo: true,
      };
    },

    async rescheduleBooking() {
      const {
        lineupTime,
        lineupDate,
        startTime,
        startDate,
        endTime,
        endDate,
        eventId,
        expectedVersion,
        accountId,
        clientId,
      } = this.rescheduleForm;
      const {
        headTimeMin,
        tailTimeMin,
      } = this.selectedEvent;

      const payload = {
        lineupTime: moment.utc(`${lineupDate} ${lineupTime}`).format(),
        startTime: moment.utc(`${startDate} ${startTime}`).format(),
        endTime: moment.utc(`${endDate} ${endTime}`).format(),
        headTimeMin,
        tailTimeMin,
        eventId,
        expectedVersion,
        accountId,
        clientId,
        serviceStartTimeOffsetMin: this.serviceStartTimeOffsetMin,
      };
      const result = await this.rescheduleEvent(payload);
      const hasErrors = result.error;
      if (!hasErrors) this.rescheduleEventDialog = false;
    },

    async changeInput(input) {
      const data = {
        eventId: this.selectedEvent.id,
        newInputId: input.id,
        accountId: this.eventAccount.code,
        clientId: this.eventClient.code,
        expectedVersion: this.selectedEvent.version,
      };

      await this.eventInputChange(data);
      console.log('shouldChangeToClientConfirmed: upload event', this.shouldChangeToClientConfirmed);
      if (this.shouldChangeToClientConfirmed) await this.save('status', 'CLIENT_CONFIRMED')
      this.showPopups.inputPopup = false;
    },

    async changeCommetnaryUser(user) {
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      newEvent.eventCommentaryUserId = user.id;
      await this.updateEvent(newEvent);
      this.editBackupValue = null;

      this.showPopups.commentaryUserPopup = false;
    },

    async ovrrideInput(input) {
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);

      newEvent.eventInputOverrideId = input.id;

      await this.updateEvent(newEvent);
      this.editBackupValue = null;

      this.showPopups.ovrrideInputPopup = false;
    },

    async addOutputs(shouldCopyStreamKeys) {
      const data = {
        eventId: this.selectedEvent.id,
        outputProfileId: this.outputProfileId,
        accountId: this.eventAccount.code,
        clientId: this.eventClient.code,
        claimsCanAccess: this.selectedEvent.claimsCanAccess,
        claimsCanEdit: this.selectedEvent.claimsCanEdit,
      };
      const result = await this.eventOutputProfileChange(data);

      console.log('%c OUTPUT UPATE', 'color:lime', result);
      if (!shouldCopyStreamKeys) {
        this.shouldCopyStreamKeys = false;
        return;
      }

      let actions = result.actions.items || [];
      actions = actions
        .map((action) => {
          const actionOutputMap = this.selectedEvent.outputs.items
            .find((el) => action.assignedOutput.id === el.output.id);

          return {
            ...action,
            actionOutputMap,
          };
        });
      const ottTargets = actions
        .filter((action) => {
          return action.assignedOutput.outputType === 'OTT';
        })
      console.log('%c OUTPUT UPATE', 'color:lime', ottTargets, result);

      await this.asyncForEach(ottTargets, async (target, index) => {
        const streamKey = this.savedStreamKeys[index].streamKey || this.savedStreamKeys[0].streamKey;
        const model = {
          eventId: this.selectedEvent.id,
          streamTargetId: target.configWowza.targets.items[0].id,
          streamKey: streamKey,
          name: target.configWowza.targets.items[0].target,
        };
        await this.setStreamKey(model);
      });
        this.shouldCopyStreamKeys = false;
      // this.shouldCopyStreamKeys = false;
      // const model = {
      //   eventId: this.selectedEvent.id,
      //   streamTargetId: target.configWowza.targets.items[0].id,
      //  streamKey: target.configWowza.targets.items[0].streamKey,
      //  name: target.configWowza.targets.items[0].target,
      // };
      // await this.setStreamKey(model);

      this.addOutputDialog = false;
    },

    async deleteOutput(output) {
      const [outputMapping] = this.selectedEvent.outputs.items
        .filter((item) => item.output.id === output.id);
      const doesMappingExist = !!outputMapping;

      if (!doesMappingExist) {
        this.$message.error(
          { message: 'Can\'t delete output. Output mapping does not exist.' },
        );
        return;
      }
      const data = {
        eventId: this.selectedEvent.id,
        input: {
          eventOutputMapId: outputMapping.id,
          accountId: this.eventAccount.code,
          clientId: this.eventClient.code,
          outputName: outputMapping.output.name,
        },
      };

      await this.eventOutputRemove(data);
    },

    getUpdatedTimes(lineupTime, startTime, endTime, headTimeMin, tailTimeMin) {
      const startDate = this.selectedEvent.startTime.split('T')[0];
      const endDate = this.selectedEvent.endTime.split('T')[0];
      const lineupDate = this.selectedEvent.lineupTime.split('T')[0];

      return {
        ...this.rescheduleForm,
        lineupTime: moment.utc(`${lineupDate} ${lineupTime}`)
          .format(),
        startTime: moment.utc(`${startDate} ${startTime}`)
          .format(),
        endTime: moment.utc(`${endDate} ${endTime}`)
          .format(),
        headTimeMin,
        tailTimeMin,
      };
    },

    async setStatus(status) {
      const confirmation = await this.$confirm('Are you sure you want to change status of the event?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.save('status', status);
    },

    async setAsPending() {
      const confirmation = await this.$confirm('Are you sure you want to set booking to pending?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.save('status', 'PENDING');
    },

    async confirmPostpone() {
      const confirmation = await this.$confirm('Are you sure you want to postpone booking?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.save('status', 'POSTPONED');
    },

    async confirmDeleteEventPermanently() {
      const confirmation = await this.$confirm('This will permanently delete booking. Are you sure?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.deleteEvent(this.selectedEvent);
      this.clearSelectedEvent();
    },

    async confirmDelete() {
      const confirmation = await this.$confirm('Are you sure you want to cancel booking?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      if (!this.isBeforeServiceTimeStart || this.eventStatus === 'LIVE') {
        this.save('status', 'LATE_CANCELLED');
        return;
      }
      this.save('status', 'CANCELLED');
    },

    async confirmConfirmation() {
      const confirmation = await this.$confirm('Once booking is confirmed MediaAtmos will confirm resources required and once done the status will change to "Resource confirmed"', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.save('status', 'CLIENT_CONFIRMED');
    },

    async sysAdminConfirmConfirmation() {
      const confirmation = await this.$confirm('Are you sure you want to confirm booking?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.save('status', 'CONFIRMED');
    },

    async startEventNow() {
      const confirmation = await this.$confirm('Are you sure you want to start booking now?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.eventManualStart(this.selectedEvent.id);
    },

    async stopEventNow() {
      const confirmation = await this.$confirm('Are you sure you want to stop booking now?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.eventManualStop(this.selectedEvent.id);
    },

    async startStream(target, event) {
      const confirmation = await this.$confirm('Are you sure you want to start the stream?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      await this.startStreamTarget({ streamTargetId: target.id, eventId: event.id });
    },

    async stopStream(target, event) {
      const confirmation = await this.$confirm('Are you sure you want to stop the stream?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      await this.stopStreamTarget({ streamTargetId: target.id, eventId: event.id });
    },

    async addAudioChannel(form, object, event) {
      const doCreate = !!event.id;
      const formObject = this.$refs[form];
      const isValid = await formObject.validate();
      if (!isValid) return null;
      const audioMappingForm = {
        active: this.audioMappingForm.active,
        channelNo: this.audioMappingForm.channelNo,
        description: this.audioMappingForm.description,
        stereo: this.audioMappingForm.stereo,
      };
      console.log('this.audioMappingForm.stereo', this.audioMappingForm.stereo);

      // if existing event is attached create mapping
      if (!doCreate) {
        this.audioMappingForm.channelNo = this.audioMappingForm.channelNo + 1;
        this.audioMappingForm.description = '';
        object.push(audioMappingForm);
      }

      const newMapping = {
        ...audioMappingForm,
        eventAudioChannelLastUpdatedById: this.userId,
        eventAudioChannelEventId: event.id,
        claimsCanAccess: this.audioClaimsCanAccess,
        claimsCanEdit: this.audioClaimsCanEdit,
      };

      await this.createEventAudioChannel(newMapping);
      this.$forceUpdate();
      return false;
    },

    async deleteAudioChannel(id, event) {
      const needsUpdate = !!event.id;
      if (!needsUpdate) {
        // object.splice(index, 1);
        return false;
      }
      await this.deleteEventAudioChannel(id);
      this.$forceUpdate();
      return false;
    },

    async addTime(event, type, time) {
      const headTime = type === 'head' ? event.headTimeMin + time : event.headTimeMin;
      const tailTime = type === 'tail' ? event.tailTimeMin + time : event.tailTimeMin;

      const startTime = type === 'head' ? moment(event.startTime).subtract(time, 'minutes') : event.startTime;
      const endTime = type === 'tail' ? moment(event.endTime).add(time, 'minutes') : event.endTime;
      const newTimes = {
        ...this.rescheduleForm,
        lineupTime: event.lineupTime,
        startTime,
        endTime,
        headTimeMin: headTime,
        tailTimeMin: tailTime,
        serviceStartTimeOffsetMin: this.serviceStartTimeOffsetMin,
      };

      delete newTimes.lineupDate;
      delete newTimes.startDate;
      delete newTimes.endDate;
      await this.rescheduleEvent(newTimes);
      console.log('event updated', event)
      this.$forceUpdate();
    },

    async openAddTime(event, type) {
      try {
        const result = await this.$prompt('Please input time in minutes', 'Extend time', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          inputPattern: /^[0-9]/,
          inputErrorMessage: 'Invalid value',
        });

        const { value } = result;
        this.addTime(event, type, parseInt(value, 10));
      } catch (error) {
        if (error === 'cancel') return;
        this.$message.error('Something went wrong!');
      }
    },

    async updateStreamKey(target) {
      const model = {
        eventId: target.eventId,
        streamTargetId: target.streamTargetId,
        streamKey: target.streamKey,
      };
      await this.setStreamKey(model);
      this.$forceUpdate();
      this.setStreamKeyDialog = false;
      return false;
    },

    async updateTranscoderProfile(target) {
      const targets = this.sortedActions.filter((el) => {
        return el.assignedOutput.outputType === 'OTT';
      });

      await this.asyncForEach(targets, async (tr, index) => {
        const [wowzaTarget] = tr.configWowza.targets.items;
        const profile = target.sysAdminOption || target.transcoderProfile;
        const payload = {
          id: wowzaTarget.id,
          transcoderProfile: profile,
          expectedVersion: wowzaTarget.version,
          claimsCanEdit: wowzaTarget.claimsCanEdit,
          claimsCanAccess: wowzaTarget.claimsCanAccess,
        };
        console.log('transcoder Profile', targets, tr, target);
        await this.updateWowzaTarget({payload, id: this.selectedEvent.id});
      });
      
      console.log('transcoder Profile', targets, this.sortedActions, target);
      this.$forceUpdate();
      this.setTranscoderDialog = false;
      return false;
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array); // eslint-disable-line
      }
    },

    async setOverrideOutputMap(target) {
      console.log('.......', target);
      const model = {
        eventId: target.eventId,
        override: target.override,
        id: target.id,
      };
      await this.setEventOutputMapOverride(model);
      this.$forceUpdate();
      this.setOverrideOutputDialog = false;
      return false;
    },

    async updateAutostart(model) {
      await this.updateWowzaConfig({ input: model, eventId: this.selectedEvent.id });
      this.$forceUpdate();
      this.setAutostartDialog = false;
      return false;
    },

    editTranscoderProfile(target) {
      this.tempTarget = {
        eventId: this.selectedEvent.id,
        streamTargetId: target.configWowza.targets.items[0].id,
        transcoderProfile: target.configWowza.targets.items[0].transcoderProfile,
        name: target.configWowza.targets.items[0].target,
        sysAdminOption: null,
        target,
      };
      this.setTranscoderDialog = true;
    },

    editStreamKey(target) {
      this.tempTarget = {
        eventId: this.selectedEvent.id,
        streamTargetId: target.configWowza.targets.items[0].id,
        streamKey: target.configWowza.targets.items[0].streamKey,
        name: target.configWowza.targets.items[0].target,
      };
      this.setStreamKeyDialog = true;
    },

    editOutputOverride(target) {
      console.log('%ceditOutputOverride', 'color:blue', target);
      this.tempTarget = {
        eventId: this.selectedEvent.id,
        id: target.actionOutputMap.id,
        override: target.actionOutputMap.outputOttApplicationOverride,
        name: target.configWowza.targets.items[0].target,
      };
      this.setOverrideOutputDialog = true;
    },

    setAutostart(action) {
      const { configWowza } = action;
      this.tempWowzaConfig = {
        id: configWowza.id,
        claimsCanEdit: configWowza.claimsCanEdit,
        claimsCanAccess: configWowza.claimsCanAccess,
        autostart: configWowza.autostart,
        autostartOffsetMin: configWowza.autostartOffsetMin,
        expectedVersion: configWowza.version,
      };
      this.setAutostartDialog = true;
    },

    getEventObjForUpdate(event) {
      return {
        bugRequired: event.bugRequired,
        claimsCanAccess: event.claimsCanAccess,
        claimsCanEdit: event.claimsCanEdit,
        description: event.description,
        endTime: event.endTime,
        eventAccountId: event.eventAccountId,
        eventCustomerId: event.eventCustomerId,
        eventInputId: event.eventInputId,
        expectedVersion: event.version,
        fixture: event.fixture,
        headTimeMin: event.headTimeMin,
        startType: event.startType,
        id: event.id,
        attachments: event.attachments,
        lineupTime: event.lineupTime,
        name: event.name,
        property: event.property,
        requestedBitrate: event.requestedBitrate,
        serviceEndTime: event.serviceEndTime,
        serviceStartTime: event.serviceStartTime,
        sport: event.sport,
        startTime: event.startTime,
        status: event.status,
        tailTimeMin: event.tailTimeMin,
        type: event.type,
        groupId: event.groupId,
        eventLastUpdatedById: this.userId,
      };
    },

    openReportDialog(event) {
      this.reportForm.eventId = event.id;
      this.reportForm.ntr = false;
      this.eventToReport = event.extendedProps;
      this.reportForm.description = '';
      this.reportEventDialog = true;

      this.reportForm.clientId = this.eventClient.code;
      this.reportForm.accountId = this.eventAccount.code;
      this.reportForm.claimsCanAccess = this.claimsCanAccess;
      this.reportForm.claimsCanEdit = this.claimsCanEdit;
    },

    async sendReport() {
      const result = await this.addEventReport(this.reportForm);
      if (!result.error) this.reportEventDialog = false;
    },

    async sendNothingToReport(event) {
      this.reportForm.eventId = event.id;
      this.reportForm.clientId = this.eventClient.code;
      this.reportForm.accountId = this.eventAccount.code;
      this.reportForm.ntr = true;
      this.reportForm.claimsCanAccess = this.claimsCanAccess;
      this.reportForm.claimsCanEdit = this.claimsCanEdit;
      delete this.reportForm.description;
      await this.addEventReport(this.reportForm);
    },
    copyToClipboard(containerid) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(document.getElementById(containerid));
      selection.removeAllRanges(); // Remove all ranges from the selection.
      selection.addRange(range);
      document.execCommand('Copy');
      this.$message.success('Copied to clipboard');
      this.showPopups.downlinkConfigPopup = false;
      this.cancel('downlinkConfigPopup', 'downlink');
      setTimeout(() => {
        this.showPopups.downlinkConfigPopup = false;
        this.$forceUpdate();
      }, 1);
    },

    // UPLOAD MEHTODS
    async downloadAttachment(attachment) {
      const clientId = this.eventClient.code;
      const accountId = this.eventAccount.code;
      const key = `public/${clientId}/${accountId}/${attachment}`;
      const file = await this.signDownloadUrl(key);
      const link = document.createElement('a');
      link.download = attachment;
      link.href = file;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async handleUploadChange(file) {
      this.uploadFileLoading = true;
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      this.fileToUpload = file;
      const name = file.name.substring(0, file.name.lastIndexOf('.'));
      const extension = file.name.substring(file.name.lastIndexOf('.'));
      const clientId = this.eventClient.code;
      const accountId = this.eventAccount.code;
      // const randomString = this.S4();
      const key = `${moment.utc().format('YYYYmmDD-hhmmss')}-${name}${extension}`;
      const result = await this.$Amplify.Storage.put(`${clientId}/${accountId}/${key}`, file.raw, {
        bucket: this.awsExports.aws_upload_bucket,
        region: 'eu-west-1',
        metadata: {
          account: accountId,
          client: clientId,
        },
      });
      this.uploadFileLoading = false;
      if (!result.key) {
        this.$message.error('Can\'t upload selected file. Please try again.');
        return;
      }
      newEvent.attachments += `,${key}`;
      await this.updateEvent(newEvent);
      console.log('shouldChangeToClientConfirmed: upload event', this.shouldChangeToClientConfirmed);
      if (this.shouldChangeToClientConfirmed) await this.save('status', 'CLIENT_CONFIRMED')
    },
    async mapInputStream(source) {
      const payload = {
        claimsCanAccess: this.claimsCanAccess,
        claimsCanEdit: this.claimsCanEdit,
        eventInputStreamsMapEventId: this.selectedEvent.id,
        eventInputStreamsMapInputStreamId: source.id,
      };
      await this.mapEventInputStream(payload);
    },
    async mapOutputStream(dest) {
      const payload = {
        claimsCanAccess: this.claimsCanAccess,
        claimsCanEdit: this.claimsCanEdit,
        eventOutputStreamsMapEventId: this.selectedEvent.id,
        eventOutputStreamsMapOutputStreamId: dest.id,
      };
      await this.mapEventOutputStream(payload);
    },
    async unmapInputStream(source) {
      const payload = {
        id: source.id,
      };
      await this.unmapEventInputStream(payload);
    },
    async unmapOutputStream(dest) {
      const payload = {
        id: dest.id,
      };
      await this.unmapEventOutputStream(payload);
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array); // eslint-disable-line
      }
    },
    copyCode(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      
      this.$message.success('Copied to clipboard');
    },
  },
};
</script>


