import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import * as qlqueries from '../graphql/queries';
import * as qlmutations from '../graphql/mutations';

const state = {
  name: 'Permissions',
  logs: {},
  inputs: null,
  outputs: null,
  outputsById: null,
  outputProfiles: null,
  routers: null,
  multiviewers: null,
  routerGroups: null,
  routerDestinations: null,
  routerSources: null,
  transcoderProfiles: null,
  clients: [],
  claims: null,
  features: null,
  loading: false,
};

const getters = {
};

const mutations = {
  setList: (state, payload) => {
    Vue.set(state, payload.property, payload.data.items);
    const { items } = payload.data;
    const listById = {};
    items.forEach((item) => { listById[item.id] = item; });

    Vue.set(state, `${payload.property}ById`, listById);
  },

  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },
};

const actions = {
  async listInputs({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listInputs,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listInputs, property: 'inputs' });
      return data.listInputs;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listInputs;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listInputs, property: 'inputs' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the INPUTS' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t add inputs' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async listOutputs({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listOutputs,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listOutputs, property: 'outputs' });
      return data.listOutputs;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listOutputs;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listOutputs, property: 'outputs' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the OUTPUTS' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t add outputs' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async listOutputProfiles({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listOutputProfiles,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listOutputProfiles, property: 'outputProfiles' });
      return data.listOutputProfiles;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listOutputProfiles;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listOutputProfiles, property: 'outputProfiles' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the output profiles' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t add output profiles' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async listRouters({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listRouters,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listRouters, property: 'routers' });
      return data.listRouters;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get routers' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async listRouterGroups({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listRouterGroups,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listRouterGroups, property: 'routerGroups' });
      return data.listRouterGroups;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listRouterGroups;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listRouterGroups, property: 'routerGroups' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the router groups' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get router groups' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async listTranscoderProfiles({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listTranscoderProfiles,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listTranscoderProfiles, property: 'transcoderProfiles' });
      return data.listTranscoderProfiles;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listTranscoderProfiles;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listTranscoderProfiles, property: 'transcoderProfiles' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the transcoding profiles' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get transcoding profiles' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async listRouterDestinations({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listRouterDestinations,
          {
            limit: 2500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listRouterDestinations, property: 'routerDestinations' });
      return data.listRouterDestinations;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listRouterDestinations;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listRouterDestinations, property: 'routerDestinations' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the destinations' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get destinations' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async listRouterSources({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listRouterSources,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listRouterSources, property: 'routerSources' });
      return data.listRouterSources;
    } catch (error) {
      console.log('error', error.data);
      const hasData = !!error.data && !!error.data.listRouterSources;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listRouterSources, property: 'routerSources' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the sources' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get sources' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async listClients({ commit }) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.listClients, {
        limit: 1500,
      }),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setList', { data: data.listClients, property: 'clients' });
    return data.listClients.items;
  },

  async listClaims({ commit }) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.listClaims, {
        limit: 250,
      }),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setList', { data: data.listClaims, property: 'claims' });
    return data.listClaims;
  },

  async listFeatures({ commit }) {
    const result = await API.graphql(
      graphqlOperation(qlqueries.listFeatures, {
        limit: 250,
      }),
    );
    const { data } = result;
    const isFailure = !!result.errors;
    if (isFailure) return { error: true, message: result.errors };
    commit('setList', { data: data.listFeatures, property: 'features' });

    return data.listFeatures;
  },

  async updateInput({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateInput,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateInput, property: 'input' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateInput;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateOutput({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateOutput,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateOutput, property: 'input' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateOutput;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateRouter({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateRouter,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateRouter, property: 'router' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateRouter;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateRouterGroup({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateRouterGroup,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateRouterGroup, property: 'router' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateRouterGroup;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateRouterSource({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateRouterSource,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateRouterSource, property: 'router' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateRouterSource;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateRouterDestination({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateRouterDestination,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateRouterDestination, property: 'router' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateRouterDestination;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateOutputProfile({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateOutputProfile,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateOutputProfile, property: 'input' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateOutputProfile;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteOutput({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteOutput,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Output deleted' });
      return data.deleteOutput;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteOutputProfile({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteOutputProfile,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Output profile deleted' });
      return data.deleteOutputProfile;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteInput({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteInput,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Input deleted' });
      return data.deleteInput;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteRouter({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteRouter,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Router deleted' });
      return data.deleteRouter;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteRouterGroup({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteRouterGroup,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Router deleted' });
      return data.deleteRouterGroup;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteRouterDestination({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteRouterDestination,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Router destination deleted' });
      return data.deleteRouterDestination;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteRouterSource({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteRouterSource,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Router source deleted' });
      return data.deleteRouterSource;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteTranscoderProfile({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteTranscoderProfile,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Transcoder profile deleted' });
      return data.deleteTranscoderProfile;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async updateTranscoderProfile({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateTranscoderProfile,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateTranscoderProfile, property: 'transocedProfile' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateTranscoderProfile;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  // delete mutations
  async createInput({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createInput,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Input created' });
      return data.createInput;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createTranscoderProfile({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createTranscoderProfile,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Transcoder profile created' });
      return data.createTranscoderProfile;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createOutput({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createOutput,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Output created' });
      return data.createOutput;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createOutputProfile({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createOutputProfile,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Profile created' });
      return data.createOutputProfile;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createRouter({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createRouter,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Route created' });
      return data.createRouter;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createRouterGroup({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createRouterGroup,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Router Group created' });
      return data.createRouterGroup;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createRouterDestination({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createRouterDestination,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Router Group Destination created' });
      return data.createRouterDestination;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createRouterSource({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createRouterSource,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Router Group Destination created' });
      return data.createRouterSource;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createOutputProfileOutputMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createOutputProfileOutputMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.createOutputProfileOutputMap;
    } catch (error) {
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteOutputProfileOutputMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteOutputProfileOutputMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.deleteOutputProfileOutputMap;
    } catch (error) {
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createOutputAccountMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createOutputAccountMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.createOutputAccountMap;
    } catch (error) {
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteOutputAccountMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteOutputAccountMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.deleteOutputAccountMap;
    } catch (error) {
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteOutputEquipmentMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteOutputEquipmentMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.deleteOutputEquipmentMap;
    } catch (error) {
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteEventOutputMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteEventOutputMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.deleteEventOutputMap;
    } catch (error) {
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createInputAccountMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createInputAccountMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.createInputAccountMap;
    } catch (error) {
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteInputAccountMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteInputAccountMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.deleteInputAccountMap;
    } catch (error) {
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteInputEquipmentMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteInputEquipmentMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.deleteInputEquipmentMap;
    } catch (error) {
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  // DEST -> Router Group
  async createRouterDestinationGroupMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createRouterDestinationGroupMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.createRouterDestinationGroupMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteRouterDestinationGroupMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteRouterDestinationGroupMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.deleteRouterDestinationGroupMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  // SOURCE -> Router Group
  async createRouterSourceGroupMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createRouterSourceGroupMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.createRouterSourceGroupMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteRouterSourceGroupMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteRouterSourceGroupMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.deleteRouterSourceGroupMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  // OUTPUT -> Router DEST
  async createOutputRouterDestinationMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createOutputRouterDestinationMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.createOutputRouterDestinationMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteOutputRouterDestinationMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteOutputRouterDestinationMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.deleteOutputRouterDestinationMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  // INPUT -> Router SOURCE
  async createInputRouterSourceMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createInputRouterSourceMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.createInputRouterSourceMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteInputRouterSourceMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteInputRouterSourceMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.deleteInputRouterSourceMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  // MULTIVIEWER
  async createMultiviewer({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createMultiviewer,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Multiviewer created' });
      return data.createMultiviewer;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteMultiviewer({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteMultiviewer,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Multiviewer deleted' });
      return data.deleteMultiviewer;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async updateMultiviewer({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateMultiviewer,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateMultiviewer, property: 'multiviewer' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateMultiviewer;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async listMultiviewers({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listMultiviewers,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listMultiviewers, property: 'multiviewers' });
      return data.listMultiviewers;
    } catch (error) {
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get multiviewers' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  // Stream Source -> Multiviewer
  async createStreamSourceMultiviewerMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createStreamSourceMultiviewerMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.createStreamSourceMultiviewerMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteStreamSourceMultiviewerMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteStreamSourceMultiviewerMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.deleteStreamSourceMultiviewerMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
  async updateStreamSourceMultiviewerMap({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateStreamSourceMultiviewerMap,
          { input: payload },
        ),
      );
      const { data } = result;
      return data.updateStreamSourceMultiviewerMap;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
