import Vue from 'vue';
import Router from 'vue-router';
import Main from './views/Main.vue';
import Home from './views/Home.vue';
import Servers from './views/Servers.vue';
import Client from './views/Client.vue';
import ClientUsers from './views/ClientUsers.vue';
import RouterPage from './views/Router.vue';
import Multiview from './views/Multiview.vue';
import Multiviewer from './views/Multiviewer.vue';
import Schedule from './views/Schedule.vue';
import ScheduleAgenda from './views/ScheduleAgenda.vue';
import ScheduleEventPreview from './views/SchedulePreviewPanel.vue';
import ScheduleEventAgendaPreview from './views/ScheduleAgendaPreviewPanel.vue';
import ScheduleAgendaPanel from './views/ScheduleAgendaPanel.vue';
import ScheduleAddBooking from './views/ScheduleAddBooking.vue';
import ScheduleReschedule from './views/ScheduleReschedule.vue';

import MVR from './views/Mvr.vue';

import Admin from './views/Admin.vue';
import AdminClient from './views/AdminClient.vue';
import AdminClientAccount from './views/AdminClientAccount.vue';
// import AdminClientUsers from './views/AdminClientUsers.vue';
// import AdminClientFeatures from './views/AdminClientFeatures.vue';
// import AdminClientClaims from './views/AdminClientClaims.vue';
import AdminFeature from './views/AdminFeature.vue';
import AdminDefault from './views/AdminDefault.vue';
import Audit from './views/Audit.vue';
import TestView from './views/TestView.vue';

// import Login from './views/Login.vue';
import Logout from './views/Logout.vue';
import Page401 from './views/401.vue';

import Login from './views/AuthLogin.vue';
import Register from './views/AuthRegister.vue';
import PasswordReset from './views/AuthPasswordReset.vue';
import UserProfile from './views/UserProfile.vue';
import Permissions from './views/Permissions.vue';
import PermissionsSimple from './views/PermissionsSimple.vue';

import Reports from './views/Reports.vue';

import MGW from './views/Mgw.vue';

import MV from './views/MV.vue';

import LiveOps from './views/LiveOps.vue';
import LiveOpsRouter from './views/LiveOpsRouter.vue';
import LiveOpsMultiviewer from './views/LiveOpsMultiviewer.vue';

import isAuthenticated from './guards/isAuthenticated';
import hasAccess from './guards/hasAccess';

Vue.use(Router);

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Main,
      children: [
        {
          path: '',
          redirect: '/home',
        },
        {
          path: 'home',
          name: 'home',
          component: Home,
          beforeEnter: hasAccess,
        },
        {
          path: 'audit',
          name: 'audit',
          component: Audit,
        },
        {
          path: 'servers',
          name: 'servers',
          component: Servers,
          beforeEnter: hasAccess,
        },
        {
          path: 'mv',
          name: 'mv',
          component: MV,
          beforeEnter: hasAccess,
        },
        {
          path: 'router',
          name: 'router',
          component: RouterPage,
          beforeEnter: hasAccess,
        },
        {
          path: 'mgw',
          name: 'mgw',
          component: MGW,
          beforeEnter: hasAccess,
        },
        {
          path: 'mvr',
          name: 'mvr',
          component: MVR,
          beforeEnter: hasAccess,
        },
        {
          path: 'permissions',
          name: 'permissions',
          component: Permissions,
          beforeEnter: hasAccess,
        },
        {
          path: 'permissionsSimple',
          name: 'permissionsSimple',
          component: PermissionsSimple,
          beforeEnter: hasAccess,
        },
        {
          path: 'reports',
          name: 'reports',
          component: Reports,
          beforeEnter: hasAccess,
        },
        {
          path: 'liveops',
          name: 'liveops',
          components: {
            default: LiveOps,
            liveRouter: LiveOpsRouter,
            multiviewer: LiveOpsMultiviewer,
          },
          beforeEnter: hasAccess,
        },
        {
          path: 'multiview',
          name: 'multiview',
          component: Multiview,
          beforeEnter: hasAccess,
        },
        {
          path: 'multiviewer',
          name: 'multiviewer',
          component: Multiviewer,
          beforeEnter: hasAccess,
        },
        {
          path: 'schedule-agenda/:id',
          name: 'scheduleagenda',
          // component: ScheduleAgenda,
          components: {
            default: ScheduleAgenda,
            aside: ScheduleEventAgendaPreview,
            addBooking: ScheduleAddBooking,
            reschedule: ScheduleReschedule,
          },
          beforeEnter: hasAccess,
        },
        {
          path: 'schedule',
          component: Schedule,
          children: [
            {
              path: '',
              name: 'scheduleDefault',
              components: {
                default: ScheduleAgendaPanel,
                right: ScheduleEventPreview,
                addBooking: ScheduleAddBooking,
                reschedule: ScheduleReschedule,
              },
            },
          ],
          beforeEnter: hasAccess,
        },
        {
          path: 'profile',
          name: 'profile',
          component: UserProfile,
        },
        {
          path: 'client/:id',
          component: Client,
          children: [
            { path: 'default', component: ClientUsers, alias: '' },
            {
              path: '',
              component: ClientUsers,
              name: 'client-users',
            },
          ],
        },
        {
          path: 'admintest',
          name: 'admintest',
          component: TestView,
        },
        {
          path: 'admin',
          name: 'admin',
          component: Admin,
          children: [
            { path: 'default', component: AdminDefault, alias: '' },
            {
              path: 'client/:id',
              name: 'admin-client',
              props: true,
              component: AdminClient,
            },
            {
              path: 'client/:id/account/:accountId',
              name: 'admin-client-account',
              props: true,
              component: AdminClientAccount,
            },
            {
              path: 'feature',
              name: 'feature',
              component: AdminFeature,
            },
            { path: '*', redirect: '/admin/client/default' },
          ],
        },
      ],
      beforeEnter: isAuthenticated,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/401',
      name: '401',
      component: Page401,
    },
    {
      path: '/register/',
      name: 'register',
      component: Register,
    },
    {
      path: '/register/:code',
      component: Register,
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: PasswordReset,
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },
    { path: '*', redirect: '/home' },
  ],
});
