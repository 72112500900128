<template>
    <div id="permissions-simple" class="content-wrapper">
      <div>
        <!-------------------------

        SIDEBAR

        ----------------------------->
        <bly-sidebar-item v-bind="{
            item: inputs,
            contextName: 'input',
            active: listType === 'input',
            select,
            add: showAddForm,
            addType: 'input',
            icon: 'input',
          }"></bly-sidebar-item>

        <bly-sidebar-item v-bind="{
            item: outputs,
            contextName: 'output',
            active: listType === 'output',
            select,
            add: showAddForm,
            addType: 'output',
            icon: 'output',
          }"></bly-sidebar-item>

        <bly-sidebar-item v-bind="{
            item: outputProfiles,
            contextName: 'outputProfile',
            active: listType === 'outputProfile',
            select,
            add: showAddForm,
            addType: 'outputProfile',
            icon: 'folder',
          }"></bly-sidebar-item>

        <bly-sidebar-item v-bind="{
            item: routers,
            contextName: 'routers',
            active: listType === 'routers',
            select,
            add: showAddForm,
            addType: 'router',
            icon: 'share',
          }"></bly-sidebar-item>

        <bly-sidebar-item v-bind="{
            item: routerGroups,
            contextName: 'routerGroups',
            active: listType === 'routerGroups',
            select,
            add: showAddForm,
            addType: 'routerGroup',
            icon: 'output',
          }"></bly-sidebar-item>

        <bly-sidebar-item v-bind="{
            item: multiviewers,
            contextName: 'multiviewer',
            active: listType === 'multiviewer',
            select,
            add: showAddForm,
            addType: 'multiviewer',
            icon: 'folder',
          }"></bly-sidebar-item>

        <bly-sidebar-item v-bind="{
            item: routerSources,
            contextName: 'sources',
            active: listType === 'sources',
            select,
            add: showAddForm,
            addType: 'routerSource',
            icon: 'folder',
          }"></bly-sidebar-item>

        <bly-sidebar-item v-bind="{
            item: routerDestinations,
            contextName: 'destinations',
            active: listType === 'destinations',
            select,
            add: showAddForm,
            addType: 'routerDestination',
            icon: 'folder',
          }"></bly-sidebar-item>

        <bly-sidebar-item v-bind="{
            item: streamSources,
            contextName: 'streamSources',
            active: listType === 'streamSources',
            select,
            add: showAddForm,
            addType: 'streamSource',
            icon: 'folder',
          }"></bly-sidebar-item>

        <bly-sidebar-item v-bind="{
            item: streamDestinations,
            contextName: 'streamDestinations',
            active: listType === 'streamDestinations',
            select,
            add: showAddForm,
            addType: 'streamDestination',
            icon: 'folder',
          }">
        </bly-sidebar-item>
        <bly-sidebar-item v-bind="{
            item: transcoderProfiles,
            contextName: 'transcoderProfiles',
            active: listType === 'transcoderProfiles',
            select,
            add: showAddForm,
            addType: 'transcoderProfile',
            icon: 'folder',
          }">
        </bly-sidebar-item>
      </div>

      <!-------------------------

        CONTENT LIST

      ----------------------------->
      <div class="data-content" v-loading="loading"
        element-loading-text="Loading data"
        element-loading-background="rgba(19, 25, 29, 1)">
        <div class="data-header">
          <div class="data-header-name">
            {{listName}} <span class="opacity-50">({{totalCount}} items)</span>
          </div>
          <div class="data-header-actions">
             <el-tooltip content="Toggle compact list">
               <i class="bi icon-btn bly-list"
                :class="{active: listCompactStyle}"
                @click="listCompactStyle = !listCompactStyle"></i>
             </el-tooltip>
          </div>
        </div>
        <div class="items" :class="{compact: listCompactStyle}">
          <transition-group name="list" tag="div">
            <div class="data-list-item"
              v-for="(item, index) in displayList"
              :key="item.id"
              :class="{ disabled: !item || item.id === 'missing' }"
              @click="selectItem(item)">
                <div class="data-list-item-name">
                  <span v-show="!item.editing">{{item.name}}
                    <span class="text-gray-500 ml-1" @click="copy(item.id)">{{item.id}}</span>
                    <span v-if="listType === 'output'" class="text-xs py-0.5 px-1 rounded  inline-block bg-red-100 text-red-500 ml-2 -mt-1 relative -top-0.5">{{item.status}}</span>
                  </span>
                  <span class="data-list-item-description" v-show="!item.editing">{{item.description | defaultValue('----')}}</span>
                  <el-input v-show="item.editing" v-model="item.name" size="mini"/>
                </div>
                <div class="data-list-item-actions">
                  <i
                    v-show="!item.editing"
                    class="bi bly-edit icon-btn" @click="editItem(item); item.editing = true"></i>
                  <el-popconfirm
                    confirmButtonText='OK'
                    cancelButtonText='Cancel'
                    @confirm="deleteItem(item, index, listType)"
                    title="Are you sure to delete this?"
                  >
                    <i
                    slot="reference"
                    v-show="!item.editing"
                    class="bi bly-delete icon-btn"></i>
                  </el-popconfirm>
                  <el-button
                    size="mini"
                    v-show="item.editing"
                    @click.native="cancelEdit(item, index);item.editing = false"
                    type="primary">Cancel</el-button>
                  <el-button
                    size="mini"
                    v-loading="item.saving"
                    v-show="item.editing"
                    @click.native="save(item, index)"
                    type="primary">Save</el-button>
                </div>
                <div class="claim-cascade" v-show="!listCompactStyle || item.editing">
                  <div>
                    <i class="bi bly-permissions"></i> Access Claims
                  </div>
                  <div class="claim-cascade-input">
                    <div v-if="!item.editing">
                      <el-tag
                        size="mini"
                        effect="dark"
                        type="info"
                        v-for="(c, index) in item.claimsCanAccess" :key="index + c">
                          {{claimsNames[c]}}
                      </el-tag>
                    </div>
                    <el-cascader
                      v-if="item.editing"
                      size="mini"
                      v-model="item.access"
                      :options="claimsTree"
                      :props="{ multiple: true, checkStrictly: true }"
                       :show-all-levels="false"
                      clearable></el-cascader>
                  </div>
                </div>
                <div class="claim-cascade" v-show="!listCompactStyle || item.editing">
                  <div>
                    <i class="bi bly-edit"></i> Editing Claims
                  </div>
                  <div class="claim-cascade-input">
                    <div v-if="!item.editing">
                      <el-tag
                        size="mini"
                        effect="dark"
                        v-for="(c, index) in item.claimsCanEdit" :key="index + c">
                        {{claimsNames[c]}}
                      </el-tag>
                    </div>
                    <el-cascader
                      size="mini"
                      v-if="item.editing"
                      v-model="item.edit"
                      :options="claimsTree"
                      :props="{ multiple: true, checkStrictly: true }"
                       :show-all-levels="false"
                      clearable></el-cascader>
                  </div>
                </div>
                <el-collapse-transition>
                  <div class="extra-fields" v-if="item.editing">
                    <el-row :gutter="12">
                      <bly-permission-form-item label="Description:"
                        v-if="listType !== 'routerGroups'">
                        <el-input v-model="item.description" size="mini"/>
                      </bly-permission-form-item>
                      <!-- ROUTER SPECIFIC DATA -->
                      <!-- ROUTER SPECIFIC DATA -->
                      <bly-permission-form-item label="Account:"
                        v-if="listType === 'routers'">
                        <el-select v-model="item.account"
                          placeholder="Select account"
                          value-key="id"
                          size="small">
                          <el-option
                            :key="account.id"
                            :value="account"
                            :label="account.client.name + ' > ' + account.name"
                            v-for="account in accounts">
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>


                      <!-- MULTIVIEWER SPECIFIC DATA -->
                      <bly-permission-form-item label="MV Type:"
                        v-if="listType === 'multiviewer'">
                        <el-select v-model="item.type"
                          placeholder="Multiviewer type"
                          size="mini">
                          <el-option
                            v-for="item in ['OTT', 'ROUTABLE']"
                            :key="item"
                            :value="item">
                            <span>{{item}}</span>
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>
                      <bly-permission-form-item label="Grid Size:"
                        v-if="listType === 'multiviewer'">
                        <el-input v-model="item.grid"
                          placeholder="Grid size"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>
                      <bly-permission-form-item label="Audio Control ID:"
                        v-if="listType === 'multiviewer'">
                        <el-input v-model="item.audioControlId"
                          placeholder="Audio control id"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Destination ID:"
                        v-if="listType === 'multiviewer'">
                        <el-input v-model="item.routableDestinationId"
                          placeholder="Destination ID"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>
                      <bly-permission-form-item label="Sources:"
                        :span="{sm: 24, md: 24, lg: 24}"
                        v-if="listType === 'multiviewer'">
                        <el-select v-model="item.streamSources"
                          placeholder="Select stream sources"
                          value-key="id"
                          multiple
                          filterable
                          size="small">
                          <el-option
                            :key="source.id + item.id"
                            :value="source"
                            :label="source.name"
                            v-for="source in mvStreamSources">
                            <span>{{source.name}}</span>
                            <small style="margin-left: 10px; opacity:0.75; float: right;">
                              {{source.description}}
                            </small>
                          </el-option>
                        </el-select>
                        <draggable tag="ul"  draggable=".item" v-model="item.streamSources" class="list-group mt-2" handle=".leading-5">
                          <li class="item list-group mb-1 p-0 px-2 bg-transparent h-8 leading-5" v-for="source in item.streamSources" :key="source.id">
                              <i class="handle far fa-bars"></i> {{source.name}}
                          </li>
                        </draggable>
                      </bly-permission-form-item>


                      <bly-permission-form-item label="Router Group:"
                        :span="{sm: 24, md: 24, lg: 24}"
                        v-if="listType === 'routers'">
                        <el-select v-model="item.routerGroups"
                          placeholder="Select"
                          value-key="id"
                          multiple
                          disabled
                          size="small">
                          <el-option
                            :key="el.id"
                            :value="el"
                            :label="el.name"
                            v-for="el in routerGroupList">
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Transcoder Profile:"
                        v-if="listType === 'transcoderProfiles'">
                        <el-input v-model="item.transcoderProfile"
                          placeholder="Transcoder Profile"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <!-- ROUTER SOURCE SPECIFIC DATA -->
                      <!-- ROUTER SOURCE SPECIFIC DATA -->
                      <bly-permission-form-item label="Origin router group:"
                        v-if="listType === 'sources'">
                        <el-input v-model="item.originRouterGroup"
                          placeholder="Origin router group"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Origin router ID:"
                        v-if="listType === 'sources'">
                        <el-input v-model="item.originRouterId"
                          placeholder="Origin router ID"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Origin router source ID:"
                        v-if="listType === 'sources'">
                        <el-input v-model="item.originRouterSourceId"
                          placeholder="Origin router source ID"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Inputs:"
                        :span="{sm: 24, md: 24, lg: 24}"
                        v-if="listType === 'sources'">
                        <el-select v-model="item.mappedInputs"
                          placeholder="Select inputs"
                          value-key="id"
                          multiple
                          filterable
                          size="small">
                          <el-option
                            :key="el.id"
                            :value="el"
                            :label="el.name"
                            v-for="el in sortedInputs">
                            <span>{{el.name}}</span>
                            <small style="margin-left: 10px; opacity:0.75; float: right;">
                              {{el.description}}
                            </small>
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Router Groups:"
                        :span="{sm: 24, md: 24, lg: 24}"
                        v-if="listType === 'sources'">
                        <el-select v-model="item.routerGroups"
                          placeholder="Select"
                          value-key="id"
                          multiple
                          disabled
                          size="small">
                          <el-option
                            :key="el.id"
                            :value="el"
                            :label="el.name"
                            v-for="el in routerGroupList">
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <!-- INPUT SPECIFIC DATA -->
                      <!-- INPUT SPECIFIC DATA -->
                      <bly-permission-form-item label="Input type:"
                        v-if="listType === 'input'">
                        <el-select v-model="item.type"
                          placeholder="Select input type"
                          size="mini">
                          <el-option
                            :key="type"
                            v-for="type in inputTypes" :value="type">
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>



                      <bly-permission-form-item label="File base name:"
                        v-if="listType === 'input'">
                        <el-input v-model="item.streamFileBaseName" size="mini"/>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Url:"
                        v-if="listType === 'input'">
                        <el-input v-model="item.url" size="mini"/>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="FRC Available"
                        v-if="listType === 'input'">
                        <el-switch v-model="item.frcAvailable" size="mini"/>
                      </bly-permission-form-item>
                      <bly-permission-form-item label="Probe:"
                        v-if="listType === 'input'">
                        <el-select v-model="item.probe"
                          placeholder="Select Probe"
                          filterable
                          value-key="key"
                          size="mini">
                          <el-option-group
                            v-for="probe in probes"
                            :key="probe.id"
                            :label="probe.name">
                            <el-option
                              v-for="item in probe.tasks"
                              :key="item.id"
                              :label="`${item.name} [${item.appId}]`"
                              :value="{...probe, task: item, key: item.id }">
                            </el-option>
                          </el-option-group>
                          <el-option
                            :key="probe.id"
                            v-for="probe in probes" :value="probe"> {{ probe.name }}
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Require Details"
                        v-if="listType === 'input'">
                        <el-switch v-model="item.requireDetails" size="mini"/>
                      </bly-permission-form-item>



                      <bly-permission-form-item label="Downlink Config:"
                        v-if="listType === 'input' && item.type === 'DOWNLINK'">
                        <div class="warning" v-if="!item.downlinkConfig">
                          <i class="bi bly-warning"></i>
                          Downlink config is missing
                        </div>
                        <div class="item-downlink-config" v-if="item.downlinkConfig">
                          <div>Satellite Name:
                            <strong>{{item.downlinkConfig.satelliteName}}</strong>
                          </div>
                          <div>
                            Frequency:
                            <strong>{{item.downlinkConfig.satelliteFrequency}}</strong>
                          </div>
                          <div>
                            Polarization:
                            <strong>{{item.downlinkConfig.satellitePolarization}}</strong>
                          </div>
                          <div>
                            BISS:
                            <strong>
                              {{item.downlinkConfig.BISS | defaultValue('----')}}
                            </strong>
                          </div>
                          <div>
                            Symbol Rate:
                            <strong>
                              {{item.downlinkConfig.symbolRate | defaultValue('----')}}
                            </strong>
                          </div>
                          <div>
                            Service:
                            <strong>
                              {{item.downlinkConfig.service | defaultValue('----')}}
                            </strong>
                          </div>
                        </div>
                      </bly-permission-form-item>

                      <!-- OUTPUT SPECIFIC DATA -->
                      <!-- OUTPUT SPECIFIC DATA -->
                      <bly-permission-form-item label="Display name:"
                        v-if="listType === 'output'">
                        <el-input v-model="item.displayName" size="mini"/>
                      </bly-permission-form-item>
                      <bly-permission-form-item label="Output type:"
                        v-if="listType === 'output'">
                        <el-select v-model="item.outputType"
                          placeholder="Select output type"
                          size="mini">
                          <el-option
                            :key="outputTypeItem"
                            v-for="outputTypeItem in outputTypes" :value="outputTypeItem">
                            {{outputTypeItem.replace('_', ' ')}}
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Output status:"
                        v-if="listType === 'output'">
                        <el-select v-model="item.status"
                          placeholder="Output Status"
                          size="small">
                          <el-option
                            :key="outputTypeItem"
                            v-for="outputTypeItem in status" :value="outputTypeItem">
                            {{outputTypeItem.replace('_', ' ')}}
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="OTT Platform:"
                        v-if="listType === 'output'">
                        <el-select v-model="item.ottPlatform"
                          placeholder="Select output type"
                          size="mini">
                          <el-option
                            :key="platformType"
                            v-for="platformType in ottPlatformTypes" :value="platformType">
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="OTT Server:"
                        v-if="listType === 'output'">
                        <el-input v-model="item.ottServer" size="mini"/>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="OTT Application:"
                        v-if="listType === 'output'">
                        <el-input v-model="item.ottApplication" size="mini"/>
                      </bly-permission-form-item>

                      <bly-permission-form-item label=""
                        v-if="listType === 'output'">
                        <el-checkbox v-model="item.ottNoStremKeyRequired">
                          No stream key required
                        </el-checkbox>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Url:"
                        v-if="listType === 'output'">
                        <el-input v-model="item.url" size="mini"/>
                      </bly-permission-form-item>

                      <!--
                      ---- OUTPUT PROFILE SPECIFIC DATA
                      ----
                      --->
                      <bly-permission-form-item label="Outputs:"
                        :span="{sm: 24, md: 24, lg: 24}"
                        v-if="listType === 'outputProfile'">
                        <el-select v-model="item.outputs"
                          multiple
                          placeholder="Select outputs"
                          value-key="id"
                          filterable
                          size="mini">
                          <el-option
                            v-for="(output, index) in sortedOutputs"
                            :key="item.id + output.id + index"
                            :label="output.name"
                            :value="output">
                            <span>{{output.name}}</span>
                            <small style="opacity: 0.75; float:right; margin-left: 10px;">
                              {{output.description}}
                            </small>
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>
                      <bly-permission-form-item label=""
                        :span="{sm: 24, md: 24, lg: 24}"
                        v-if="listType === 'outputProfile'">

                        <div v-if="itemAccountsFromSelectedClaims(item)">
                          <small style="color: red">
                            Some of the assigned outputs has wrong user rights.
                          </small>
                        </div>
                      </bly-permission-form-item>

                      <!-- ROUTER DESTINATION SPECIFIC DATA -->
                      <!-- ROUTER DESTINATION SPECIFIC DATA -->
                      <bly-permission-form-item label="Origin router group:"
                        v-if="listType === 'destinations'">
                        <el-input v-model="item.originRouterGroup"
                          placeholder="Origin router group"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Origin router ID:"
                        v-if="listType === 'destinations'">
                        <el-input v-model="item.originRouterId"
                          placeholder="Origin router ID"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Default Source:"
                        v-if="listType === 'destinations'">
                        <el-select v-model="item.routerDestinationDefaultSourceId"
                          placeholder="Select default source"
                          value-key="id"
                          filterable
                          size="small">
                          <el-option
                            :key="el.id"
                            :value="el"
                            :label="el.name"
                            v-for="el in sortedSources">
                            <span>{{el.name}}</span>
                            <small style="margin-left: 10px; opacity:0.75; float: right;">
                              {{el.description}}
                            </small>
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>
                      <bly-permission-form-item label="Origin router destination ID:"
                        v-if="listType === 'destinations'">
                        <el-input v-model="item.originRouterDestinationId"
                          placeholder="Origin router sestination ID"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Outputs:"
                        :span="{sm: 24, md: 24, lg: 24}"
                        v-if="listType === 'destinations'">
                        <el-select v-model="item.mappedOutputs"
                          placeholder="Select outputs"
                          value-key="id"
                          multiple
                          filterable
                          size="small">
                          <el-option
                            :key="el.id"
                            :value="el"
                            :label="el.name"
                            v-for="el in sortedOutputs">
                            <span>{{el.name}}</span>
                            <small style="margin-left: 10px; opacity:0.75; float: right;">
                              {{el.description}}
                            </small>
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Router Groups:"
                        :span="{sm: 24, md: 24, lg: 24}"
                        v-if="listType === 'destinations'">
                        <el-select v-model="item.routerGroups"
                          placeholder="Select"
                          value-key="id"
                          multiple
                          disabled
                          size="small">
                          <el-option
                            :key="el.id"
                            :value="el"
                            :label="el.name"
                            v-for="(el) in routerGroupList">
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <!-- ROUTER GROUP SPECIFIC DATA -->
                      <bly-permission-form-item label="Router:"
                        v-if="listType === 'routerGroups'">
                        <el-select v-model="item.router"
                          placeholder="Select router"
                          value-key="id"
                          size="small">
                          <el-option
                            :key="router.id"
                            :value="router"
                            :label="router.name + ' (' + router.account.name + ')'"
                            v-for="router in routers">
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Sources:"
                        :span="{sm: 24, md: 24, lg: 24}"
                        v-if="listType === 'routerGroups'">
                        <el-select v-model="item.sources"
                          placeholder="Select sources"
                          value-key="id"
                          multiple
                          filterable
                          size="small">
                          <el-option
                            :key="source.id + item.id"
                            :value="source"
                            :label="source.name"
                            v-for="source in sortedSources">
                            <span>{{source.name}}</span>
                            <small style="margin-left: 10px; opacity:0.75; float: right;">
                              {{source.description}}
                            </small>
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Destinations:"
                        :span="{sm: 24, md: 24, lg: 24}"
                        v-if="listType === 'routerGroups'">
                        <el-select v-model="item.destinations"
                          placeholder="Select destinations"
                          value-key="id"
                          multiple
                          filterable
                          size="small">
                          <el-option
                            :key="dest.id + item.id + dest.name"
                            :value="dest"
                            :label="dest.name"
                            v-for="dest in sortedDest">
                            <span>{{dest.name}}</span>
                            <small style="margin-left: 10px; opacity:0.75; float: right;">
                              {{dest.description}}
                            </small>
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <!-- STREAM DESTINATION SPECIFIC DATA -->
                      <!-- STREAM DESTINATION SPECIFIC DATA -->
                      <bly-permission-form-item label="Router destination ID:"
                        v-if="listType === 'streamDestinations'">
                        <el-select v-model="item.streamDestinationRouterDestinationId"
                          placeholder="Select router destination"
                          value-key="id"
                          filterable
                          size="small">
                          <el-option
                            :key="el.id"
                            :value="el.id"
                            :label="el.name"
                            v-for="el in sortedRouterDestinations">
                            <span>{{el.name}}</span>
                            <small style="margin-left: 10px; opacity:0.75; float: right;">
                              {{el.description}}
                            </small>
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Type:"
                        v-if="listType === 'streamDestinations'">
                        <el-select v-model="item.type"
                          placeholder="Select stream type"
                          size="small">
                          <el-option
                            :key="el"
                            :value="el"
                            :label="el"
                            v-for="el in streamTypes">
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Port:"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.port"
                          placeholder="Port"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Address:"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.address"
                          placeholder="Address"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Talkback Address:"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.talkbackAddress"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Talkback Port:"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.talkbackPort"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Latency (ms):"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.latencyMs"
                          placeholder="Latency "
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Buffer Max (ms):"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.bufferMaxMs"
                          placeholder=""
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Buffer Min (ms):"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.bufferMinMs"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Enc Passphrase:"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.encPassphrase"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Enc Key Length:"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.encKeyLen"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Enc Type:"
                        v-if="listType === 'streamDestinations'">
                        <el-select v-model="item.encType"
                          placeholder="Select enc type"
                          size="small">
                          <el-option
                            :key="el"
                            :value="el"
                            :label="el"
                            v-for="el in streamEncryptionTypes">
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="UDP Address:"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.udpAddress"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="UDP Port:"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.udpPort"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="UDP Rtt:"
                        v-if="listType === 'streamDestinations'">
                        <el-input v-model="item.udpRtt"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <!-- STREAM SOURCE SPECIFIC DATA -->
                      <!-- STREAM SOURCE SPECIFIC DATA -->
                      <bly-permission-form-item label="Type:"
                        v-if="listType === 'streamSources'">
                        <el-select v-model="item.type"
                          placeholder="Select stream type"
                          size="small">
                          <el-option
                            :key="el"
                            :value="el"
                            :label="el"
                            v-for="el in streamTypes">
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Port:"
                        v-if="listType === 'streamSources'">
                        <el-input v-model="item.port"
                          placeholder="Port"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Address:"
                        v-if="listType === 'streamSources'">
                        <el-input v-model="item.address"
                          placeholder="Address"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Talkback Address:"
                        v-if="listType === 'streamSources'">
                        <el-input v-model="item.talkbackAddress"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Talkback Port:"
                        v-if="listType === 'streamSources'">
                        <el-input v-model="item.talkbackPort"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Latency (ms):"
                        v-if="listType === 'streamSources'">
                        <el-input v-model="item.latencyMs"
                          placeholder="Latency "
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Buffer Max (ms):"
                        v-if="listType === 'streamSources'">
                        <el-input v-model="item.bufferMaxMs"
                          placeholder=""
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Buffer Min (ms):"
                        v-if="listType === 'streamSources'">
                        <el-input v-model="item.bufferMinMs"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Enc Passphrase:"
                        v-if="listType === 'streamSources'">
                        <el-input v-model="item.encPassphrase"
                          required
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Enc Key Length:"
                        v-if="listType === 'streamSources'">
                        <el-input v-model="item.encKeyLen"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="Enc Type:"
                        v-if="listType === 'streamSources'">
                        <el-select v-model="item.encType"
                          placeholder="Select enc type"
                          size="small">
                          <el-option
                            :key="el"
                            :value="el"
                            :label="el"
                            v-for="el in streamEncryptionTypes">
                          </el-option>
                        </el-select>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="UDP Address:"
                        v-if="listType === 'streamSources'">
                        <el-input v-model="item.udpAddress"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>

                      <bly-permission-form-item label="UDP Port:"
                        v-if="listType === 'streamSources'">
                        <el-input v-model="item.udpPort"
                          size="small">
                        </el-input>
                      </bly-permission-form-item>
                    </el-row>
                  </div>
                </el-collapse-transition>
            </div>
          </transition-group>

          <pagination v-model="page" :per-page="50" :records="totalCount" @paginate="loadPage" :options="options"/>
        </div>
      </div>
      <!-------------------------

        ADD ITEM

      ----------------------------->
      <el-dialog
        :title="'Create ' + formType"
        :visible.sync="showAddDialog"
        :close-on-click-modal="false"
        width="360px">
        <div class="dialog-form" v-if="showAddDialog">
          <el-form ref="addItemForm" :model="form" label-position="top">
            <!-- BASIC FIELDS -->
            <!-- BASIC FIELDS -->
            <el-form-item required  prop="name">
              <el-input v-model="form.name" placeholder="Name"></el-input>
            </el-form-item>
            <el-form-item prop="description" v-show="formType !== 'routerGroup'">
              <el-input type="text" v-model="form.description"
                size="small"
                placeholder="Description"></el-input>
            </el-form-item>

            <el-form-item prop="id">
              <el-input type="text" v-model="form.id"
                size="small"
                placeholder="id"></el-input>
            </el-form-item>

            <div v-show="formType === 'transcoderProfile'">
              <el-divider content-position="left"
              >Transcoder Profile specific data</el-divider>
              <el-form-item prop="description" v-show="formType === 'transcoderProfile'">
                <el-input type="text" v-model="form.transcoderProfile.transcoderProfile"
                  size="small"
                  required
                  placeholder="Transcoder Profile"></el-input>
              </el-form-item>
            </div>

            <!-- accounts -->
            <!-- accounts -->
            <div v-if="formType === 'input' || formType === 'output'">
              <el-divider content-position="left">Map to accounts</el-divider>
              <small>
                Please set access permissions for the added account,
                otherwise users won't be able to access it
              </small>
              <el-form-item prop="ottPlatform">
                <el-select v-model="form.accounts"
                  placeholder="Select account"
                  value-key="id"
                  multiple
                  size="small">
                  <el-option
                    :key="account.id"
                    :value="account"
                    :label="account.client.name + ' > ' + account.name"
                    v-for="account in accounts">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>


            <!-- INPUT SPECIFIC FIELDS -->
            <!-- INPUT SPECIFIC FIELDS -->
            <div v-if="formType === 'input'">
              <el-divider content-position="left">Input specific data</el-divider>

              <el-form-item prop="streamFileBaseName">
                <el-input v-model="form.input.streamFileBaseName"
                  size="small"
                  placeholder="Stream file base name"></el-input>
              </el-form-item>


              <el-form-item prop="ottPlatform">
                <el-select v-model="form.input.type"
                  placeholder="Select input type"
                  size="small">
                  <el-option
                    :key="inputType"
                    v-for="inputType in inputTypes" :value="inputType">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item prop="inputDownlinkConfigId">
                <el-input v-model="form.input.inputDownlinkConfigId"
                  size="small"
                  placeholder="Downlink config ID"></el-input>
              </el-form-item>
              <el-form-item prop="frcAvailable">
                <el-switch v-model="form.input.frcAvailable"
                  size="small"
                   active-text="FRC Available"></el-switch>
              </el-form-item>
              <el-form-item prop="requireDetails">
                <el-switch v-model="form.input.requireDetails"
                  size="small"
                   active-text="Require Details"></el-switch>
              </el-form-item>
            </div>

            <!-- ROUTER SPECIFIC FIELDS -->
            <!-- ROUTER SPECIFIC FIELDS -->
            <div v-if="formType === 'router'">
              <el-divider content-position="left">Router specific data</el-divider>

              <el-form-item prop="routerAccountId">
                <el-select v-model="form.router.routerAccountId"
                  placeholder="Select account"
                  value-key="id"
                  size="small">
                  <el-option
                    :key="account.id"
                    :value="account"
                    :label="account.client.name + ' > ' + account.name"
                    v-for="account in accounts">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <!-- OUTPUT SPECIFIC FIELDS -->
            <!-- OUTPUT SPECIFIC FIELDS -->
            <div v-if="formType === 'output'">
              <el-divider content-position="left">Output specific data</el-divider>
              <el-form-item prop="displayName">
                <el-input v-model="form.output.displayName"
                  size="small"
                  placeholder="Display Name"></el-input>
              </el-form-item>
              <el-form-item prop="output.outputType" required="">
                <el-select v-model="form.output.outputType"
                  placeholder="Output Type"
                  size="small">
                  <el-option
                    :key="outputTypeItem"
                    v-for="outputTypeItem in outputTypes" :value="outputTypeItem">
                    {{outputTypeItem.replace('_', ' ')}}
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item prop="output.status" required="">
                <el-select v-model="form.output.status"
                  placeholder="Output Status"
                  size="small">
                  <el-option
                    :key="outputTypeItem"
                    v-for="outputTypeItem in status" :value="outputTypeItem">
                    {{outputTypeItem.replace('_', ' ')}}
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item prop="ottServer" v-show="form.output.outputType === 'OTT'">
                <el-input v-model="form.output.ottServer"
                  size="small"
                  placeholder="OTT Server"></el-input>
              </el-form-item>

              <el-form-item prop="ottPlatform" v-show="form.output.outputType === 'OTT'">
                <el-select v-model="form.output.ottPlatform"
                  placeholder="Select OTT platform type"
                  size="small">
                  <el-option
                    :key="platformType"
                    v-for="platformType in ottPlatformTypes" :value="platformType">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item prop="ottApplication" v-show="form.output.outputType === 'OTT'">
                <el-input v-model="form.output.ottApplication"
                  size="small"
                  placeholder="OTT Application"></el-input>
              </el-form-item>

              <el-form-item prop="ottNoStremKeyRequired" v-show="form.output.outputType === 'OTT'">
                <el-checkbox
                  v-model="form.output.ottNoStremKeyRequired"
                  label="Stream key not required"
                  size="small"
                  placeholder="OTT Application"/>
              </el-form-item>
            </div>
            <!-- OUTPUTPROFILE SPECIFIC FIELDS -->
            <div v-if="formType === 'outputProfile'">
              <el-divider content-position="left">Output profile specific data</el-divider>
              <!-- {{accountsFromSelectedClaims}} -->
              <el-form-item prop="outputs">
                <el-select v-model="form.outputProfile.outputs"
                  multiple
                  collapse-tags
                  filterable
                  placeholder="Select outputs"
                  size="mini">
                  <el-option
                    v-for="item in sortedOutputs"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    <span>{{item.name}}</span>
                    <small style="opacity: 0.75; float:right; margin-left: 10px;">
                      {{item.description}}
                    </small>
                  </el-option>
                </el-select>
                <small v-show="doesUserHaveAccessToOutputs" style="color: red">
                  Some of the assigned outputs has wrong user rights.
                </small>
              </el-form-item>
            </div>

            <!-- MULTIVIEWERS SPECIFIC FIELDS -->
            <div v-if="formType === 'multiviewer'">
              <el-divider content-position="left">Multiviewers specific data</el-divider>
              <!-- {{accountsFromSelectedClaims}} -->
              <el-form-item prop="audioControlId">
                <el-input v-model="form.multiviewer.audioControlId"
                  placeholder="Audio control id"
                  required
                  size="small">
                </el-input>
              </el-form-item>
              <el-form-item prop="routableDestinationId">
                <el-input v-model="form.multiviewer.routableDestinationId"
                  placeholder="Destination id"
                  required
                  size="small">
                </el-input>
              </el-form-item>
              <el-form-item prop="type">
                <el-select v-model="form.multiviewer.type"
                  placeholder="Multiviewer type"
                  size="mini">
                  <el-option
                    v-for="item in ['OTT', 'ROUTABLE']"
                    :key="item"
                    :value="item">
                    <span>{{item}}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="outputs">
                <el-select v-model="form.multiviewer.streamSources"
                  multiple
                  collapse-tags
                  filterable
                  placeholder="Select stream sources"
                  size="mini">
                  <el-option
                    v-for="item in mvStreamSources"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    <span>{{item.name}}</span>
                    <small style="opacity: 0.75; float:right; margin-left: 10px;">
                      {{item.description}}
                    </small>
                  </el-option>
                </el-select>
                <!-- <small v-show="doesUserHaveAccessToOutputs" style="color: red">
                  Some of the assigned outputs has wrong user rights.
                </small> -->
                <!-- <ul class="list-group " v-sortable="{ handle: '.handle' }">
                    <li class="list-group-item mb-4 p-3 bg-gray-100" v-for="source in form.multiviewer.streamSources" :key="source">
                      {{sourceNameById(source)}} <i class="handle far fa-bars"></i>
                    </li>

                </ul> -->
                 <draggable tag="ul"  draggable=".item" v-model="form.multiviewer.streamSources" class="list-group mt-2" handle=".handle">
                  <li class="item list-group mb-1 p-1 px-2 bg-gray-100" v-for="source in form.multiviewer.streamSources" :key="source">
                      <i class="handle far fa-bars"></i> {{sourceNameById(source)}}
                  </li>
                </draggable>
              </el-form-item>
              <el-form-item prop="grid">
                <el-input v-model="form.multiviewer.grid"
                  size="small"
                  placeholder="Multiviewer grid size"></el-input>
              </el-form-item>
            </div>

            <!-- ROUTER GROUP SPECIFIC FIELDS -->
            <!-- ROUTER GROUP SPECIFIC FIELDS -->
            <div v-if="formType === 'routerGroup'">
              <el-divider content-position="left">Router Group specific data</el-divider>

              <el-form-item prop="routerAccountId">
                <el-select v-model="form.routerGroup.routerGroupRouterId"
                  placeholder="Select router"
                  value-key="id"
                  required
                  size="small">
                  <el-option
                    :key="el.id"
                    :value="el.id"
                    :label="el.name"
                    v-for="el in routers">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item prop="routerGroup.sources">
                <el-select v-model="form.routerGroup.sources"
                  placeholder="Select sources"
                  value-key="id"
                  required
                  multiple
                  filterable
                  size="small">
                  <el-option
                    :key="el.id"
                    :value="el"
                    :label="el.name"
                    v-for="el in sortedSources">
                    <span>{{el.name}}</span>
                    <small style="margin-left: 10px; opacity:0.75; float: right;">
                      {{el.description}}
                    </small>
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item prop="routerGroup.destinations">
                <el-select v-model="form.routerGroup.destinations"
                  placeholder="Select destinations"
                  value-key="id"
                  required
                  filterable
                  multiple
                  size="small">
                  <el-option
                    :key="el.id"
                    :value="el"
                    :label="el.name"
                    v-for="el in sortedDest">
                    <span>{{el.name}}</span>
                    <small style="margin-left: 10px; opacity:0.75; float: right;">
                      {{el.description}}
                    </small>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <!-- ROUTER SOURCE SPECIFIC FIELDS -->
            <div v-if="formType === 'routerSource'">
              <el-divider content-position="left">Router Source specific data</el-divider>
              <el-form-item prop="mappedInputs">
                <el-select v-model="form.routerSource.mappedInputs"
                  placeholder="Select inputs"
                  value-key="id"
                  multiple
                  filterable
                  size="small">
                  <el-option
                    :key="el.id"
                    :value="el"
                    :label="el.name"
                    v-for="el in sortedInputs">
                    <span>{{el.name}}</span>
                    <small style="margin-left: 10px; opacity:0.75; float: right;">
                      {{el.description}}
                    </small>
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item prop="routerSource.originRouterGroup" required>
                <el-input v-model="form.routerSource.originRouterGroup"
                  placeholder="Origin router group"
                  required
                  size="small">
                </el-input>
              </el-form-item>

              <el-form-item prop="routerSource.originRouterId" required>
                <el-input v-model="form.routerSource.originRouterId"
                  placeholder="Origin router ID"
                  required
                  size="small">
                </el-input>
              </el-form-item>

              <el-form-item prop="routerSource.originRouterSourceId" required>
                <el-input v-model="form.routerSource.originRouterSourceId"
                  placeholder="Origin router source id"
                  required
                  size="small">
                </el-input>
              </el-form-item>
            </div>

            <!-- ROUTER DESTINATION SPECIFIC FIELDS -->
            <!-- ROUTER DESTINATION SPECIFIC FIELDS -->
            <div v-if="formType === 'routerDestination'">
              <el-divider content-position="left">Router Destination specific data</el-divider>
              <el-form-item prop="mappedOutputs">
                <el-select v-model="form.routerDestination.mappedOutputs"
                  placeholder="Select outputs"
                  value-key="id"
                  multiple
                  filterable
                  size="small">
                  <el-option
                    :key="el.id"
                    :value="el"
                    :label="el.name"
                    v-for="el in sortedOutputs">
                    <span>{{el.name}}</span>
                    <small style="margin-left: 10px; opacity:0.75; float: right;">
                      {{el.description}}
                    </small>
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item prop="routerDestination.originRouterGroup" required>
                <el-input v-model="form.routerDestination.originRouterGroup"
                  placeholder="Origin router group"
                  size="small">
                </el-input>
              </el-form-item>

              <el-form-item prop="routerDestination.originRouterId" required>
                <el-input v-model="form.routerDestination.originRouterId"
                  placeholder="Origin router ID"
                  size="small">
                </el-input>
              </el-form-item>

              <el-form-item prop="routerDestination.originRouterDestinationId" required>
                <el-input v-model="form.routerDestination.originRouterDestinationId"
                  placeholder="Origin router destination ID"
                  size="small">
                </el-input>
              </el-form-item>

              <el-form-item prop="routerDestination.routerDestinationDefaultSourceId">
                <el-select v-model="form.routerDestination.routerDestinationDefaultSourceId"
                  placeholder="Select default source"
                  value-key="id"
                  filterable
                  size="small">
                  <el-option
                    :key="el.id"
                    :value="el"
                    :label="el.name"
                    v-for="el in sortedSources">
                    <span>{{el.name}}</span>
                    <small style="margin-left: 10px; opacity:0.75; float: right;">
                      {{el.description}}
                    </small>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <!-- STREAM DESTINATION SPECIFIC FIELDS -->
            <!-- STREAM DESTINATION SPECIFIC FIELDS -->
            <div v-if="formType === 'streamDestination'">
              <el-divider content-position="left">Stream Destination specific data</el-divider>

              <el-form-item prop="streamDestination.type" required>
                <el-select v-model="form.streamDestination.type"
                  placeholder="Select stream type"
                  size="small">
                  <el-option
                    :key="el"
                    :value="el"
                    :label="el"
                    v-for="el in streamTypes">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-row :gutter="14">
                <el-col :span="15">
                  <el-form-item prop="streamDestination.udpAddress">
                    <el-input v-model="form.streamDestination.udpAddress"
                      placeholder="UDP Address"
                      required
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item prop="streamDestination.udpPort">
                    <el-input type="number" v-model="form.streamDestination.udpPort"
                      placeholder="UDP Port"
                      required
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item prop="streamDestination.udpRtt">
                <el-input v-model="form.streamDestination.udpRtt"
                  type="number"
                  placeholder="UDP Rtt"
                  required
                  size="small">
                </el-input>
              </el-form-item>

              <el-form-item prop="streamDestination.streamDestinationRouterDestinationId">
                <el-select v-model="form.streamDestination.streamDestinationRouterDestinationId"
                  placeholder="Select router destination"
                  value-key="id"
                  filterable
                  size="small">
                  <el-option
                    :key="el.id"
                    :value="el.id"
                    :label="el.name"
                    v-for="el in sortedRouterDestinations">
                    <span>{{el.name}}</span>
                    <small style="margin-left: 10px; opacity:0.75; float: right;">
                      {{el.description}}
                    </small>
                  </el-option>
                </el-select>
              </el-form-item>

              <el-row :gutter="14">
                <el-col :span="15">
                  <el-form-item prop="streamDestination.address">
                    <el-input v-model="form.streamDestination.address"
                      placeholder="Address"
                      required
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item prop="streamDestination.port">
                    <el-input v-model="form.streamDestination.port"
                      placeholder="Port"
                      type="number"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="14">
                <el-col :span="15">
                  <el-form-item prop="streamDestination.talkbackAddress">
                    <el-input v-model="form.streamDestination.talkbackAddress"
                      placeholder="Talkback Address"
                      required
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item prop="streamDestination.talkbackPort">
                    <el-input v-model="form.streamDestination.talkbackPort"
                      placeholder="Talkback Port"
                      type="number"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="14">
                <el-col :span="8">
                  <el-form-item prop="streamDestination.latencyMs">
                    <el-input v-model="form.streamDestination.latencyMs"
                      placeholder="Latency (ms)"
                      type="number"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="streamDestination.bufferMaxMs">
                    <el-input v-model="form.streamDestination.bufferMaxMs"
                      placeholder="Max Buffer (ms)"
                      type="number"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="streamDestination.bufferMinMs">
                    <el-input v-model="form.streamDestination.bufferMinMs"
                      placeholder="Min Buffer (ms)"
                      type="number"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item prop="streamDestination.encPassphrase">
                <el-input v-model="form.streamDestination.encPassphrase"
                  placeholder="Enc Passphrase"
                  required
                  size="small">
                </el-input>
              </el-form-item>

              <el-row :gutter="14">
                <el-col :span="12">
                  <el-form-item prop="streamDestination.encKeyLen">
                    <el-input v-model="form.streamDestination.encKeyLen"
                      placeholder="Enc Key Length"
                      type="number"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="streamDestination.encType">
                    <el-select v-model="form.streamDestination.encType"
                      placeholder="Select enc type"
                      size="small">
                      <el-option
                        :key="el"
                        :value="el"
                        :label="el"
                        v-for="el in streamEncryptionTypes">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <!-- STREAM SOURCE SPECIFIC FIELDS -->
            <!-- STREAM SOURCE SPECIFIC FIELDS -->
            <div v-if="formType === 'streamSource'">
              <el-divider content-position="left">Stream Destination specific data</el-divider>

              <el-form-item prop="streamSource.type" required>
                <el-select v-model="form.streamSource.type"
                  placeholder="Select stream type"
                  size="small">
                  <el-option
                    :key="el"
                    :value="el"
                    :label="el"
                    v-for="el in streamTypes">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-row :gutter="14">
                <el-col :span="15">
                  <el-form-item prop="streamSource.udpAddress">
                    <el-input v-model="form.streamSource.udpAddress"
                      placeholder="UDP Address"
                      required
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item prop="streamSource.udpPort">
                    <el-input v-model="form.streamSource.udpPort"
                      placeholder="UDP Port"
                      type="number"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="14">
                <el-col :span="15">
                  <el-form-item prop="streamSource.address">
                    <el-input v-model="form.streamSource.address"
                      placeholder="Address"
                      required
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item prop="streamSource.port">
                    <el-input v-model="form.streamSource.port"
                      placeholder="Port"
                      type="number"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="14">
                <el-col :span="15">
                  <el-form-item prop="streamSource.talkbackAddress">
                    <el-input v-model="form.streamSource.talkbackAddress"
                      placeholder="Talkback Address"
                      required
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item prop="streamSource.talkbackPort">
                    <el-input v-model="form.streamSource.talkbackPort"
                      placeholder="Talkback Port"
                      required
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="14">
                <el-col :span="8">
                  <el-form-item prop="streamSource.latencyMs">
                    <el-input v-model="form.streamSource.latencyMs"
                      placeholder="Latency (ms)"
                      type="number"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="streamSource.bufferMaxMs">
                    <el-input v-model="form.streamSource.bufferMaxMs"
                      placeholder="Max Buffer (ms)"
                      type="number"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <div v-if="formType === 'streamSource'">
                    <el-form-item prop="streamSource.bufferMinMs">
                      <el-input v-model="form.streamSource.bufferMinMs"
                        placeholder="Min Buffer (ms)"
                        type="number"
                        size="small">
                      </el-input>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>

              <el-form-item prop="streamSource.encPassphrase">
                <el-input v-model="form.streamSource.encPassphrase"
                  placeholder="Enc Passphrase"
                  required
                  size="small">
                </el-input>
              </el-form-item>

              <el-row :gutter="14">
                <el-col :span="12">
                  <el-form-item prop="streamSource.encKeyLen">
                    <el-input v-model="form.streamSource.encKeyLen"
                      placeholder="Enc Key Length"
                      type="number"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="streamSource.encType">
                    <el-select v-model="form.streamSource.encType"
                      placeholder="Select enc type"
                      size="small">
                      <el-option
                        :key="el"
                        :value="el"
                        :label="el"
                        v-for="el in streamEncryptionTypes">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <!-- CLAIMS -->
            <el-divider content-position="left">Claims</el-divider>
            <el-form-item prop="claimsCanAccess" required="">
              <div class="claim-cascade">
                <div class="claim-cascade-input">
                  <i class="bi bly-permissions"></i>
                  <el-cascader
                    size="small"
                    placeholder="Select access claims"
                    v-model="form.claimsCanAccess"
                    :options="claimsTree"
                    collapse-tags
                    :props="{ multiple: true, checkStrictly: true }"
                    :show-all-levels="false"
                    clearable>
                    </el-cascader>
                </div>
              </div>
              <small v-if="formType === 'outputProfile'">
                Please specify account users <el-tag size="mini">(usr.{acc}.{client})</el-tag><br>
                for Output Profiles due to list visibility reasons
              </small>
            </el-form-item>
            <el-form-item prop="claimsCanEdit" required="">
              <div class="claim-cascade">
                  <div class="claim-cascade-input">
                    <i class="bi bly-edit"></i>
                    <el-cascader
                      size="small"
                      placeholder="Select edit claims"
                      v-model="form.claimsCanEdit"
                      :options="claimsTree"
                      collapse-tags
                      :props="{ multiple: true, checkStrictly: true }"
                      :show-all-levels="false"
                      clearable></el-cascader>
                  </div>
                </div>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showAddDialog = false; resetForm()">Cancel</el-button>
          <el-button type="primary" @click="addItem(form)">Save</el-button>
        </span>
      </el-dialog>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import draggable from 'vuedraggable';
import Pagination from 'vue-pagination-2';
import PaginationTemplate from './PaginationTemplate.vue';
import ProbesServices from '../services/Probes';

export default {
  components: {
    draggable,
    Pagination,
  },
  data() {
    return {
      // psgination
      page: 1,
      pageSize: 50,
      sortedList: [],
      options: {
        template: PaginationTemplate,
        edgeNavigation: true,
      },
      probes: [],
      //
      listCompactStyle: true,
      activeList: [],
      activeItem: null,
      listType: '',
      inputList: null,
      loading: false,
      showAddDialog: false,
      formType: '',
      editedItem: null,
      inEditMode: false,
      varPlaceholder: null,
      form: {
        name: '',
        description: null,
        claimsCanAccess: [],
        claimsCanEdit: [],
        accounts: [],
        input: {
          streamFileBaseName: null,
          type: null,
          inputDownlinkConfigId: null,
          url: null,
          frcAvailable: null,
          requireDetails: null,
          probeProjectId: null,
          probeTaskId: null,
        },
        transcoderProfile: {
          transcoderProfile: null,
        },
        multiviewer: {
          grid: 2,
          streamSources: [],
          routableDestinationId: null,
          type: null,
        },
        output: {
          outputType: null,
          ottApplication: null,
          ottNoStremKeyRequired: false,
          ottPlatform: null,
          status: null,
          ottServer: null,
          displayName: null,
          url: null,
        },
        outputProfile: {
          outputs: [],
        },
        router: {
          routerAccountId: null,
          routerGroups: [],
        },
        routerSource: {
          originRouterGroup: null,
          originRouterId: null,
          originRouterSourceId: null,
          routerGroups: [],
          mappedInputs: [],
        },
        routerDestination: {
          originRouterDestinationId: null,
          originRouterGroup: null,
          originRouterId: null,
          routerDestinationRoutedSourceId: null,
          routerDestinationDefaultSourceId: null,
          routerGroups: [],
          mappedInputs: [],
          mappedOutputs: [],
        },
        routerGroup: {
          routerGroupRouterId: null,
          sources: [],
          destinations: [],
        },
        streamSource: {
          type: null,
          port: null,
          address: null,
          talkbackAddress: null,
          talkbackPort: null,

          latencyMs: null,
          bufferMaxMs: null,
          bufferMinMs: null,
          encPassphrase: null,
          encKeyLen: null,
          encType: null,
          udpAddress: null,
          udpPort: null,
        },
        streamDestination: {
          type: null,
          streamDestinationRouterDestinationId: null,
          port: null,
          address: null,
          talkbackAddress: null,
          talkbackPort: null,

          latencyMs: null,
          bufferMaxMs: null,
          bufferMinMs: null,
          encPassphrase: null,
          encKeyLen: null,
          encType: null,
          udpAddress: null,
          udpPort: null,
          udpRtt: null,
        },
      },
      status: [
        'ONLINE',
        'OFFLINE',
        'DELETED',
      ],
      outputTypes: [
        'BROADCAST',
        'OTT',
        'BROADCAST_FIXED',
        'PLAYOUT',
        'RECORDING',
        'STREAM',
      ],
      ottPlatformTypes: [
        'FACEBOOK',
        'GENERIC',
        'HLS',
        'RTMP',
        'TWITCH',
        'TWITTER',
        'YOUTUBE',
      ],
      inputTypes: [
        'DOWNLINK',
        'ROUTE',
      ],
    };
  },
  computed: {
    ...mapState({
      // loading: state => state.permissions.loading,
      clients: (state) => state.permissions.clients,
      claims: (state) => state.permissions.claims,
      inputs: (state) => state.permissions.inputs,
      outputs: (state) => state.permissions.outputs,
      multiviewers: (state) => state.permissions.multiviewers,
      outputsById: (state) => state.permissions.outputsById,
      outputProfiles: (state) => state.permissions.outputProfiles,
      routers: (state) => state.permissions.routers,
      routerGroups: (state) => state.permissions.routerGroups,
      routerDestinations: (state) => state.permissions.routerDestinations,
      routerSources: (state) => state.permissions.routerSources,
      streamDestinations: (state) => state.stream.streamDestinations,
      streamSources: (state) => state.stream.streamSources,
      userSession: (state) => state.userAccount.userSession,
      transcoderProfiles: (state) => state.permissions.transcoderProfiles,
      streamEncryptionTypes: (state) => state.stream.encryptionTypes,
      streamTypes: (state) => state.stream.streamTypes,
    }),
    userId() {
      return this.userSession.idToken.payload.sub;
    },
    claimsByCode() {
      const claims = {};
      this.claims.forEach((claim) => {
        claims[claim.code] = claim;
      });
      return claims;
    },
    displayList() {
      const start = this.page * this.pageSize - this.pageSize;
      const end = start + this.pageSize;
      const newList = this.sortedList.slice(start, end);
      console.log('displayList', start, end, this.page, this.pageSize)
      return newList;
    },

    listName() {
      return this.listType.split(/(?=[A-Z])/).join(' ');
    },

    totalCount() {
      return this.sortedList.length;
    },

    mvStreamSources() {
      return this.streamSources
        .filter((item) => item.type === 'HLS' || item.type === 'DASH');
    },
    totalItems() {
      return this.sortedList.length;
    },

    // output profile - outputs access test
    //
    accountsFromSelectedClaims() {
      const accounts = this.form.claimsCanAccess
        .flat(Infinity)
        .map((claim) => claim.split('.').pop());
      return accounts;
    },

    selectedProfileOutputs() {
      let outputs = [];
      this.form.outputProfile.outputs.forEach((el) => {
        outputs = [
          ...outputs,
          this.outputsById[el],
        ];
      });

      return outputs;
    },

    selectedProfileOutputAccounts() {
      const accounts = this.selectedProfileOutputs
        .map((el) => el.claimsCanAccess)
        .flat(Infinity)
        .map((el) => el.split('.').pop());
      return [...new Set(accounts)];
    },

    doesUserHaveAccessToOutputs() {
      let doesNotHaveAccess = false;

      this.selectedProfileOutputAccounts.forEach((el) => {
        if (this.accountsFromSelectedClaims.indexOf(el) === -1) doesNotHaveAccess = true;
      });

      return doesNotHaveAccess;
    },
    //
    // end output profile - outputs access test

    // output profile edit - outputs access test
    //
    itemAccountsFromSelectedClaims() {
      return (item) => {
        console.log('item =>', item);
        const accountsClaims = item.claimsCanAccess
          .map((claim) => claim.split('.').pop());

        const accounts = item.outputs
          .map((el) => el.claimsCanAccess)
          .flat(Infinity)
          .map((el) => el.split('.').pop());

        let doesNotHaveAccess = false;

        accounts.forEach((el) => {
          if (accountsClaims.indexOf(el) === -1) doesNotHaveAccess = true;
        });

        return doesNotHaveAccess;
      };
    },
    //
    // output profile edit - outputs access test

    sortedRouterDestinations() {
      return this.routerDestinations
        .slice(0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    sortedOutputs() {
      return this.outputs
        .slice(0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    sortedInputs() {
      return this.inputs
        .slice(0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    sortedDest() {
      return this.routerDestinations
        .slice(0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    sortedSources() {
      return this.routerSources
        .slice(0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    routerGroupList() {
      return this.routerGroups.filter((item) => {
        if (!item) return false;
        return true;
      }).sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
    },

    sourceNameById() {
      return (id) => {
        let name = '';
        this.mvStreamSources.forEach(el => name = el.id === id ? el.name : name)
        return name;
      };
    },

    accounts() {
      const accountList = [];
      this.clients.forEach((client) => {
        client.accounts.items.forEach((account) => {
          accountList.push({
            ...account,
            client,
          });
        });
      });
      return accountList;
    },

    activeItemPermissions() {
      // const permissions = {};
      const permissions = this.clients.map((client) => {
        const newClientObject = {
          name: client.name,
          code: client.code,
          description: client.description,
          accounts: {},
          claim: `usr.${client.code}`,
        };
        newClientObject.accounts = client.accounts.items.map((account) => {
          const newAccountObject = {
            name: account.name,
            code: account.code,
            description: account.description,
            claim: `usr.${account.code}.${client.code}`,
            claims: {},
          };
          // console.log('newAccountObject', newAccountObject);
          this.claims.forEach((claim) => {
            if (claim.code === 'ca') return;
            newAccountObject.claims[claim.code] = `${claim.code}.${account.code}.${client.code}`;
          });
          return newAccountObject;
        });

        return newClientObject;
      });
      return permissions;
    },
    claimsNames() {
      const claims = {};
      this.claimsTree.forEach((clientClaim) => {
        claims[clientClaim.value] = clientClaim.label;
        clientClaim.children.forEach((accountClaim) => {
          claims[accountClaim.value] = accountClaim.label;

          const hasChildren = !!accountClaim.children;

          if (hasChildren) {
            accountClaim.children.forEach((childrenClaim) => {
              claims[childrenClaim.value] = childrenClaim.label;
            });
          }
        });
      });
      return claims;
    },
    claimsTree() {
      // const permissions = [];
      if (!this.clients) return [];
      const newClaimTree = this.clients.map((client) => {
        const newClientObject = {
          label: `${client.name} [usr.${client.code}]`,
          value: `usr.${client.code}`,
          children: [],
        };

        newClientObject.children = client.accounts.items.map((account) => {
          const newAccountObject = {
            label: `${account.name} [usr.${account.code}.${client.code}]`,
            value: `usr.${account.code}.${client.code}`,
            children: [],
          };
          // console.log('newAccountObject', newAccountObject);
          this.claims.forEach((claim) => {
            if (claim.code === 'ca') return;
            const claimString = `${claim.code}.${account.code}.${client.code}`;
            newAccountObject.children.push({
              value: claimString,
              label: `${claim.name} [${claimString}]`,
            });
          });
          return newAccountObject;
        });

        newClientObject.children.unshift({
          label: `Client Admin [ca.${client.code}.${client.code}]`,
          value: `ca.${client.code}.${client.code}`,
        });
        return newClientObject;
      });
      return newClaimTree;
    },
    isClaimAssign() {
      return (claim, object) => {
        const isActvie = object.indexOf(claim) > -1;
        return isActvie ? 'active' : '';
      };
    },
    isClaimDisabled() {
      return (claim, object) => {
        const isActvie = object.indexOf(claim) > -1;
        return isActvie ? ' disabled' : '';
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      listInputs: 'permissions/listInputs',
      listOutputs: 'permissions/listOutputs',
      listMultiviewers: 'permissions/listMultiviewers',
      listClients: 'permissions/listClients',
      listClaims: 'permissions/listClaims',
      listRouters: 'permissions/listRouters',
      listRouterGroups: 'permissions/listRouterGroups',
      listRouterDestinations: 'permissions/listRouterDestinations',
      listRouterSources: 'permissions/listRouterSources',
      listOutputProfiles: 'permissions/listOutputProfiles',
      listTranscoderProfiles: 'permissions/listTranscoderProfiles',

      updateInput: 'permissions/updateInput',
      updateOutput: 'permissions/updateOutput',
      updateMultiviewer: 'permissions/updateMultiviewer',
      updateOutputProfile: 'permissions/updateOutputProfile',
      updateRouter: 'permissions/updateRouter',
      updateRouterGroup: 'permissions/updateRouterGroup',
      updateRouterSource: 'permissions/updateRouterSource',
      updateRouterDestination: 'permissions/updateRouterDestination',
      updateTranscoderProfile: 'permissions/updateTranscoderProfile',

      deleteInput: 'permissions/deleteInput',
      deleteOutput: 'permissions/deleteOutput',
      deleteMultiviewer: 'permissions/deleteMultiviewer',
      deleteOutputProfile: 'permissions/deleteOutputProfile',
      deleteRouter: 'permissions/deleteRouter',
      deleteRouterGroup: 'permissions/deleteRouterGroup',
      deleteRouterSource: 'permissions/deleteRouterSource',
      deleteRouterDestination: 'permissions/deleteRouterDestination',
      deleteTranscoderProfile: 'permissions/deleteTranscoderProfile',

      createInput: 'permissions/createInput',
      createOutput: 'permissions/createOutput',
      createMultiviewer: 'permissions/createMultiviewer',
      createOutputProfile: 'permissions/createOutputProfile',
      createRouter: 'permissions/createRouter',
      createRouterGroup: 'permissions/createRouterGroup',
      createRouterDestination: 'permissions/createRouterDestination',
      createRouterSource: 'permissions/createRouterSource',
      createTranscoderProfile: 'permissions/createTranscoderProfile',

      // MAP: Output profile <- output
      createOutputProfileOutputMap: 'permissions/createOutputProfileOutputMap',
      deleteOutputProfileOutputMap: 'permissions/deleteOutputProfileOutputMap',

      // MAP: Account -> input/output
      createOutputAccountMap: 'permissions/createOutputAccountMap',
      createInputAccountMap: 'permissions/createInputAccountMap',

      // MAP: router group -> source/destination
      createRouterDestinationGroupMap: 'permissions/createRouterDestinationGroupMap',
      createRouterSourceGroupMap: 'permissions/createRouterSourceGroupMap',
      deleteRouterDestinationGroupMap: 'permissions/deleteRouterDestinationGroupMap',
      deleteRouterSourceGroupMap: 'permissions/deleteRouterSourceGroupMap',

      // MAP: input/output -> source/destination
      createInputRouterSourceMap: 'permissions/createInputRouterSourceMap',
      createOutputRouterDestinationMap: 'permissions/createOutputRouterDestinationMap',
      deleteInputRouterSourceMap: 'permissions/deleteInputRouterSourceMap',
      deleteOutputRouterDestinationMap: 'permissions/deleteOutputRouterDestinationMap',

      // MAP : delete others
      deleteEventOutputMap: 'permissions/deleteEventOutputMap',
      deleteOutputEquipmentMap: 'permissions/deleteOutputEquipmentMap',
      deleteOutputAccountMap: 'permissions/deleteOutputAccountMap',
      deleteInputAccountMap: 'permissions/deleteInputAccountMap',

      // Stream Sources/Destinations
      listStreamSources: 'stream/listStreamSources',
      listStreamDestinations: 'stream/listStreamDestinations',
      createStreamDestination: 'stream/createStreamDestination',
      createStreamSource: 'stream/createStreamSource',
      deleteStreamDestination: 'stream/deleteStreamDestination',
      deleteStreamSource: 'stream/deleteStreamSource',
      updateStreamDestination: 'stream/updateStreamDestination',
      updateStreamSource: 'stream/updateStreamSource',

      // MAP: Stream Source -> Multiviewer
      createStreamSourceMultiviewerMap: 'permissions/createStreamSourceMultiviewerMap',
      deleteStreamSourceMultiviewerMap: 'permissions/deleteStreamSourceMultiviewerMap',
      updateStreamSourceMultiviewerMap: 'permissions/updateStreamSourceMultiviewerMap',
    }),
    async init() {
      this.loading = true;
      await this.listInputs();
      this.listOutputs();
      this.listMultiviewers();
      this.listOutputProfiles();
      this.listTranscoderProfiles();
      this.listRouters();
      this.listRouterGroups();
      this.listRouterDestinations();
      this.listRouterSources();
      this.listStreamSources();
      this.listStreamDestinations();
      this.listClaims();
      this.listClients();
      const result = await ProbesServices.getProbesList();
      if (result.data) {
        this.probes = result.data;
      }
      console.log('%cprobes', 'color: lime', this.probes);
      this.select(this.inputs, 'input');
    },

    showAddForm(type) {
      this.formType = type;
      this.showAddDialog = true;
    },

    select(data, type) {

      if (this.listType !== type) this.page = 1;
      console.log('data,type =>', data, type);
      this.inEditMode = false;
      this.loading = true;
      this.listType = type;
      this.activeList = [];
      this.$forceUpdate();
      const processedItemList = data.map((item) => {
        if (!item) return { id: 'missing', name: 'Corrupted' };
        const access = item.claimsCanAccess.map((claim) => {
          const claims = claim.split('.');
          const claimLevel = claims.length;
          const isForUser = claims[0] === 'usr';
          if (claimLevel === 3 && isForUser) return [`${claims[0]}.${claims[2]}`, claim];
          if (claimLevel === 2 && isForUser) return [claim];
          return [
            `usr.${claims[2]}`,
            `usr.${claims[1]}.${claims[2]}`,
            claim,
          ];
        });

        const edit = item.claimsCanEdit.map((claim) => {
          const claims = claim.split('.');
          const claimLevel = claims.length;
          const isForUser = claims[0] === 'usr';
          const isClientAdmin = claims[0] === 'ca';

          if (isClientAdmin) return [`usr.${claims[2]}`, claim];
          if (claimLevel === 3 && isForUser) return [`${claims[0]}.${claims[2]}`, claim];
          if (claimLevel === 2 && isForUser) return [claim];
          return [
            `usr.${claims[2]}`,
            `usr.${claims[1]}.${claims[2]}`,
            claim,
          ];
        });

        const newItem = {
          ...item,
          editing: false,
          saving: false,
          access,
          edit,
        };
        if (type === 'multiviewer') {
          newItem.streamSources = item.streamSources.items.map((map) => {
            const isNotNull = !!map;
            if (isNotNull) return {
              ...map.streamSource,
              sortOrder: map.sortOrder,
              mapId: map.id,
            };
            return {};
          })
          .sort((a, b) => {
            if (a.sortOrder < b.sortOrder) { return -1; }
            if (a.sortOrder > b.sortOrder) { return 1; }
            return 0;
          });
          newItem.originalStreamSources = item.streamSources.items;
        }
        if (type === 'outputProfile') {
          newItem.outputs = item.outputs.items.map((outputMap) => outputMap.output);
          newItem.outputMapping = item.outputs.items;
          newItem.originalOutputs = item.outputs.items.map((map) => map.output.id);
        }
        if (type === 'routers') {
          newItem.routerGroups = item.routerGroups.items;
          newItem.originalRouterGroups = item.routerGroups.items;
        }
        if (type === 'routerGroups') {
          // newItem.destinations = item.destinations.items.map(dest => dest.routerDestination);
          newItem.destinations = item.destinations.items.map((map) => {
            const isNotNull = !!map;
            if (isNotNull) return map.routerDestination;
            return {};
          });
          // newItem.sources = item.sources.items.map(source => source.routerSource);
          newItem.sources = item.sources.items.map((map) => {
            const isNotNull = !!map;
            if (isNotNull) return map.routerSource;
            return {};
          });
          newItem.originalDestinations = item.destinations.items;
          newItem.originalSources = item.sources.items;
        }
        if (type === 'sources') {
          newItem.mappedInputs = item.mappedInputs.items.map((map) => {
            const isNotNull = !!map;
            if (isNotNull) return map.input;
            return {};
          });
          newItem.originalMappedInputs = item.mappedInputs.items;
          newItem.routerGroups = item.routerGroups.items.map((map) => {
            const isNotNull = !!map;
            if (isNotNull) return map.routerGroup;
            return {};
          });
          newItem.originalRouterGroups = item.routerGroups.items;
        }
        if (type === 'destinations') {
          newItem.mappedOutputs = item.mappedOutputs.items.map((map) => map.output);
          newItem.routerDestinationDefaultSourceId = item.defaultSource;
          newItem.originalMappedOutputs = item.mappedOutputs.items;
          newItem.routerGroups = item.routerGroups.items.map((map) => {
            const isNotNull = !!map;
            if (isNotNull) return map.routerGroup;
            return {};
          });
          newItem.originalRouterGroups = item.routerGroups.items;
        }
        if (type === 'input') {
          this.probes.forEach((probe) => {
            probe.tasks.forEach((task) => {

              if (String(task.id) === String(newItem.probeTaskId)) {

                newItem.probe = {
                  ...probe,
                  task,
                  key: task.id,
                };
              }
            });
          });

        }
        return newItem;
      });

      console.log("inputtttttt", processedItemList);
      this.activeList = processedItemList;
      this.sortedList = this.activeList
        .slice(0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      this.loading = false;
    },
    selectItem(data) {
      this.activeItem = data;
    },
    togglePermission(claim, object) {
      const hasClaim = object.indexOf(claim) > -1;
      if (hasClaim) object.splice(object.indexOf(claim), 1);
      if (!hasClaim) object.push(claim);
    },

    async deleteItem(item, index, type) {
      const newItem = {
        id: item.id,
        expectedVersion: item.version,
      };

      let result = null;
      switch (type) {
        case 'transcoderProfiles':
          result = await this.deleteTranscoderProfile(newItem);
          break;
        case 'routers': {
          let sources = [];
          let destinations = [];
          const groups = [];
          item.routerGroups.forEach(async (el) => {
            sources = el.sources.items.map((map) => map.id);
            destinations = el.destinations.items.map((map) => map.id);
            groups.push(el.id);
          });

          sources.forEach(async (el) => {
            await this.deleteRouterSourceGroupMap({ id: el });
          });

          destinations.forEach(async (el) => {
            await this.deleteRouterDestinationGroupMap({ id: el });
          });

          groups.forEach(async (el) => {
            await this.deleteRouterGroup({ id: el });
          });
          result = await this.deleteRouter(newItem);
          break;
        }
        case 'multiviewer':
          item.originalStreamSources.forEach(async (el) => {
            await this.deleteStreamSourceMultiviewerMap({ id: el.id });
          });
          result = await this.deleteMultiviewer(newItem);
          break;
        case 'input':
          item.accounts.items.forEach(async (el) => {
            await this.deleteInputAccountMap({ id: el.id });
          });
          item.broadcastSources.items.forEach(async (el) => {
            await this.deleteInputRouterSourceMap({ id: el.id });
          });
          item.equipment.items.forEach(async (el) => {
            await this.deleteInputEquipmentMap({ id: el.id });
          });
          result = await this.deleteInput(newItem);
          break;
        case 'output':
          const newOutput = {
            id: item.id,
            name: item.name,
            description: item.description,
            expectedVersion: item.version,
            claimsCanAccess: newClaimsCanAccess,
            claimsCanEdit: newClaimsCanEdit,
          };
          item.accounts.items.forEach(async (el) => {
            await this.deleteOutputAccountMap({ id: el.id });
          });
          item.equipment.items.forEach(async (el) => {
            await this.deleteOutputEquipmentMap({ id: el.id });
          });
          item.broadcastDestinations.items.forEach(async (el) => {
            await this.deleteOutputRouterDestinationMap({ id: el.id });
          });
          item.events.items.forEach(async (el) => {
            await this.deleteEventOutputMap({ id: el.id });
          });
          item.outputProfiles.items.forEach(async (el) => {
            await this.deleteOutputProfileOutputMap({ id: el.id });
          });
          newOutput.outputType = item.outputType;
          newOutput.status = 'DELETED';
          newOutput.url = item.url;
          newOutput.ottApplication = item.ottApplication;
          newOutput.ottNoStremKeyRequired = item.ottNoStremKeyRequired;
          newOutput.ottPlatform = item.ottPlatform;
          newOutput.ottServer = item.ottServer;
          newOutput.displayName = item.displayName;
          result = await this.updateOutput(newItem);
          await this.listOutputs();
          this.select(this.outputs, 'output');
          // result = await this.deleteOutput(newItem);
          break;
        case 'outputProfile':
          item.outputMapping.forEach(async (el) => {
            await this.deleteOutputProfileOutputMap({ id: el.id });
          });
          result = await this.deleteOutputProfile(newItem);
          break;
        case 'routerGroups':
          item.originalDestinations.forEach(async (el) => {
            await this.deleteRouterDestinationGroupMap({ id: el.id });
          });
          item.originalSources.forEach(async (el) => {
            await this.deleteRouterSourceGroupMap({ id: el.id });
          });
          result = await this.deleteRouterGroup(newItem);
          break;
        case 'sources':
          item.originalRouterGroups.forEach(async (el) => {
            await this.deleteRouterSourceGroupMap({ id: el.id });
          });
          item.originalMappedInputs.forEach(async (el) => {
            await this.deleteInputRouterSourceMap({ id: el.id });
          });
          result = await this.deleteRouterSource(newItem);
          break;
        case 'destinations':
          item.originalRouterGroups.forEach(async (el) => {
            await this.deleteRouterDestinationGroupMap({ id: el.id });
          });
          item.originalMappedOutputs.forEach(async (el) => {
            await this.deleteOutputRouterDestinationMap({ id: el.id });
          });
          result = await this.deleteRouterDestination(newItem);
          break;
        case 'streamSources':
          result = await this.deleteStreamSource(newItem);
          break;
        case 'streamDestinations':
          result = await this.deleteStreamDestination(newItem);
          break;
        default: console.log('UKNOWN TYPE');
      }
      if (result.error) console.log('DELETE ERROR');
      this.sortedList = this.sortedList.filter((el) => el.id !== item.id);
      console.log('sadads', this.activeList);
      this.$forceUpdate();
    },

    async addItem(item) {
      const isFormValid = await this.$refs.addItemForm.validate();
      if (!isFormValid) return;

      const newClaimsCanEdit = item.claimsCanEdit.map((claim) => claim.pop());
      const newClaimsCanAccess = item.claimsCanAccess.map((claim) => claim.pop());
      const newItem = {
        name: item.name,
        claimsCanAccess: newClaimsCanAccess,
        claimsCanEdit: newClaimsCanEdit,
      };

      // check if description is provided
      const hasDescription = !!item.description;
      if (hasDescription) newItem.description = item.description;
      // assign id if specified
      const hasId = !!item.id;
      if (hasId) newItem.id = item.id;

      newItem[`${this.formType}LastUpdatedById`] = this.userId;

      let result = null;
      switch (this.formType) {
        case 'transcoderProfile':
          newItem.transcoderProfile = item.transcoderProfile.transcoderProfile;
          result = await this.createTranscoderProfile(newItem);

          if (result.error) return;
          await this.listTranscoderProfiles();
          if (this.listType === this.formType) this.select(this.transcoderProfiles, 'transcoderProfiles');
          break;

        case 'multiviewer':
          newItem.grid = item.multiviewer.grid;
          newItem.type = item.multiviewer.type;
          newItem.audioControlId = item.multiviewer.audioControlId;
          newItem.routableDestinationId = item.multiviewer.routableDestinationId;

          result = await this.createMultiviewer(newItem);

          if (result.error) return;
          // mapping sources
          item.multiviewer.streamSources.forEach(async (source, index) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              sortOrder: index,
              name: newItem.name,
              streamSourceMultiviewerMapStreamSourceId: source,
              streamSourceMultiviewerMapMultiviewerId: result.id,
              streamSourceMultiviewerMapLastUpdatedById: this.userId,
            };
            await this.createStreamSourceMultiviewerMap(newMapping);
          });

          await this.listMultiviewers();
          if (this.listType === this.formType) this.select(this.multiviewers, 'multiviewers');
          break;
        case 'input':
          newItem.streamFileBaseName = item.input.streamFileBaseName;
          newItem.type = item.input.type;
          newItem.url = item.input.url;
          newItem.inputDownlinkConfigId = item.input.inputDownlinkConfigId;
          newItem.frcAvailable = item.input.frcAvailable;
          newItem.requireDetails = item.input.requireDetails;
          result = await this.createInput(newItem);

          if (result.error) return;

          item.accounts.forEach(async (account) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              inputAccountMapAccountsId: account.id,
              inputAccountMapLastUpdatedById: this.userId,
              inputAccountMapInputId: result.id,
            };

            await this.createInputAccountMap(newMapping);
          });
          await this.listInputs();
          if (this.listType === this.formType) this.select(this.inputs, 'input');
          break;
        case 'output':
          newItem.ottApplication = item.output.ottApplication;
          newItem.ottNoStremKeyRequired = item.output.ottNoStremKeyRequired;
          newItem.ottPlatform = item.output.ottPlatform;
          newItem.ottServer = item.output.ottServer;
          newItem.status = item.output.status;
          newItem.outputType = item.output.outputType;
          newItem.displayName = item.output.displayName;
          newItem.url = item.output.url;
          result = await this.createOutput(newItem);

          if (result.error) return;

          item.accounts.forEach(async (account) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              outputAccountMapAccountsId: account.id,
              outputAccountMapLastUpdatedById: this.userId,
              outputAccountMapOutputId: result.id,
            };

            await this.createOutputAccountMap(newMapping);
          });

          await this.listOutputs();
          if (this.listType === this.formType) this.select(this.outputs, 'output');
          break;
        case 'outputProfile':
          result = await this.createOutputProfile(newItem);
          if (result.error) return;
          // mapping outputs
          item.outputProfile.outputs.forEach(async (output) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              outputProfileOutputMapOutputId: output,
              outputProfileOutputMapOutputProfileId: result.id,
              outputProfileOutputMapLastUpdatedById: this.userId,
            };
            await this.createOutputProfileOutputMap(newMapping);
          });

          await this.listOutputProfiles();
          if (this.listType === this.formType) this.select(this.outputProfiles, 'outputProfile');
          break;
        case 'router':
          newItem.routerAccountId = item.router.routerAccountId.id;
          result = await this.createRouter(newItem);
          await this.listRouters();
          await this.listRouterGroups();
          break;
        case 'routerGroup': {
          const { routerGroup } = item;
          newItem.routerGroupRouterId = routerGroup.routerGroupRouterId;
          result = await this.createRouterGroup(newItem);
          if (result.error) return;

          routerGroup.destinations.forEach(async (dest) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              routerDestinationGroupMapRouterDestinationId: dest.id,
              routerDestinationGroupMapRouterGroupId: result.id,
              routerDestinationGroupMapLastUpdatedById: this.userId,
            };
            await this.createRouterDestinationGroupMap(newMapping);
          });

          routerGroup.sources.forEach(async (source) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              routerSourceGroupMapRouterSourceId: source.id,
              routerSourceGroupMapRouterGroupId: result.id,
              routerSourceGroupMapLastUpdatedById: this.userId,
            };
            await this.createRouterSourceGroupMap(newMapping);
          });
          await this.listRouterGroups();
          await this.listRouters();
          break;
        }
        case 'routerSource': {
          const { routerSource } = item;
          newItem.originRouterGroup = routerSource.originRouterGroup;
          newItem.originRouterId = routerSource.originRouterId;
          newItem.originRouterSourceId = routerSource.originRouterSourceId;
          result = await this.createRouterSource(newItem);
          if (result.error) return;

          routerSource.mappedInputs.forEach(async (input) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              inputRouterSourceMapInputId: input.id,
              inputRouterSourceMapRouterSourceId: result.id,
              inputRouterSourceMapLastUpdatedById: this.userId,
            };
            await this.createInputRouterSourceMap(newMapping);
          });

          await this.listRouterSources();
          break;
        }
        case 'routerDestination': {
          const { routerDestination } = item;
          newItem.originRouterDestinationId = routerDestination.originRouterDestinationId;
          newItem.originRouterGroup = routerDestination.originRouterGroup;
          newItem.originRouterId = routerDestination.originRouterId;

          if (routerDestination.routerDestinationRoutedSourceId) {
            const { routerDestinationRoutedSourceId } = routerDestination;
            newItem.routerDestinationRoutedSourceId = routerDestinationRoutedSourceId;
          }
          if (routerDestination.routerDestinationDefaultSourceId) {
            const { routerDestinationDefaultSourceId } = routerDestination;
            newItem.routerDestinationDefaultSourceId = routerDestinationDefaultSourceId.id;
          }
          result = await this.createRouterDestination(newItem);
          if (result.error) return;

          routerDestination.mappedOutputs.forEach(async (output) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              outputRouterDestinationMapOutputId: output.id,
              outputRouterDestinationMapRouterDestinationId: result.id,
              outputRouterDestinationMapLastUpdatedById: this.userId,
            };
            await this.createOutputRouterDestinationMap(newMapping);
          });
          await this.listRouterDestinations();
          break;
        }
        case 'streamSource': {
          const { streamSource } = item;
          if (streamSource.type) newItem.type = streamSource.type;
          if (streamSource.port) newItem.port = streamSource.port;
          if (streamSource.address) newItem.address = streamSource.address;
          if (streamSource.talkbackAddress) newItem.talkbackAddress = streamSource.talkbackAddress;
          if (streamSource.talkbackPort) newItem.talkbackPort = streamSource.talkbackPort;
          if (streamSource.latencyMs) newItem.latencyMs = streamSource.latencyMs;

          if (streamSource.bufferMaxMs) newItem.bufferMaxMs = streamSource.bufferMaxMs;
          if (streamSource.bufferMinMs) newItem.bufferMinMs = streamSource.bufferMinMs;

          if (streamSource.encPassphrase) newItem.encPassphrase = streamSource.encPassphrase;
          if (streamSource.encKeyLen) newItem.encKeyLen = streamSource.encKeyLen;
          if (streamSource.encType) newItem.encType = streamSource.encType;
          if (streamSource.udpAddress) newItem.udpAddress = streamSource.udpAddress;
          if (streamSource.udpPort) newItem.udpPort = streamSource.udpPort;

          result = await this.createStreamSource(newItem);
          if (result.error) return;
          await this.listStreamSources();
          break;
        }
        case 'streamDestination': {
          const { streamDestination } = item;
          newItem.streamDestinationRouterDestinationId = streamDestination.streamDestinationRouterDestinationId;

          if (streamDestination.type) newItem.type = streamDestination.type;
          if (streamDestination.address) newItem.address = streamDestination.address;

          if (streamDestination.port) newItem.port = streamDestination.port;
          if (streamDestination.talkbackAddress) newItem.talkbackAddress = streamDestination.talkbackAddress;
          if (streamDestination.talkbackPort) newItem.talkbackPort = streamDestination.talkbackPort;
          if (streamDestination.latencyMs) newItem.latencyMs = streamDestination.latencyMs;

          if (streamDestination.bufferMaxMs) newItem.bufferMaxMs = streamDestination.bufferMaxMs;
          if (streamDestination.bufferMinMs) newItem.bufferMinMs = streamDestination.bufferMinMs;
          if (streamDestination.encPassphrase) newItem.encPassphrase = streamDestination.encPassphrase;
          if (streamDestination.encKeyLen) newItem.encKeyLen = streamDestination.encKeyLen;
          if (streamDestination.encType) newItem.encType = streamDestination.encType;
          if (streamDestination.udpAddress) newItem.udpAddress = streamDestination.udpAddress;
          if (streamDestination.udpPort) newItem.udpPort = streamDestination.udpPort;
          if (streamDestination.udpRtt) newItem.udpRtt = streamDestination.udpRtt;

          result = await this.createStreamDestination(newItem);
          if (result.error) return;

          console.log('StreamDestination => ', newItem, result);
          await this.listStreamDestinations();
          break;
        }
        default: console.log('UKNOWN TYPE');
      }

      this.showAddDialog = false;
      this.resetForm();
      this.$forceUpdate();
    },

    resetForm() {
      this.form = {
        name: '',
        description: null,
        claimsCanAccess: [],
        claimsCanEdit: [],
        accounts: [],
        transcoderProfile: {
          transcoderProfile: null,
        },
        input: {
          streamFileBaseName: null,
          type: null,
          inputDownlinkConfigId: null,
          url: null,
        },
        output: {
          outputType: null,
          status: null,
          ottApplication: null,
          ottNoStremKeyRequired: false,
          ottPlatform: null,
          ottServer: null,
          url: null,
        },
        multiviewer: {
          grid: 1,
          streamSources: [],
          routableDestinationId: null,
          type: null,
        },
        outputProfile: {
          outputs: [],
        },
        router: {
          routerAccountId: null,
          routerGroups: [],
        },
        routerSource: {
          originRouterGroup: null,
          originRouterId: null,
          originRouterSourceId: null,
          routerGroups: [],
          mappedInputs: [],
        },
        routerDestination: {
          originRouterDestinationId: null,
          originRouterGroup: null,
          originRouterId: null,
          routerDestinationRoutedSourceId: null,
          routerGroups: [],
          inputs: [],
          outputs: [],
        },
        routerGroup: {
          routerGroupRouterId: null,
          sources: [],
          destinations: [],
        },
        streamSource: {
          type: null,
          port: null,
          address: null,
          talkbackAddress: null,
          talkbackPort: null,

          latencyMs: null,
          bufferMaxMs: null,
          bufferMinMs: null,
          encPassphrase: null,
          encKeyLen: null,
          encType: null,
          udpAddress: null,
          udpPort: null,
        },
        streamDestination: {
          type: null,
          streamDestinationRouterDestinationId: null,
          port: null,
          address: null,
          talkbackAddress: null,
          talkbackPort: null,

          latencyMs: null,
          bufferMaxMs: null,
          bufferMinMs: null,
          encPassphrase: null,
          encKeyLen: null,
          encType: null,
          udpAddress: null,
          udpPort: null,
          udpRtt: null,
        },

      };
    },

    async save(item, index) {
      this.sortedList[index].saving = true;
      const newClaimsCanEdit = item.edit.map((claim) => claim.pop());
      const newClaimsCanAccess = item.access.map((claim) => claim.pop());
      const newItem = {
        id: item.id,
        name: item.name,
        description: item.description,
        expectedVersion: item.version,
        claimsCanAccess: newClaimsCanAccess,
        claimsCanEdit: newClaimsCanEdit,
      };
      let result = null;
      switch (this.listType) {
        case 'transcoderProfiles':
          newItem.transcoderProfile = item.transcoderProfile;
          result = await this.updateTranscoderProfile(newItem);
          await this.listTranscoderProfiles();
          this.select(this.transcoderProfiles, 'transcoderProfiles');
          // await this.listInputs();
          break;
        case 'multiviewer':
          newItem.grid = item.grid;
          newItem.type = item.type;
          newItem.audioControlId = item.audioControlId;
          newItem.routableDestinationId = item.routableDestinationId;
          result = await this.updateMultiviewer(newItem);

          // mapping outputs
          const { originalStreamSources, streamSources } = item;
          const originalStreamSourcesMapings = originalStreamSources.map((map) => map.streamSource.id)
          const originalStreamSourcesToMap = streamSources.filter(
            (streamSource) => originalStreamSourcesMapings.indexOf(streamSource.id) === -1,
          );
          //  const outputsToMap = item.outputs.filter(
          //   (output) => originalOutputs.indexOf(output.id) === -1,
          // );
          const currentStreamSourcesByMapId = streamSources.map((streamSource) => streamSource.mapId);

          originalStreamSources.forEach(async (source, index) => {
            const newSourceIndex = currentStreamSourcesByMapId.indexOf(source.id);
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              sortOrder: newSourceIndex || 0,
              id: source.id,
            };
            await this.updateStreamSourceMultiviewerMap(newMapping);
          });


          const currentStreamSources = streamSources.map((streamSource) => streamSource.id);
          const mappingsToDelete = originalStreamSources.filter(
            (map) => currentStreamSources.indexOf(map.streamSource.id) === -1,
          );
          originalStreamSourcesToMap.forEach(async (source) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              sortOrder: index,
              name: newItem.name,
              streamSourceMultiviewerMapStreamSourceId: source.id,
              streamSourceMultiviewerMapMultiviewerId: result.id,
              streamSourceMultiviewerMapLastUpdatedById: this.userId,
            };
            await this.createStreamSourceMultiviewerMap(newMapping);
          });

          mappingsToDelete.forEach(async (map) => {
            const newMap = {
              id: map.id,
            };
            await this.deleteStreamSourceMultiviewerMap(newMap);
          });


          await this.listMultiviewers();
          this.select(this.multiviewers, 'multiviewer');
          await this.listMultiviewers();
          break;
        case 'input':
          newItem.streamFileBaseName = item.streamFileBaseName;
          newItem.type = item.type;
          newItem.url = item.url;
          newItem.inputDownlinkConfigId = item.inputDownlinkConfigId;
          newItem.frcAvailable = item.frcAvailable;
          newItem.requireDetails = item.requireDetails;
          if (item.probe && item.probe.id) {
            newItem.probeProjectId = item.probe.id;
            newItem.probeTaskId = item.probe.key;
          }
          result = await this.updateInput(newItem);
          await this.listInputs();
          this.select(this.inputs, 'input');
          // await this.listInputs();
          break;
        case 'output':
          newItem.outputType = item.outputType;
          newItem.status = item.status;
          newItem.url = item.url;
          newItem.ottApplication = item.ottApplication;
          newItem.ottNoStremKeyRequired = item.ottNoStremKeyRequired;
          newItem.ottPlatform = item.ottPlatform;
          newItem.ottServer = item.ottServer;
          newItem.displayName = item.displayName;
          result = await this.updateOutput(newItem);
          await this.listOutputs();
          this.select(this.outputs, 'output');
          break;
        case 'outputProfile': {
          result = await this.updateOutputProfile(newItem);
          if (result.error) return;

          // mapping outputs
          const { originalOutputs } = item;
          const outputsToMap = item.outputs.filter(
            (output) => originalOutputs.indexOf(output.id) === -1,
          );

          const currentOutputs = item.outputs.map((output) => output.id);
          const mappingsToDelete = item.outputMapping.filter(
            (map) => currentOutputs.indexOf(map.output.id) === -1,
          );

          outputsToMap.forEach(async (output) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              outputProfileOutputMapOutputId: output.id,
              outputProfileOutputMapOutputProfileId: item.id,
              outputProfileOutputMapLastUpdatedById: this.userId,
            };
            await this.createOutputProfileOutputMap(newMapping);
          });

          mappingsToDelete.forEach(async (map) => {
            const newMap = {
              id: map.id,
            };
            await this.deleteOutputProfileOutputMap(newMap);
          });

          await this.listOutputProfiles();
          this.select(this.outputProfiles, 'outputProfile');
          break;
        }
        case 'routers':
          newItem.routerAccountId = item.account.id;
          result = await this.updateRouter(newItem);
          await this.listRouters();
          await this.listRouterGroups();
          this.select(this.routers, 'routers');
          this.select(this.routerGroups, 'routers');
          // await this.listInputs();
          break;
        case 'routerGroups': {
          newItem.routerGroupRouterId = item.router.id;
          result = await this.updateRouterGroup(newItem);
          // mapping outputs
          const {
            originalDestinations,
            originalSources,
            destinations,
            sources,
          } = item;

          // DESTINATION MAPS
          const originalDestinationsIds = originalDestinations.map((el) => el.routerDestination.id);
          const destsToMap = destinations.filter(
            (el) => originalDestinationsIds.indexOf(el.id) === -1,
          );

          const currentDests = item.destinations.map((el) => el.id);
          const destMappingsToDelete = item.originalDestinations.filter(
            (map) => currentDests.indexOf(map.routerDestination.id) === -1,
          );

          destsToMap.forEach(async (destination) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              routerDestinationGroupMapRouterDestinationId: destination.id,
              routerDestinationGroupMapRouterGroupId: item.id,
              routerDestinationGroupMapLastUpdatedById: this.userId,
            };
            await this.createRouterDestinationGroupMap(newMapping);
          });

          destMappingsToDelete.forEach(async (map) => {
            const newMap = {
              id: map.id,
            };
            await this.deleteRouterDestinationGroupMap(newMap);
          });

          // SOURCE MAPS
          const originalSourcesIds = originalSources.map((el) => el.routerSource.id);
          const sourcesToMap = sources.filter(
            (el) => originalSourcesIds.indexOf(el.id) === -1,
          );

          const currentSources = sources.map((el) => el.id);
          const sourceMappingsToDelete = originalSources.filter(
            (map) => currentSources.indexOf(map.routerSource.id) === -1,
          );

          sourcesToMap.forEach(async (source) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              routerSourceGroupMapRouterSourceId: source.id,
              routerSourceGroupMapRouterGroupId: item.id,
              routerSourceGroupMapLastUpdatedById: this.userId,
            };
            await this.createRouterSourceGroupMap(newMapping);
          });

          sourceMappingsToDelete.forEach(async (map) => {
            const newMap = {
              id: map.id,
            };
            await this.deleteRouterSourceGroupMap(newMap);
          });

          await this.listRouterGroups();
          this.select(this.routerGroups, 'routerGroups');
          break;
        }
        case 'sources': {
          const { originalMappedInputs } = item;
          newItem.originRouterGroup = item.originRouterGroup;
          newItem.originRouterId = item.originRouterId;
          newItem.originRouterSourceId = item.originRouterSourceId;

          await this.updateRouterSource(newItem);
          // INPUT MAPS
          const inputsToMap = item.mappedInputs.filter(
            (el) => originalMappedInputs.indexOf(el.id) === -1,
          );

          const currentInputIds = item.mappedInputs.map((el) => el.id);
          const inputMappingsToDelete = originalMappedInputs.filter(
            (map) => currentInputIds.indexOf(map.input.id) === -1,
          );

          inputsToMap.forEach(async (input) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              inputRouterSourceMapInputId: input.id,
              inputRouterSourceMapRouterSourceId: item.id,
              inputRouterSourceMapLastUpdatedById: this.userId,
            };
            await this.createInputRouterSourceMap(newMapping);
          });

          inputMappingsToDelete.forEach(async (map) => {
            const newMap = {
              id: map.id,
            };
            await this.deleteInputRouterSourceMap(newMap);
          });
          await this.listRouterSources();
          this.select(this.routerSources, 'sources');
          break;
        }
        case 'destinations': {
          const { originalMappedOutputs, mappedOutputs } = item;
          newItem.originRouterDestinationId = item.originRouterDestinationId;
          if (item.routerDestinationDefaultSourceId) newItem.routerDestinationDefaultSourceId = item.routerDestinationDefaultSourceId.id;
          newItem.originRouterGroup = item.originRouterGroup;
          newItem.originRouterId = item.originRouterId;

          await this.updateRouterDestination(newItem);
          // INPUT MAPS
          const originalMappedOutputsIds = originalMappedOutputs.map((el) => el.output.id);
          const outputsToMap = mappedOutputs.filter(
            (el) => originalMappedOutputsIds.indexOf(el.id) === -1,
          );

          const currentOutputsIds = mappedOutputs.map((el) => el.id);
          const outputMappingsToDelete = originalMappedOutputs.filter(
            (map) => currentOutputsIds.indexOf(map.output.id) === -1,
          );

          outputsToMap.forEach(async (output) => {
            const newMapping = {
              claimsCanAccess: newClaimsCanAccess,
              claimsCanEdit: newClaimsCanEdit,
              outputRouterDestinationMapOutputId: output.id,
              outputRouterDestinationMapRouterDestinationId: item.id,
              outputRouterDestinationMapLastUpdatedById: this.userId,
            };
            await this.createOutputRouterDestinationMap(newMapping);
          });
          outputMappingsToDelete.forEach(async (map) => {
            const newMap = {
              id: map.id,
            };
            await this.deleteOutputRouterDestinationMap(newMap);
          });
          await this.listRouterDestinations();
          this.select(this.routerDestinations, 'destinations');
          break;
        }
        case 'streamSources': {
          if (item.type) newItem.type = item.type;
          if (item.port) newItem.port = item.port;
          if (item.address) newItem.address = item.address;
          if (item.talkbackAddress) newItem.talkbackAddress = item.talkbackAddress;
          if (item.talkbackPort) newItem.talkbackPort = item.talkbackPort;
          if (item.latencyMs) newItem.latencyMs = item.latencyMs;

          if (item.bufferMaxMs) newItem.bufferMaxMs = item.bufferMaxMs;
          if (item.bufferMinMs) newItem.bufferMinMs = item.bufferMinMs;

          if (item.encPassphrase) newItem.encPassphrase = item.encPassphrase;
          if (item.encKeyLen) newItem.encKeyLen = item.encKeyLen;
          if (item.encType) newItem.encType = item.encType;
          if (item.udpAddress) newItem.udpAddress = item.udpAddress;
          if (item.udpPort) newItem.udpPort = item.udpPort;

          await this.updateStreamSource(newItem);
          await this.listStreamSources();
          this.select(this.streamSources, 'streamSources');

          break;
        }
        case 'streamDestinations': {
          newItem.streamDestinationRouterDestinationId = item.itemRouterDestinationId;

          if (item.type) newItem.type = item.type;
          if (item.address) newItem.address = item.address;

          if (item.port) newItem.port = item.port;
          if (item.talkbackAddress) newItem.talkbackAddress = item.talkbackAddress;
          if (item.talkbackPort) newItem.talkbackPort = item.talkbackPort;
          if (item.latencyMs) newItem.latencyMs = item.latencyMs;

          if (item.bufferMaxMs) newItem.bufferMaxMs = item.bufferMaxMs;
          if (item.bufferMinMs) newItem.bufferMinMs = item.bufferMinMs;
          if (item.encPassphrase) newItem.encPassphrase = item.encPassphrase;
          if (item.encKeyLen) newItem.encKeyLen = item.encKeyLen;
          if (item.encType) newItem.encType = item.encType;
          if (item.udpAddress) newItem.udpAddress = item.udpAddress;
          if (item.udpPort) newItem.udpPort = item.udpPort;
          if (item.udpRtt) newItem.udpRtt = item.udpRtt;

          await this.updateStreamDestination(newItem);
          await this.listStreamDestinations();
          this.select(this.streamDestinations, 'streamDestinations');
          break;
        }
        default: console.log('UKNOWN TYPE');
      }
      this.sortedList[index].saving = false;
      this.inEditMode = false;
      this.$forceUpdate();
    },

    editItem(item) {
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.inEditMode = true;
    },
    cancelEdit(item, index) {
      this.inEditMode = false;
      this.sortedList[index].id = this.editedItem.id;
      this.sortedList[index].name = this.editedItem.name;
      this.sortedList[index].description = this.editedItem.description;
      this.sortedList[index].expectedVersion = this.editedItem.version;
      this.sortedList[index].claimsCanAccess = this.editedItem.claimsCanAccess;
      this.sortedList[index].claimsCanEdit = this.editedItem.edit;

      switch (this.listType) {
        case 'input':
          this.sortedList[index].streamFileBaseName = this.editedItem.streamFileBaseName;
          this.sortedList[index].type = this.editedItem.type;
          this.sortedList[index].inputDownlinkConfigId = this.editedItem.inputDownlinkConfigId;
          this.sortedList[index].frcAvailable = this.editedItem.frcAvailable;
          this.sortedList[index].requireDetails = this.editedItem.requireDetails;
          break;
        case 'output':
          this.sortedList[index].outputType = this.editedItem.outputType;
          this.sortedList[index].status = this.editedItem.status;
          this.sortedList[index].ottApplication = this.editedItem.ottApplication;
          this.sortedList[index].ottNoStremKeyRequired = this.editedItem.ottNoStremKeyRequired;
          this.sortedList[index].ottPlatform = this.editedItem.ottPlatform;
          this.sortedList[index].ottServer = this.editedItem.ottServer;
          this.sortedList[index].displayName = this.editedItem.displayName;
          break;
        case 'outputProfile': {
          break;
        }
        case 'routers':
          this.sortedList[index].routerAccountId = this.editedItem.account.id;
          break;
        case 'routerGroups': {
          this.sortedList[index].routerGroupRouterId = this.editedItem.router.id;
          break;
        }
        case 'sources': {
          this.sortedList[index].originRouterGroup = this.editedItem.originRouterGroup;
          this.sortedList[index].originRouterId = this.editedItem.originRouterId;
          this.sortedList[index].originRouterSourceId = this.editedItem.originRouterSourceId;
          break;
        }
        case 'destinations': {
          this.sortedList[index].originRouterDestinationId = this.editedItem.originRouterDestinationId;
          this.sortedList[index].routerDestinationDefaultSourceId = this.editedItem.routerDestinationDefaultSourceId;
          this.sortedList[index].originRouterGroup = this.editedItem.originRouterGroup;
          this.sortedList[index].originRouterId = this.editedItem.originRouterId;
          break;
        }
        case 'streamSources': {
          if (this.editedItem.type) this.sortedList[index].type = this.editedItem.type;
          if (this.editedItem.port) this.sortedList[index].port = this.editedItem.port;
          if (this.editedItem.address) this.sortedList[index].address = this.editedItem.address;
          if (this.editedItem.talkbackAddress) this.sortedList[index].talkbackAddress = this.editedItem.talkbackAddress;
          if (this.editedItem.talkbackPort) this.sortedList[index].talkbackPort = this.editedItem.talkbackPort;
          if (this.editedItem.latencyMs) this.sortedList[index].latencyMs = this.editedItem.latencyMs;

          if (this.editedItem.bufferMaxMs) this.sortedList[index].bufferMaxMs = this.editedItem.bufferMaxMs;
          if (this.editedItem.bufferMinMs) this.sortedList[index].bufferMinMs = this.editedItem.bufferMinMs;

          if (this.editedItem.encPassphrase) this.sortedList[index].encPassphrase = this.editedItem.encPassphrase;
          if (this.editedItem.encKeyLen) this.sortedList[index].encKeyLen = this.editedItem.encKeyLen;
          if (this.editedItem.encType) this.sortedList[index].encType = this.editedItem.encType;
          if (this.editedItem.udpAddress) this.sortedList[index].udpAddress = this.editedItem.udpAddress;
          if (this.editedItem.udpPort) this.sortedList[index].udpPort = this.editedItem.udpPort;
          break;
        }
        case 'streamDestinations': {
          this.sortedList[index].streamDestinationRouterDestinationId = this.editedItem.itemRouterDestinationId;

          if (this.editedItem.type) this.sortedList[index].type = this.editedItem.type;
          if (this.editedItem.address) this.sortedList[index].address = this.editedItem.address;

          if (this.editedItem.port) this.sortedList[index].port = this.editedItem.port;
          if (this.editedItem.talkbackAddress) this.sortedList[index].talkbackAddress = this.editedItem.talkbackAddress;
          if (this.editedItem.talkbackPort) this.sortedList[index].talkbackPort = this.editedItem.talkbackPort;
          if (this.editedItem.latencyMs) this.sortedList[index].latencyMs = this.editedItem.latencyMs;

          if (this.editedItem.bufferMaxMs) this.sortedList[index].bufferMaxMs = this.editedItem.bufferMaxMs;
          if (this.editedItem.bufferMinMs) this.sortedList[index].bufferMinMs = this.editedItem.bufferMinMs;
          if (this.editedItem.encPassphrase) this.sortedList[index].encPassphrase = this.editedItem.encPassphrase;
          if (this.editedItem.encKeyLen) this.sortedList[index].encKeyLen = this.editedItem.encKeyLen;
          if (this.editedItem.encType) this.sortedList[index].encType = this.editedItem.encType;
          if (this.editedItem.udpAddress) this.sortedList[index].udpAddress = this.editedItem.udpAddress;
          if (this.editedItem.udpPort) this.sortedList[index].udpPort = this.editedItem.udpPort;
          if (this.editedItem.udpRtt) this.sortedList[index].udpRtt = this.editedItem.udpRtt;
          break;
        }
        default: console.log('UKNOWN TYPE');
      }
    },
    copy(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$message(
        { message: 'ID copied to clipboard' },
      );
    },
    loadPage(page) {
      console.log('LoadPage', page, this.page);
    },
  },
};
</script>
<style>
  #permissions-simple .data-content .items .data-list-item {
  cursor: pointer;
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.el-loading-spinner svg {
  margin: 0 auto;
}

pre {
  line-height: 13px;
  color: #fff;
}

#permissions-simple {
  display: grid;
  grid-template-columns: 200px auto;
  padding: 10px 20px;
  overflow-y: hidden;
}
#permissions-simple .data-header {
  margin-bottom: 0;
  display: grid;
  align-items: center;
  background: #13191d;
  position: fixed;
  top: 49px;
  left: 219px;
  right: 20px;
  z-index: 1;
  padding-left: 16px;
}
#permissions-simple .data-header-name {
  font-size: 24px;
  color: #CDD6EE;
  text-transform: capitalize;
}
#permissions-simple .data-header .icon-btn.active {
  color: #00FF80 !important;
}
#permissions-simple .dialog-form .claim-cascade-input i.bi {
  top: 6px;
  padding: 3px 0;
  left: -6px;
  background: #fff;
  z-index: 1;
  position: absolute;
}
#permissions-simple .el-divider {
  margin-top: 35px;
}
#permissions-simple .el-divider .el-divider__text {
  color: #13191D;
}
#permissions-simple .sidebar-item {
  font-size: 14px;
}
#permissions-simple .sidebar-item-actions {
  opacity: 1;
  padding-top: 0px;
}
#permissions-simple .sidebar-item-actions .bi {
  color: #CDD6EE;
}
#permissions-simple .data-content {
  display: grid;
  gap: 16px;
}
#permissions-simple .data-content .btn-group {
  margin-left: 12px;
  display: inline-block;
  border-radius: 2px;
  line-height: 32px;
  padding-left: 8px;
}
#permissions-simple .data-content .btn-group .icon-btn {
  line-height: 32px !important;
}
#permissions-simple .data-content .active-item-details {
  display: grid;
  grid-template-columns: 400px auto;
}
#permissions-simple .data-content .active-item-details .active-item-actions {
  text-align: right;
}
#permissions-simple .data-content .items {
  display: grid;
  gap: 8px;
  margin-top: 40px;
}
#permissions-simple .data-content .items input.el-input__inner {
  border: 1px solid #13191D;
  background: #13191D;
  color: #fff;
}
#permissions-simple .data-content .items .item-downlink-config {
  line-height: 18px;
}
#permissions-simple .data-content .items .item-downlink-config div {
  min-width: 100px;
  margin-right: 20px;
}
#permissions-simple .data-content .items .item-downlink-config strong {
  color: #fff;
  font-weight: normal;
}
#permissions-simple .data-content .items .extra-fields {
  grid-column: 1/span 2;
  padding-left: 16px;
  padding-right: 16px;
}
#permissions-simple .data-content .items .extra-fields .data-item {
  display: grid;
  grid-template-columns: 130px auto;
}
#permissions-simple .data-content .items .extra-fields .data-item-label {
  line-height: 16px;
  display: grid;
  align-content: center;
  padding-right: 3px;
}
#permissions-simple .data-content .items .extra-fields .data-item .el-select {
  width: 100%;
}
#permissions-simple .data-content .items .extra-fields .data-item .el-tag {
  background: #242A33;
  border: 1px solid #13191D;
}
#permissions-simple .data-content .items .claim-cascade {
  display: grid;
  grid-template-columns: 130px auto;
  grid-column: 1/span 2;
  padding-right: 16px;
  padding-left: 16px;
  line-height: 32px;
  margin-bottom: 0px;
}
#permissions-simple .data-content .items .claim-cascade-input .el-cascader {
  width: 100%;
  background: transparent;
}
#permissions-simple .data-content .items .claim-cascade-input .el-cascader .el-input input {
  background: #13191D;
  border: 1px solid #13191D;
}
#permissions-simple .data-content .items .claim-cascade-input .el-cascader .el-tag {
  background: #242A33;
}
#permissions-simple .data-content .items .vb-content {
  padding: 2px 20px 4px 4px;
}
#permissions-simple .data-content .items .data-list-item {
  cursor: pointer;
}
#permissions-simple .data-content .items .data-list-item .data-list-item-name {
  text-overflow: ellipsis;
  overflow: hidden;
}
#permissions-simple .data-content .items .data-list-item .data-list-item-name .data-list-item-description {
  display: block;
  line-height: 1;
  position: relative;
  top: -7px;
  opacity: 0.5;
}
#permissions-simple .data-content .items .data-list-item .data-list-item-actions {
  margin-right: 8px;
}
#permissions-simple .data-content .items .data-list-item .data-list-item-actions .el-tag {
  margin-left: 10px;
}
#permissions-simple .data-content .items .data-list-item .claim-cascade-input .el-tag {
  margin-right: 10px;
}
#permissions-simple .data-content .items .data-list-item.active {
  outline: 2px solid #ff9f31;
}
#permissions-simple .data-content .disabled {
  pointer-events: none;
  opacity: 0.3;
}
#permissions-simple .data-content .active-item-details {
  font-size: 18px;
  color: #fff;
  margin-bottom: 24px;
}
#permissions-simple .data-content .icon-btn {
  color: #CDD6EE;
}
#permissions-simple .data-content .client {
  padding: 16px;
  border: 1px solid #242A33;
  border-radius: 2px;
  margin-bottom: 24px;
}
#permissions-simple .data-content .client .active {
  color: #fff;
  background: #ea0d51;
}
#permissions-simple .data-content .client-info {
  display: grid;
  grid-template-columns: 200px auto;
  margin-top: -33px;
  background: #242a33;
  padding: 0 16px;
  line-height: 40px;
  margin: -33px -17px 10px -17px;
  border-radius: 2px 4px 0px 0px;
}
#permissions-simple .data-content .client-info .client-name {
  color: #CDD6EE;
}
#permissions-simple .data-content .client-info .client-claims {
  text-align: right;
}
#permissions-simple .data-content .client .account .account-info {
  display: grid;
  grid-template-columns: 200px auto;
  margin-top: 20px;
  line-height: 40px;
}
#permissions-simple .data-content .client .account .account-info .account-name {
  color: #CDD6EE;
}
#permissions-simple .data-content .client .account .account-info .account-claims {
  text-align: right;
}
#permissions-simple .data-content .account-permissions .claim {
  display: inline-block;
  width: 250px;
  margin-bottom: 6px;
  margin-right: 6px;
  background: #242A33;
  padding: 6px;
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  height: 40px;
}
#permissions-simple .data-content .account-permissions .claim .claim-actions {
  position: absolute;
  top: 0;
  right: 0;
  background: #242A33;
}
#permissions-simple .data-content .account-permissions .claim .claim-actions .active {
  color: #fff;
  background: #ea0d51;
}
#permissions-simple .data-content .account-permissions .claim .claim-name {
  color: #CDD6EE;
}
#permissions-simple .data-content .account-permissions .claim .claim-name span {
  font-size: 12px;
  width: 100%;
  color: #66718D;
  display: block;
}
#permissions-simple .data-content .account-permissions .claim .claim-name small {
  font-size: 10px;
  color: #66718D;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
#permissions-simple .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed;
  opacity: 0.7;
}

.el-dialog__wrapper .el-cascader, .el-dialog__wrapper .el-select {
  width: 100%;
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: all;
}

.list-enter {
  opacity: 0;
}

.list-enter-to {
  opacity: 1;
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  transform-origin: left top;
}
</style>
