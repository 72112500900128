<template>
  <div class="fixed inset-0 overflow-hidden z-50 right-0 w-96 left-auto" style="z-index: 999" v-show="showModal">
    <div class="absolute inset-0 overflow-hidden">
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
        <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
        <div class="pointer-events-auto w-96 translate-x-0">
          <div class="flex h-full flex-col overflow-y-scroll bg-white py-0 shadow-xl -mr-3">
            <button
              @click="showModal = false"
              style="z-index: 100"
              type="button" class="absolute right-2 top-2 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span class="sr-only">Close panel</span>
              <!-- Heroicon name: outline/x-mark -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div class="relative">
              <!-- Replace with your content -->
              <slot></slot>
              <!-- /End replace -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Aside',
  props: {
    show: Boolean,
  },
  model: {
    prop: 'show',
    event: 'showChanged'
  },
  computed: {
    showModal: {
      get: function() {
          return this.show
      },
      set: function(value) {
          this.$emit('showChanged', value)
      }
    }
  },
};
</script>
