import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import MultiviewService from '../services/Multiview';
import * as qlqueries from '../graphql/queries';

const state = {
  name: 'Multiview',
  sourceConfigurations: [],
  sourceConfigurationsObj: {},
  sourceConfigOptions: [],
  selectedSourceConfig: '',
  selectedSourceConfigObj: null,
  selectedMultiviewer: null,
  multiviewers: null,
  sources: [],
  gridColumns: 4,
  gridRows: 4,

  // MV CONTROL
  layout: null,
  player: null,
  activeAudioChannel: null,
  playersNumber: 0,
  channelsNumber: 0,

  // CINEGY
  cinegyMultiviewer: null,
};

const getters = {
};

const mutations = {
  setSourceConfig: (state, payload) => {
    Vue.set(state, 'project', payload);
    Vue.set(state, 'currentFolder', payload.homeFolder);
  },
  setMultiview: (state, payload) => {
    Vue.set(state, 'project', payload);
    Vue.set(state, 'currentFolder', payload.homeFolder);
  },
  setSourceConfigurations: (state, payload) => {
    Vue.set(state, 'sourceConfigurations', payload);
  },
  setSourceConfigurationsObj: (state, payload) => {
    Vue.set(state, 'sourceConfigurationsObj', payload);
  },
  setSourceConfigOptions: (state, payload) => {
    Vue.set(state, 'selectedSourceConfig', payload);
  },
  setSelectedSourceConfig: (state, payload) => {
    // Vue.set(state, 'selectedMultiviewer', payload);
    if (!payload) return;
    Vue.set(state, 'selectedMultiviewer', payload);
    Vue.set(state, 'selectedSourceConfig', payload);
    Vue.set(state, 'selectedSourceConfigObj', state.sourceConfigurationsObj[payload]);

    Vue.set(state, 'gridColumns', payload.grid);

    if (!payload.streamSources) return;
    const sources = payload.streamSources.items
      .map((el) => {
        const source = {
          ...el,
          ...el.source,
          loading: false,
          progress: false,
          player: null,
          levels: null,
        };
        return source;
      })
      .sort((a, b) => {
        if (a.sortOrder < b.sortOrder) { return -1; }
        if (a.sortOrder > b.sortOrder) { return 1; }
        return 0;
      });
      Vue.set(state, 'gridRows', Math.ceil(sources.length / state.gridColumns));

    if (sources.length === 1) {
      state.gridColumns = 1;
      state.gridRows = 1;
    }
    Vue.set(state, 'sources', sources);
   // localStorage.setItem('source', payload);
    // set('source', payload);
  },
  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },
  setList: (state, payload) => {
    Vue.set(state, payload.property, payload.data.items);
    const { items } = payload.data;
    const listById = {};
    items.forEach((item) => { listById[item.id] = item; });

    Vue.set(state, `${payload.property}ById`, listById);

    if (!items[0]) return;
    Vue.set(state, 'multiviewers', payload.data.items);
  },
  setLayout: (state, payload) => {
    Vue.set(state, 'layout', payload);
  },
  setPlayer: (state, payload) => {
    Vue.set(state, 'player', payload);
  },
  setActiveAudioChannel: (state, payload) => {
    Vue.set(state, 'activeAudioChannel', payload);
  },
  setPlayersNumber: (state, payload) => {
    Vue.set(state, 'playersNumber', payload);
  },
  setChannelsNumber: (state, payload) => {
    Vue.set(state, 'channelsNumber', payload);
  },

};

const actions = {
  async listMultiviewers({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listMultiviewers,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listMultiviewers, property: 'multiviewers' });
      return data.listMultiviewers;
    } catch (error) {
      console.log('error', error);
      const hasData = !!error.data && !!error.data.listMultiviewers;

      if (hasData) {
        const { data } = error;
        console.debug('Oops something went wrong. Can\'t get some of the multiviewers', data);
        const mvs = data.listMultiviewers.items.map((el) => {
          const newEl = {
            ...el,
          };

          const streamSources = el.streamSources.items.filter((item) => item);

          newEl.streamSources.items = streamSources;

          return newEl;
        });
        const listMultiviewers = {
          ...data.listMultiviewers,
          items: mvs,
        };

        console.debug('Oops something went wrong. Can\'t get some of the multiviewers', listMultiviewers);
        commit('setList', { data: listMultiviewers, property: 'multiviewers' });
        return { error: true, message: error.errors };
      }

      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t get multiviewers' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchConfiguration({ commit }) {
    const result = await MultiviewService.getConfig();
    const { data } = result;
    const isSuccess = data.error === '';

    if (!isSuccess) return { error: true, message: data.error };

    commit('setSourceConfigurations', data.data);

    const sources = {};
    const configOptions = [];
    data.data.forEach((item) => {
      sources[item.name] = item;
      const haveSources = !!item.sources[0];
      const isHttps = haveSources && item.sources[0].manifest.startsWith('https');
      sources[item.name].protocol = isHttps ? 'https:' : 'http:';
      if (!haveSources) sources[item.name].protocol = 'https:';
      configOptions.push(item.name);
    });
    commit('setSourceConfigurationsObj', sources);
    commit('setSourceConfigOptions', configOptions);
    const urlString = window.location.href;
    const url = new URL(urlString);
    const source = url.searchParams.get('source') || localStorage.getItem('source');

    const doesSourceExist = configOptions.indexOf(source) !== -1;
    let selectedSource = configOptions[0];

    if (source && doesSourceExist) selectedSource = source;
    commit('setSelectedSourceConfig', selectedSource);

    // const isSelectedSet
    return result.data.data;
  },

  async setActiveLayout({ commit }, layout) {

    const result = await MultiviewService.setActiveLayout(layout);
    console.log('setLayout', result);
    commit('setLayout', layout);
  },

  async setActivePlayer({ commit }, player) {

    const result = await MultiviewService.setActivePlayer(player);
      console.log('setPlayer', result);
    commit('setPlayer', player);
  },


  async setActiveAudioChannel({ commit }, channel) {

    const result = await MultiviewService.setActiveAudioChannel(channel);
    console.log('setAudioChannel', result);
    commit('setAudioChannel', player);
  },

  async getActiveAudioChannel({ commit }) {
    const result = await MultiviewService.getActiveAudioChannel();
    console.log('getActiveAudioChannel', result);
    if (result.data.ok) {
      commit('setActiveAudioChannel', result.data.data);
    }
  },

  async getPlayersNumber({ commit }) {
    const result = await MultiviewService.getPlayersNumber();
    console.log('getPlayersNumber', result);
    if (result.data.ok) {
      commit('setPlayersNumber', result.data.data);
    }
  },

  async getAudioChannelsNo({ commit }) {
    const result = await MultiviewService.getAudioChannelsNo();
    console.log('getActiveAudioChannels', result);
    if (result.data.ok) {
      commit('setChannelsNumber', result.data.data);
    }
  },

  /*
  async fetchConfiguration({ commit }, id) {
    const result = await
    commit('setCurrentFolder', result.data.folder);
    return result.data.folder;
  },
  */
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
