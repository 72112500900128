<template>
  <div id="client-profile">
      <div>
        <div class="title-card">
          <div class="title-card-top">
            <div class="title-card-top-icon">
              <el-avatar>{{initials}}</el-avatar>
            </div>
            <div class="title-card-top-name">
              {{client.name | defaultValue('---')}}
              <div class="title-card-top-name-description">
                {{client.description}}
              </div>
              <div class="title-card-top-name-additional">
              </div>
            </div>
            <div class="title-card-top-actions">
              <el-button type="default" @click="editClientDialog = true">Edit</el-button>
              <el-button type="primary" @click="createUserDialog = true">Add user</el-button>
            </div>
          </div>
          <div class="title-card-bottom"></div>
        </div>
        <div class="content-wrapper">
          <transition name="el-fade-in-linear">
            <router-view :props="{id: id}"/>
          </transition>
        </div>
      </div>

      <!-- EDIT CLIENT -->
      <el-dialog
        title="Create Client"
        :visible.sync="editClientDialog"
        :width="'dialogWidth'">
        <div class="dialog-form">
          <el-form :model="client" v-if="client">
            <el-form-item required>
              <el-col :span="6">
                <el-form-item prop="code">
                  <el-input v-model="client.code" placeholder="Code"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1"> &nbsp; </el-col>
              <el-col :span="17">
                <el-form-item  prop="name">
                  <el-input v-model="client.name" placeholder="Company name"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="description">
              <el-input v-model="client.description" placeholder="Description"></el-input>
            </el-form-item>
            <el-form-item required>
              <el-col :span="12">
                <el-form-item  prop="email" required>
                  <el-input v-model="client.email" placeholder="Email address"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1"> &nbsp; </el-col>
              <el-col :span="11">
                <el-form-item  prop="phoneno">
                  <el-input v-model="client.phoneno"  placeholder="Phone number"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editClientDialog = false">Cancel</el-button>
          <el-button type="primary" @click="saveClient()">Save</el-button>
        </span>
      </el-dialog>

      <!-- ADD USER -->
      <el-dialog
        title="Create Feature"
        :visible.sync="createFeatureDialog"
        :width="dialogWidth">
        <div class="dialog-form">
          <el-form :model="featureForm">
            <el-form-item required>
              <el-col :span="6">
                <el-form-item prop="code">
                  <el-input v-model="featureForm.code" placeholder="Code"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1"> &nbsp; </el-col>
              <el-col :span="17">
                <el-form-item  prop="name">
                  <el-input v-model="featureForm.name" placeholder="Feature name"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="description">
              <el-input v-model="featureForm.description" placeholder="Description"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="createFeatureDialog = false">Cancel</el-button>
          <el-button type="primary" @click="addFeature()">Save</el-button>
        </span>
      </el-dialog>

      <!-- ADD FEATURE -->
      <el-dialog
        title="Create Feature"
        :visible.sync="createFeatureDialog"
        :width="dialogWidth">
        <div class="dialog-form">
          <el-form :model="featureForm">
            <el-form-item required>
              <el-col :span="6">
                <el-form-item prop="code">
                  <el-input v-model="featureForm.code" placeholder="Code"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1"> &nbsp; </el-col>
              <el-col :span="17">
                <el-form-item  prop="name">
                  <el-input v-model="featureForm.name" placeholder="Feature name"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="description">
              <el-input v-model="featureForm.description" placeholder="Description"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="createFeatureDialog = false">Cancel</el-button>
          <el-button type="primary" @click="addFeature()">Save</el-button>
        </span>
      </el-dialog>

      <!-- ADD CLAIM -->
      <el-dialog
        title="Create Feature"
        :visible.sync="createClaimDialog"
        :width="dialogWidth">
        <div class="dialog-form">
          <el-form :model="claimForm">
            <el-form-item required>
              <el-col :span="6">
                <el-form-item prop="code" required="">
                  <el-input v-model="claimForm.code" placeholder="Code"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1"> &nbsp; </el-col>
              <el-col :span="17">
                <el-form-item  prop="name" required="">
                  <el-input v-model="claimForm.name" placeholder="Claim name"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="description">
              <el-input v-model="claimForm.description" placeholder="Description"></el-input>
            </el-form-item>
            <!--
            <el-form-item prop="feature">
              <el-select v-model="claimForm.feature" placeholder="Select feature">
                <el-option
                  v-for="item in features"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            -->
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="createClaimDialog = false">Cancel</el-button>
          <el-button type="primary" @click="addClaim()">Save</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';

export default {
  props: ['id'],
  data() {
    return {
      error: null,
      isMobileOnly,
      featureForm: {
        code: '',
        name: '',
        description: '',
      },

      claimForm: {
        code: '',
        name: '',
        description: '',
        feature: ['sss'],
      },

      // dialogs
      editClientDialog: false,
      createFeatureDialog: false,
      createClaimDialog: false,
    };
  },

  computed: {
    ...mapState({
      clients: (state) => state.admin.clients,
      clientId: (state) => state.client.clientId,
      client: (state) => state.client.client,
    }),

    dialogWidth() {
      return this.isMobileOnly ? '90%' : '420px';
    },

    initials() {
      if (!this.client.name) return '';
      return this.client.name.split(' ').map((n) => n[0]).join('');
    },
  },

  watch: {
    id(newVal, oldVal) {
      console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      this.setCurrentClientId(newVal);
    },
  },

  mounted() {
    this.setCurrentClientId(this.id);
    this.setCurrentClientId(this.$route.params.id);
    this.fetchClientDetails(this.clientId);
  },

  created() {
  },

  methods: {
    ...mapActions({
      updateClient: 'client/updateClient',
      deleteClient: 'client/deleteClient',
      fetchClientDetails: 'client/fetchClientDetails',
    }),
    ...mapMutations({
      setCurrentClientId: 'client/setCurrentClientId',
    }),

    async saveClient() {
      const newClient = {
        id: this.client.id,
        name: this.client.name,
        description: this.client.description,
        email: this.client.email,
        phoneno: this.client.phoneno,
        code: this.client.code,
      };
      const result = await this.updateClient(newClient);
      if (result.error) {
        this.$message({
          showClose: true,
          message: 'Oops, can\'t add client.',
          type: 'error',
        });
        return;
      }

      this.$message({
        showClose: true,
        message: 'Updated',
        type: 'info',
      });
      this.editClientDialog = false;
    },
    btnText(text) {
      if (this.isBtnLoading) return 'Loading...';
      return text;
    },
  },
};
</script>

<style>
#client-profile .content-wrapper .data-header-tabs-tab {
  cursor: pointer;
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}



.el-loading-spinner svg {
  margin: 0 auto;
}

#client-profile {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding-top: 0;
  margin: 20px;
}
#client-profile .title-card {
  grid-template-columns: 1fr;
}
#client-profile .content-wrapper .data-header {
  grid-template-columns: 1fr auto;
}
#client-profile .content-wrapper .data-header-tabs {
  color: #CDD6EE;
}
#client-profile .content-wrapper .data-header-tabs-tab {
  padding-right: 16px;
  display: inline-block;
}
#client-profile .content-wrapper .data-header-tabs-tab:hover, #client-profile .content-wrapper .data-header-tabs-tab.active {
  color: #00D4FF;
}
#client-profile .content-wrapper .data-header-actions {
  display: grid;
  justify-content: end;
}
</style>
