<template>
  <div id="liveops-multiviewer" class="liveops">
    <div class="selects">
      <div>
        <el-select
            size="mini" v-model="selectedMulti" placeholder="Select"
          value-key="id">
          <el-option
            v-for="item in configuration"
            :key="item.id"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="">
        <i class="arrow right"></i>
      </div>
      <div>
        <el-select
            size="mini"
            @change="layoutChange(selectedMulti)"
            v-model="selectedMulti.activeLayout" placeholder="Select">
          <el-option
            v-for="item in availableLayouts"
            :key="item"
            :label="'Layout ' + item"
            :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="reset">
        <el-button size="mini" @click="reset(selectedMulti.id)"
          :disabled="!selectedMulti.id"
          type="primary">
          RESET
        </el-button>
      </div>
    </div>
    <div class="section-label">PLAYERS ({{playersCount}}) <small style="float:right">Click to change active player</small></div>
    <div class="players">
      <div class="player"
        v-for="(player, index) in selectedMulti.playerLabels"
        :class="{active: player.player === selectedMulti.activePlayer}"
        @click="playerChange(selectedMulti, player)"
        :key="player.player + index">
        {{player.name}}
      </div>
    </div>
    <div class="section-label">AUDIO CHANNELS ({{audioCount}}) <small style="float:right">Click to change active audio channel</small></div>
    <div class="players">
      <div class="player"
        v-for="(channel, index) in availableAudioChannels"
        @click="audioChange(selectedMulti, channel)"
        :class="{active: channel === selectedMulti.activeAudioChannel}"
        :key="channel + index">
        {{channel}}
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import _ from 'lodash';
import moment from 'moment';
import { detect } from 'detect-browser';
import ColorGenerator from '@/services/ColorGenerator';
import 'splitpanes/dist/splitpanes.css';

export default {
  name: 'LiveOpsMultiviewer',
  data() {
    return {
      isMobileOnly,
      selectedMulti: {
        playerLabels: [],
      },
      selectedLayout: null,
      layouts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      now: moment.utc(),
    };
  },

  created() {
    this.init();
    this.initInterval();
  },

  beforeDestroy() {
    window.clearInterval(this.interval);
    this.interval = null;
  },

  computed: {
    ...mapState({
      aclEx: (state) => state.userAccount.userPermissions,
      acl: (state) => state.userAccount.aclCurrent,
      isAdmin: (state) => state.userAccount.isAdmin,
      client: (state) => state.userAccount.client,
      account: (state) => state.userAccount.account,
      clients: (state) => state.userAccount.clients,
      siteList: (state) => state.router.siteList,
      selection: (state) => state.router.selectedSite,
      loadings: (state) => state.router.loadings,

      configuration: (state) => state.multiviewCinegy.configuration,
    }),
    availableLayouts() {
      return this.layouts.filter((item, index) => index < this.selectedMulti.layouts);
    },
    playersCount() {
      return this.selectedMulti ? this.selectedMulti.playerLabels.length : 0;
    },
    audioCount() {
      return this.availableAudioChannels ? this.availableAudioChannels.length : 0;
    },
    availableAudioChannels() {
      return this.layouts.filter((item, index) => index < this.selectedMulti.audioChannels);
    },

    liveEvents() {
      return this.events.filter((event) => event.status === 'LIVE');
    },

    upcoming() {
      return this.events.filter((event) => moment.utc(event.serviceStartTime).isAfter(this.now));
    },
  },

  watch: {
    seletedRouterGroup() {
      this.selectedSource = null;
      this.selectedDestination = null;
    },
  },

  methods: {
    ...mapActions({
      fetchConfiguration: 'multiviewCinegy/fetchConfiguration',
      changeLayout: 'multiviewCinegy/changeLayout',
      changePlayer: 'multiviewCinegy/changePlayer',
      changeAudioChannel: 'multiviewCinegy/changeAudioChannel',
      restart: 'multiviewCinegy/restart',
    }),

    initInterval() {
      this.interval = setInterval(async () => {
        this.refresh();
        this.$forceUpdate();
      }, 15000);
    },

    async reset(id) {
      await this.restart({ multiviewerId: id });
    },

    async playerChange(multi, player) {
      await this.changePlayer({ multiviewerId: multi.id, newPlayer: player.player });
      await this.fetchConfiguration();
      console.log(multi, player);
    },
    async audioChange(multi, channel) {
      await this.changeAudioChannel({ multiviewerId: multi.id, player: multi.activePlayer, newAudioChannel: channel });
      await this.fetchConfiguration();
    },

    async layoutChange(multi) {
      console.log('multi', multi);
      await this.changeLayout({ multiviewerId: multi.id, newLayout: multi.activeLayout });
    },

    async init() {
      await this.fetchConfiguration();
      this.configuration.forEach((el) => {
        if (el.id === this.selectedMulti.id) this.selectedMulti = el;
      });
    },

    async refresh() {
      this.now = moment.utc();
      this.init();
    },
  },
};
</script>

<style>


.el-loading-spinner svg {
  margin: 0 auto;
}

#liveops-multiviewer {
  padding: 2px;
}
#liveops-multiviewer .section-label {
  margin-top: 20px;
  margin-bottom: 0;
}
#liveops-multiviewer .players {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 12px;
  align-items: center;
}
#liveops-multiviewer .players .player {
  padding: 16px;
  border-radius: 4px;
  align-items: center;
  background: #232b33;
  color: #CDD6EE;
  font-size: 12px;
  cursor: pointer;
}
#liveops-multiviewer .players .player.active {
  outline: 2px solid #ff9f31;
  font-weight: bold;
}
#liveops-multiviewer .players .player:hover {
  background: #ff9f31;
  color: #fff;
}
#liveops-multiviewer .selects {
  display: grid;
  grid-template-columns: 1fr 10px 1fr 70px;
  gap: 24px;
}
#liveops-multiviewer .selects div {
  line-height: 28px;
}
#liveops-multiviewer .selects .arrow {
  border: solid #373e46;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
#liveops-multiviewer .selects .right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
#liveops-multiviewer .selects .el-select {
  width: 100%;
}
#liveops-multiviewer .selects .el-select .el-input__inner {
  background: #373e46;
  border-color: #373e46;
  color: #fff;
}
</style>
