<template>
  <div id="admin-feature">
      <div>
        <div class="title-card">
          <div class="title-card-top">
            <div class="title-card-top-icon">
              <el-avatar>{{initials}}</el-avatar>
            </div>
            <div class="title-card-top-name">
              {{client.name}}
              <div class="title-card-top-name-description">
                {{client.description}}
              </div>
              <div class="title-card-top-name-additional">
                <i class="bi bly-info"></i> {{client.email}}
                <i class="bi bly-info"></i> {{client.phoneno}}
              </div>
            </div>
            <div class="title-card-top-actions">
              <el-button type="primary">Edit</el-button>
            </div>
          </div>
          <div class="title-card-bottom"></div>
        </div>
        <div class="content-wrapper">
          <transition name="el-fade-in-linear">
            <router-view />
          </transition>
        </div>
      </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

export default {
  props: ['id'],
  data() {
    return {
      queries,
      mutations,
      error: null,
      isBtnLoading: false,
      user: null,
      clientId: null,
    };
  },

  computed: {
    ...mapState({
      clients: (state) => state.admin.clients,
    }),

    client() {
      return this.clients[this.id];
    },

    initials() {
      if (!this.client) return '';
      return this.client.name.split(' ').map((n) => n[0]).join('');
    },
  },

  watch: {
  },

  mounted() {
    console.log('CLIENT MOUNTED WITH ID: ', this.id);
  },

  created() {
  },

  methods: {
    ...mapActions({
      updateClient: 'admin/updateClient',
      deleteClient: 'admin/deleteClient',
    }),

    btnText(text) {
      if (this.isBtnLoading) return 'Loading...';
      return text;
    },
    async getUserProfile() {
      const result = await this.$Amplify.Auth.currentAuthenticatedUser();
      Vue.set(this, 'user', result);
      const userData = await API.graphql(
        graphqlOperation(this.queries.getUser, { id: this.user.username }),
      );
    },
  },
};
</script>

<style>
  #admin-client {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding-top: 0;
  }
</style>
