<template>
  <div class="fixed bottom-0 py-1 px-2 pl-4">
    <div class="flex space-x-2">
      <div class="bg-black bg-opacity-70 px-2 rounded-sm flex items-center"><span class="uppercase text-xs mr-1">Total </span> <span class="text-white"> {{eventsStats.total}}</span> </div>
      <div class="bg-black bg-opacity-70 px-2 rounded-sm flex items-center"><span class="uppercase text-xs mr-1">Live </span> <span class="text-white"> {{eventsStats.live}}</span> </div>
      <div class="bg-black bg-opacity-70 px-2 rounded-sm flex items-center"><span class="uppercase text-xs mr-1">Completed </span> <span class="text-white"> {{eventsStats.completed}}</span> </div>
      <div class="bg-black bg-opacity-70 px-2 rounded-sm flex items-center"><span class="uppercase text-xs mr-1">Remaining </span> <span class="text-white"> {{eventsStats.remaining }}</span> </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ScheduleStats',
  props: {
    eventsStats: {
      type: Object,
      default: () => {
        return {
        };
      },
    },
  },

  data() {
    return {

    };
  },
};
</script>
