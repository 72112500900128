<template>
  <div class="file">
    <div class="name">
      {{file.id}}
    </div>
    <div class="actions">
      <div class="status">
        {{file.uptime}}
      </div>
      <i class="bi bly-more icon-btn" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    file: {
      type: Object,
      default: () => {
        console.log('');
        return {
          id: 0,
          application: ' ',
          streamfile: '',
          uptime: 0,
          bytesIn: 0,
          bytesOut: 0,
          bytesInRate: 0,
          bytesOutRate: 0,
          totalConnections: 0,
        };
      },
    },
  },

  data() {
    return {

    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.file {
  background: #1B2127;
  margin-bottom: 8px;
  line-height: 40px;
  display: grid;
  grid-template-columns: 1fr 150px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  margin-left: 32px;
}
.file .progress {
  height: 100%;
  position: absolute;
  top: 0;
  left: -1px;
  border-right: 1px solid rgba(207, 223, 255, 0.1);
  background: -moz-linear-gradient(left, rgba(102, 113, 141, 0) 0%, rgba(102, 113, 141, 0.15) 100%);
  background: -webkit-linear-gradient(left, rgba(102, 113, 141, 0) 0%, rgba(102, 113, 141, 0.15) 100%);
  background: linear-gradient(to right, rgba(102, 113, 141, 0) 0%, rgba(102, 113, 141, 0.15) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#26ffffff",GradientType=1 );
}
.file.uploading .actions .status {
  color: #00FF80;
}
.file.completed {
  background: rgba(0, 255, 128, 0.1);
}
.file.completed .name i {
  color: #00FF80;
}
.file.completed .actions .status {
  color: #00FF80;
}
.file.failed {
  background: rgba(252, 72, 127, 0.2);
}
.file.failed .name {
  color: #FC487F;
}
.file.failed .name i {
  color: #FC487F;
}
.file.failed .actions .status {
  color: #FC487F;
}
.file .name {
  margin-left: 16px;
}
.file .name i {
  margin-right: 8px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  color: #CFDFFF;
  font-size: 16px;
}
.file .actions {
  text-align: right;
  padding-right: 16px;
}
.file .actions .status {
  font-weight: bold;
  display: inline-block;
}
</style>
