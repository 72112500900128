<template>
   <div class="h-full">

      <div class="inner-wrapper relative h-[calc(100vh-60px)] flex items-center" ref="pw-0">

    <div class="sm:w-full flex items-start justify-center sm:order-last -order-1">
          <div id="player" class="w-full"></div>
          <!-- <pre>
            {{activeMV}}
          </pre> -->
      </div>
        <!-- <div class="overlay top-5 rounded-md left-5 right-5 bg-black p-5 absolute" :class="{hidden : activeOverlay !== source.id }">
          <div class="tiny"></div>
          <div class="videoInfo" :ref="'vp' + source.id + '-meta'">
            Video Resolution: <strong>{{getResolution(source)}}</strong> <br>
            Bitrate: <strong v-if="source.levels">{{getBitrate(source)}} k</strong>
          </div>
        </div> -->
        <!-- <div
          class="absolute top-2 left-2 hover:text-brand cursor-pointer z-40"
          type="primary"
          size="mini" @click="toogleFullscreenForViewPort(source.id)">
          <i class="bi bly-fullscreen"></i>
        </div> -->
        <!-- <div class="bottom absolute bottom-2 left-2 right-2 flex justify-between items-center ">
            <div class=" flex w-20 items-center">
              <i class="bi bly-info icon-btn w-6 text-white" @click="toggleOverlay(source.id)"></i>
              <div class="w-6 h-6">
                <div class="quality uppercase text-gray-500 text-[9px]">quality</div>
                <div>
                  <div class="link text-xs" @click="setLevel(index,-1)"
                      :class="{'text-brand': getLvl(source) === -1}" >auto </div>
                  <div class="link text-xs"
                      v-for="(quality, i) in source.levels"
                      :key="quality.attrs.RESOLUTION"
                      @click="setLevel(index, i)"
                      :class="{'text-brand': getLvl(source) === i}" >
                        {{quality.height}}p
                      </div>
                </div>
                <div class="ma-popover hidden">
                  <div class="ma-popover-content">
                    <span class="p-title">Quality</span>
                    <div class="link" @click="setLevel(index,-1)"
                      :class="{'active': getLvl(source) === -1}" >auto </div>
                    <div class="link"
                      v-for="(quality, i) in source.levels"
                      :key="quality.attrs.RESOLUTION"
                      @click="setLevel(index, i)"
                      :class="{'active': getLvl(source) === i}" >
                        {{quality.height}}p
                      </div>
                  </div>
                  <div slot="reference" size="mini" class="bg-transparent h-6 w-6" >
                    <i class="bi bly-more  h-6 w-6 bg-black" v-if="!isMobileOnly"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-white font-bold">
              {{source.streamSource.name}}
            </div>
            <div>
              <span class="buffering text-brand text-xs" v-show="source.loading">Buffering...</span>
            </div>
        </div>
        <div class="peak-meter bg-black absolute top-0 right-0 bottom-0 w-20" :ref="'vp0-meter'">
        </div> -->
      </div>
      <div class="audio border-t border-black">
        <!-- <div class="players flex gap-1">
          <div class="player px-2 py-2 bg-slate-800 rounded cursor-pointer text-xs text-center"
            v-for="(player, index) in selectedMulti.playerLabels"
            :class="{'ring-orange-500 ring-2': player.player === selectedMulti.activePlayer}"
            @click="playerChange(selectedMulti, player)"
            :key="player.player + index">
            {{player.name}}
          </div>
        </div> -->
        <!-- <div class="section-label text-xs my-1">AUDIO CHANNELS <small style="float:right">Click to change active audio channel</small></div>
          <div class="players flex gap-4">
            <div class="player px-8 py-2 bg-slate-800 rounded cursor-pointer"
              v-for="(channel, index) in [0, 1, 2, 3]"
              @click="audioChange(selectedMulti, channel)"
              :class="{'ring-orange-500 ring-2': channel === selectedMulti.activeAudioChannel}"
              :key="channel + index">
              {{channel}}
            </div>
          </div>-->
      </div> 
   </div>
</template>

<script>

import { detect } from 'detect-browser';
import { mapState, mapMutations, mapActions } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import shaka from 'shaka-player';
import Hls from 'hls.js';
import Vue from 'vue';

import WebAudioPeakMeter from '../peakMeter';

export default {
  name: 'mvbox',
  props: ['mvId', 'sourceId'],
  data() {
    return {
      isMobileOnly,
      activeViewPort: null,
      activeFullScreenViewPort: null,
      shakaPlayers: {},
      hideGrid: false,
      hlsconfig: {
        capLevelToPlayerSize: true,
      },
      // gridColumns: 4,
      // gridRows: 4,
      // sources: [],
      ppms: {}, // peak meters resources
      isFullscreen: false,
      showPPM: true,
      keys: {
        1: 0,
        2: 1,
        3: 2,
        4: 3,
        5: 4,
        6: 5,
        7: 6,
        8: 7,
        q: 8,
        w: 9,
        e: 10,
        r: 11,
        t: 12,
        y: 13,
        u: 14,
        i: 15,
      },
      // source: null,
      selectedConfig: null,
      selectedMulti: {
        id: '1050',
        playerLabels: [],
      },

      // errors
      hlsMediaIssue: false,
      hlsIssue: false,

      // ui
      activeOverlay: null,
      showSupportMessage: false,
      showInitialSelection: true,
      sourcePickerValue: null,

      tempDisable: true,
      sourcesConfig: {},
      source: null,
      interval: null,
    };
  },

  computed: {
    ...mapState({
      selectedMultiviewer: (state) => state.multiview.selectedMultiviewer,
      multiviewers: (state) => state.multiview.multiviewers,
      sources: (state) => state.multiview.sources,
      gridColumns: (state) => state.multiview.gridColumns,
      gridRows: (state) => state.multiview.gridRows,
      configuration: (state) => state.multiviewCinegy.configuration,
    }),
    sortedMultiviers() {
      return this.multiviewers
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },
    gridClass() {
      const ppm = this.showPPM ? 'show-ppm' : '';
      // if (this.isMobileOnly) return 'grid-mobile';
      return `grid-${this.gridColumns}x${this.gridRows} ${ppm}`;
    },

    getResolution() {
      return (source) => {
        console.log();
        return source.player ? `${source.player.videoWidth}x${source.player.videoHeight}` : '';
      };
    },

    getLvl() {
      return (source) => {
        const isHls = source.type === 'hls';
        if (!isHls) return '-';
        return source.obj ? source.obj.currentLevel : '';
      };
    },

    isOverlayVisible() {
      return (index) => this.activeOverlay === index;
    },

    getBitrate() {
      return (source) => {
        const isHls = source.type === 'hls';
        if (!isHls) return '-';
        const level = source.levels[this.getLvl(source)];
        return level ? Math.round(level.bitrate / 1024) : '';
      };
    },

    isHttps() {
      return window.location.protocol === 'https:';
    },

    currentProtocol() {
      return window.location.protocol;
    },

    currentLocation() {
      return window.location.href.substring(window.location.protocol.length);
    },

    keymap() {
      return {
        1: this.keyActions,
        2: this.keyActions,
        3: this.keyActions,
        4: this.keyActions,
        5: this.keyActions,
        6: this.keyActions,
        7: this.keyActions,
        8: this.keyActions,
        q: this.keyActions,
        w: this.keyActions,
        e: this.keyActions,
        r: this.keyActions,
        t: this.keyActions,
        y: this.keyActions,
        u: this.keyActions,
        i: this.keyActions,
        'ctrl+shift+f': {
          keyup: this.toggleFullscreen,
        },
        'ctrl+alt+2': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+3': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+4': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+5': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+6': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+7': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+8': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+q': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+w': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+e': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+r': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+t': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+y': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+u': {
          keyup: this.keySourceFullscreen,
        },
        'ctrl+alt+i': {
          keyup: this.keySourceFullscreen,
        },
      };
    },
  },

  watch: {

  },

  created() {
    // this.listMultiviewers();
    this.initMultiview();
  },

  beforeDestroy() {
    this.setSelectedSourceConfig(null);
    this.sldpPlayer.destroy(function () {
      console.log('SLDP Player is destroyed.');
    });
    this.disposePlayers();

    window.clearInterval(this.interval);
    this.interval = null;
  },

  async mounted() {
    this.interval = setInterval(async () => {
        this.fetchCinegyConfiguration();
      }, 15000);
      setTimeout(() => {
        this.initSLDPPlayer();
      }, 1000)
    if (document.addEventListener) {
      document.addEventListener('webkitfullscreenchange', this.refreshPPM, false);
      document.addEventListener('mozfullscreenchange', this.refreshPPM, false);
      document.addEventListener('fullscreenchange', this.refreshPPM, false);
      document.addEventListener('MSFullscreenChange', this.refreshPPM, false);
    }
  },

  methods: {
    ...mapActions({
      fetchConfiguration: 'multiview/fetchConfiguration',
      fetchConfiguration2: 'multiviewCinegy/fetchConfiguration',
      changeAudioChannel: 'multiviewCinegy/changeAudioChannel',
      changePlayer: 'multiviewCinegy/changePlayer',
      listMultiviewers: 'multiview/listMultiviewers',
    }),

    ...mapMutations({
      setSelectedSourceConfig: 'multiview/setSelectedSourceConfig',
    }),

    // async getSources() {
    //   this.selectedConfig = this.selectedSourceConfig;
    //   this.source = this.selectedSourceConfig;
    // },
    async playerChange(multi, player) {
      await this.changePlayer({ multiviewerId: multi.id, newPlayer: player.player });
      await this.fetchConfiguration2();
      console.log(multi, player);
    },
    async audioChange(multi, channel) {
      await this.changeAudioChannel({ multiviewerId: multi.id, player: multi.activePlayer, newAudioChannel: channel });
      setTimeout(() => {
        this.fetchConfiguration2();
      }, 3000)
      // await this.fetchConfiguration();
    },
    async fetchCinegyConfiguration() {
      await this.fetchConfiguration2();
      this.configuration.forEach((el) => {
        if (el.id === this.selectedMulti.id) this.selectedMulti = el;
      });
    },
    async initMultiview() {

      const urlString = window.location.href;
      const url = new URL(urlString);
      const urlSource = url.searchParams.get('source');
      const isSSLReload = !!urlSource;

      await this.fetchConfiguration2();
      this.configuration.forEach((el) => {
        if (el.id === this.selectedMulti.id) this.selectedMulti = el;
      });
      
      const mvs = await this.listMultiviewers();
      // if (isSSLReload) {
      //   await this.$confirm(`You've been successfully switched to ${urlSource}`,
      //     '', {
      //       center: true,
      //       showCancelButton: false,
      //       confirmButtonText: 'OK',
      //       type: 'info',
      //     });
      //   this.showInitialSelection = false;
      //   // await this.fetchConfiguration();
      //   // await this.listMultiviewers();
      //   // this.loadMultiview(urlSource);
      //   return;
      // }
      // await this.fetchConfiguration();
      // await this.listMultiviewers();
      if (mvs && mvs.items) {
        const mv = mvs.items.find((el) => el.id === this.mvId);
        console.log('mvs', this.mvId, mv, mvs);
        if (!mv) return;
        const sources = mv.streamSources.items;
        this.source = sources[0];
        if (this.sourceId) {
          this.source = sources.find((el) => el.id === this.sourceId);
        }
        this.sourcesConfig[this.source.id] = {};
        // this.loadMultiview(this.source);
        this.$forceUpdate();
        this.disposePlayers();
        this.$forceUpdate();

        shaka.polyfill.installAll();
        this.setSelectedSourceConfig(this.source);
        setTimeout(() => {
          // this.loadMultiview(this.source);

          this.initPlayer(this.source, this.source.id);

          // this.disposePlayers();

        }, 1000);
      }
      this.sourcePickerValue = this.source;

      this.showInitialSelection = true;
      const browser = detect();
      const isBrowserSupported = browser.name === 'firefox' || browser.name === 'chrome';
      const savedMV = localStorage.getItem('source');
      // if (savedMV) {
      //   const source = JSON.parse(savedMV);
      //   const matchingSource = this.multiviewers.find((el) => el.id === source.id)
      //   if (!matchingSource) return;
      //   this.setSelectedSourceConfig(matchingSource);
      //   this.sourcePickerValue = matchingSource;
      //   this.$forceUpdate();        
      // }
      if (!isBrowserSupported) this.showSupportMessage = true;
    },

    loadMultiview(newSource) {
      // this.tempDisable = false;
      console.log('loadMultiview', newSource);
      localStorage.setItem('latestMV', newSource.id);

      // this.$forceUpdate();
      // this.disposePlayers();
      // this.$forceUpdate();

      this.hideGrid = true;
      setTimeout(() => this.hideGrid = false, 1000);
      this.$forceUpdate();
      this.showInitialSelection = false;
      localStorage.setItem('source', JSON.stringify(newSource));
      this.setSelectedSourceConfig(newSource);
      this.$forceUpdate();
      this.tempDisable = true;
      this.$nextTick(() => {
        // const doReload = this.selectedConfig.protocol !== this.currentProtocol;
        // if (doReload) this.handleSSL(this.selectedConfig.protocol, newSource);
        shaka.polyfill.installAll();
        this.sources.forEach((source, index) => {
          this.initPlayer(source, source.id);
        });
      });
      //  this.$nextTick(() => {
      //   shaka.polyfill.installAll();
      //   this.sources.forEach((source, index) => {
      //     this.initPlayer(source, index);
      //   });
      // });
    },

    disposePlayers() {
      document.removeEventListener('webkitfullscreenchange', this.refreshPPM, false);
      document.removeEventListener('mozfullscreenchange', this.refreshPPM, false);
      document.removeEventListener('fullscreenchange', this.refreshPPM, false);
      document.removeEventListener('MSFullscreenChange', this.refreshPPM, false);

      this.sources.forEach((source, key) => {
        const doesPlayerExist = !!source.player;

        if (source.type === 'hls' && source.obj) source.obj.stopLoad();
        this.sources[key].obj = null;
        if (doesPlayerExist) {
          source.player.pause();
          source.player.removeEventListener('playing', this.onPlaing);
          source.player.removeEventListener('canplay', this.onCanPlay);
          source.player.removeEventListener('waiting', this.onWaiting);
        }
        this.$forceUpdate();
      });
      this.$forceUpdate();
    },

    async handleSSL(protocol, source) {
      window.history.replaceState(null, null, window.location.pathname);
      const newUrl = `${protocol}${this.currentLocation}?source=${source}`;
      console.log('handleSSL', protocol, source, newUrl);
      const confirmation = await this.$confirm(
        `To show you ${source} we need to switch protocols. You may need to login again after the switch. Do you want to continue?`,
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          center: true,
        },
      ).catch((error) => {
        console.log('error', error);
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      window.location.href = newUrl;
    },

    toggleOverlay(index) {
      const isCurrent = this.activeOverlay === index;

      if (isCurrent) {
        this.activeOverlay = null;
        return;
      }

      this.activeOverlay = index;
    },

    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;

      if ((document.fullScreenElement && document.fullScreenElement !== null)
        || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
          return;
        }
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
          return;
        }
        if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

    toogleFullscreenForViewPort(index) {
      const element = this.$refs[`pw-0`];
      if (
        document.fullscreenElement
        || document.webkitFullscreenElement
        || document.mozFullScreenElement
        || document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        }
        if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        }
        if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
        if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
        const isActive = this.activeViąewPort === index;
        if (!isActive) this.activateViewPort(index);
      }
      return false;
    },

    refreshPPM() {
      setTimeout(() => {
        this.sources.forEach((source, index) => {
          this.ppms[source.id].wap.updateMeretSize();
        });
      }, 200);
    },

    setLevel(index, lvl) {
      this.sourcesConfig.obj.currentLevel = lvl;
    },

    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },

    keyActions(event) {
      this.activateViewPort(this.keys[event.key]);
    },

    keySourceFullscreen(event) {
      this.toogleFullscreenForViewPort(this.keys[event.key]);
    },

    initHlsPlayer(conf, id) {
      const hls = new Hls(this.hlsconfig);
      const videoelem = this.$refs['vp0'];
      console.log('initHlsPlayer', conf.streamSource.address, videoelem, this.$refs.vp0);
      
      hls.attachMedia(videoelem);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource('https://cdn3.wowza.com/5/a29LUkZ3czE2U1cw/MAOrigin_IMG/IMG_MV_OTT/playlist.m3u8');
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          videoelem.muted = false;
          // hls.loadSource(conf.streamSource.address);
          videoelem.play();
          this.onPlayerLoaded(videoelem, 'hls', 0, hls);
        });
        setTimeout(() => {

        }, 5000);
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              this.hlsMediaIssue = true;
              hls.recoverMediaError();
              break;
            default:
              // cannot recover
              this.hlsIssue = true;
              hls.destroy();
              break;
          }
        }
      });
      // TODO: Store in list
      hls.on(Hls.Events.LEVEL_SWITCH, (event, data) => {
        const level = hls.levels[data.level];
        const metaelem = this.$refs[`${hls.media.id}-meta`];
        metaelem.innerHTML = `${(level.bitrate / 1000).toFixed(0)}kbps`;
      });
    },

    initDashPlayer(conf, index) {
      const videoelem = this.$refs[`vp${index}`][0];
      const shakap = new shaka.Player(videoelem);
      this.shakaPlayers[index] = shakap;
      videoelem.addEventListener('progress', this.onProgress);

      shakap.load(conf.address).then(() => {
        // videoelem.muted = true;
        shakap.setMaxHardwareResolution(600, 600);
        videoelem.play();
        this.onPlayerLoaded(videoelem, 'shaka', 0, shakap);
      }).catch((e) => { console.log('Error: ', e); });
    },

    onProgress(ev) {
      // console.log('onProgress EV', ev);
      const { index } = ev.target.dataset;
      if (!index) return;
      const p = this.shakaPlayers[index];
      if (!p) return;
      const stats = p.getStats();
      this.sourcesConfig.progress = `${(stats.streamBandwidth / 1000).toFixed(0)}kbps`;
    },

    initPlayer(conf, id) {
      console.log('==== initPlayer : conf', conf);
      // if (conf.streamSource.type === 'HLS') this.initHlsPlayer(conf, id);
      // if (conf.streamSource.type === 'DASH') this.initDashPlayer(conf, id);
    },
    initSLDPPlayer(){
      this.sldpPlayer = SLDP.init({
        container:           'player',
        stream_url:          'wss://ll-img.streams.elas.tech:8081/Multiviewer01/MV50',
        adaptive_bitrate: {
          initial_rendition: '240p'
        },
        buffering:           500,
        height: 'parent',
        width: 'parent',
        autoplay: true,
      });
    },

    activateViewPort(id) {
      // const activeEl = document.querySelector('.player-wrapper.active');
      // if (activeEl) activeEl.classList.remove('active');
      // const isActive = this.activeViewPort === id;
      // const isActiveSet = !!this.activeViewPort || this.activeViewPort === 0;

      // if (isActiveSet) this.muteVideo(this.activeViewPort);

      // if (isActive) {
      //   this.activeViewPort = null;
      //   return;
      // }

      // this.sources.forEach((source, i) => {
      //   console.log('this.sources.forEach', source);
      //   if (id === source.id) {
      //     console.log('this.sources.forEach 2', source);
      //     // source.player.muted = false;
      //     this.ppms[id].sourceNode.connect(this.ppms[id].audioCtx.destination);
      //   }
      // });
      // this.activeViewPort = id;
      // document.querySelector('.' + 'pw-' + id).classList.add('active');;
    },

    togglePlayback(videoelem) {
      if (videoelem.paused) {
        videoelem.play();
      } else {
        videoelem.pause();
      }
    },

    togglePlaybackOnAllViewPorts() {
      this.sources.forEach((source) => this.togglePlayback(source.player));
    },

    onPlayerLoaded(videoelem, type, index, obj) {
      console.log('onPlayerLoaded', videoelem, type, index, obj);
      this.sourcesConfig = {};

      console.log('onPlayerLoaded 2');
      Vue.set(this.sourcesConfig, 'player', videoelem);
      Vue.set(this.sourcesConfig, 'playerType', type);
      console.log('onPlayerLoaded 3');
      this.sourcesConfig.obj = obj;
      this.sourcesConfig.levels = [];
      this.sourcesConfig.audioTracks = [];
      const isHls = type === 'hls';
      console.log('onPlayerLoaded 3');
      if (isHls) {
        this.sourcesConfig.obj.currentLevel = this.indexOfSmallestQuality(obj.levels);
        this.sourcesConfig.levels = obj.levels;
        this.sourcesConfig.audioTracks = obj.audioTracks;
      }

      console.log('onPlayerLoaded 4');
      this.$nextTick(() => {
        videoelem.addEventListener('playing', this.onPlaing);
        videoelem.addEventListener('canplay', this.onCanPlay);
        videoelem.addEventListener('waiting', this.onWaiting);
        // videoelem.addEventListener('loadedmetadata', this.onLoadedMetadata);
        this.initPeakMeter(0);
      });

    },

    onCanPlay(event) {
      const { index } = event.target.dataset;
      this.sourcesConfig.loading = false;
    },

    onWaiting(ev) {
      const { index } = ev.target.dataset;
      this.sourcesConfig.loading = true;
    },

    onPlaying(ev) {
      const { index } = ev.target.dataset;
      this.sourcesConfig.loading = false;
      // ev.target.className = ev.target.className.replace("video-buffering", "");
    },

    initPeakMeter() {
      console.log('initPeakMeter');
      const myMeterElement = this.$refs[`vp0-meter`];
      const myAudio = this.sourcesConfig.player;
      console.log('initPeakMeter', myMeterElement, myAudio);

      const AudioContext = window.AudioContext || window.webkitAudioContext || false;
      if (!AudioContext) {
        // when Audiocontext can't be initialized mute video and return
        this.sourcesConfig.player.muted = true;
        return;
      }
      const audioCtx = new AudioContext();
      const wap = WebAudioPeakMeter();
      const sourceNode = audioCtx.createMediaElementSource(myAudio);
      const dest = audioCtx.createMediaStreamDestination();
      sourceNode.connect(dest);
      // sourceNode.connect(audioCtx.destination);
      const meterNode = wap.createMeterNode(sourceNode, audioCtx);
      if (!this.isMobileOnly) wap.createMeter(myMeterElement, meterNode, {});

      this.ppms[0] = {
        myMeterElement,
        myAudio,
        audioCtx,
        wap,
        sourceNode,
        dest,
        meterNode,
      };
    },

    muteVideo(index) {
      this.ppms[index].sourceNode.disconnect(this.ppms[index].audioCtx.destination);
    },

    indexOfSmallestQuality(a) {
      return a.reduce((lowest, next, index) => {
        console.log();
        return next.height < a[lowest].height ? index : lowest;
      }, 0);
    },
  },
}
</script>

<style>

</style>