<template>
  <div
    class="agenda-group-event bg-gray-800 relative p-1 rounded mb-1 cursor-pointer hover:bg-black"
    :class="{}"
  >
    <div
      class="w-3 top-1 bottom-1 rounded absolute text-xs text-black text-center font-bold pt-0.5"
      :class="bgStatusColor(eventStatus)"
    >
      G
      <div
        class="absolute bottom-0 left-0 right-0 transform rotate"
        :class="{ 'rotate-90': !isOpen, '-rotate-90 -left- -right-': isOpen }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="rotate-[270deg]"></div>
    </div>
    <div class="group-header md:flex justify-between mb-1">
      <!-- GROUP NAME -->
      <div class="flex space-x-1 items-center text-sm ml-6">
        <span class="" :class="eventStatusTextColorClass">
          {{ findCommonPartFirst || findCommonPartSecond || event.name }}
          <span v-if="eventGroup">({{ eventGroup.length }})</span>
        </span>
        <span
          v-for="status in groupStatuses"
          :key="status"
          class="w-3 h-3 inline-block ml-1 rounded transform"
          :class="bgStatusColor(status)"
        ></span>
        <div class="agenda-day-event-name-description ml-1">
          {{ event.description }}
        </div>
      </div>
      <!-- GROUP CLIENT/ACCOUNT -->
      <div class="text-xs text-gray-100 ml-6 md:ml-0 py-0.5 px-1 rounded bg-gray-900">
        <span class="fc-event-account">
          <span v-if="event.commentaryLanguage" class="commentary-language"
            ><i class="fas fa-globe-americas"></i> {{ event.commentaryLanguage }}
          </span>
          <span :class="eventStatusTextColorClass"> GROUP </span>
          <span class="vertical-divinder"></span>
          <span class="fc-event-account-client">{{ event.customer.name }}</span>
        </span>
      </div>
    </div>
    <div class="sm:flex justify-between items-center">
      <div
        class="group-details md:flex ml-6 md:space-x-4 text-gray-100 text-sm w-full"
        :class="headerColumnClass"
      >
        <div
          v-for="col in visibleColumnDefs"
          :key="'i' + col.headerName"
          :class="'agenda-day-event-' + col.headerName + ' ' + col.field"
          :style="{ width: colWidth(col) }"
          class="flex flex-initial"
        >
          <span class="agenda-label inline-block md:hidden text-gray-500 w-20">
            {{ col.headerName }}
          </span>
          <span class="time" v-if="col.type === 'time'">
            {{ event[col.field] | dateUtc("H:mm") }}
            <span class="opacity-50 block text-xs">{{ event[col.field] | dateUtc("DD/MM") }}</span>
          </span>
          <span
            class="other"
            v-if="
              col.type === 'html' &&
              col.field !== 'outputUI' &&
              col.field !== 'audio' &&
              col.field !== 'inputName'
            "
            v-html="event[col.field]"
          >
          </span>

          <span v-if="col.field === 'outputUI'" class="relative group">
            <span class="other output leading-[11px]" v-html="outputNamesShort"> </span>
            <span
              v-html="outputNames"
              class="absolute hidden group-hover:block top-0 z-50 bg-slate-900 p-3 w-72 rounded-sm"
            >
            </span>
          </span>
          <span class="relative group" v-if="col.field === 'inputName'">
            <span class="leading-[11px]">
              <div
                class="text-[9px] leading-[11px] flex items-center gap-1"
                v-for="inputName in inputNames"
                :key="inputName"
              >
                <img
                  :src="inputStatusDataById(inputName.split('__')[1])"
                  alt=""
                  class="w-6"
                  v-if="isSysAdmin || event.status === 'LIVE'"
                />
                <img
                  :src="inputStatusDataById(inputName.split('__')[1])"
                  alt=""
                  class="absolute hidden w-58 group:hover:block z-50"
                  v-if="isSysAdmin || event.status === 'LIVE'"
                />
                <div v-html="inputName.split('__')[0]"></div>
              </div>
            </span>

            <div class="hidden">{{ inputAlert }}</div>
          </span>
          <span
            class="other"
            v-if="!col.type && col.field !== 'audioUI' && col.field !== 'outputUI'"
            :class="{ warning: event[col.field] === 'not set' }"
          >
            {{ event[col.field] }}
          </span>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AgendaGroupHeader",
  props: {
    event: Object,
    eventGroup: Array,
    groupEventsStatuses: Array,
    visibleColumnDefs: Array,
    isOpen: Boolean,
    isMobileOnly: Boolean,
    inputStatuses: Object,
    isSysAdmin: Boolean,
    streamManagerConnections: Object,
  },

  computed: {
    inputStatusData() {
      const { input, inputOverride } = this.event;
      // console.log("input.name getInputStatus x", input, inputOverride, this.event);
      if (
        !this.inputStatuses ||
        !input ||
        !this.inputStatuses ||
        !this.inputStatuses[34] ||
        !input.probeTaskId
      )
        return {};

      this.thumb =
        (this.inputStatuses[34][input.probeTaskId] &&
          this.inputStatuses[34][input.probeTaskId].thumb) ||
        {};
      return this.inputStatuses[34][input.probeTaskId] || {};
    },
    inputAlert() {
      return this.inputStatusData.alarms || [];
    },
    groupStatuses() {
      return [...new Set(this.groupEventsStatuses)];
    },
    eventStatus() {
      return this.event.status;
    },
    eventStatusTextColorClass() {
      return `text-${this.eventStatus}`;
    },
    bgStatusColor() {
      return (status) => `bg-${status}`;
    },
    headerColumnClass() {
      // return '';
      return `grid-cols-${this.visibleColumnDefs.length}`;
    },
    baseOutputNames () {
      const outputs = this.eventGroup.map((event) => this.getOutputsStrings(event)).flat();
      if (!outputs || outputs.length === 0) {
        return "";
      }

      // Extract names
      const names = outputs;
      return names;
    },
    outputNames() {
      

      // Check if all names are the same
      const allSame = this.baseOutputNames.every((name) => name === this.baseOutputNames[0]);

      if (allSame) {
        // All names are the same, return the first one
        return this.baseOutputNames[0];
      } else {
        // Names are different, return unique names
        const uniqueNames = [...new Set(this.baseOutputNames)];
        return `<span class="text-[9px] leading-[11px]">${uniqueNames
          .join(", ")
          .replaceAll(",", "")}</span>`;
      }
      
    },
    outputNamesShort() {
      // const outputs = this.eventGroup.map((event) => this.getOutputsStrings(event)).flat();
      // if (!outputs || outputs.length === 0) {
      //   return "";
      // }

      // // Extract names
      // const names = outputs;

      // Check if all names are the same
      const allSame = this.baseOutputNames.every((name) => name === this.baseOutputNames[0]);

      if (allSame) {
        // All names are the same, return the first one
        return this.baseOutputNames[0];
      } else {
        // Names are different, return unique names
        const uniqueNames = [...new Set(this.baseOutputNames)];
        // Return only the first two unique names, if more than two, add "..."

        const displayNames = uniqueNames.slice(0, 4);
        let result = `<span class="text-[9px] ">${displayNames
          .join(", ")
          .replaceAll(",", "")
          .replaceAll('<span class="output-type-tag BROADCAST">BROADCAST</span>', "")
          .replaceAll('<span class="output-type-tag output-status-ONLINE">ONLINE</span>', "")
          .replaceAll('<span class="output-type-tag OTT">OTT</span>', "")}</span>`;
        if (uniqueNames.length > 2) {
          result += "...";
        }
        return result;
      }

    },
    inputNames() {
      const inputs = this.eventGroup.map((e) => e.inputName + "__" + e.probes.probeTaskId);
      if (!inputs || inputs.length === 0) {
        return "";
      }

      // Extract names
      const names = inputs; // .map(input => input.name);

      // Check if all names are the same
      const allSame = names.every((name) => name === names[0]);

      if (allSame) {
        // All names are the same, return the first one
        return [names[0]];
      } else {
        // Names are different, return unique names
        const uniqueNames = [...new Set(names)];
        return uniqueNames;
      }
    },
    eventsName() {
      return this.eventGroup.map((e) => e.name);
    },
    findCommonPartSecond() {
      if (this.eventsName.length === 0) return "";

      // Reverse strings to find common suffix as if it were a prefix
      const reversedNames = this.eventsName.map((name) => name.split("").reverse().join(""));

      // Find the shortest reversed string
      const shortest = reversedNames.reduce((a, b) => (a.length <= b.length ? a : b));

      let commonPart = "";
      for (let i = 0; i < shortest.length; i++) {
        const char = shortest[i];

        // Check if this character is present at the same position in all reversed strings
        if (reversedNames.every((str) => str[i] === char)) {
          commonPart += char;
        } else {
          break; // Stop at the first mismatch
        }
      }

      // Reverse the common part to get the original suffix
      commonPart = commonPart.split("").reverse().join("");

      // Remove a single lowercase letter at the start or 'x -'
      if (commonPart.match(/^[a-z] -/)) {
        commonPart = commonPart.substring(3); // Remove the first 3 characters ('x -')
      } else if (commonPart.match(/^[a-z]/)) {
        commonPart = commonPart.substring(1); // Remove the first character
      }

      return commonPart;
    },
    findCommonPartFirst() {
      if (this.eventsName.length === 0) return "";

      // Find the shortest string
      const shortest = this.eventsName.reduce((a, b) => (a.length <= b.length ? a : b));

      let commonPart = "";
      for (let i = 0; i < shortest.length; i++) {
        const char = shortest[i];

        // Check if this character is present at the same position in all strings
        if (this.eventsName.every((str) => str[i] === char)) {
          commonPart += char;
        } else {
          break; // Stop at the first mismatch
        }
      }

      return commonPart;
    },
  },

  data() {
    return {
      thumb: {},
      outputStatus: [],
    };
  },
  methods: {
    getInputStatusData(event) {
      let thumb = {};
      const { input, inputOverride } = event;
      // console.log("input.name getInputStatus x", input, inputOverride, event);
      if (
        !this.inputStatuses ||
        !input ||
        !this.inputStatuses ||
        !this.inputStatuses[34] ||
        !input.probeTaskId
      )
        return {};

      thumb = this.inputStatuses[34][input.probeTaskId].thumb || {};
      return this.inputStatuses[34][input.probeTaskId] || {};
    },
    colWidth(col) {
      if (col.field === "inputName" || col.field === "outputUI") {
        return `auto`;
      }
      return this.isMobileOnly ? "auto" : col.width + "px";
    },
    inputStatusDataById(probeTaskId) {
      if (!this.inputStatuses || !this.inputStatuses[34] || !probeTaskId) return "";

      return (
        (this.inputStatuses[34][probeTaskId] && this.inputStatuses[34][probeTaskId].thumb.data) ||
        ""
      );
    },
    updateOutput(newItem, el) {
      let outputUI = "";
      const [outputConfig] = newItem.actions.items.filter(
        (action) => action.assignedOutput && action.assignedOutput.id === el.output.id
      );
      if (el.output && el.output.ottApplication) {
        this.outputStatus.push({
          name: el.output.name,
          ottServer: el.output.ottServer,
          ottApplication: el.output.ottApplication,
        });
      }
      const hasWowzaConfig = outputConfig && outputConfig.configWowza;
      const autostartTitle =
        hasWowzaConfig && outputConfig.configWowza.autostart
          ? outputConfig.configWowza.autostartOffsetMin
          : 0;
      const autostartColor = hasWowzaConfig && outputConfig.configWowza.autostart ? "green" : "red";
      const autostartString = `<i class="bi bly-time ${autostartColor}" title="Offset ${autostartTitle} min"></i>`;
      const autostartPDFString = autostartTitle ? `[Offset ${autostartTitle} min]` : "";

      let isConnectedString = "";

      if (this.isSysAdmin && el.output.smEndpoints) {
        const t = JSON.parse(el.output.smEndpoints);
        const ids = t.map((sm) => sm.interfaceId);
        ids.forEach((id) => {
          if (
            this.streamManagerConnections &&
            this.streamManagerConnections[id] &&
            this.streamManagerConnections[id].outputs_connected !== ""
          ) {
            isConnectedString = '<span class="w-2 h-2 bg-green-500 inline-block"></span>';
          } else if (
            this.streamManagerConnections &&
            this.streamManagerConnections[id] &&
            this.streamManagerConnections[id].outputs_connected === ""
          ) {
            isConnectedString = '<span class="w-2 h-2 bg-red-500 inline-block"></span>';
          } else if (this.streamManagerConnections && !this.streamManagerConnections[id]) {
            isConnectedString = '<span class="w-2 h-2 bg-slate-500 inline-block"></span>';
          }
        });
      }

      const urlPdf = el.output.url ? `URL: ${el.output.url}` : "";
      const ottsSeverString =
        el.output.ottServer && el.output.ottApplication
          ? `<div class="show-ma ${el.output.ottServer}">(${el.output.ottServer} - ${el.output.ottApplication})</div>`
          : "";
      outputUI += `
      <div class="output-${el.output.status}">
        ${el.output.displayName || el.output.name}
        <span class="output-type-tag ${el.output.outputType}">${el.output.outputType}</span>
        <span class="output-type-tag output-status-${el.output.status}">${el.output.status}</span>
        ${hasWowzaConfig ? autostartString : ""}
        ${ottsSeverString} ${isConnectedString}
      </div>
    `;

      if (el.outputOttApplicationOverride) {
        outputUI += `<span class="show-ma" >(OTT Override: <strong>${el.outputOttApplicationOverride}</strong>)</span>`;
      }

      return outputUI;
    },
    getOutputsStrings(event) {
      const outputNames = [];
      event.outputs.items.filter((output) => !!output).forEach((el) => {
        outputNames.push(this.updateOutput(event, el));
      });

      return outputNames;
    },
  },
};
</script>

