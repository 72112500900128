<template>
  <div id="audit">
    <!-- Password Change Form -->
      <div>
        <div class="title-card">
          <div>
            <!--
            <el-date-picker
              v-model="filter.dateRange"
              type="daterange"
              align="right"
              start-placeholder="Start Date"
              end-placeholder="End Date">
            </el-date-picker>
            -->
            <el-date-picker
              v-model="filter.invokedDate"
              type="date"
              clearable
              placeholder="Pick a day"
              :picker-options="pickerOptions">
            </el-date-picker>
          </div>
          <div>
            <el-select v-model="filter.linkedType" placeholder="Select linked type" clearable>
              <el-option
                v-for="item in linkedTypes"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select v-model="filter.actionType" placeholder="Select action type" clearable>
              <el-option
                v-for="item in actionTypes"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>

          <div>
            <el-select v-model="filter.account" placeholder="Account (not implemented)" clearable>
              <el-option
                v-for="item in accounts"
                :key="item.account.id"
                :label="item.account.name"
                :value="item.account.name">
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select v-model="filter.user" placeholder="Invoked by (not implemented)" clearable>
              <el-option
                v-for="(item, key) in users"
                :key="key"
                :label="key"
                :value="key">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="content-wrapper"
          v-loading="loading"
          element-loading-text="Loading..."
          element-loading-background="#13191d">
          <div class="item-list infinite-list" v-infinite-scroll="load">
            <div
              v-for="(group, key) in groupCurrentLogs"
              :key="key">
              <el-divider content-position="left">
                <strong>{{key | date('DD/MM/YYYY')}}</strong>
              </el-divider>
              <div class="panel item-list--item"
                v-for="item in group" :key="item.id"
                >
                <div class="item-list--item-date">
                  <div>{{item.invokedDate | date('HH:mm')}}</div>
                </div>
                <div>
                  <span class="name">{{item.actionTaken}}</span>
                  <div class="log-tags">
                    <el-tag size="small" :style="{background: typeColor(item.linkedType)}">
                      {{item.linkedType}}
                    </el-tag>
                    <el-tag size="small" :style="{background: typeColor(item.actionType)}">
                      {{item.actionType}}
                    </el-tag>
                  </div>
                </div>
                <div class="item-list--item-right">
                  <div>{{item.linkedId}}</div>
                  <div class="item-list--item-right-account">
                    <span v-if="item.accountId">{{item.accountId.name}}</span>
                    <span v-if="!item.accountId" class="warning">not set</span>
                  </div>
                  <div class="item-list--item-right-user">
                    <span v-if="item.invokedBy">{{item.invokedBy.name}}</span>
                    <span v-if="!item.invokedBy" class="warning">not set</span>
                  </div>
                </div>
              </div>
            </div>

            <p v-if="loading">Loading...</p>
            <p v-if="noMore">No more entries</p>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import ColorGenerator from '@/services/ColorGenerator';

export default {
  data() {
    return {
      filter: {
        invokedDate: '',
        actionType: '',
        actionTaken: '',
        linkedId: '',
        linkedType: '',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },

  computed: {
    ...mapState({
      logs: (state) => state.audit.logs,
      loading: (state) => state.audit.loading,
      listClients: (state) => state.userAccount.listClients,
    }),

    avaiableAccounts() {
      const accounts = [];
      if (!this.listClients.items) return [];
      this.listClients.items.forEach((client) => {
        client.accounts.items.forEach((account) => {
          const isDefault = account.code === client.code;
          accounts.push({
            account,
            client,
          });
        });
      });

      return accounts;
    },

    sortedLogs() {
      if (!this.logs.items) return [];
      return this.logs.items
        .sort((left, right) => moment.utc(right.invokedDate).diff(moment.utc(left.invokedDate)));
    },

    groupCurrentLogs() {
      console.log('groupCurrentLogs => ', _.groupBy(this.sortedLogs, (date) => moment(date.invokedDate).startOf('day')));
      return _.groupBy(this.sortedLogs, (date) => moment(date.invokedDate).startOf('day'));
    },

    noMore() {
      return !this.logs.nextToken;
    },

    disabled() {
      return this.loading || this.noMore;
    },

    linkedTypes() {
      if (!this.logs.items) return [];
      return ['Event']; // _.groupBy(this.logs.items,'linkedType');
    },

    actionTypes() {
      if (!this.logs.items) return [];
      return [
        'Application restart',
        'Application stop',
        'StreamKey Change',
        'StreamTarget Stop',
        'StreamTarget Start',
        'Application autostart',
      ]; // _.groupBy(this.logs.items,'actionType');
    },

    accounts() {
      if (!this.logs.items) return [];
      return this.avaiableAccounts; // _.groupBy(this.logs.items,'accountId.name');
    },

    users() {
      if (!this.logs.items) return [];
      return _.groupBy(this.logs.items, 'invokedBy.name');
    },

    typeColor() {
      return (type) => ColorGenerator.stringToColor(type);
    },

    logFilter() {
      const cleanFilter = {};
      const modelStringInput = {
        attributeExists: true,
      };

      if (this.filter.invokedDate && this.filter.invokedDate !== '') {
        cleanFilter.invokedDate = {
          ...modelStringInput,
          eq: this.filter.invokedDate,
        };
      }

      if (this.filter.actionTaken && this.filter.actionTaken !== '') {
        cleanFilter.actionTaken = {
          ...modelStringInput,
          eq: this.filter.actionTaken,
        };
      }

      if (this.filter.actionType && this.filter.actionType !== '') {
        cleanFilter.actionType = {
          ...modelStringInput,
          eq: this.filter.actionType,
        };
      }

      if (this.filter.linkedType && this.filter.linkedType !== '') {
        cleanFilter.linkedType = {
          ...modelStringInput,
          eq: this.filter.linkedType,
        };
      }
      if (_.isEmpty(cleanFilter)) return null;

      return cleanFilter;
    },
  },

  watch: {
    async logFilter(newValue, old) {
      await this.filterAutomationAudits(newValue);
    },
  },

  created() {
    this.listAutomationAudits();
  },

  methods: {
    ...mapActions({
      listAutomationAudits: 'audit/listAutomationAudits',
      filterAutomationAudits: 'audit/filterAutomationAudits',
    }),

    load() {
      if (this.noMore) return;
      this.loading = true;
      console.log('loading more', !this.logs.nextToken);
      setTimeout(() => {
        this.count += 2;
        this.loading = false;
      }, 2000);
    },
  },
};
</script>

<style>


.el-loading-spinner svg {
  margin: 0 auto;
}

.title-card {
  background: #343c49;
  border-radius: 4px;
  grid-template-columns: 1fr auto auto auto 1fr 1fr 1fr;
  grid-gap: 16px;
}
.title-card .el-input .el-input__inner {
  color: #fff;
  background: #13191D;
  border-color: #13191D;
}
.title-card .el-input input {
  background: transparent;
  color: #CDD6EE;
}

#audit {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 20px;
  padding-top: 0;
}
#audit .content-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}
#audit .el-divider {
  background-color: transparent;
}
#audit .el-divider__text {
  background-color: #000;
  padding: 0 20px;
  color: #66718D;
  font-weight: 400;
  font-size: 16px;
  width: 140px;
  line-height: 30px;
}
#audit .item-list--item {
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  margin-bottom: 6px;
  grid-gap: 16px;
  align-items: center;
  border-radius: 4px;
}
#audit .item-list--item:hover {
  outline: 2px solid #ff9f31;
  color: #fff !important;
}
#audit .item-list--item .name {
  color: #fff;
  margin-right: 16px;
}
#audit .item-list--item .log-tags {
  display: inline-block;
}
#audit .item-list--item-right {
  text-align: right;
  display: grid;
  grid-template-columns: 1fr 200px 140px;
}
#audit .item-list--item-right div {
  display: inline-block;
  margin-left: 16px;
}
#audit .item-list--item-right-account {
  text-align: right;
  color: #CDD6EE;
}
#audit .item-list--item .el-tag {
  color: #fff;
  border: 1px solid transparent;
}
#audit .panel {
  position: relative;
  background: #232b33;
  padding: 8px 16px;
}
#audit .panel .el-tag {
  margin-right: 16px;
}
#audit .panel.center {
  text-align: center;
}
#audit .panel .title-description {
  margin: 0px;
  color: #73809c;
  padding-bottom: 15px;
}
#audit .panel .title {
  font-size: 24px;
  margin: 0;
}
#audit .panel svg {
  margin: 42px auto 64px;
  display: block;
}
#audit .panel .qr {
  margin: 32px auto 44px;
}
#audit h1 {
  color: #fff;
}

@media screen and (max-width: 1000px), (orientation: portrait) {
  #audit .title-card {
    grid-template-columns: auto;
    padding-right: 16px;
  }
  #audit .title-card .el-select {
    width: 100%;
  }
  #audit .item-list--item {
    display: grid;
    grid-template-columns: auto;
  }
  #audit .item-list--item-date {
    position: absolute;
    top: 14px;
    left: 14px;
    font-size: 12px;
  }
  #audit .item-list--item .name {
    margin-top: 10px;
    padding-top: 24px;
    display: inline-block;
  }
  #audit .item-list--item .log-tags {
    position: absolute;
    top: 10px;
    right: 8px;
  }
  #audit .item-list--item .log-tags .el-tag {
    margin-right: 0px;
    margin-left: 8px;
    font-size: 11px;
    border-radius: 3px;
  }
  #audit .item-list--item-right {
    text-align: left;
    grid-template-columns: 1fr;
  }
  #audit .item-list--item-right div {
    margin-left: 0px;
  }
  #audit .item-list--item-right-account {
    text-align: left;
  }
  #audit .item-list--item .el-tag {
    color: #fff;
    border: 1px solid transparent;
  }
}
</style>
