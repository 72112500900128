a<template>
  <div id="admin" :class="{admin : acl.admin}">
    <div class="admin-left sidebar">
      <!-- CLIENTS -->
      <div class="sidebar-group">
        <div class="sidebar-group-name">Clients</div>
        <div class="sidebar-group-actions">
          <el-tooltip content="Add client"
              v-if="isSysAdmin"
              placement="right">
            <i class="bi bly-plus icon-btn" @click="createClientDialog = true"></i>
          </el-tooltip>
        </div>
      </div>
      <div class="sidebar-item"
        :class="clientClassNames(id)"
        v-for="(client, id) in clients" :key="id">
        <div class="sidebar-item-name" @click="goToClient(client.id)">
         {{client.name | defaultValue('---')}}
        </div>
        <div class="sidebar-item-actions">
          <i class="bi bly-delete icon-btn"
            v-if="isSysAdmin"
            @click.native="deleteClientPrompt(id)"></i>
        </div>
      </div>
      <el-drawer
          custom-class="clients-push-nav"
          size="300px"
          title="Select client from the list"
          :visible.sync="showClientDrawer"
          :with-header="false">
          <div v-bar class="drawer-wrapper">
            <div class="drawer-client-list">
              <el-dropdown-item class=""
                :class="clientClassNames(id)"
                v-for="(client, id) in clients" :key="id">
                <span class="" @click="goToClient(client.id)">
                {{client.name | defaultValue('---')}}
                </span>
                  <i class="bi bly-delete icon-btn"
                    v-if="isAdmin"
                    @click.native="deleteClientPrompt(id)"></i>
              </el-dropdown-item>
              <el-dropdown-item icon="bi bly-plus"
                divided
                 @click.native="createClientDialog = true">
                Create client
              </el-dropdown-item>
            </div>
          </div>
        </el-drawer>
      <!-- ACCOUNTS
      <div class="sidebar-group">
        <div class="sidebar-group-name">Accounts</div>
        <div class="sidebar-group-actions">
        </div>
      </div>

      <div class="sidebar-item"
        v-for="(account, id) in accounts" :key="id">
        <div class="sidebar-item-name" @click="goToClient(account.id)">
         {{account.name | defaultValue('---')}}
        </div>
        <div class="sidebar-item-actions">
          <bly-button type="clear" @click.native="deleteAccountPrompt(id)">
            <i class="bi bly-delete"></i>
          </bly-button>
        </div>
      </div>
      -->

      <!-- FEATURES -->
      <div class="sidebar-group" v-if="isDevAdmin">
        <div class="sidebar-group-name">Features</div>
        <div class="sidebar-group-actions">
          <el-tooltip content="Add feature" placement="right">
             <bly-button type="clear" @click.native="createFeatureDialog = true">
              <i class="bi bly-plus"></i>
            </bly-button>
          </el-tooltip>
        </div>
      </div>

      <div v-if="isDevAdmin">
        <div class="sidebar-item"
          v-for="(item, id) in features" :key="id">
          <div class="sidebar-item-name">
            <span class="code">{{item.feature.code}}</span>
            {{item.feature.name}}
          </div>
          <div class="sidebar-item-actions">
            <bly-button type="clear" @click.native="deleteFeaturePrompt(id)">
              <i class="bi bly-delete"></i>
            </bly-button>
          </div>
        </div>
      </div>

      <!-- CLAIMS -->

      <div class="sidebar-group" v-if="isDevAdmin">
        <div class="sidebar-group-name">Claims</div>
        <div class="sidebar-group-actions">
          <el-tooltip content="Add claim" placement="right">
            <bly-button type="clear" @click.native="createClaimDialog = true">
              <i class="bi bly-plus"></i>
            </bly-button>
          </el-tooltip>
        </div>
      </div>

      <div v-if="isDevAdmin">
        <div class="sidebar-item"
          v-for="(claim, id) in claims" :key="id">
          <div class="sidebar-item-name">
            <span class="code">{{claim.code}}</span>
            {{claim.name}}
          </div>
          <div class="sidebar-item-actions">
            <bly-button type="clear" @click.native="deleteClaimPrompt(id)">
              <i class="bi bly-delete"></i>
            </bly-button>
          </div>
        </div>
      </div>

      <!-- USERS -->

      <div class="sidebar-group" v-if="isDevAdmin">
        <div class="sidebar-group-name">USERS</div>
        <div class="sidebar-group-actions">
          <el-tooltip content="Add user" placement="right">
             <bly-button type="clear" @click.native="createUserDialog = true">
              <i class="bi bly-plus"></i>
            </bly-button>
          </el-tooltip>
        </div>
      </div>

      <div v-if="isDevAdmin">
        <div class="sidebar-item"
          v-for="(user, id) in users" :key="id">
          <div class="sidebar-item-name">
            {{user.name}}
            <small>{{user.email}}</small>
          </div>
          <div class="sidebar-item-actions">
            <bly-button type="clear" @click.native="deleteUserPrompt(user.id)">
              <i class="bi bly-delete"></i>
            </bly-button>
          </div>
        </div>
      </div>
    </div>
    <div class="admin-content transition-wrapper">
      <transition name="slide" mode="out-in" appear>
        <router-view />
      </transition>
    </div>

    <!--
      ------------------------------
      DIALOG WINDOWS
      ------------------------------
     -->

    <!-- ADD CLIENT -->
    <el-dialog
      title="Create Client"
      :visible.sync="createClientDialog"
      :close-on-click-modal="false"
      :width="dialogWidth">
      <div class="dialog-form">
        <el-form ref="addClientForm" :model="clientForm" class="form-dark">
          <el-form-item required>
            <el-col :span="6">
              <el-form-item prop="code" label="Code" required>
                <el-input v-model="clientForm.code" placeholder="Code" maxlength="3"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"> &nbsp; </el-col>
            <el-col :span="17">
              <el-form-item  prop="name" label="Name" required>
                <el-input v-model="clientForm.name" placeholder="Company name"></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item required>
            <el-col :span="12">
              <el-form-item  prop="email" label="Email">
                <el-input v-model="clientForm.email" placeholder="Email address"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"> &nbsp; </el-col>
            <el-col :span="11">
              <el-form-item  prop="phoneno" label="Phone">
                <el-input v-model="clientForm.phoneno"  placeholder="Phone number"></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item prop="description" label="Description">
            <el-input v-model="clientForm.description" placeholder="Description"></el-input>
          </el-form-item>
          <el-form-item prop="address" label="Address">
            <el-input v-model="clientForm.address" placeholder="Address"></el-input>
          </el-form-item>
          <el-form-item prop="website" label="Website URL">
            <el-input v-model="clientForm.website" placeholder="Website"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createClientDialog = false">Cancel</el-button>
        <el-button type="primary" @click="addClient()">Save</el-button>
      </span>
    </el-dialog>

    <!-- ADD FEATURE -->
    <el-dialog
      title="Create Feature"
      :visible.sync="createFeatureDialog"
      :close-on-click-modal="false"
      :width="dialogWidth">
      <div class="dialog-form">
        <el-form :model="featureForm">
          <el-form-item required>
            <el-col :span="6">
              <el-form-item prop="code">
                <el-input v-model="featureForm.code" placeholder="Code"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"> &nbsp; </el-col>
            <el-col :span="17">
              <el-form-item  prop="name">
                <el-input v-model="featureForm.name" placeholder="Feature name"></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item prop="description">
            <el-input v-model="featureForm.description" placeholder="Description"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createFeatureDialog = false">Cancel</el-button>
        <el-button type="primary" @click="addFeature()">Save</el-button>
      </span>
    </el-dialog>

    <!-- ADD CLAIM -->
    <el-dialog
      title="Create Feature"
      :visible.sync="createClaimDialog"
      :close-on-click-modal="false"
      :width="dialogWidth">
      <div class="dialog-form">
        <el-form :model="claimForm">
          <el-form-item required>
            <el-col :span="6">
              <el-form-item prop="code" required="">
                <el-input v-model="claimForm.code" placeholder="Code"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"> &nbsp; </el-col>
            <el-col :span="17">
              <el-form-item  prop="name" required="">
                <el-input v-model="claimForm.name" placeholder="Claim name"></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item prop="description">
            <el-input v-model="claimForm.description" placeholder="Description"></el-input>
          </el-form-item>
          <el-form-item prop="feature">
            <el-select v-model="claimForm.featureClaimsId" placeholder="Select feature">
              <el-option
                v-for="item in features"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createClaimDialog = false">Cancel</el-button>
        <el-button type="primary" @click="addClaim()">Save</el-button>
      </span>
    </el-dialog>

    <!-- ADD USER -->
    <el-dialog
      title="Create user"
      :visible.sync="createUserDialog"
      :close-on-click-modal="false"
      :width="dialogWidth">
      <div class="dialog-form">
        <el-form :model="userForm">
          <el-form-item prop="name" required="">
            <el-input v-model="userForm.name" placeholder="Name"></el-input>
          </el-form-item>
          <el-form-item  prop="email" required="">
            <el-input v-model="userForm.email" placeholder="Email address"></el-input>
          </el-form-item>
          <el-form-item label="Approved">
            <el-switch v-model="userForm.isApproved"></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createUserDialog = false">Cancel</el-button>
        <el-button type="primary" @click="addUser()">Save</el-button>
      </span>
    </el-dialog>

    <div class="config mv-config">
      <i class="bi bly-user icon-btn" @click="showClientDrawer = !showClientDrawer"></i>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';

export default {
  data() {
    return {
      // subscriptions,
      error: null,
      isBtnLoading: false,
      user: null,
      isMobileOnly,
      state: {},

      clientForm: {
        code: '',
        name: '',
        description: null,
        email: null,
        phoneno: null,
        claimsCanAccess: [],
        claimsCanEdit: [],
        clientLastUpdatedById: this.userId,
      },

      featureForm: {
        code: '',
        name: '',
        description: null,
      },

      userForm: {
        email: '',
        name: '',
        claimsCanAccess: [],
        claimsCanEdit: [],
        isApproved: true,
      },

      claimForm: {
        code: '',
        name: '',
        description: null,
        // feature: ['sss'],
      },

      // dialogs
      createClientDialog: false,
      createFeatureDialog: false,
      createClaimDialog: false,
      createUserDialog: false,

      // other
      subscribers: {},
      showClientDrawer: false,
    };
  },

  computed: {
    ...mapState({
      userId: (state) => state.userAccount.userId,
      aclEx: (state) => state.userAccount.userPermissions,
      acl: (state) => state.userAccount.aclCurrent,
      isAdmin: (state) => state.userAccount.isAdmin,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      isDevAdmin: (state) => state.userAccount.isDevAdmin,
      clients: (state) => state.admin.clients,
      features: (state) => state.admin.features,
      claims: (state) => state.admin.claims,
      users: (state) => state.admin.users,
      clientId: (state) => state.admin.clientId,
      accounts: (state) => state.admin.accounts,
    }),

    dialogWidth() {
      return this.isMobileOnly ? '90%' : '420px';
    },

    clientClassNames() {
      return (id) => {
        const isActvie = id === this.clientId;
        return isActvie ? 'active' : '';
      };
    },
  },

  created() {
    this.init();
    // this.listUsers();
    // this.listAppSettings();
    this.subscribe();
  },

  beforeDestroy() {
    this.unsubscribe();
  },

  methods: {
    ...mapActions({
      fetchClientList: 'admin/fetchClientList',
      fetchClaimList: 'admin/fetchClaimList',
      fetchFeatureList: 'admin/fetchFeatureList',
      createClient: 'admin/createClient',
      createClaim: 'admin/createClaim',
      createFeature: 'admin/createFeature',
      createUser: 'admin/createUser',
      deleteUser: 'admin/deleteUser',
      updateUser: 'admin/updateUser',
      deleteClient: 'admin/deleteClient',
      deleteClaim: 'admin/deleteClaim',
      deleteFeature: 'admin/deleteFeature',
      deleteAccount: 'admin/deleteAccount',
      fetchAccountList: 'admin/fetchAccountList',
      fetchUserList: 'admin/fetchUserList',
    }),
    async init() {
      try {
        const clients = await this.fetchClientList();
        const firstClient = clients.items[0];

        if (!this.acl.admin && !!firstClient) this.goToClient(firstClient.id);

        await this.fetchClaimList();
        await this.fetchAccountList();
        await this.fetchFeatureList();
        if (this.isSysAdmin) {
          await this.fetchUserList();
        }
      } catch (error) {
        this.$message({
          showClose: true,
          message: 'Oops, something went wrong.',
          type: 'error',
        });
      }
    },

    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },

    goToClient(id) {
      this.$router.push({ name: 'admin-client', params: { id } });
      this.showClientDrawer = false;
    },

    subscribe() {
      /*
      this.subscribers.createUser = API.graphql(
        graphqlOperation(subscriptions.onCreateUser),
      ).subscribe({
        next: (todoData) => {
          console.log('User Added', todoData);
        },
      });

      this.subscribers.createClient = API.graphql(
        graphqlOperation(subscriptions.onCreateClient),
      ).subscribe({
        next: (todoData) => {
          console.log('Client Added', todoData);
        },
      });
      */
    },
    unsubscribe() {
      // this.subscriptions.createUser.unsubscribe();
      // this.subscriptions.createClient.unsubscribe();
      Object.keys(this.subscribers).forEach((key) => {
        if (this.subscribers[key]) this.subscribers[key].unsubscribe();
      });
    },

    //
    // DELETE PROMPT METHODS
    //

    async deleteAccountPrompt(id) {
      const message = `Are you sure you want to delete <strong> ${this.accounts[id].name}</strong>?`;
      const confirmation = await this.$confirm(message, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        dangerouslyUseHTMLString: true,
        type: 'warning',
        center: true,
      }).catch((error) => {
        console.log('error', error);
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      const result = await this.deleteAccount({ id, version: this.accounts[id].version });
      if (result.error) {
        this.$message({
          showClose: true,
          message: 'Oops, can\'t get client list.',
          type: 'error',
        });
        return;
      }

      this.$message({
        showClose: true,
        message: 'Account deleted',
        type: 'info',
      });
    },

    async deleteClientPrompt(id) {
      const message = `Are you sure you want to delete <strong> ${this.clients[id].name}</strong>?`;
      const confirmation = await this.$confirm(message, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        dangerouslyUseHTMLString: true,
        type: 'warning',
        center: true,
      }).catch((error) => {
        console.log('error', error);
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      const result = await this.deleteClient({ id, version: this.clients[id].version });
      if (result.error) {
        this.$message({
          showClose: true,
          message: 'Oops, can\'t get client list.',
          type: 'error',
        });
        return;
      }

      this.$message({
        showClose: true,
        message: 'Client deleted',
        type: 'info',
      });
    },

    async deleteFeaturePrompt(id) {
      const message = `Are you sure you want to delete <strong> ${this.features[id].name}</strong>?`;
      const confirmation = await this.$confirm(message, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        dangerouslyUseHTMLString: true,
        type: 'warning',
        center: true,
      }).catch((error) => {
        console.log('error', error);
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      const result = await this.deleteFeature(id);
      if (result.error) {
        this.$message({
          showClose: true,
          message: 'Oops, can\'t delete feature.',
          type: 'error',
        });
        return;
      }

      this.$message({
        showClose: true,
        message: 'Feature deleted',
        type: 'info',
      });
    },

    async deleteClaimPrompt(id) {
      const message = `Are you sure you want to delete <strong> ${this.claims[id].name}</strong>?`;
      const confirmation = await this.$confirm(message, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        dangerouslyUseHTMLString: true,
        type: 'warning',
        center: true,
      }).catch((error) => {
        console.log('error', error);
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      const result = await this.deleteClaim(id);
      if (result.error) {
        this.$message({
          showClose: true,
          message: 'Oops, can\'t delete claim.',
          type: 'error',
        });
        return;
      }

      this.$message({
        showClose: true,
        message: 'Claim deleted',
        type: 'info',
      });
    },

    async deleteUserPrompt(id) {
      const message = `Are you sure you want to delete <strong> ${this.users[id].email}</strong>?`;
      const confirmation = await this.$confirm(message, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        dangerouslyUseHTMLString: true,
        type: 'warning',
        center: true,
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      await this.deleteUser(id);
    },

    //
    // FORM ACTIONS
    //
    async addClient() {
      const isFormValid = this.$refs.addClientForm.validate();
      if (!isFormValid) return;

      const { code } = this.clientForm;
      const newClient = {
        ...this.clientForm,
        id: code.toLowerCase(),
        code: code.toLowerCase(),
        clientLastUpdatedById: this.userId,
        claimsCanAccess: [`usr.${code}.${code}`, `usr.${code}`],
        claimsCanEdit: [`ca.${code}.${code}`, `aa.${code}.${code}`],
      };
      await this.createClient(newClient);
      this.createClientDialog = false;
    },

    async addClaim() {
      const result = await this.createClaim(this.claimForm);
      if (result.error) {
        this.$message({
          showClose: true,
          message: 'Oops, can\'t get create claim.',
          type: 'error',
        });
        return;
      }

      this.$message({
        showClose: true,
        message: 'Claim added',
        type: 'info',
      });
      this.createClaimDialog = false;
    },

    async addFeature() {
      const result = await this.createFeature(this.featureForm);
      if (result.error) {
        this.$message({
          showClose: true,
          message: 'Oops, can\'t get create feature.',
          type: 'error',
        });
        return;
      }
      this.$message({
        showClose: true,
        message: 'Feature added',
        type: 'info',
      });
      this.createFeatureDialog = false;
    },

    async addUser() {
      const result = await this.createUser(this.userForm);
      if (result.error) {
        this.$message({
          showClose: true,
          message: 'Oops, can\'t get create user.',
          type: 'error',
        });
        return;
      }
      this.$message({
        showClose: true,
        message: 'User added',
        type: 'info',
      });
      this.createUserDialog = false;
    },
  },
};
</script>

<style>


.el-loading-spinner svg {
  margin: 0 auto;
}

#admin {
  display: grid;
  grid-template-columns: 0px 1fr;
  overflow-x: hidden;
  grid-gap: 0px;
  margin: 20px;
  padding-top: 0;
}
#admin .title-card {
  grid-template-columns: 1fr;
}
#admin .sidebar {
  visibility: hidden;
}
#admin.admin {
  grid-template-columns: 260px 1fr;
  grid-gap: 20px;
}
#admin.admin .sidebar {
  visibility: visible;
}
#admin .content-header .data-header {
  grid-template-columns: 1fr auto;
}

.mobile #admin.admin {
  grid-template-columns: 0px 1fr;
  grid-gap: 0;
}
.mobile #admin.admin .sidebar {
  visibility: hidden;
}
.mobile .drawer-client-list .el-dropdown-menu__item {
  position: relative;
  line-height: 44px;
}
.mobile .drawer-client-list .el-dropdown-menu__item *.active {
  color: #ff9f31;
  font-weight: bold;
}
.mobile .drawer-client-list .el-dropdown-menu__item .icon-btn {
  position: absolute;
  right: 0;
}
</style>
