<template>
  <div id="app">
    <div class="bg-CONFIRMED bg-COMPLETED bg-CLIENT_CONFIRMED bg-PENDING, bg-POSTPONED, bg-FAILED bg-LATE_CANCELLED bg-LIVE bg-RECEIVED bg-CANCELLED bg-AWAITING_SIGNAL bg-"></div>
    <transition name="el-fade-in-linear">
      <router-view />
    </transition>
  </div>
</template>
<script>
export default {
  created() {
  },

  methods: {
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500");
@import "./assets/icons/css/style.css";
@import "./assets/icons/update/css/style.css";
@import "~vue-datetime/dist/vue-datetime.css";
@import "~vant/lib/index.css";
@import "~element-ui/packages/theme-chalk/lib/index.css";
.dropdown-item, .sidebar-item, .link {
  user-select: none;
}

.dropdown-item, .content-wrapper .data-list-item-name-link:hover, .content-wrapper .data-header-tabs-tab, .sidebar-item, .link, .link-color {
  cursor: pointer;
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.el-loading-spinner svg {
  margin: 0 auto;
}

.primary {
  color: #ff9f31;
}

.warning {
  color: #FF5722;
}

.info {
  color: #0077FF;
}

.text-left {
  text-align: left;
}

.clear {
  float: none;
}

.el-dialog__title {
  font-weight: bold;
}
.el-radio {
  margin-right: 16px;
}
.el-checkbox {
  font-weight: 400;
}
.el-tooltip__popper.is-dark {
  background: #000000;
}
.el-button--small {
  border-radius: 2px;
}
.el-button--grey {
  background: #73809c;
  border-color: #73809c;
  color: #fff;
}
.el-button--grey:hover {
  background: #babdc7;
  border-color: #babdc7;
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  font-family: "Nunito", Helvetica, Arial, sans-serif;
}
body .theme-mini {
  font-weight: 400;
  font-family: "Rubik", Helvetica, Arial, sans-serif !important;
}
body .theme-mini .el-dialog__title {
  font-weight: 500;
}
body .theme-mini .el-checkbox {
  font-weight: 400;
}
body .theme-mini .el-button, body .theme-mini .el-tag, body .theme-mini div#event-preview .label, body .theme-mini div#event-preview .content {
  font-weight: 400;
  border-radius: 4px;
}
body .theme-mini strong {
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

.bly-admin-tile-icon:before {
  content: "";
}

.bly-permissionssimple-tile-icon:before {
  content: "";
}

.link-color {
  color: #00D4FF;
}

.link:hover {
  text-decoration: underline;
}

.config .el-checkbox__inner {
  border: 1px solid #607D8B;
  width: 16px;
  height: 16px;
  background-color: #13191d;
}
.config .el-checkbox__label {
  color: #CDD6EE;
}
.config .el-checkbox.is-bordered {
  border-color: transparent;
}

.el-dropdown-menu .dd-account-name {
  margin-bottom: -20px;
  font-weight: 600;
}

.title-card {
  display: grid;
  padding: 20px 0px 20px 20px;
  background: #1b242e;
  border-radius: 3px;
  margin-bottom: 16px;
}
.title-card-top {
  display: grid;
  grid-template-columns: 40px 1fr 1fr;
  min-height: 40px;
  padding: 0;
  align-items: center;
  grid-gap: 16px;
}
.title-card-top-name {
  font-size: 18px;
  color: #CDD6EE;
}
.title-card-top-name .bly-small-up {
  top: 3px;
  position: relative;
  font-size: 16px;
  opacity: 0.3;
  margin: 0 6px;
}
.title-card-top-name-description {
  color: #66718D;
  font-size: 14px;
}
.title-card-top-name-additional {
  color: #66718D;
  font-size: 14px;
}
.title-card-top-name-additional .bi {
  position: relative;
  top: 2px;
}
.title-card-top-actions {
  text-align: right;
}

.sidebar-group {
  color: #66718D;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr auto;
  line-height: 42px;
  margin-top: 24px;
  text-transform: uppercase;
  font-weight: 600;
}
.sidebar-group:first-child {
  margin-top: 0;
}
.sidebar-group-actions {
  display: grid;
  justify-content: end;
  padding-top: 4px;
}
.sidebar-item {
  color: #CDD6EE;
  font-size: 16px;
  display: grid;
  grid-template-columns: 1fr auto;
  line-height: 42px;
  margin-left: -12px;
  padding-left: 12px;
}
.sidebar-item .code {
  text-transform: uppercase;
  color: white;
  position: relative;
  top: -1px;
  font-size: 12px;
  font-weight: bold;
  background: #66718D;
  border-radius: 3px;
  padding: 0 8px;
}
.sidebar-item-actions {
  opacity: 0;
}
.sidebar-item:hover {
  color: #ff9f31;
  border-left: 3px solid #323C4B;
}
.sidebar-item:hover .sidebar-item-actions {
  opacity: 1;
}
.sidebar-item.active {
  color: #ff9f31;
}
.sidebar-item-actions {
  display: grid;
  justify-content: end;
  padding-top: 4px;
}

.content-wrapper .data-header {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 16px;
}
.content-wrapper .data-header-tabs {
  color: #CDD6EE;
}
.content-wrapper .data-header-tabs-tab {
  padding-right: 16px;
  padding-left: 16px;
  display: inline-block;
  line-height: 32px;
  text-align: center;
}
.content-wrapper .data-header-tabs-tab:hover, .content-wrapper .data-header-tabs-tab.active {
  border-bottom: 2px solid #ff9f31;
  color: #ff9f31 !important;
}
.content-wrapper .data-header-actions {
  display: grid;
  justify-content: end;
}
.content-wrapper .data-list-item {
  background: #232b33;
  margin-bottom: 8px;
  line-height: 40px;
  display: grid;
  grid-template-columns: 1fr auto;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.content-wrapper .data-list-item-name {
  color: #CDD6EE;
  padding-left: 16px;
}
.content-wrapper .data-list-item-name i {
  position: relative;
  top: 2px;
  margin-right: 8px;
}
.content-wrapper .data-list-item-name-link:hover {
  color: #1A9DFF;
}
.content-wrapper .data-list-item-actions {
  text-align: right;
}
.content-wrapper .data-list-item-actions span.actions-separator {
  display: inline-block;
  width: 25px;
}
.content-wrapper .data-list-item-actions .icon-btn {
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.content-wrapper .data-list-item-actions .bly-play {
  color: #00FF80;
}
.content-wrapper .data-list-item-actions .bly-stop {
  color: #ea0d51;
}

.el-tooltip__popper {
  pointer-events: none;
}

.bi-rotate-45 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  transform: rotate(-45deg);
}

.dropdown-item {
  line-height: 35px;
  color: #13191D;
  font-size: 13px;
  padding: 0 16px;
  margin: 5px -8px;
  font-weight: normal;
  font-weight: 600;
}
.dropdown-item i {
  color: #66718D;
  margin-right: 16px;
  font-size: 16px;
  vertical-align: sub;
}
.dropdown-item i.bly-right-arrow {
  float: right;
}
.dropdown-item span {
  float: right;
}
.dropdown-item:hover {
  background: #f3f5fb;
}
.dropdown-item.active {
  color: #ff9f31;
}
.dropdown-item.separator {
  border-bottom: 1px solid #e0e6f4;
  margin: 5px 0;
}
.dropdown-item.group {
  color: #91a8b7;
  padding: 8px;
}
.dropdown-item.group:hover {
  background: none;
}

#app {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #66718d;
  background: #13191d;
  min-height: 100vh;
  padding: 0px 20px;
}
#app #nav {
  background: #13191d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  line-height: 45px;
  padding: 0px 0px 0px 20px;
  height: 50px;
}
#app #nav #logo {
  float: left;
}
#app #nav #logo img {
  margin-top: 10px;
  margin-bottom: -5px;
}
#app #nav #right-nav {
  float: right;
}

#nav {
  padding: 10px 30px;
  z-index: 9;
}
#nav a {
  font-weight: bold;
  text-transform: uppercase;
  line-height: 50px;
  display: inline-block;
  padding: 0 20px;
  text-decoration: none;
  color: #66718d;
}
#nav a.router-link-exact-active {
  color: #ffa233;
}

.noselect {
  user-select: none;
}

#auth {
  padding-top: 15vh;
}
#auth .form {
  width: 90vw;
  max-width: 380px;
  margin: 0 auto;
  background: #1b242e;
  padding: 30px;
}

.form h1, .el-form h1 {
  font-size: 24px;
  margin: 0;
  color: #fff;
}
.form .title-description, .el-form .title-description {
  margin: 0px;
  color: #73809c;
  padding-bottom: 15px;
}
.form .el-form-item__label, .el-form .el-form-item__label {
  padding: 0;
  line-height: 26px;
  color: #73809c;
}
.form .el-form-item__content, .el-form .el-form-item__content {
  line-height: 18px;
}
.form .el-alert--error, .el-form .el-alert--error {
  background-color: transparent;
  color: #F44336;
  border: 1px solid #F44336;
}
.form.form-dark h1, .el-form.form-dark h1 {
  color: #000;
}
.form.form-dark .title-description, .el-form.form-dark .title-description {
  color: #13191D;
}
.form.form-dark .el-form-item__label, .el-form.form-dark .el-form-item__label {
  color: #13191D;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.2s ease-in-out;
}

.slide-enter {
  opacity: 0;
}

.slide-leave-active {
  opacity: 0;
}

.transition-wrapper {
  overflow-x: hidden;
}

.el-dialog {
  margin-top: 50px !important;
}
.el-dialog__body {
  padding-top: 16px;
}
.el-date-editor {
  width: auto !important;
}
.el-form-item {
  margin-bottom: 16px;
}
.el-dropdown-menu__item {
  padding: 10px 16px;
  min-width: 170px;
  line-height: 16px;
}
.el-dropdown-menu__item:before {
  margin: 0 -10px;
}
.el-dropdown-menu__item .bi {
  margin-right: 16px;
  position: relative;
  top: 2px;
}
.el-dropdown-menu__item .dropdown-user {
  display: inline-block;
  position: relative;
  top: -8px;
}
.el-dropdown-menu__item .dropdown-user .bi {
  margin-right: 16px;
  position: relative;
  top: 11px;
  vertical-align: top;
}
.el-dropdown-menu__item .dropdown-user small {
  display: block;
  margin-top: -36px;
  position: relative;
  top: 16px;
}

.audio-channel {
  display: grid;
  grid-template-columns: 114px 1fr 70px 100px 50px;
  line-height: 32px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
  padding-left: 8px;
  border-radius: 4px;
}
.audio-channel-th {
  border-color: transparent;
  border: 0px solid #fff;
  line-height: 16px;
}
.audio-channel-name-left {
  color: #000;
}
.audio-channel-actions .bly-button {
  color: #13191D;
}
.audio-channel-actions .bly-button:hover {
  color: #000 !important;
}
.audio-channel-actions .bly-button:hover path {
  fill: transparent !important;
}

.mobile .data-header-tabs {
  color: #CDD6EE;
}
.mobile .data-header-tabs-tab {
  padding-right: 8px;
  padding-left: 8px;
}
.mobile #nav #logo {
  width: 50px;
  overflow: hidden;
}
.mobile .mv-config.config {
  left: 80px;
}

.bly-Router::before {
  content: "";
}

.bly-Scheduler::before {
  content: "";
}

.bly-Admin::before {
  content: "";
}

.bly-Multiviewer::before {
  content: "";
}

.bly-Servers::before {
  content: "";
}

.bly-Probes::before {
  content: "";
}

.bly-Permissions::before {
  content: "";
}

.el-divider__text.is-left {
  left: 0;
  padding-left: 0px;
}

.el-divider__text {
  color: #babdc7;
}

.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0 13px 0;
}

.el-divider {
  background-color: rgba(179, 189, 214, 0.48);
  position: relative;
}

.dd-popup-wrapper .vb-content {
  max-height: calc(100vh - 120px);
}

.h-300 {
  height: 300px;
}
</style>
