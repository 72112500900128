<template>
  <div id="schedule-agenda" class="relative flex items-stretch flex-1 overflow-hidden agenda">
    <main class="relative flex-1 px-4 wrapper " :class="{'loading': loading}">
      <div class="absolute top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center align-middle bg-gray-900 loading bg-opacity-60" v-if="loading">
        <div id="loading-view" class="w-56 text-center"
          v-loading="loading"
          element-loading-text="Loading bookings"
          element-loading-background="rgba(19, 25, 29, 1)"
          >
        </div>
      </div>
      <header class="flex items-center justify-between h-12 text-center">
        <div>
          <div class="fc-button-group">
            <button type="button" class="fixed left-0 bg-transparent border-0 fc-prev-button fc-button fc-button-primary sm:relative sm:left-auto" aria-label="prev"><span class="fc-icon fc-icon-chevron-left" @click="goBackAWeek()"></span></button>
            <button type="button" class="bg-transparent border-0 fc-next-button fc-button fc-button-primary" aria-label="next"><span class="fc-icon fc-icon-chevron-right" @click="goAheadAWeek()"></span></button>
          </div>
          <button type="button" @click="goToday()" class="hidden bg-transparent border-0 fc-today-button fc-button fc-button-primary sm:inline-block">today</button>
        </div>
        <div>
          <date-range-picker
            ref="picker"
            :opens="'center'"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
            :singleDatePicker="false"
            :date-format="dateFormat"
            :ranges="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="dateRange"
            @start-selection="handleStartDateSelection"
            @finish-selection="handleEndDateSelection"
            :linkedCalendars="true"
            >
            <template v-slot:input="picker" style="min-width: 350px;" class="relative font-bold bg-black">
                <div class="text-base sm:text-2xl">{{ moment.utc(picker.startDate).format('DD/MM/yyyy')}} <span class="hidden sm:inline"> - {{ moment.utc(picker.endDate).format('DD/MM/yyyy') }}</span></div>
            </template>
          </date-range-picker>
        </div>

        <div class="fc-toolbar">
          <div class="fc-right">
            <button type="button" class="hidden bg-transparent border-0 sm:inline-block fc-timeGridWeek-button fc-button fc-button-primary hover:text-yellow-500 hover:bg-transparent " @click="goTo('timeGridWeek')">week</button>
            <button type="button" class="hidden bg-transparent border-0 sm:inline-block fc-timeGrid3Day-button fc-button fc-button-primary hover:text-yellow-500 hover:bg-transparent" @click="goTo('timeGrid3Day')">3 day</button>
            <button type="button" class="bg-transparent border-0 fc-timeGridDay-button fc-button fc-button-primary hover:text-yellow-500 hover:bg-transparent" @click="goTo('timeGridDay')">day</button>
            <button type="button" class="bg-transparent border-0 fc-listWeek-button fc-button fc-button-primary hover:text-yellow-500 hover:bg-transparent fc-button-active">list</button></div>
        </div>
      </header>
      <!-- TABLE HEADER -->
      <div class="items-center justify-between hidden py-0 pl-3 pr-0 text-xs text-gray-300 bg-gray-700 tab-header sm:flex">
        <div class="hidden pl-1 space-x-4 text-xs font-bold uppercase sm:flex opacity-70"
        :class="headerColumnClass">
          <div v-for="col in visibleColumnDefs" :key="col.headerName"

            :style="{width: col.width + 'px'}">
            {{col.headerName}}
          </div>
        </div>
        <div class="flex w-20">
          <!-- <div class="agenda-header-filter">
          <el-popover placement="bottom"
          height="400"
          trigger="click">
            <div class="agenda-filter-popup" >
              <strong>Filters</strong><br>
              <label class="group-label">Show bookings:</label>
              <el-radio-group @change="filterChanged" v-model="filter.pastEvents" size="small">
                <el-radio-button label="past">Past</el-radio-button>
                <el-radio-button label="future">Future</el-radio-button>
                <el-radio-button label="all">All</el-radio-button>
              </el-radio-group>
              <label class="group-label">Accounts:</label>
              <div style="max-height: 300px; overflow-y: auto;">
                <div v-for="(client, key) in filter.clients" :key="'filter-' + key">
                  <el-checkbox
                  @change="filterChanged"
                    :label="key" v-model="client.value">
                  </el-checkbox>
                </div>
              </div>
              <br>
              <el-button-group>
                <el-button type="primary" size="mini" @click="toggleClientFilter(true)">Check all</el-button>
                <el-button type="primary" size="mini" @click="toggleClientFilter(false)">Uncheck all</el-button>
              </el-button-group>
            </div>
            <i slot="reference" class="bi bly-filter icon-btn"
            :class="{active: isFilterActive}"></i>
          </el-popover>
        </div> -->
        <div class="agenda-header-settings">
          <el-popover placement="bottom"
          trigger="click">
            Hide/Show columns <br>
            <div v-for="col in columnDefs" :key="'x' + col.headerName">
              <el-checkbox
                :label="col.headerName" v-model="col.visible">
              </el-checkbox>
            </div>
            <i slot="reference" class="bi bly-more icon-btn"></i>
          </el-popover>
        </div>
        </div>
      </div>

      <!-- mobile filters -->
      <div class="flex items-center justify-between sm:hidden">
        <div class="text-xs">
          <input class="w-full px-3 py-2 rounded-md bg-black/50" type="text" v-model="searchQuery" placeholder="Search...">
          <span class="px-2 py-2 text-xs bg-black btn" v-show="showCheck" @click="showCheck = false">Cancel</span>
          <!-- <span class="px-2 py-2 ml-2 text-xs text-white btn" v-show="showCheck" @click="showEdit">Export CSV ({{selectedEvents.length}})</span> -->
          <span class="px-2 py-2 ml-2 text-xs text-white btn" v-show="showCheck" @click="showEdit">Edit Selected 1({{selectedEvents.length}})</span>
          <span class="px-2 py-2 ml-2 text-xs text-white btn" v-show="showCheck" @click="showExportDialog = true">Show Selected ({{selectedEvents.length}})</span>
        </div>
        <div class="flex sm:hidden">
          <div class="flex w-20">
            <!-- <div class="agenda-header-filter">
              <el-popover placement="bottom"
              height="400"
              trigger="click">
                <div class="agenda-filter-popup" >
                  <strong>Filters</strong><br>
                  <label class="group-label">Show bookings:</label>
                  <el-radio-group @change="filterChanged" v-model="filter.pastEvents" size="small">
                    <el-radio-button label="past">Past</el-radio-button>
                    <el-radio-button label="future">Future</el-radio-button>
                    <el-radio-button label="all">All</el-radio-button>
                  </el-radio-group>
                  <label class="group-label">Accounts:</label>
                  <div style="max-height: 300px; overflow-y: auto;">
                    <div v-for="(client, key) in filter.clients" :key="'filter-' + key">
                      <el-checkbox
                      @change="filterChanged"
                        :label="key" v-model="client.value">
                      </el-checkbox>
                    </div>
                  </div>
                  <br>
                  <el-button-group>
                    <el-button type="primary" size="mini" @click="toggleClientFilter(true)">Check all</el-button>
                    <el-button type="primary" size="mini" @click="toggleClientFilter(false)">Uncheck all</el-button>
                  </el-button-group>
                </div>
                <i slot="reference" class="bi bly-filter icon-btn"
                :class="{active: isFilterActive}"></i>
              </el-popover>
            </div> -->
          <div class="agenda-header-settings">
            <el-popover placement="bottom"
            trigger="click">
              Hide/Show columns <br>
              <div v-for="col in columnDefs" :key="'x' + col.headerName">
                <el-checkbox
                  :label="col.headerName" v-model="col.visible">
                </el-checkbox>
              </div>
              <i slot="reference" class="bi bly-more icon-btn"></i>
            </el-popover>
          </div>
          </div>
        </div>
      </div>

      <div class="items-center hidden gap-4 mt-1 text-xs sm:flex">
        <input class="w-full px-3 py-2 rounded-md bg-black/50" type="search" v-model="searchQuery" placeholder="Search..." @search="search">
        <div class="flex-shrink-0">
          <span class="bg-gray-800 btn btn-sm" v-show="!showCheck" @click="showCheck = true">Select Events</span>
          <span class="bg-black btn" v-show="showCheck" @click="showCheck = false">Cancel</span>
          <span class="ml-2 text-white btn" v-show="showCheck" @click="exportAsCSV">Export CSV ({{selectedEvents.length}})</span>
          <span class="ml-2 text-white btn" v-show="showCheck" @click="showEdit">Edit Selected ({{selectedEvents.length}})</span>
          <span class="ml-2 text-white btn" v-show="showCheck" @click="showExportDialog = true">Show Selected ({{selectedEvents.length}})</span>
        </div>
      </div>

      <!-- EVENTS GROUPS -->
      <div class="events-container ">
        <!-- <span class="bg-black btn" v-show="showCheck" @click="showCheck = false">Cancel</span> -->
        <div class="agenda-day-group" v-view="viewHandler" :data-day="key"
            v-for="(group, key) in groupCurrentEvents"
            :key="key">
            <div>
              <div class="inline-block w-32 px-2 py-1 text-lg text-center bg-black"
              :class="{today: groupsIsTodayFlags[key]}">{{ moment(key).utc().format('DD/MM/yyy') }}</div>
            </div>
            <div class="relative" v-for="event in group" :key=" event.id" :class="{'event-group': event.groupId}">
              <bly-agenda-group-header
                @click.native="toggleGroup(event)"
                v-if="event.groupId && eventGroups[event.groupId].length > 1"
                :event="event"
                :groupEventsStatuses="groupEventsStatuses[event.groupId]"
                :visibleColumnDefs="visibleColumnDefs"
                :isOpen="!collapsedEvents[event.id]"
                :isMobileOnly="isMobile"
                :isSysAdmin="isSysAdmin"
                :inputStatuses="inputStatuses"
                :streamManagerConnections="streamManagerConnections"
                :eventGroup="eventGroups[event.groupId]"></bly-agenda-group-header>

              <bly-agenda-item
                v-if="!event.groupId || eventGroups[event.groupId].length <= 1"
                @click.native="toggleSelection(event)"
                :event="event"
                :serverStatus="serverStatuses"
                :inputStatuses="inputStatuses"
                :isMobileOnly="isMobile"
                :checked="event.checked"
                :showCheck.sync="showCheck"
                :selectedEvents="selectedEvents"
                :isSysAdmin="isSysAdmin"
                :selected="previeEventId === event.id"
                :groupEventsStatuses="groupEventsStatuses[event.groupId]"
                :streamManagerConnections="streamManagerConnections"
                :visibleColumnDefs="visibleColumnDefs">
                <!-- EVENT ACTIONS -->
                <div class="agenda-day-event-actions">
                  <el-tooltip content="MOS Check/Uncheck"
                    v-show="isSysAdmin">
                    <button type="button" class="top-0.5 bg-gray-200 mt-1 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" role="switch" aria-checked="false"
                        @click="save('mosChecked', event.mosChecked, event)">
                        <span
                          :class="{'translate-x-0': !event.mosChecked, 'translate-x-5': event.mosChecked}"
                          aria-hidden="true" class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full shadow pointer-events-none ring-0"></span>
                      </button>
                  </el-tooltip>
                  <el-tooltip content="Download PDF">
                    <i class="bi bly-file icon-btn"
                      @click="createPdf(event)"></i>
                  </el-tooltip>
                  <el-tooltip content="Duplicate booking"
                    v-if="isAdmin || acl.clientAdmin || acl.ee || acl.eventCoordinator">
                    <i class="bi bly-clipbin icon-btn"
                      @click="duplicateBooking(event)"></i>
                  </el-tooltip>
                  <el-tooltip content="Reschedule"
                    v-if='!event.isPastServiceTime'
                    v-show="isAdmin || acl.clientAdmin || acl.ee">
                    <i class="bi bly-opening-times icon-btn"
                      @click="setRescheduleEventDialog(true)"></i>
                  </el-tooltip>
                  <el-tooltip content="Nothing to report"
                    v-if="event.status === 'LIVE' || event.status === 'COMPLETED'"
                    v-show="isAdmin || acl.clientAdmin || acl.ee || acl.eo">
                    <i class="bi bly-check green icon-btn" @click="sendNothingToReport(event)"></i>
                  </el-tooltip>
                  <el-tooltip content="Create report"
                    v-if="event.status === 'LIVE' || event.status === 'COMPLETED'"
                    v-show="isAdmin || acl.clientAdmin || acl.ee || acl.eo">
                    <i class="bi bly-warning-2 icon-btn" @click="openReportDialog(event)"></i>
                  </el-tooltip>
                  <el-tooltip content="Cancel booking"
                    v-if="event.status === 'LIVE' || !event.isPastServiceTime"
                    v-show="event.status !== 'REJECTED'">
                    <i class="bi bly-close icon-btn red"
                      @click="confirmDelete(event)"></i>
                  </el-tooltip>
                </div>
              </bly-agenda-item>

              <!-- GROUP EVENTS -->
              <div
                class="relative pl-3 mb-4"
                v-if="event.groupId && !collapsedEvents[event.id] && !collapsedEvents[event.id]">
                <div class="absolute w-1 bg-gray-800 -top-1 bottom-1 left-2">

                </div>
                <div v-for="groupEvent in eventGroups[event.groupId]"
                    :key="groupEvent.id">
                    <bly-agenda-item
                    @click.native="toggleSelection(groupEvent)"
                    :event="groupEvent"
                    :isMobileOnly="isMobile"
                    :checked="groupEvent.checked"
                    :showCheck.sync="showCheck"
                    :serverStatus="serverStatuses"
                    :inputStatuses="inputStatuses"
                    :selectedEvents="selectedEvents"
                    :isSysAdmin="isSysAdmin"
                    :selected="previeEventId === groupEvent.id"
                    :streamManagerConnections="streamManagerConnections"
                    :visibleColumnDefs="visibleColumnDefs">
                    <!-- EVENT ACTIONS -->
                    <div class="agenda-day-event-actions">
                      <el-tooltip content="MOS Check/Uncheck"
                        v-show="isSysAdmin">
                        <button type="button" class="top-0.5 bg-gray-200 mt-1 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" role="switch" aria-checked="false"
                            @click="save('mosChecked', groupEvent.mosChecked, groupEvent)">
                            <span
                              :class="{'translate-x-0': !groupEvent.mosChecked, 'translate-x-5': groupEvent.mosChecked}"
                              aria-hidden="true" class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full shadow pointer-events-none ring-0"></span>
                          </button>
                      </el-tooltip>
                      <el-tooltip content="Download PDF">
                        <i class="bi bly-file icon-btn"
                          @click="createPdf(groupEvent)"></i>
                      </el-tooltip>
                      <el-tooltip content="Duplicate booking"
                        v-if="isAdmin || acl.clientAdmin || acl.ee || acl.eventCoordinator">
                        <i class="bi bly-clipbin icon-btn"
                          @click="duplicateBooking(groupEvent)"></i>
                      </el-tooltip>
                      <el-tooltip content="Reschedule"
                        v-if='!groupEvent.isPastServiceTime'
                        v-show="isAdmin || acl.clientAdmin || acl.ee">
                        <i class="bi bly-opening-times icon-btn"
                          @click="setRescheduleEventDialog(true)"></i>
                      </el-tooltip>
                      <el-tooltip content="Nothing to report"
                        v-if="groupEvent.status === 'LIVE' || groupEvent.status === 'COMPLETED'"
                        v-show="isAdmin || acl.clientAdmin || acl.ee || acl.eo">
                        <i class="bi bly-check green icon-btn" @click="sendNothingToReport(groupEvent)"></i>
                      </el-tooltip>
                      <el-tooltip content="Create report"
                        v-if="groupEvent.status === 'LIVE' || groupEvent.status === 'COMPLETED'"
                        v-show="isAdmin || acl.clientAdmin || acl.ee || acl.eo">
                        <i class="bi bly-warning-2 icon-btn" @click="openReportDialog(groupEvent)"></i>
                      </el-tooltip>
                      <el-tooltip content="Cancel booking"
                        v-if="groupEvent.status === 'LIVE' || !groupEvent.isPastServiceTime"
                        v-show="groupEvent.status !== 'REJECTED'">
                        <i class="bi bly-close icon-btn red"
                          @click="confirmDelete(groupEvent)"></i>
                      </el-tooltip>
                    </div>
                  </bly-agenda-item>
                </div>
              </div>
            </div>
        </div>
      </div>
    </main>
    <!-- Preview Panel -->
    <aside
      v-if="!isMobile"
      style="height: calc(100vh - 66px); overflow:hidden; margin-top: 8px; width: 340px !important;"
      class="hidden w-96 flex-shrink-0 h-[200px] overflow-y-auto bg-white 2xl:block" >
        <bly-agenda-preview :eventsWithTheSameOTT="eventsWithTheSameOTT"></bly-agenda-preview>
    </aside>

    <div class="2xl:hidden max-w-96">
      <bly-aside v-model="showAside" >
        <bly-agenda-preview :eventsWithTheSameOTT="eventsWithTheSameOTT"></bly-agenda-preview>
      </bly-aside>
    </div>
    <!-- -------------------------------------------------------------------------- -->
    <!-- ADD EVENT DIALOG ------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <!-- <router-view name="addBooking"/> -->
    <bly-agenda-add-booking></bly-agenda-add-booking>
    <!-- -------------------------------------------------------------------------- -->
    <!-- RESCHEDULE DIALOG -------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <!-- <router-view name="reschedule"/> -->
    <bly-agenda-reschedule></bly-agenda-reschedule>
    <!-- -------------------------------------------------------------------------------------- -->
    <!-- NAV OPTIONS -------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->
    <div class="items-center hidden config mv-config sm:flex">
      <div class="flex items-center cursor-pointer" if="userEmail === 'adrian@mediaatmos.com'" @click="test">lll
      <!-- <img src="javascript:PopUpShowThumb('img_lv_7508')" alt=""> -->
      </div>
      <el-tooltip content="Upload schedule"
        v-if="false">
        <i class="bi bly-upload icon-btn" @click="uploadDialogVisible = true"></i>
      </el-tooltip>
      <el-tooltip content="Add booking"
        v-if="isAdmin || acl.clientAdmin || acl.eventCoordinator || acl.ee">
        <i class="bi bly-plus icon-btn" @click="openCreateEventDialog()"></i>
      </el-tooltip>
      <el-tooltip content="Generate TX Report"
        v-if="isSysAdmin || acl.clientAdmin">
        <i class="bi bly-tasks icon-btn" @click="showGenerateReportDialog()"></i>
      </el-tooltip>
      <div class="w-px h-4 mx-2 bg-gray-600" v-if="isSysAdmin || hasMultipleClients"></div>
      <el-tooltip content="Show Cancelled Bookings"
        v-if="isSysAdmin">
        <i class="bi bly-close icon-btn" :class="{active: showCanceled}"
          @click="showCanceled = !showCanceled"></i>
      </el-tooltip>
      <el-tooltip content="See all bookings"
        v-if="isSysAdmin || hasMultipleClients">
        <el-switch
          style="display: inline-block; top:-2px;left: 16px; color:red"
          v-model="showAllEvents"
          @change="showAllEventsChanged"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-text="'Show all bookings'">
        </el-switch>
      </el-tooltip>
      <el-tooltip content="See only active">
        <el-switch
          style="display: inline-block; top:-2px;left: 26px; color:red"
          v-model="showAllActiveEvents"
          @change="showAllActiveEventsChanged"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="Show completed">
        </el-switch>
      </el-tooltip>
      <el-tooltip style="visibility:hidden">
        <i class="bi bly-plus icon-btn"></i>
      </el-tooltip>
      <div class="w-px h-4 mx-2 bg-gray-600" v-if="isSysAdmin || hasMultipleClients"></div>
      <el-tooltip content="Resources used" placement="right">
        <span class="relative mr-2">
          <svg class="text-gray-500 cursor-pointer hover:text-yellow-500" v-show="isSysAdmin" @click="openResourcesUsed" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M16 128h416c8.84 0 16-7.16 16-16V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v32c0 8.84 7.16 16 16 16zm480 96H80c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zm-64 160H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" fill="currentColor"/></svg>
            <span v-show="hasOverlapingInputs" class="absolute top-0 right-0 block h-1.5 w-1.5 transform -translate-y-1/2 translate-x-1/2 rounded-full bg-red-500"></span>
        </span>
      </el-tooltip>
      <el-tooltip content="Key" placement="right">
        <el-popover
          placement="top-start"
          trigger="hover">
          <bly-schedule-help></bly-schedule-help>
          <!-- <i slot="reference" class="bi bly-question icon-btn"></i> -->
          <svg slot="reference" class="ml-2 -m-px text-gray-500 cursor-pointer hover:text-yellow-500" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm137.8 69.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L248 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H190.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM192 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" fill="currentColor"/></svg>
        </el-popover>
      </el-tooltip>
      <el-tooltip content="Import" placement="right"  v-if="isSysAdmin">
        <div class="px-2"><bly-import></bly-import></div>
      </el-tooltip>
    </div>
    <div class="flex items-center config mv-config sm:hidden">
    <div class="sm:hidden">
        <i class="bi bly-more bi-rotate-90 icon-btn" @click="mobileConfigNav= true"></i>
        <el-drawer
          custom-class="config-push-nav"
          size="300px"
          :title="'View options'"
          :visible.sync="mobileConfigNav"
          :with-header="false">
          <div v-bar class="drawer-wrapper">
            <div>
              <el-dropdown-item
                v-if="false"
                icon="bly-upload"
                @click.native="uploadDialogVisible = true">
                Upload schedule
              </el-dropdown-item>
              <el-dropdown-item
              v-if="isAdmin || acl.clientAdmin || acl.eventCoordinator || acl.ee"
                icon="bi bly-plus"
                @click.native="openCreateEventDialog()">
                Add booking
              </el-dropdown-item>
              <el-dropdown-item
              v-if="isSysAdmin"
                icon="bi bly-tasks"
                @click.native="showGenerateReportDialog()">
                Generate TX Report
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isSysAdmin"
                divided>
                <el-switch
                  v-model="showCanceled"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Show Cancelled Bookings">
                </el-switch>
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isSysAdmin || hasMultipleClients">
                <el-switch
                  v-model="showAllEvents"
                  active-color="#13ce66"
                  inactive-color="#ff4949"

                  @change="showAllEventsChanged"
                  active-text="See all bookings">
                </el-switch>
              </el-dropdown-item>
              <el-dropdown-item>
                  <el-switch
                    style="display: inline-block; top:-4px;left: 26px; color:red"
                    v-model="showAllActiveEvents"
                    @change="showAllActiveEventsChanged"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="Show completed">
                  </el-switch>
              </el-dropdown-item>
              <el-divider>
              </el-divider>
              <div class="mobile-status-legend">
                <h3><strong>Booking color coding</strong></h3>
                <img  v-if="false" src="../assets/schedule-status-legend.svg" alt="" >
                <div>
                  <div class="status-legend"
                    v-for="(status, key) in scheduleUIConfig.eventColorsSimple" :key="key">
                    <span class="color"
                      :style="{background: status}"></span>
                    <span class="name">{{key.replace('_', ' ')}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>
    <!-- -------------------------------------------------------------------------- -->
    <!-- EVENT REPORT ------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Create report for booking"
      :visible.sync="reportEventDialog"
      width="80%"
      :close-on-click-modal="false">
      <div class="send-report-dialog">
        <el-row :gutter="24">
          <el-col :span="16">
            <el-form ref="createReportForm" :model="reportForm" label-position="top">
              <strong>Issue description</strong>
              <quill-editor v-model="reportForm.description"
                ref="myQuillEditor"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady($event)">
              </quill-editor>
            </el-form>
          </el-col>
          <el-col :span="8">
            <div v-if="eventToReport" class="tx-report-booking-details">
              <strong>{{eventToReport.name}}</strong>
              <el-row>
                <el-col :span="8">
                  <div class="label">Line-up</div>
                  <div class="content">{{eventToReport.lineupTime | date('HH:mm DD:MM:YYYY')}}</div>
                </el-col>
                <el-col :span="8">
                  <div class="label">Kick-off</div>
                  <div class="content">{{eventToReport.startTime | date('HH:mm')}}</div>
                </el-col>
                <el-col :span="8">
                  <div class="label">End Time</div>
                  <div class="content">{{eventToReport.endTime | date('HH:mm')}}</div>
                </el-col>
              </el-row>

              <div class="label">Fixture</div>
              <div class="content">{{eventToReport.fixture | defaultValue('---')}}</div>

              <div class="label">Property</div>
              <div class="content">{{eventToReport.property | defaultValue('---')}}</div>

              <div class="label">Sport</div>
              <div class="content">{{eventToReport.sport | defaultValue('---')}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reportEventDialog = false">Cancel</el-button>
        <el-button type="primary" @click="sendReport" v-loading="loading">Create</el-button>
      </span>
    </el-dialog>


     <!-- -------------------------------------------------------------------------- -->
    <!-- BATCH EVENT EDIT --------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Events"
      :visible.sync="showEditDialog"
      width="360px"
      :close-on-click-modal="false">
      <div class="w-full select-text send-report-dialog" id="edit-events">
        <div>
          {{selectedEvents.length}} event<span v-if="selectedEvents.length > 1">s</span> selected!
        </div>
        <div class="mt-3 text-gray-400 uppercase" v-if="batchEdit.sameStatus">Update status</div>
        <div class="flex flex-wrap mb-2 justify-evenly" v-if="batchEdit.sameStatus">
          <div class="flex items-center px-4 py-2 text-center uppercase border-2 border-yellow-500 rounded-md cursor-pointer"
            :class="{'bg-yellow-500 text-white': batchEdit.status === 'RECEIVED'}" @click="batchEdit.status = 'RECEIVED'" v-if="batchEdit.originalStatus === 'CLIENT_CONFIRMED' && isSysAdmin">
            RECEIVED
          </div>
          <div class="flex items-center px-4 py-2 text-center uppercase border-2 border-yellow-500 rounded-md cursor-pointer"
            :class="{'bg-yellow-500 text-white': batchEdit.status === 'AWAITING_SIGNAL'}" @click="batchEdit.status = 'AWAITING_SIGNAL'" v-if="(batchEdit.originalStatus === 'CLIENT_CONFIRMED' || batchEdit.originalStatus === 'RECEIVED') && isSysAdmin">
            AWAITING <br> SIGNAL
          </div>
          <div class="flex items-center px-4 py-2 text-center uppercase border-2 border-yellow-500 rounded-md cursor-pointer"
            :class="{'bg-yellow-500 text-white': batchEdit.status === 'CONFIRMED'}" @click="batchEdit.status = 'CONFIRMED'"
            v-if="(batchEdit.originalStatus === 'CLIENT_CONFIRMED' || batchEdit.originalStatus === 'RECEIVED' || batchEdit.originalStatus === 'AWAITING_SIGNAL') && isSysAdmin">
            MOS <br>Confirm
          </div>
        </div>
        <div class="mt-3 text-gray-400 uppercase" v-if="batchEdit.sameDates">Reschedule</div>
        <div class="mb-2" v-if="batchEdit.sameDates">
          <el-collapse-transition>
            <el-alert type="warning" v-if="isCloseToServiceStartTime">
              Late Event. This event is close to start time needs to be started manually
            </el-alert>
          </el-collapse-transition>
          <el-collapse-transition>
            <el-alert type="warning" v-if="doesEventStarted">
              Late Event. This event is after start time needs to be started manually
            </el-alert>
          </el-collapse-transition>
          <el-form ref="rescheduleForm" :model="rescheduleForm" label-position="top">
            <el-row :gutter="16">
              <el-col :span="12">
                <el-form-item size="small" label="Line-up date (GMT)" prop="lineupDate" required>
                  <el-date-picker
                    v-model="rescheduleForm.lineupDate"
                    :picker-options="datePickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="Select line-up date"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="Line-up time (GMT)" prop="lineupTime" required>
                  <el-time-picker
                    value-format="HH:mm:ss"
                    v-model="rescheduleForm.lineupTime"
                    type="datetime"
                    format="HH:mm"
                    placeholder="Select line-up Time"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="Start date (GMT)" prop="startDate" required>
                  <el-date-picker
                    v-model="rescheduleForm.startDate"
                    :picker-options="datePickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="Select start date"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="Start Time (GMT)" prop="startTime" required>
                  <el-time-picker
                    v-model="rescheduleForm.startTime"
                    value-format="HH:mm:ss"
                    format="HH:mm"
                    placeholder="Select start time"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="End date (GMT)" prop="endDate" required>
                  <el-date-picker
                    v-model="rescheduleForm.endDate"
                    :picker-options="datePickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="Select end date"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="End time (GMT)" prop="endTime" required>
                  <el-time-picker
                    v-model="rescheduleForm.endTime"
                    value-format="HH:mm:ss"
                    format="HH:mm"
                    placeholder="Select end time"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="Add time" prop="tailTimeMin">
                  <el-input type="number" v-model="rescheduleForm.tailTimeMin"
                    size="small"
                    placeholder="Please input time in minutes"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="showEditDialog = false">Cancel</el-button>
        <el-button type="primary" @click="saveBatch" v-loading="loading">Save</el-button>
      </span>
    </el-dialog>

    <!-- -------------------------------------------------------------------------- -->
    <!-- EVENT Export ------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Events"
      :visible.sync="showExportDialog"
      width="360px"
      :close-on-click-modal="false">
      <div class="w-full space-x-2 select-text send-report-dialog" id="export-events">
        <div class="block max-w-lg pl-0 mb-2 space-y-1 align-top export-event" v-for="event in sortedSelectedEvents" :key="event.id">
          <div class="xxx"><span class="text-lg font-bold text-black data">{{event.fixture}}</span></div>
          <div class=""><span class="text-gray-700 label">Property:</span> <span class="text-black data">{{event.property}}</span></div>
          <div class=""><span class="text-gray-700 label">Sport:</span> <span class="text-black data">{{event.sport}}</span></div>
          <div class="xxx"><span class="text-gray-700 label">Description:</span> <span class="inline-block text-black data">{{event.description}}</span></div>
          <div class="xxx" v-if="event.overrideInputName"><span class="text-gray-700 label" >Source:</span> <span class="inline-block font-bold text-black data" >{{event.inputOriginalName}} <span v-if="event.inputDetails">({{event.inputDetails}})</span></span></div>
          <div class="xxx"><span class="text-gray-700 label">Input:</span> <span class="inline-block font-bold text-black data" >{{event.overrideInputName || event.inputOriginalName}}</span></div>
          <!-- <div class="xxx"><span class="text-gray-700 align-top label">Output:</span> <span class="inline-block font-bold text-black data">{{event.outputString}}</span></div> -->
          <div>---------------------------------</div>
          <div class="xxx"><span class="text-gray-700 label">Line-up:</span> <span class="inline-block text-black data">{{moment.utc(event.lineupTime).format('YYYY-MM-DD')}} <span class="ml-4">- {{moment.utc(event.lineupTime).format('HH:mm')}}</span> GMT</span></span></div>
          <div class="xxx"><span class="text-gray-700 label">Start:</span> <span class="inline-block text-black data">{{moment.utc(event.startTime).format('YYYY-MM-DD')}} <span class="ml-4">- {{moment.utc(event.startTime).format('HH:mm')}}</span> GMT</span></span></div>
          <div class="xxx"><span class="text-gray-700 label">End:</span> <span class="inline-block text-black data">{{moment.utc(event.endTime).format('YYYY-MM-DD')}} <span class="ml-4">- {{moment.utc(event.endTime).format('HH:mm')}}</span> GMT</span></div>
          <hr class="mt-4 border-black">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="copyToClipboard('export-events')">Copy</el-button>
        <el-button type="primary" @click="showExportDialog = false" v-loading="loading">OK</el-button>
      </span>
    </el-dialog>

    <!-- -------------------------------------------------------------------------- -->
    <!-- GENERATE REPORT DIALOG --------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Generate TX Report"
      :visible.sync="generateReportDialog"
      width="96%"
      class="report-dialog-window"
      :close-on-click-modal="false">
      <table class="tx-reports-header" v-if="!isMobileOnly">
        <tr>
          <th width="70px">Line-up</th>
          <th width="70px">Start</th>
          <th width="70px">End</th>
          <th width="75px">Tail Time</th>
          <th width="75px">Head Time</th>
          <!--<th width="200px">Fixture</th>-->
          <th width="150px">Sport</th>
          <th width="200px">Property</th>
          <th width="150px">Client</th>
          <th width="170px">Input</th>
          <th width="160px">Output</th>
          <th>Audio</th>
        </tr>
      </table>
      <div class="report-dialog" v-bar>
        <div>
          <div class="tx-reports">
            <div class="tx-reports-day-group"
              v-for="(reportGroup, reportDate) in txReports" :key="reportDate">
              <div class="tx-reports-day-group-date">
                <span>{{reportDate | date('DD/MM/YYYY')}}</span>
              </div>
              <!-- DESKTOP VERSION -->
              <div v-if="!isMobileOnly">
                <div
                  class="tx-report"
                  v-for="report in reportGroup" :key="report.id"
                  :class="{ntr: report.ntr}">
                  <div class="tx-report-event">
                    <div class="tx-report-event-name">
                      <strong>{{report.event.fixture}}</strong>
                    </div>
                    <div class="tx-report-event-details">
                      <table>
                        <tr>
                          <td width="70px">{{report.event.lineupTime | date("HH:mm DD:MM:YYYY")}}</td>
                          <td width="70px">{{report.event.startTime | date("HH:mm")}}</td>
                          <td width="70px">{{report.event.endTime| date("HH:mm") }}</td>
                          <td width="75px">{{report.event.tailTime | defaultValue("0")}}</td>
                          <td width="75px">{{report.event.headTime | defaultValue("0")}}</td>
                          <!--<td width="200px">{{report.event.fixture}}</td>-->
                          <td width="150px">{{report.event.sport}}</td>
                          <td width="200px">{{report.event.property}}</td>
                          <td width="150px">{{report.event.customer.name}}</td>
                          <td width="170px"
                            v-if="!report.event.input" class="warning">Not set</td>
                          <td width="170px"
                            v-if="report.event.input">
                              {{report.event.input.name | defaultValue('---')}}
                          </td>
                          <td>
                            <el-popover
                            placement="bottom"
                            trigger="hover">
                              <div class="output" v-for="output in report.event.outputs.items"
                                :key="output.id">
                                {{output.output.name}} <br>
                                {{output.output.description}}
                              </div>
                              <span slot="reference">
                                <strong>{{report.event.outputs.items.length}}</strong> outputs
                                <i class="bi bly-info"></i>
                              </span>
                            </el-popover>
                          </td>
                          <td>
                            <el-popover
                              placement="bottom"
                              trigger="hover"
                              :disabled="report.event.audioMapping.items.length === 0">
                              <div class="audio"
                                v-for="mapping in report.event.audioMapping.items"
                                :key="mapping.id">
                                {{mapping.channelNo}} {{mapping.description}}
                                <span v-show="mapping.active">(active)</span>
                              </div>
                              <span slot="reference">
                                <strong>
                                  {{report.event.audioMapping.items.length}}
                                </strong> audio channels
                                <i class="bi bly-info"
                                  v-show="report.event.audioMapping.items.length !== 0"
                                ></i>
                              </span>
                            </el-popover>

                          </td>
                        </tr>
                      </table>

                    </div>
                  </div>
                  <div class="tx-report-issue" v-if="report.description">
                    <i class="bi bly-warning-2 red"></i>
                    {{report.description | stripTags | truncate(100) }}
                    <span
                      v-show="report.description && report.description.length > 100"
                      class="link primary"
                      @click="showMoreDetails(report.description)">show full report</span>
                  </div>
                </div>
              </div>
              <!-- MOBILE VERSION -->
              <div v-if="isMobileOnly">
                <div
                  class="tx-report tx-report-mobile"
                  v-for="report in reportGroup" :key="report.id"
                  :class="{ntr: report.ntr}">
                  <div class="tx-report-event">
                    <div class="tx-report-event-name">
                      <strong>{{report.event.name}}</strong>
                    </div>
                    <div class="tx-report-event-details">
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          Line-up Time
                        </div>
                      <div>  {{report.event.lineupTime | date("HH:mm")}}</div>
                      </div>
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          Start Time
                        </div>
                      <div>  {{report.event.startTime | date("HH:mm")}}</div>
                      </div>
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          End Time
                        </div>
                      <div>  {{report.event.endTime| date("HH:mm") }}</div>
                      </div>
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          Tail Time
                        </div>
                      <div>  {{report.event.tailTime | defaultValue("0")}}</div>
                      </div>
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          Head Time
                        </div>
                      <div>  {{report.event.headTime | defaultValue("0")}}</div>
                      </div>
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          Fixture
                        </div>
                      <div>  {{report.event.fixture}}</div>
                      </div>
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          Sport
                        </div>
                      <div>  {{report.event.sport}}</div>
                      </div>
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          Property
                        </div>
                      <div>  {{report.event.property}}</div>
                      </div>
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          Client
                        </div>
                      <div>  {{report.event.customer.name}}</div>
                      </div>
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          Input
                        </div>
                        <div>
                          <span
                            v-if="!report.event.input" class="warning">Not set</span>
                          <span
                            v-if="report.event.input">
                              {{report.event.input.name | defaultValue('---')}}
                          </span>
                        </div>
                      </div>
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          Outputs
                        </div>
                        <div>
                        <el-popover
                        placement="bottom"
                        trigger="hover">
                          <div class="output" v-for="output in report.event.outputs.items"
                            :key="output.id">
                            {{output.output.name}} <br>
                            {{output.output.description}}
                          </div>
                          <span slot="reference">
                            <strong>{{report.event.outputs.items.length}}</strong> outputs
                            <i class="bi bly-info"></i>
                          </span>
                        </el-popover>
                        </div>
                      </div>
                      <div class="tx-report-event-details-entry">
                        <div class="tx-report-event-label">
                          Outputs
                        </div>
                        <div>
                        <el-popover
                          placement="bottom"
                          trigger="hover"
                          :disabled="report.event.audioMapping.items.length === 0">
                          <div class="audio" v-for="mapping in report.event.audioMapping.items"
                            :key="mapping.id">
                            {{mapping.channelNo}} {{mapping.description}}
                            <span v-show="mapping.active">(active)</span>

                          </div>
                          <span slot="reference">
                            <strong>{{report.event.audioMapping.items.length}}</strong>
                            audio channels
                            <i class="bi bly-info"
                              v-show="report.event.audioMapping.items.length !== 0"
                            ></i>
                          </span>
                        </el-popover>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tx-report-issue" v-if="report.description">
                    <i class="bi bly-warning-2 red"></i>
                    {{report.description | stripTags | truncate(100) }}
                    <span
                      v-show="report.description && report.description.length > 100"
                      class="link primary"
                      @click="showMoreDetails(report.description)">show full report</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <el-dialog
        :width="isMobile ? '90%' : '30%'"
        title="Issue description"
        :visible.sync="generateReportDetailsDialog"
        append-to-body>
        <div class="tx-report-issue-description">
          <div v-html="reportFullDescription"></div>
        </div>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="generateReportDialog = false">Cancel</el-button>
        <el-button type="primary" @click="generateReport(eventReports.items)">Generate</el-button>
      </span>
    </el-dialog>
    <bly-schedule-stats :eventsStats="eventsStats"></bly-schedule-stats>

    <!-- -------------------------------------------------------------------------- -->
    <!-- RESURCES DIALOG ---------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <div v-if="showResourcesUsed">
      <bly-modal :show.sync="showResourcesUsed" :overflow="true">
        <h3 slot="header">Input resource view</h3>
        <bly-resource-view-2 :dataset="resourcesUsed" :range="dateRange">
        </bly-resource-view-2>
      </bly-modal>
    </div>
  </div>
</template>
<script>
import Vue, { set } from 'vue';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { mapState, mapActions, mapMutations } from 'vuex';
import DateRangePicker from 'vue2-daterange-picker';
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { isMobileOnly } from 'mobile-device-detect';
import _ from 'lodash';
import { debounce } from 'lodash';
import Servers from '../services/Servers'
import { getInput } from '@/graphql/queries';

const moment = extendMoment(Moment);

export default {
  components: { DateRangePicker },
  data() {
    return {
      searchQuery: '',
      isMobileOnly,
      isMobile: false,
      loading: false,
      stickyDate: '',
      mobileConfigNav: false,
      dateRange: {
        startDate: moment.utc().subtract(7, 'days').startOf('day').format(),
        endDate: moment.utc().add(1, 'days').endOf('day').format(),
      },
      rangeStartDate: null,
      // grid data
      moment,
      showAside: false,
      selectedEvents: [],
      selectedEvent: {},
      previeEventId: 'stats',
      showExportDialog: false,
      showEditDialog: false,
      showCheck: false,
      reportFullDescription: null,
      generateReportDetailsDialog: false,
      showResourcesUsed: false,
      delayUI: true,
      batchEdit: {
        status: 'RECIVED',
        originalStatus: 'RECIVED',
        sameStatus: true,
      },
      serverStatuses: {},
      columnDefs: [
        {
          headerName: 'Line-up',
          field: 'lineupTime',
          sortable: true,
          visible: true,
          width: 60,
          type: 'time',
        },
        {
          headerName: 'Kick-off',
          field: 'startTime',
          sortable: true,
          visible: true,
          width: 60,
          type: 'time',
        },
        {
          headerName: 'End time',
          field: 'endTime',
          visible: true,
          width: 60,
          type: 'time',
        },
        {
          headerName: 'Sport',
          field: 'sport',
          sortable: true,
          visible: true,
          width: 100,
        },
        {
          headerName: 'Property',
          field: 'property',
          sortable: true,
          visible: true,
          width: 120,
        },
        /*
        {
          headerName: 'Fixture',
          field: 'fixture',
          visible: true,
          width: 110,
        },

        {
          headerName: 'Client',
          field: 'customerName',
          sortable: true,
          visible: true,
          width: 130,
        },
        */
        {
          headerName: 'Input',
          field: 'inputName',
          sortable: true,
          visible: true,
          type: 'html',
          width: 130,
        },
        {
          headerName: 'Outputs',
          field: 'outputUI',
          sortable: true,
          type: 'html',
          visible: true,
          width: 240,
        },
        {
          headerName: 'Audio',
          field: 'audioUI',
          sortable: true,
          visible: true,
          width: 'auto',
        },
      ],

      reportForm: {
        ntr: false,
        eventId: null,
        description: null,
      },
      eventGroups: {},
      eventGroupsSorted: {},
      editorOption: {
        theme: 'bubble',
        placeholder: 'Describe the issue',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
          ],
        },
      },
      // Reported Event Details
      reportFormEvent: null,
      eventToReport: null,
      reportEventDialog: false,
      // rescheduleEventDialog: false,
      rescheduleForm: {
        startDate: null,
        lineupDate: null,
        endDate: null,
        endTime: null,
        startTime: null,
        eventId: null,
        lineupTime: null,
      },
      datePickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < new Date(new Date().setDate(new Date().getDate() - 1))
          );
        },
      },
      filter: {
        pastEvents: 'all',
        clients: [],
      },
      showCanceled: false,
      showAllEvents: true,
      showAllActiveEvents: false,
      generateReportDialog: false,
      collapsedEvents: {},
      groupEventsStatuses: {},
      groupsIsTodayFlags: {},
      baseEventList: [],
      currentEvents: [],
      groupCurrentEvents: {},
      eventsFlags: {},
      resourcesUsed: [],
      overlapingEvents: [],
      localStorage: window.localStorage,
      outputStatus: [],
      smEndpoints: [],
      streamManagerConnections: {},
      thumbsProjectIds: [],
      inputStatuses: {},
      inputThumbs: {},
      inputStatusesInterval: null
    };
  },

  computed: {
    ...mapState({
      userId: (state) => state.userAccount.userId,
      userEmail: (state) => state.userAccount.userEmail,
      aclEx: (state) => state.userAccount.userPermissions,
      isAdmin: (state) => state.userAccount.isAdmin,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      acl: (state) => state.userAccount.aclCurrent,
      account: (state) => state.userAccount.account,
      client: (state) => state.userAccount.client,
      clientAccounts: (state) => state.userAccount.clientAccounts,
      clients: (state) => state.userAccount.listClients,
      eventReports: (state) => state.schedule.eventReports,
      showDataFromAllAccounts: (state) => state.userAccount.showDataFromAllAccounts,

      events: (state) => state.schedule.events,
      listEvents: (state) => state.schedule.listEventsAgenda,
      eventStatuses: (state) => state.schedule.eventStatuses,
      eventTypes: (state) => state.schedule.eventTypes,
      viewsSettings: (state) => state.schedule.viewsSettings,
      inputs: (state) => state.schedule.inputs,
      outputs: (state) => state.schedule.outputs,

      scheduleDateRange: (state) => state.schedule.scheduleDateRange,
      eventsWithTheSameOTT: (state) => state.schedule.eventsWithTheSameOTT,
      scheduleUIConfig: (state) => state.schedule.scheduleUIConfig,
    }),

    eventsWithTheSameOTT() {
      const overlapingEvents = [];
      this.overlapingEvents.forEach((event) => {
        // console.group(event.id);
        const { overlappingOTT, outputs } = event;
        const mappedOutputs = outputs.items
          .filter((out) => out)
          .map((out) => {
            const ottApplication = out.outputOttApplicationOverride || out.output.ottApplication;
            return {
              id: out.id,
              ottApplication,
              ottPlatform: out.output.ottPlatform,
              ottServer: out.output.ottServer,
            };
          });

        const sameOTT = overlappingOTT
          .filter((ev) => {
            const mappedOuts = ev.outputs.items
              .filter((out) => out)
              .map((out) => {
                const ottApplication = out.outputOttApplicationOverride || out.output.ottApplication;
                return {
                  id: out.id,
                  ottApplication,
                  ottPlatform: out.output.ottPlatform,
                  ottServer: out.output.ottServer,
                };
              })
              .filter((out) => {
                const t = mappedOutputs.filter((output) => {
                  // console.log('OUT => ', output.ottServer, '=>', out.ottServer, output.ottApplication === out.ottApplication , output.ottServer === out.ottServer);
                  // console.log('OUT => ', output.ottApplication, '=>', out.ottApplication);
                  if (!output.ottApplication && !output.ottServer) return false;
                  return output.ottApplication === out.ottApplication && output.ottServer === out.ottServer;
                });
                return t.length > 0;
              });
            // console.log('mappedOUTS', ev.id, mappedOuts);
            return mappedOuts.length > 0;
          });
        // console.log('sameOTT', sameOTT);
        if (sameOTT.length > 0) {
          overlapingEvents.push({
            ...event,
            sameOTT,
          });
        }
        // console.log('mappedOutputs', event.id, overlappingOTT, sameOTT);
        // console.groupEnd();
      });
      return overlapingEvents;
    },

    hasOverlapingInputs() {
      return this.resourcesUsed.filter((event) => {
        return event.overlappingInputs.length > 0;
      })
      .map(event => event.overlappingInputs.length)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },

    eventsStats() {
      const remainingStatuses = [
        'PENDING',
        'CLIENT_CONFIRMED',
        'RECEIVED',
        'CONFIRMED',
      ];
      console.log('eventsStats', this.currentEvents);
      const liveEvents = this.currentEvents
        .filter((event) => {
          return event.status === "LIVE";
        });
      const completedEvents = this.currentEvents
        .filter((event) => {
          return event.status === "COMPLETED";
        });
      const remainingEvents = this.currentEvents
        .filter((event) => {
          return remainingStatuses.indexOf(event.status) >= 0;
        });
      const stats = {
        total: this.currentEvents.length,
        live: liveEvents.length,
        completed: completedEvents.length,
        remaining: remainingEvents.length,
      };
      this.setStats(stats);
      return stats
    },

    hasMultipleClients() {
      if (!this.clients.items) return false;
      return this.clients.items.length > 1;
    },

    headerColumnClass() {
      return `grid-cols-${this.visibleColumnDefs.length}`;
    },
    sortedSelectedEvents() {
      return this.selectedEvents.sort((left, right) => moment.utc(left.serviceStartTime)
          .diff(moment.utc(right.serviceStartTime)));
    },

    getSelectedIndex() {
      return (event => {
        let index = -1;
        this.selectedEvents.forEach((ev, i) => {
          if (event.id === ev.id) index = i;
        });

        return index;
      });
    },
    filterClients() {

      console.log('%c T1 EVENT LOADED listEvents filterClients 7', 'font-size: 20px; color: lime', this.filterAllAccounts);
      if (this.showAllEvents) {
        console.log('filterClients', this.filterAllAccounts);
        return this.filterAllAccounts;
      }
      const doesAccountExist = Array.isArray(this.clientAccounts);
      if (!doesAccountExist) return [];
      const clients = this.clientAccounts.filter((account) => {
        // filter acounts that are not selected
        return this.client.code === this.account.code || this.account.code === account.code;
      }).map((item) => item.name + '-' + this.client.code);
      console.log('filterClients', clients)
      return _.union(clients);
    },

    accounts() {
      const doesAccountExist = !!this.clientAccounts;
      if (!doesAccountExist) return [];
      return this.clientAccounts.map((account) => account.id);
    },

    accountsNames() {
      const doesAccountExist = !!this.clientAccounts;
      if (!doesAccountExist) return [];
      return this.clientAccounts.map((account) => account.name);
    },
    // used to generate reports
    txReports() {
      if (!this.eventReports) return [];
      const doesReportsExist = !!this.eventReports.items;
      if (!doesReportsExist) return [];
      const sortedReports = this.eventReports.items
        .slice(0)
        .sort((left, right) => moment.utc(left.event.startTime)
          .diff(moment.utc(right.event.startTime)));
      return _.groupBy(sortedReports, (date) => moment(date.event.startTime).startOf('day'));
    },

    filterAllAccounts() {
      const doesClientsExist = !!this.clients && !!this.clients.items;
      if (!doesClientsExist) return [];

      const accounts = [];
      this.clients.items.forEach((client) => {
        client.accounts.items.forEach((account) => accounts.push(account.name + '-' + client.code));
      });
      return accounts;
    },

    visibleColumnDefs() {
      return this.columnDefs.filter((column) => column.visible);
    },

    isEventActvie() {
      return (id) => this.selectedEvent && this.selectedEvent.id === id;
    },

    agendaHeaderStyle() {
      let gridColumns = '';
      this.columnDefs.forEach((column) => {
        const isVisible = column.visible;
        const isAuto = column.width === 'auto';
        if (!isVisible) return;
        gridColumns += isAuto ? ' auto' : `${column.width}px `;
      });
      const style = `
        <style>
          .agenda-header-columns, .agenda-day-event-details {
            grid-template-columns: ${gridColumns};
          }
        </style>
      `;
      return style;
    },

    groupStatuses() {
      return statuses => [...new Set(statuses)];
    },


    claimsCanEdit() {
      const accountId = this.account.code;
      const clientId = this.client.code;
      return [`ee.${accountId}.${clientId}`, `eo.${accountId}.${clientId}`];
    },

    claimsCanAccess() {
      const accountId = this.account.code;
      const clientId = this.client.code;
      return [
        `ev.${accountId}.${clientId}`,
        `ee.${accountId}.${clientId}`,
        `eo.${accountId}.${clientId}`,
      ];
    },

    isFilterActive() {
      let isClientFilterInactive = false;
      Object.keys(this.filter.clients).forEach((client) => {
        if (!this.filter.clients[client].value) isClientFilterInactive = true;
      });
      return this.filter.pastEvents !== 'all' || isClientFilterInactive;
    },
    // isToday() {
    //   return (date) => {
    //     console.log('someDate', date);
    //     const today = new Date();
    //     const someDate = new Date(date);
    //     return someDate.getDate() == today.getDate()
    //       && someDate.getMonth() == today.getMonth()
    //       && someDate.getFullYear() == today.getFullYear();
    //   };
    // },
    serviceStartTimeOffsetMin() {
      return moment(this.selectedEvent.lineupTime).diff(this.selectedEvent.serviceStartTime, 'minutes');
    },

    isStartTimeModified() {
      if (!this.rescheduleForm || !this.rescheduleForm.startTime || !this.rescheduleForm.startDate) return false;
      return !(moment.utc(`${this.rescheduleForm.startDate} ${this.rescheduleForm.startTime}`).isSame(moment(this.selectedEvent.startTime)));
    },

    isEndTimeModified() {
      if (!this.rescheduleForm || !this.rescheduleForm.endTime || !this.rescheduleForm.endDate) return false;
      return !(moment.utc(`${this.rescheduleForm.endDate} ${this.rescheduleForm.endTime}`).isSame(moment(this.selectedEvent.endTime)));
    },

    isCloseToServiceStartTime() {
      const format = 'HH:mm:ss';
      const now = moment.utc();
      const before = moment.utc(this.rescheduleForm.startTime, format).subtract('1', 'hours');
      const currentStartTime = moment.utc(this.rescheduleForm.startTime, format);
      const isCurrentDate = moment.utc(this.rescheduleForm.startDate).isSame(new Date(), 'day');
      if (!isCurrentDate) return false;
      return now.isBetween(before, currentStartTime, '[]');
    },

    doesEventStarted() {
      const format = 'HH:mm:ss';
      const now = moment.utc();
      const currentStartTime = moment.utc(this.rescheduleForm.startTime, format);
      const currentEndTime = moment.utc(this.rescheduleForm.endTime, format);
      const isCurrentDate = moment.utc(this.rescheduleForm.startDate).isSame(new Date(), 'day');

      if (!isCurrentDate) return false;
      return now
        .isBetween(currentStartTime, currentEndTime, '[]');
    },

    rescheduleEventDialog: {
      set(value) {
        this.setRescheduleEventDialog(value);
      },
      get() {
        return this.showRescheduleEventDialog;
      },
    },
    eventsWithTheSameOTT() {
      const overlapingEvents = [];
      this.overlapingEvents.forEach((event) => {
        // console.group(event.id);
        const { overlappingOTT, outputs } = event;
        const mappedOutputs = outputs.items
          .filter((out) => out)
          .map((out) => {
            const ottApplication = out.outputOttApplicationOverride || out.output.ottApplication;
            return {
              id: out.id,
              ottApplication,
              ottPlatform: out.output.ottPlatform,
              ottServer: out.output.ottServer,
            };
          });

        const sameOTT = overlappingOTT
          .filter((ev) => {
            const mappedOuts = ev.outputs.items
              .filter((out) => out)
              .map((out) => {
                const ottApplication = out.outputOttApplicationOverride || out.output.ottApplication;
                return {
                  id: out.id,
                  ottApplication,
                  ottPlatform: out.output.ottPlatform,
                  ottServer: out.output.ottServer,
                };
              })
              .filter((out) => {
                const t = mappedOutputs.filter((output) => {
                  // console.log('OUT => ', output.ottServer, '=>', out.ottServer, output.ottApplication === out.ottApplication , output.ottServer === out.ottServer);
                  // console.log('OUT => ', output.ottApplication, '=>', out.ottApplication);
                  if (!output.ottApplication && !output.ottServer) return false;
                  return output.ottApplication === out.ottApplication && output.ottServer === out.ottServer;
                });
                return t.length > 0;
              });
            // console.log('mappedOUTS', ev.id, mappedOuts);
            return mappedOuts.length > 0;
          });
        // console.log('sameOTT', sameOTT);
        if (sameOTT.length > 0) {
          overlapingEvents.push({
            ...event,
            sameOTT,
          });
        }
        // console.log('mappedOutputs', event.id, overlappingOTT, sameOTT);
        // console.groupEnd();
      });
      return overlapingEvents;
    },
  },

  watch: {
    eventsWithTheSameOTT(newVal) {
      console.log('eventsWithTheSameOTT watch', newVal);
      if (newVal.length > 0 && this.isSysAdmin) {
        let message = '';
        newVal.forEach((ev) => {
          message += `
            <div><b>${ev.fixture}</b></div>
            <small style="margin-top: -7px;display: block; opacity: .6;">${ev.id}</small>`;
        });
        if (!this.isOverlappingEventsNotificationSent) {
          this.$notify({
            title: 'Overlapping events have the same OTT outputs',
            message,
            duration: 0,
            dangerouslyUseHTMLString: true,
            type: 'warning',
            position: 'top-left',
          });
          this.isOverlappingEventsNotificationSent = true;
        }
      }
      this.setEventsWithTheSameOTT(newVal);
    },
    '$route.params': function() {
      console.log('route changed', this.$route);
      this.previeEventId = this.$route.params.id;
    },
    dateRange(newDateRange) {
      console.log('newDateRange', newDateRange);
      this.updateDates(newDateRange);
    },
    listEvents: {
      handler: async function (newVal) {
        console.log('%c T1 EVENT LOADED listEvents', 'font-size: 20px; color: lime', newVal);
        this.baseEventList = await this.getBaseEvents(newVal);
        this.currentEvents = await this.getCurrentEvents(this.baseEventList);
        this.groupCurrentEvents = await this.getEventGroupedByDate(this.currentEvents);
        this.overlapingEvents = await this.getOverlappingEvents(this.baseEventList);
        console.log('%c T1 EVENT LOADED listEvents 2', 'font-size: 20px; color: lime',  this.baseEventList, this.currentEvents,  this.groupCurrentEvents);
      },
      deep: true,
    },
    searchQuery: {
      handler: debounce(async function (newVal) {
        console.log('%c T1 EVENT LOADED listEvents searchQuery', 'font-size: 20px; color: lime', newVal);
        // this.baseEventList = await this.getBaseEvents(newVal);
        this.currentEvents = await this.getCurrentEvents(this.baseEventList);
        this.groupCurrentEvents = await this.getEventGroupedByDate(this.currentEvents);
        console.log('%c T1 EVENT LOADED listEvents 2', 'font-size: 20px; color: lime', newVal);
      }, 500), // 300ms debounce time
      deep: true,
    },
    showCanceled: {
      handler: async function (newVal) {
        console.log('%c T1 EVENT LOADED showCanceled', 'font-size: 20px; color: lime', newVal);
        this.baseEventList = await this.getBaseEvents(this.listEvents);
        this.currentEvents = await this.getCurrentEvents(this.baseEventList);
        this.groupCurrentEvents = await this.getEventGroupedByDate(this.currentEvents);
      },
      deep: true,
    },
    filter: {
      handler: async function(newValue) {
        console.log('%c T1 filter EVENT LOADED', 'font-size: 20px; color: lime', this.listEvents);
        // this.baseEventList = await this.getBaseEvents(this.listEvents);
        this.currentEvents = await this.getCurrentEvents(this.baseEventList);
        this.groupCurrentEvents = await this.getEventGroupedByDate(this.currentEvents);
        // this.overlapingEvents = await this.getOverlappingEvents(this.baseEventList);
      },
      deep: true
    },
    filterClients(newVal) {
      this.filter.clients = {};
      const filterString = localStorage.getItem('scheduler-agenda-filterx');
      const savedFilter = JSON.parse(filterString);
      const clients = {}
      console.log('%c T1 EVENT LOADED listEvents filterClients 6', 'font-size: 20px; color: lime', newVal);
      newVal.forEach((client) => {
        Vue.set(this.filter.clients, client, {
          name: client,
          value: true,
        });
      });

      this.filter.clients = Object.keys(this.filter.clients).sort().reduce(
        (obj, key) => {
          obj[key] = this.filter.clients[key];
          return obj;
        },
        {}
      );

    },
    groupCurrentEvents(newVal) {
      this.stickyDate = moment.utc(Object.keys(newVal)[0]).format('DD/MM/YYYY');
    },
    async showAllEvents() {
      console.log('T1 showAllEvents', this.showAllEvents);
      this.baseEventList = await this.getBaseEvents(this.listEvents);
      this.currentEvents = await this.getCurrentEvents(this.baseEventList);
      this.groupCurrentEvents = await this.getEventGroupedByDate(this.currentEvents);
      this.overlapingEvents = this.getOverlappingEvents(this.baseEventList);
      this.$forceUpdate();
    },
    async showAllActiveEvents() {
      console.log('T1 showAllActiveEvents', this.showAllActiveEvents);
      this.baseEventList = await this.getBaseEvents(this.listEvents);
      this.currentEvents = await this.getCurrentEvents(this.baseEventList);
      this.resourcesUsed = await this.getResourcesUsed(this.currentEvents);
      this.groupCurrentEvents = await this.getEventGroupedByDate(this.currentEvents);
      this.overlapingEvents = this.getOverlappingEvents(this.baseEventList);
      this.$forceUpdate();
    },
  },

  created() {

    console.log('created', this.showAllActiveEvents, this.isSysAdmin);
    setTimeout(() => {
      if (this.isSysAdmin) {
        const showAllEvents = localStorage.getItem('showAllEvents');
        const showAllActive = localStorage.getItem('showAllActive');
        if (showAllEvents) {
          const showAllEventsBool = showAllEvents.toLowerCase() === 'true';
          this.showAllEvents = showAllEvents ? showAllEventsBool : true;
        }
        if (showAllActive) {
          const showAllActiveBool = showAllActive.toLowerCase() === 'true';
          this.showAllActiveEvents = showAllActive ? showAllActiveBool : true;
        }
        // console.log('created 2', showAllActive, showAllActiveBool, this.showAllActiveEvents);
      }
      const dateRange = JSON.parse(localStorage.getItem('scheduleViewDateRange'));
      if (dateRange) this.updateDates(dateRange);
      else this.updateDates(this.dateRange);
      const filter = JSON.parse(localStorage.getItem('scheduler-agenda-filter'));
      if (filter) {
        this.filter.pastEvents = filter.pastEvents;
      }
    }, 100);
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
    window.clearInterval(this.inputStatusesInterval);
  },
  async mounted() {
    // console.log('mounted', this.filterClients);
    this.getDimensions();
    window.addEventListener('resize', this.getDimensions);

    if (this.$route.params.id) {
      this.previeEventId = this.$route.params.id;
    }

    setTimeout(() => {
      // window.scroll(0, document.querySelector('.today').offsetTop - 150);
    }, 1800);
    setTimeout(async () => {
      await this.fetchInputs();
      await this.fetchOutputs();
      await this.listAudioMappingProfiles();
    }, 500);

    this.inputStatusesInterval = setInterval(() => {
      this.getInputStatus()

      this.getServerStatuses();
    }, 10000)
    console.log('list events mounted test', this.listEvents);
  },


  methods: {
    ...mapActions({
      fetchEventsByDate: 'schedule/fetchEventsByDateLazyLoading',
      createEvent: 'schedule/createEvent',
      addBooking: 'schedule/addBooking',
      listEventReports: 'schedule/listEventReports',
      deleteEvent: 'schedule/deleteEvent',
      updateEvent: 'schedule/updateEvent',
      deleteEventAudioChannel: 'schedule/deleteEventAudioChannel',
      createEventAudioChannel: 'schedule/createEventAudioChannel',
      updateEventAudioChannel: 'schedule/updateEventAudioChannel',
      addEventReport: 'schedule/addEventReport',
      rescheduleEvent: 'schedule/rescheduleEvent',

      fetchInputs: 'schedule/fetchInputs',
      listAudioMappingProfiles: 'schedule/listAudioMappingProfiles',
      fetchOutputs: 'schedule/fetchOutputs',

      startStreamTarget: 'schedule/startStreamTarget',
      stopStreamTarget: 'schedule/stopStreamTarget',
      restartEvent: 'schedule/restartEvent',
      restartIncomingStream: 'schedule/restartIncomingStream',
      updateWowzaTarget: 'schedule/updateWowzaTarget',

      createPdf: 'schedule/createPdf',
    }),
    ...mapMutations({
      setEventsWithTheSameOTT: 'schedule/setEventsWithTheSameOTT',
      setActiveEvent: 'schedule/setActiveEvent',
      setSelectedEventProperty: 'schedule/setSelectedEventProperty',
      setRescheduleEventDialog: 'schedule/setRescheduleEventDialog',
      setShowCreateEventDialog: 'schedule/setShowCreateEventDialog',
      setBookingClone: 'schedule/setBookingClone',
      setDateRange: 'schedule/setDateRange',
      setStats: 'schedule/setStats',
    }),

       exportAsCSV() {
         const events = this.selectedEvents.map((event) => {
           return {
             'Fixture': event.fixture,
             'Line-up': event.lineupTime.replace('T', ' ').replace('.000Z', ''),
             'Kick-off': event.startTime.replace('T', ' ').replace('.000Z', ''),
             'End time': event.endTime.replace('T', ' ').replace('.000Z', ''),
             'Inputs': event.inputPDF,
             'Outputs': event.outputPDF,
           };
         });

         // Add headers to the CSV content
         const headers = Object.keys(events[0]).join(';');
         const csvContent = [headers, ...events.map(event => Object.values(event).join(';').trim())]
          .join('\n')

         // Create a file and download it
         const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
         const fileName = 'events.csv';
         if (navigator.msSaveBlob) {
           navigator.msSaveBlob(file, fileName);
         } else {
           const link = document.createElement('a');
           link.href = URL.createObjectURL(file);
           link.setAttribute('download', fileName);
           document.body.appendChild(link);
           link.click();
         }
       },
    search(searchEvent) {
      console.log('search', searchEvent);
      if (this.searchQuery === '') {
        this.updateList();
      }
    },
    getDimensions() {
      this.isMobile = this.$isMobile();
    },
    goBackAWeek() {
      const newRange = {
        startDate: moment.utc(this.dateRange.startDate).subtract(1, 'week'),
        endDate: this.dateRange.startDate,
      }
      this.updateDates(newRange);
    },
    goToday() {
      const newRange = {
        startDate: moment.utc().subtract(3, 'days'),
        endDate: moment.utc().add(3, 'days'),
      }
      this.updateDates(newRange);
    },
    goAheadAWeek() {
      const newRange = {
        startDate: this.dateRange.endDate,
        endDate: moment.utc(this.dateRange.endDate).add(1, 'week'),
      }
      this.updateDates(newRange);
    },
    async updateDates(dates) {
      const start = dates.startDate || dates.start;
      const end = dates.endDate || dates.end;
      this.dateRange.startDate = start;
      this.dateRange.endDate = end;
      console.log('date range', dates)
      this.setDateRange(dates);
      localStorage.setItem('scheduleViewDateRange', JSON.stringify(this.dateRange));
      this.loading = true;
      await this.fetchEventsByDate({ ...this.dateRange, showCompleted: this.showAllActiveEvents });

      this.updateList();
      this.loading = false;
    },
    async getServerStatuses() {
      try {
        const result = await Servers.getOutputsStatus({
          outputs: this.outputStatus,
        })

        // console.log('OUTPUT getServerStatuses ------------', result)
        this.serverStatuses = result.data;
      } catch (error) {
        console.log('OUTPUT getServerStatuses error')
      }
    },
    async getStreamManagerConnections(ids) {
      try {
        const result = await Servers.getStreamManagerConnections(ids)

        console.log('OUTPUT getStreamManagerConnections ------------', result)
        if (result.data && result.data.ok) {
          result.data.data.forEach(item => {
            Vue.set(this.streamManagerConnections, item.id, item)
            // this.streamManagerConnections[item.id] = item
          })
        }
      } catch (error) {
        console.log('OUTPUT getStreamManagerConnections error')
      }
    },
    async getInputStatus() {
      console.log('INPUT getServerStatuses ------------', this.thumbsProjectIds)
      if (this.thumbsProjectIds.length === 0) return;
      try {
        const tasks = []

        // const items = [...new Set(this.thumb
        const result = await Servers.getInputsStatusThumbs({
          projects: this.thumbsProjectIds,
        })
        Vue.set(this, 'inputStatuses', result.data);

        // this.inputStatuses = result.data;
        return result.data;
      } catch (error) {
        console.log('INPUT getInputStatus error', error)
      }
    },
    async updateList() {
      console.log('T1 update list of events');
      this.baseEventList = await this.getBaseEvents(this.listEvents);
      this.currentEvents = await this.getCurrentEvents(this.baseEventList);
      this.groupCurrentEvents = await this.getEventGroupedByDate(this.currentEvents);

      // this.overlapingEvents = this.getOverlappingEvents(this.baseEventList);
      this.$forceUpdate();
      // this.resourcesUsed = this.getResourcesUsed(this.currentEvents);
      this.$forceUpdate();
    },
    async save(property, value, event) {
      console.log('ssssss', property, value, event);
      const newEvent = this.getEventObjForUpdate(event);
      newEvent[property] = value;
      if (property === 'fixture') {
        newEvent.name = value;
      }
      return await this.updateEvent(newEvent);
    },

    getEventObjForUpdate(event) {
      return {
        bugRequired: event.bugRequired,
        claimsCanAccess: event.claimsCanAccess,
        claimsCanEdit: event.claimsCanEdit,
        description: event.description,
        endTime: event.endTime,
        eventAccountId: event.eventAccountId,
        eventCustomerId: event.eventCustomerId,
        eventInputId: event.eventInputId,
        expectedVersion: event.version,
        fixture: event.fixture,
        headTimeMin: event.headTimeMin,
        startType: event.startType,
        id: event.id,
        attachments: event.attachments,
        lineupTime: event.lineupTime,
        name: event.name,
        property: event.property,
        requestedBitrate: event.requestedBitrate,
        serviceEndTime: event.serviceEndTime,
        serviceStartTime: event.serviceStartTime,
        sport: event.sport,
        startTime: event.startTime,
        status: event.status,
        tailTimeMin: event.tailTimeMin,
        type: event.type,
        eventLastUpdatedById: this.userId,
      };
    },

    toggleClientFilter(value) {
      console.log('toogle chasdasd', value, this.filter.clients);
      // for (const property in this.filter.clients) {
      //   this.filter.clients[property].value = value;
      //   console.log(`${property}: ${this.filter.clients[property]}`);
      // }
      localStorage.setItem('scheduler-agenda-filter', JSON.stringify(this.filter));
    },

    duplicateBooking(booking) {
      const event = {
        ...booking,
        extendedProps: booking,
      };
      this.setBookingClone(event);
      this.setShowCreateEventDialog(true);
    },

    openReportDialog(event) {
      this.reportForm.eventId = event.id;
      this.reportFormEvent = event;
      this.reportForm.ntr = false;
      this.eventToReport = event;
      this.reportForm.description = '';
      this.reportEventDialog = true;

      this.reportForm.clientId = this.client.code;
      this.reportForm.accountId = this.account.code;
      this.reportForm.claimsCanAccess = this.claimsCanAccess;
      this.reportForm.claimsCanEdit = this.claimsCanEdit;
    },

    async sendReport() {
      const result = await this.addEventReport(this.reportForm);
      if (!result.error) {
        this.reportEventDialog = false;
        this.eventToReport = null;
      }
    },

    async sendNothingToReport(event) {
      this.reportForm.eventId = event.id;
      this.reportForm.clientId = this.client.code;
      this.reportForm.accountId = this.account.code;
      this.reportForm.ntr = true;
      this.reportForm.claimsCanAccess = this.claimsCanAccess;
      this.reportForm.claimsCanEdit = this.claimsCanEdit;
      delete this.reportForm.description;
      await this.addEventReport(this.reportForm);
    },

    openRescheduleBooking(event) {
      this.rescheduleForm = {
        endTime: event.end.split('T')[1],
        startTime: event.start.split('T')[1],
        eventId: event.id,
        lineupTime: event.lineupTime.split('T')[1],
        expectedVersion: event.version,
        accountId: this.account.code,
        clientId: this.client.code,
        tailTimeMin: event.tailTimeMin,
        headTimeMin: event.headTimeMin,
      };

      this.rescheduleEventDialog = true;
    },

    // async rescheduleBooking() {
    //   const startDate = this.selectedEvent.startTime.split('T')[0];
    //   const endDate = this.selectedEvent.endTime.split('T')[0];
    //   const lineupDate = this.selectedEvent.lineupTime.split('T')[0];

    //   const newTimings = {
    //     ...this.rescheduleForm,
    //     lineupTime: moment.utc(`${lineupDate} ${this.rescheduleForm.lineupTime}`)
    //       .format(),
    //     startTime: moment.utc(`${startDate} ${this.rescheduleForm.startTime}`)
    //       .format(),
    //     endTime: moment.utc(`${endDate} ${this.rescheduleForm.endTime}`)
    //       .format(),
    //   };
    //   const result = await this.rescheduleEvent(newTimings);
    //   const hasErrors = result.error;
    //   if (!hasErrors) this.rescheduleEventDialog = false;
    // },

    async confirmDelete(event) {
      const confirmation = await this.$confirm('Are you sure you want to cancel booking?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      const newEvent = this.getEventObjForUpdate(event);
      newEvent.status = 'CANCELLED';
      if (event.status === 'LIVE' || event.isPastServiceTime) newEvent.status = 'LATE_CANCELLED';
      await this.updateEvent(newEvent);
    },

    getEventObjForUpdate(event) {
      return {
        bugRequired: event.bugRequired,
        claimsCanAccess: event.claimsCanAccess,
        claimsCanEdit: event.claimsCanEdit,
        description: event.description,
        endTime: event.endTime,
        eventAccountId: event.eventAccountId,
        eventCustomerId: event.eventCustomerId,
        eventInputId: event.eventInputId,
        expectedVersion: event.version,
        fixture: event.fixture,
        headTimeMin: event.headTimeMin,
        id: event.id,
        lineupTime: event.lineupTime,
        name: event.name,
        property: event.property,
        requestedBitrate: event.requestedBitrate,
        serviceEndTime: event.serviceEndTime,
        serviceStartTime: event.serviceStartTime,
        sport: event.sport,
        startTime: event.startTime,
        status: event.status,
        tailTimeMin: event.tailTimeMin,
        type: event.type,
        eventLastUpdatedById: this.userId,
      };
    },
    filterChanged() {
      console.log('xxxx');
      localStorage.setItem('scheduler-agenda-filter', JSON.stringify(this.filter));
    },
    toggleGroup(event) {
      console.log('xxxx', event);
      this.collapsedEvents[event.id] = !this.collapsedEvents[event.id];
      // collapsedEvents[event.id] = !collapsedEvents[event.id];
      // this.$forceUpdate();
    },
    viewHandler(e) {
      // console.log(e.type, e.target.element.dataset.day, e); // 'enter', 'exit', 'progress'
      const { top } = e.target.rect;
      if (top < 146) {
        console.log('STICK', moment.utc(e.target.element.dataset.day).format('DD/MM/YYYY'));
        this.stickyDate = moment(e.target.element.dataset.day).utc().format('DD/MM/YYYY');
      }
      // console.log(e.percentInView) // 0..1 how much element overlap the viewport
      // console.log(e.percentTop) // 0..1 position of element at viewport 0 - above , 1 - below
      // console.log(e.percentCenter) // 0..1 position the center of element at viewport 0 - center at viewport top, 1 - center at viewport bottom
      // console.log(e.scrollPercent) // 0..1 current scroll position of page
      // console.log(e.scrollValue) // 0..1 last scroll value (change of page scroll offset)
      // console.log(e.target.rect) // element.getBoundingClientRect() result
    },

    toggleSelection(event) {
      // this.selectedEvent = event;
      console.log('toggle selection', this.isMobile, document.body.offsetWidth);
      if (this.isMobile || document.body.offsetWidth < 1570) {
        this.showAside = true;
      }
      if (this.$route.params.id !== event.id) this.goToEvent(event.id);
      if (!this.showCheck) return;
      const index = this.getSelectedIndex(event);

      if (index === -1) this.selectedEvents.push(event);
      if (index !== -1) this.selectedEvents.splice(index, 1);
    },

    copyToClipboard(containerid) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(document.getElementById(containerid));
      selection.removeAllRanges(); // Remove all ranges from the selection.
      selection.addRange(range);
      document.execCommand('Copy');
      this.$message.success('Copied to clipboard');
      const sel = window.getSelection ? window.getSelection() : document.selection;
      if (sel) {
          if (sel.removeAllRanges) {
              sel.removeAllRanges();
          } else if (sel.empty) {
              sel.empty();
          }
      }
    },

    getBaseEvents(list) {
      if (!list) return [];

      const { startDate, endDate } = this.dateRange;
      const calRange = moment.range(moment.utc(startDate).subtract(1, 'days').startOf('day'), moment.utc(endDate).add(2, 'days').endOf('day'));

      const isActive = status => ['COMPLETED', 'POSTPONED'].includes(status);
      const isCanceled = status => ['CANCELLED', 'DELETED', 'REJECTED'].includes(status);
      const overlapsDateRange = (start, end) => moment.range(moment.utc(start), moment.utc(end)).overlaps(calRange);

      return list.filter(event => {
        if (isActive(event.status) && !this.showAllActiveEvents) return false;
        if (isCanceled(event.status) && !this.showCanceled) return false;
        if (overlapsDateRange(event.serviceStartTime, event.serviceEndTime)) return true;
        return false;
      });
    },


    async getCurrentEvents(list) {
      const nowUtc = moment().utc();
      const query = this.searchQuery ? this.searchQuery.toLowerCase() : null;

      this.smEndpoints = [];
      const mapEvent = (item) => {
        const newItem = this.createNewItem(item, nowUtc);
        const flags = this.createFlags(item, nowUtc);
        this.updateNewItem(newItem, item, flags);
        this.updateAudioAndOutput(newItem, item);
        this.updateFlagsAndNewItem(newItem, item, flags);
        return newItem;
      };

      // this.smEndpoints = [...new Set(this.smEndpoints)];
      let clients = {};
        if (this.showAllEvents) {
          // console.log('filterClients', this.filterAllAccounts);
          clients = this.filterAllAccounts;
        } else {
          const doesAccountExist = Array.isArray(this.clientAccounts);
          if (!doesAccountExist) return [];
          clients = this.clientAccounts.filter((account) => {
            // filter acounts that are not selected
            return this.client.code === this.account.code || this.account.code === account.code;
          }).map((item) => item.name + '-' + this.client.code);
          // console.log('filterClients', clients)
          clients =  _.union(clients);
        }


      const filterEvent = (event) => {
        // if (this.showAllEvents && this.isSysAdmin) return true;
        const filterAccountProperty = event.account.name + '-' + event.customer.code;


        const hideByAccount = !(clients.indexOf(filterAccountProperty) === -1 || clients.indexOf(filterAccountProperty)  === -1) ;
        // console.log('%c T1 EVENT LOADED listEvents filterClients 8', 'font-size: 20px; color: lime', hideByAccount, filterAccountProperty, clients);
        const showFuture = this.filter.pastEvents === 'future' && moment.utc(event.serviceEndTime).isBefore(nowUtc);
        const showPast = this.filter.pastEvents === 'past' && moment.utc(event.serviceEndTime).isAfter(nowUtc);
        return hideByAccount; // && !showPast && !showFuture;
      };

      const searchFilter = (event) => {
        return event.fixture.toLowerCase().includes(query)
          || (event.sport && event.sport.toLowerCase().includes(query))
          || (event.description && event.description.toLowerCase().includes(query))
          || (event.property && event.property.toLowerCase().includes(query))
          || (event.inputName && event.inputName.toLowerCase().includes(query))
          || (event.outputUI && event.outputUI.toLowerCase().includes(query))
      };

      // console.log('%c T1 EVENT LOADED listEvents 3', 'font-size: 20px; color: lime', this.filter.clients);
      let mappedEvents = list.map(mapEvent).filter(filterEvent);
      // console.log('%c T1 EVENT LOADED listEvents 4', 'font-size: 20px; color: lime', mappedEvents);
      if (query) {
        mappedEvents = mappedEvents.filter(searchFilter);
      }

      console.log('getCurrentEvents', mappedEvents);

      const smEnpointsIds =  [...new Set(this.smEndpoints.map(sm => sm.interfaceId))].map(sm => {
        return { id: sm }
      })
      console.log('this.smEndpoints', smEnpointsIds, this.smEndpoints);

      console.log('%c T1 EVENT LOADED listEvents 5', 'font-size: 20px; color: lime', mappedEvents);
      this.getStreamManagerConnections(smEnpointsIds);
      return await mappedEvents.sort((left, right) =>
        moment.utc(left.lineupTime).diff(moment.utc(right.lineupTime)) || left.fixture.localeCompare(right.fixture) || left.inputName.localeCompare(right.inputName));
    },
    getOverlappingEvents(events) {
      const overlappingEvents = [];
      const activeEvents = events.filter(event => event.status !== 'CANCELLED' && event.status !== 'COMPLETED');

      activeEvents.forEach((event) => {
        const {
          serviceStartTime,
          serviceEndTime,
          id,
        } = event;

        const eventRange = moment.range(moment.utc(serviceStartTime), moment.utc(serviceEndTime));

        const related = activeEvents.filter((booking) => {
          if (booking.id === id) return false;
          const bookingRange = moment.range(moment.utc(booking.serviceStartTime), moment.utc(booking.serviceEndTime));
          return eventRange.overlaps(bookingRange);
        });

        const relatedOTT = related.filter((booking) => {
          const { items } = booking.outputs;
          return items.some(out => out && out.output.outputType === 'OTT');
        });

        if (related.length > 0) {
          overlappingEvents.push({
            ...event,
            overlapping: related,
            overlappingOTT: relatedOTT,
          });
        }
      });

      return overlappingEvents;
    },
    getOverlappingEventsOld(events) {
      const overlappingEvents = [];
      events.forEach((event) => {
        const {
          serviceStartTime,
          serviceEndTime,
          startTime,
          endTime,
          id,
        } = event;

        const newStart = moment.utc(serviceStartTime).subtract(60, 'minutes').toDate();
        const newEnd = moment.utc(serviceEndTime).add(60, 'minutes').toDate();

        const related = events.filter((booking) => {
          if (booking.status === 'CANCELLED' || booking.status === 'COMPLETED') return false;
          // return booking.outputs === outputs;
          if (booking.id === id) return false;
          const start = moment.utc(booking.serviceStartTime).toDate();
          const end =  moment.utc(booking.serviceEndTime).toDate();
          const eventRange =  moment.range(moment.utc(serviceStartTime), moment.utc(serviceEndTime));
          const bookingRange = moment.range(moment.utc(booking.serviceStartTime), moment.utc(booking.serviceEndTime));
          const areOverlapping = eventRange.overlaps(bookingRange);
          // console.log('OVERRALPING TEST', areOverlapping, moment.utc(serviceStartTime).format('MM/DD HH:mm'), moment.utc(serviceEndTime).format('MM/DD HH:mm'), moment.utc(booking.serviceStartTime).format('MM/DD HH:mm'), moment.utc(booking.serviceEndTime).format('MM/DD HH:mm'));
          if (!areOverlapping) {
            return false;
          }
          return true;
        });

        const relatedOTT = related.filter((booking) => {
          if (booking.status === 'CANCELLED' || booking.status === 'COMPLETED') return false;
          const { items } = booking.outputs;
          const ottOutputs = items.filter((out) => {
            if (!out) return null;
            if (out.output.outputType === 'OTT') return true;
            return false;
          });
          return ottOutputs.length > 0;
        });
        if (related.length > 0) {
          if (event.status !== 'CANCELLED' && event.status !== 'COMPLETED') {
            overlappingEvents.push({
              ...event,
              overlapping: related,
              overlappingOTT: relatedOTT,
            });
          }
        }
      });
      return overlappingEvents;
    },

    getCommonNameForGroup(groupId) {
      // Check if the group exists
      if (!this.eventGroups[groupId] || this.eventGroups[groupId].length === 0) {
        return null; // Or some default value indicating no common name found
      }

      // Example: Assuming the common name is based on a shared property 'eventName'
      // and all events in the group have the same 'eventName'
      const commonName = this.eventGroups[groupId][0].eventName;

      // Verify that all events in the group share this common name
      // This step is optional and depends on how you define 'common name'
      const allMatch = this.eventGroups[groupId].every(event => event.eventName === commonName);

      return allMatch ? commonName : null; // Or some indication that not all names match
    },

    async getEventGroupedByDate(test) {
      // return _.groupBy(this.currentEvents, (date) => moment.utc(date.startServiceTime).startOf('day'));
      const list = await test;
      console.log('T1 getEventGroupedByDate', list);
      this.eventGroups = {};
      const events = list
        .filter((event) => {
          const { groupId } = event;
          const hasGroup = !!groupId;
          if (!hasGroup) return true;

          const doesGroupExists = !!this.eventGroups[groupId];
          if (!doesGroupExists) {
            Vue.set(this.eventGroups, groupId, []);
            Vue.set(this.groupEventsStatuses, groupId, []);
            Vue.set(this.collapsedEvents, event.id, this.collapsedEvents.hasOwnProperty(event.id) ? this.collapsedEvents[event.id] : true);
            this.eventGroups[groupId].push(event);
            this.groupEventsStatuses[groupId].push(event.status);
            return true;
          }
          this.groupEventsStatuses[groupId].push(event.status);
          this.eventGroups[groupId].push(event);
          return false;
        })
        .sort((left, right) =>
        moment.utc(left.lineupTime).diff(moment.utc(right.lineupTime)) || left.fixture.localeCompare(right.fixture) || left.inputName.localeCompare(right.inputName));;


      // this.$forceUpdate();
      this.thumbsProjectIds = [];
      // const outputs
      events.forEach((event) => {

        if (event.input && event.input.name && (event.input.name.startsWith('MOS-IMG') || event.input.name.startsWith('APE'))) {
          console.log('input name', event.input.name, event.input)
          // this.thumbsProjectIds.push(event.input.name.trim());
        }
        if (event.input && event.input.probeProjectId && event.input.probeTaskId) {
          this.thumbsProjectIds.push({projectId: event.input.probeProjectId, taskId: event.input.probeTaskId});
        }
        if (event.inputOverride) {
          if (event.inputOverride.probeProjectId && event.inputOverride.probeTaskId) {
            this.thumbsProjectIds.push({projectId: event.inputOverride.probeProjectId, taskId: event.inputOverride.probeTaskId});
          }

        }
      })

      // this.getInputStatus().then((res) => {
      //   console.log('INPUT getInputStatus ------------', res)
      //   // this.inputStatuses = res;
      // })
      const groups = _.groupBy(events, date => moment.utc(date.startServiceTime).startOf('day'));
      Object.keys(groups).forEach((key) => {
        const today = new Date();
        const someDate = new Date(key);
        this.groupsIsTodayFlags[key] = someDate.getDate() == today.getDate()
          && someDate.getMonth() == today.getMonth()
          && someDate.getFullYear() == today.getFullYear();
      });
      // console.timeEnd('getEventGroupedByDate', groups);
      console.log('T1 getEventGroupedByDate', groups);
      return groups;
    },

    // Get CURRENT EVENTS HELPERS

    createNewItem(item, nowUtc) {
      const newItem = {
        ...item,
        id: item.id,
        start: item.startTime,
        startServiceTime: item.serviceStartTime,
        lineupTime: item.lineupTime,
        end: item.endTime,
        title: item.name,
        content: item.content,
        classNames: `${item.status} ${item.type}`,
        status: item.status,
        data: item,
        collapsed: true,
        checked: false,
        isPastServiceTime: moment(nowUtc).isAfter(moment(item.serviceEndTime)),
      };
      if (item.customer) newItem.customerName = item.customer.name;
      return newItem;
    },

    createFlags(item, nowUtc) {
      const flags = {};
      flags.isToday = moment(nowUtc).isSame(moment.utc(item.startTime), 'day');
      flags.highlight = flags.isToday && (item.status === 'CLIENT_CONFIRMED' || item.status === 'RECIVED' || item.status === 'AWAINTING_SIGNAL');
      return flags;
    },

    updateNewItem(newItem, item, flags) {
      const isOptus = 'otu' === newItem.customer.code && 'otu' === newItem.account.code;
      const isDownlink = newItem.input && newItem.input.type === 'DOWNLINK';
      newItem.showRemap = isOptus && isDownlink;
      flags.showRemap = newItem.showRemap;
      newItem.inputName = item.input ? item.input.name : '';
      newItem.inputOriginalName = item.input ? item.input.name : '';
      newItem.probes = {
        probeProjectId: item.input ? item.input.probeProjectId : null,
        probeTaskId: item.input ? item.input.probeTaskId : null,
      };
      newItem.inputName += item.inputDetails ? `<div class="muted">${item.inputDetails}</div>` : '';
      newItem.overrideInputName = item.inputOverride ? item.inputOverride.name : '';
      if (newItem.overrideInputName !== '' && this.isSysAdmin) {
        newItem.inputName = ` ${newItem.overrideInputName} <br /><small class="text-xs opacity-50">${newItem.inputName}</small>  `;
      }
    },

    updateAudioAndOutput(newItem, item) {
      newItem.audioUI = '';
      newItem.audioPDF = '';
      item.audioMapping.items.forEach((channel) => {
        newItem.audioUI += `${channel.channelNo} ${channel.description}  \r\n`;
        newItem.audioPDF += `${channel.channelNo} ${channel.description} \r\n`;
      });
      newItem.audioUI = newItem.audioUI.trim();
      newItem.outputUI = '';
      newItem.outputSmEndpintsIds = '';
      newItem.outputPDF = '';
      newItem.outputString = '';

      item.outputs.items.filter((output) => !!output).forEach((el) => {
        this.updateOutput(newItem, el);
      });
      console.log('getStreamManagerConnections', item.outputIds, newItem);
    },

    updateOutput(newItem, el) {
      const [outputConfig] = newItem.actions.items
        .filter((action) => action.assignedOutput && action.assignedOutput.id === el.output.id);
      if (el.output && el.output.ottApplication) {
        this.outputStatus.push({
          "name": el.output.name,
          "ottServer": el.output.ottServer,
          "ottApplication": el.output.ottApplication
        })
      }
      const hasWowzaConfig = outputConfig && outputConfig.configWowza;
      const autostartTitle = hasWowzaConfig && outputConfig.configWowza.autostart ? outputConfig.configWowza.autostartOffsetMin : 0;
      const autostartColor = hasWowzaConfig && outputConfig.configWowza.autostart ? 'green' : 'red';
      const autostartString = `<i class="bi bly-time ${autostartColor}" title="Offset ${autostartTitle} min"></i>`;
      const autostartPDFString = autostartTitle ? `[Offset ${autostartTitle} min]` : '';


        // console.log('===> ', el, newItem. outputConfig);
      const urlPdf = el.output.url ? `URL: ${el.output.url}` : '';
      const ottsSeverString = el.output.ottServer && el.output.ottApplication ? `<div class="show-ma ${el.output.ottServer}">(${el.output.ottServer} - ${el.output.ottApplication})</div>` : '';

      let isConnectedString = '';

          if (this.isSysAdmin && el.output.smEndpoints) {
            const t = JSON.parse(el.output.smEndpoints);
            console.log('smEndpoints xxx', t);
            // newItem.smEndpoints = smEndpoints || [];
            const ids =  t.map(sm => sm.interfaceId);
            let isConnected = false;
            ids.forEach(id => {
              console.log('smEndpoints xxx ::', id, this.streamManagerConnections[id], (this.streamManagerConnections && this.streamManagerConnections[id] && this.streamManagerConnections[id].outputs_connected === ''))
              if(this.streamManagerConnections && this.streamManagerConnections[id] && this.streamManagerConnections[id].outputs_connected !== '') {
                isConnectedString = '<span class="w-2 h-2 bg-green-500 inline-block"></span>';
                isConnected = true;
              }
              if (this.streamManagerConnections && this.streamManagerConnections[id] && this.streamManagerConnections[id].outputs_connected === '') {
                isConnectedString = '<span class="w-2 h-2 bg-red-500 inline-block"></span>';

              }
              if(this.streamManagerConnections && !this.streamManagerConnections[id]) {
                isConnectedString = '<span class="w-2 h-2 bg-slate-500 inline-block"></span>';
              }
            });
          }

      newItem.outputUI += `
        <div class="output-${el.output.status}">
          ${el.output.displayName || el.output.name}
          <span class="output-type-tag ${el.output.outputType}">${el.output.outputType}</span>
          <span class="output-type-tag output-status-${el.output.status}">${el.output.status}</span>
          ${hasWowzaConfig ? autostartString : ''}
          ${ottsSeverString} ${isConnectedString}
        </div>
      `;

      const ott = `[${el.output.ottServer} - ${el.output.ottApplication}]`;
      const ottOverrride = `[OTT Override: ${el.outputOttApplicationOverride}]`;
      newItem.outputPDF += `${el.output.displayName || el.output.name} [${el.output.outputType}]${hasWowzaConfig ? autostartPDFString : ''} ${el.output.ottServer ? ott : ''} ${el.outputOttApplicationOverride ? ottOverrride : ''} ${urlPdf} \r\n`;

      newItem.outputString += `${el.output.displayName || el.output.name} [${el.output.outputType}]${hasWowzaConfig ? autostartPDFString : ''} ${el.output.ottServer ? ott : ''} ${el.outputOttApplicationOverride ? ottOverrride : ''}, `;
      if (el.outputOttApplicationOverride) {
        newItem.outputUI += `<span class="show-ma" >(OTT Override: <strong>${el.outputOttApplicationOverride}</strong>)</span>
        `;
      }


      if (this.isSysAdmin && el.output.smEndpoints) {
        const t = JSON.parse(el.output.smEndpoints);
        console.log('smEndpoints', t, this.smEndpoints);
        // newItem.smEndpoints = smEndpoints || [];

        this.smEndpoints = [...this.smEndpoints, ...t];
      }
    },

    updateFlagsAndNewItem(newItem, item, flags) {
      const hasRelatedEvents = this.eventsWithTheSameOTT.filter((ev) => ev.id === item.id).length > 0;
      if (hasRelatedEvents) newItem.outputUI += '<span class="warning">OVERLAPPING EVENTS WITH THE SAME OUTPUT</span>';
      if (newItem.outputUI === '') newItem.outputUI = 'not set';
      if (newItem.inputName === '') newItem.inputName = 'not set';
      if (newItem.outputPDF === '') newItem.outputPDF = 'not set';
      if (newItem.audioPDF === '') newItem.audioPDF = 'not set';
      newItem.inputPDF = newItem.inputName.replace('<br>', '');
      Vue.set(this.eventsFlags, item.id, flags);
    },

    // END Get CURRENT EVENTS HELPERS

    getResourcesUsed(currentEvents = []) {
      const inputs = {};
      const eventsWithInput = currentEvents
        .filter((event) => {
          return event.input || event.inputOverride;
        });
      const events = eventsWithInput
        .map((event) => {
          const eventInput = event.inputOverride || event.input;
          const inputName = eventInput.name;

          // console.groupCollapsed('%cxxxx', 'color:lime', event);
          const overlappingInputs = eventsWithInput.filter((ev) => {
            // console.log('e resourcesUsed', ev.autostartTitle === event.autostartTitle, ev, event);
            if (ev.id === event.id || ev.title === event.title) return false;

            const evInput = ev.inputOverride || ev.input;
            if (evInput.id !== eventInput.id) return false;

            const range1 = moment.range(moment.utc(ev.serviceStartTime), moment.utc(ev.serviceEndTime));
            const range2 = moment.range(moment.utc(event.serviceStartTime), moment.utc(event.serviceEndTime));

            const isOverlaping = range1.overlaps(range2);

            // console.log('%cxxxx', 'color:lime', moment.utc(ev.serviceStartTime).isBetween(event.serviceStartTime, event.serviceEndTime), isOverlaping);
            // debugger;
            return isOverlaping;
          })
          // console.groupEnd();
          const newEvent = {
            ...event,
            inputName: inputName,
            overlappingInputs,
          }
          return newEvent;
        })
        // .forEach((event) => {
        //   const eventInput = event.extendedProps.input || event.extendedProps.inputOverride;
        //   const inputName = eventInput.name;
        //   if (!inputs[inputName]) inputs[inputName] = [];
        //   inputs[inputName].push({
        //     ...event,
        //   });
        // });
      return events;
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array); // eslint-disable-line
      }
    },
    async saveBatch() {
      console.log('T1 saveBatch', this.selectedEvents);
      await this.asyncForEach(this.selectedEvents, async (event) => {
        const updatedEvent = await this.save('status', this.batchEdit.status, event);

        console.log('T1 event Update', this.batchEdit.sameStatus, event, updatedEvent, this.batchEdit);
        if (this.batchEdit.sameDates) await this.rescheduleBooking(updatedEvent);
      });
      await this.fetchEventsByDate({ ...this.dateRange, showCompleted: this.showAllActiveEvents });
      this.baseEventList = await this.getBaseEvents(this.listEvents);
      this.currentEvents = await this.getCurrentEvents(this.baseEventList);
      this.groupCurrentEvents = await this.getEventGroupedByDate(this.currentEvents);
      // this.overlapingEvents = this.getOverlappingEvents(this.baseEventList);

      this.showEditDialog = false;
      this.selectedEvents = [];
    },
    showEdit() {
      this.batchEdit.sameStatus = true;
      this.batchEdit.sameDates = true;
      this.batchEdit.sameTail = true;
      // let initStatus =
      const statuses = this.selectedEvents
        .map((event) => event.status)
        .filter((value, index, self) =>
          index === self
          .findIndex((t) => t.status === value.status)
        )
      this.selectedEvents
        .forEach((value, index, arr) => {
          if (index === 0 || !this.batchEdit.sameDates) return;
          const { lineupTime, startTime, endTime, tailTimeMin } = value;
          const prevEl = arr[index -1];
          if (lineupTime !== prevEl.lineupTime || startTime !== prevEl.startTime || endTime !== prevEl.endTime) this.batchEdit.sameDates = false;
          if (tailTimeMin !== prevEl.tailTimeMin) this.batchEdit.sameTail = false;
        });
      for (const event of this.selectedEvents) {
        console.log('for of event', event);
      }
      // batchEdit: {
      //   status: 'RECIVED',
      //   sameStatus: true,
      // },

      console.log('batch event edit', statuses, this.batchEdit);
      if (statuses.length > 1) this.batchEdit.sameStatus = false;
      this.batchEdit.rescheduleForm = {
        startDate: null,
        lineupDate: null,
        endDate: null,
        endTime: null,
        startTime: null,
        eventId: null,
        lineupTime: null,
      };
      if (this.batchEdit.sameDates) {
        this.rescheduleForm = {
          endDate: this.selectedEvents[0].endTime.split('T')[0],
          startDate: this.selectedEvents[0].startTime.split('T')[0],
          lineupDate: this.selectedEvents[0].lineupTime.split('T')[0],
          eventId: this.selectedEvents[0].id,
          endTime: this.selectedEvents[0].endTime.split('T')[1],
          startTime: this.selectedEvents[0].startTime.split('T')[1],
          lineupTime: this.selectedEvents[0].lineupTime.split('T')[1],
          expectedVersion: this.selectedEvents[0].version,
          accountId:this.selectedEvents[0].account.code,
          clientId: this.selectedEvents[0].customer.code,
          tailTimeMin: this.selectedEvents[0].tailTimeMin,
          headTimeMin: this.selectedEvents[0].headTimeMin,
        };
      }
      if (this.batchEdit.sameTime) {
        this.rescheduleForm = {
          tailTimeMin: this.selectedEvents[0].tailTimeMin,
          headTimeMin: this.selectedEvents[0].headTimeMin,
        };
      }
      this.batchEdit.status = statuses[0];
      this.batchEdit.originalStatus = statuses[0];

      console.log('batch event edit', this.batchEdit);
      this.showEditDialog = true;
    },
    getUpdatedTimes(lineupTime, startTime, endTime, headTimeMin, tailTimeMin) {
      const startDate = this.selectedEvent.startTime.split('T')[0];
      const endDate = this.selectedEvent.endTime.split('T')[0];
      const lineupDate = this.selectedEvent.lineupTime.split('T')[0];


      return {
        ...this.rescheduleForm,
        lineupTime: moment.utc(`${lineupDate} ${lineupTime}`)
          .format(),
        startTime: moment.utc(`${startDate} ${startTime}`)
          .format(),
        endTime: moment.utc(`${endDate} ${endTime}`)
          .format(),
        headTimeMin,
        tailTimeMin,
      };
    },
    async rescheduleBooking(event) {
      const {
        lineupTime,
        lineupDate,
        startTime,
        startDate,
        endTime,
        endDate,
        eventId,
        expectedVersion,
        accountId,
        clientId,
      } = this.rescheduleForm;
      const {
        headTimeMin,
        tailTimeMin,
      } = event;

      const head = this.isStartTimeModified ? 0 : headTimeMin;
      const tail = this.isEndTimeModified ? 0 : tailTimeMin;

      console.log('ddd', event);
      const payload = {
        lineupTime: moment.utc(`${lineupDate} ${lineupTime}`).format(),
        startTime: moment.utc(`${startDate} ${startTime}`).format(),
        endTime: moment.utc(`${endDate} ${endTime}`).format(),
        headTimeMin: head,
        tailTimeMin: tail,
        eventId: event.id,
        expectedVersion: event.version,
        accountId: event.account.id,
        clientId: event.customer.id,
        serviceStartTimeOffsetMin: this.serviceStartTimeOffsetMin,
      };
      console.log('ddd', payload);
      const result = await this.rescheduleEvent(payload);
      const hasErrors = result.error;
      if (!hasErrors) this.rescheduleEventDialog = false;
    },

    async addTime(event, type, time) {
      const headTime = type === 'head' ? event.headTimeMin + time : event.headTimeMin;
      const tailTime = type === 'tail' ? event.tailTimeMin + time : event.tailTimeMin;

      const startTime = type === 'head' ? moment(event.startTime).subtract(time, 'minutes') : event.startTime;
      const endTime = type === 'tail' ? moment(event.endTime).add(time, 'minutes') : event.endTime;
      const newTimes = {
        ...this.rescheduleForm,
        lineupTime: event.lineupTime,
        startTime,
        endTime,
        headTimeMin: headTime,
        tailTimeMin: tailTime,
        serviceStartTimeOffsetMin: this.serviceStartTimeOffsetMin,
      };

      delete newTimes.lineupDate;
      delete newTimes.startDate;
      delete newTimes.endDate;
      await this.rescheduleEvent(newTimes);
    },
    showAllEventsChanged(value) {
      console.log('%cshow change, value', 'color: lime', value);
      localStorage.setItem('showAllEvents', value);
      this.updateList();
    },
    showAllActiveEventsChanged(value) {
      console.log('%cshow change, value', 'color: lime', value, this.listEvents);
      localStorage.setItem('showAllActive', value);
      this.updateDates(this.dateRange);
      this.updateList();
    },
    goToEvent(id) {
      this.$router.push({ name: 'scheduleagenda', params: {id} });
    },
    goTo(view) {
      localStorage.setItem('scheduleView', view);
      this.$router.push('/schedule');
    },
    openCreateEventDialog() {
      this.setShowCreateEventDialog(true);
      this.mobileConfigNav = false;
    },
    showGenerateReportDialog() {
      this.generateReportDialog = true;
      this.listEventReports();
    },
    dateFormat (classes, date) {
      if (!this.rangeStartDate) return classes;
      if (moment(date).isBefore(moment(this.rangeStartDate).subtract(14, 'days'))) {
        classes.disabled = true;
      }
      if (moment(date).isAfter(moment(this.rangeStartDate).add(14, 'days'))) {
        classes.disabled = true;
      }
      // if (!classes.disabled) {
      //   classes.disabled = date < new Date()
      // }
      return classes;
    },
    handleStartDateSelection(startDate) {
      this.rangeStartDate = startDate;
    },
    handleEndDateSelection(startDate) {
      this.rangeStartDate = null;
    },
    async test() {
      this.loading = true;
      console.log('test');
      await this.fetchEventsByDate({ ...this.dateRange, showCompleted: this.showAllActiveEvents });
      console.log('test 2');
      this.loading = false;
    },
    openResourcesUsed() {
      this.resourcesUsed = this.getResourcesUsed(this.currentEvents);
      this.overlapingEvents = this.getOverlappingEvents(this.baseEventList);
      this.$forceUpdate();
      this.showResourcesUsed = true;
    }
  },
};
</script>
<style>
@media (max-width: 1536px) {
  .v-modal {
    z-index: 998 !important;
  }
}
.reportrange-text {
  border: 0px solid #000 !important;
  background: transparent !important;
  padding: 0 !important;
  cursor: pointer;
}
.events-container {
  height: calc(100vh - 175px);
  overflow-y: scroll;
  padding-bottom: 10px;
  margin: 0 -16px;
  padding: 15px;
  overflow-x: clip;
}
#schedule-agenda .show-ma {
    display: none;
}
@media screen and (min-width: 1536px) {
  #schedule-agenda main.wrapper {
    width: calc(100% - 360px) !important;
  }
}
#schedule-agenda .agenda-event .show-ma {
    display: block !important;
    font-size: 80%;
    opacity: 0.8;
    margin-bottom: 4px !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #13191d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(55, 65, 81, 1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(129, 131, 135, 1);
}
</style>
