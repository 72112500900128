<template>
  <div id="admin-client">
      <div>
        <div class="title-card"
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.3)">
          <div class="title-card-top">
            <div class="title-card-top-icon">
              <el-tooltip :content="client.name">
                <el-avatar>{{initials}}</el-avatar>
              </el-tooltip>
            </div>
            <div class="title-card-top-name">
              <span class="client-name">{{client.name | defaultValue('---')}}</span>
              <div class="title-card-top-name-description">
                {{client.description}}
              </div>
              <!-- <div class="title-card-top-name-additional">
                <i class="bi bly-info"></i> {{client.email}}
                <i class="bi bly-info"></i> {{client.phoneno}}
              </div>
              -->
            </div>
            <div class="title-card-top-actions">
              <el-tooltip content="Edit client details">
                <bly-link-button
                  v-if="acl.admin || acl.clientAdmin || acl.ca"
                  @click.native="openEditClientDialog(client)"
                  :type="'clear'"><i class="bi bly-edit"></i>
                </bly-link-button>
              </el-tooltip>
              <el-tooltip content="More">
                <bly-link-button :type="'clear'"><i class="bi bly-more bi-rotate-90"></i>
                </bly-link-button>
              </el-tooltip>
            </div>
          </div>
          <div class="title-card-bottom"></div>
        </div>
        <div class="content-wrapper">
          <div class="data-header">
            <div class="data-header-tabs">
              <span
                class="data-header-tabs-tab"
                :class="tabClass('accounts')"
                @click="changeContext('accounts')">Accounts</span>
              <span class="data-header-tabs-tab"
                :class="tabClass('users')"
                v-if="acl.admin || acl.clientAdmin || acl.ca"
                @click="changeContext('users')">Client Users</span>
              <span class="data-header-tabs-tab"
                :class="tabClass('features')"
                v-if="acl.admin"
                @click="changeContext('features')">Client Features</span>
              <!--<span class="data-header-tabs-tab"
                :class="tabClass('claims')"
                @click="changeContext('claims')">Client Claims</span>
              -->
            </div>
            <div class="data-header-actions">
              <!-- ACCOUNT ACTIONS -->
              <div class="data-header-actions-accounts" v-if="isTabContentVisible('accounts')">
                <el-tooltip content="Create account" >
                  <bly-button
                    :type="'clear'"
                    v-if="acl.admin || acl.clientAdmin || acl.ca"
                    @click.native="createAccountDialog = true">
                    <i class="bi bly-plus"></i></bly-button>
                </el-tooltip>
                <bly-button :type="'clear'"><i class="bi bly-more bi-rotate-90"></i></bly-button>
              </div>
              <!-- USERS ACTIONS -->
              <div class="data-header-actions-accounts" v-if="isTabContentVisible('users')">
                <el-tooltip content="Assign user" v-if="false">
                  <bly-button :type="'clear'"
                    v-if="acl.admin || acl.clientAdmin || acl.ca"
                    @click.native="openAssignUser()">
                      <i class="bi bly-user-add"></i>
                  </bly-button>
                </el-tooltip>
                <el-tooltip content="Get register URL" v-if="false">
                  <bly-button :type="'clear'"
                    @click.native="getRegisterUrl()">
                      <i class="bi bly-url"></i>
                  </bly-button>
                </el-tooltip>
              </div>

              <!-- FEATURES ACTIONS -->
              <div class="data-header-actions-accounts" v-if="isTabContentVisible('feature')">
                <el-tooltip content="Create account" >
                  <bly-button
                    :type="'clear'"
                    @click.native="createAccountDialog = true">
                    <i class="bi bly-plus"></i></bly-button>
                </el-tooltip>
                <bly-button :type="'clear'"><i class="bi bly-more bi-rotate-90"></i></bly-button>
              </div>
            </div>
          </div>
          <div class="data-content"
            v-loading="loading"
            element-loading-background="#13191d">
            <!-- ACCOUNT LIST -->
            <div class="account-list" v-if="isTabContentVisible('accounts')">
              <div class="data-list-item"
                v-for="item in clientAccounts"
                :key="item.id">
                  <router-link
                    tag="div"
                    :to="{
                      name: 'admin-client-account',
                      params: { id: client.id, accountId: item.id }
                    }"
                    class="data-list-item-name data-list-item-name-link">
                    <bly-status-tag
                      v-if="isAccountDefault(item)"
                      type="grey" size="small">Default</bly-status-tag>
                    {{item.name}}
                  </router-link>
                  <div class="data-list-item-actions">
                    <el-tooltip content="Get register URL" >
                      <bly-link-button :type="'clear'"
                        @click.native="getRegisterUrl(client.code, item.code)">
                          <i class="bi bly-url"></i>
                      </bly-link-button>
                    </el-tooltip>
                    <bly-link-button
                      :type="'clear'"
                      v-if="acl.admin || acl.clientAdmin || (aclEx.all[client.code][item.code]
                        && aclEx.all[client.code][item.code].aa)"
                      @click.native="openEditAccountDialog(item)">
                      <i class="bi bly-edit"></i>
                    </bly-link-button>
                    <el-dropdown trigger="click">
                      <bly-link-button :type="'clear'" class="el-dropdown-link">
                        <i class="bi bly-more bi-rotate-90"></i>
                      </bly-link-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          icon="bi bly-url"
                          @click.native="getRegisterUrl(client.code, item.code)"
                          v-if="acl.admin || acl.clientAdmin || (aclEx.all[client.code][item.code]
                            && aclEx.all[client.code][item.code].aa)">
                            Get register URL
                        </el-dropdown-item>
                        <el-dropdown-item
                          icon="bi bly-edit"
                          v-if="acl.admin || acl.clientAdmin || (aclEx.all[client.code][item.code]
                            && aclEx.all[client.code][item.code].aa)"
                          @click.native="openEditAccountDialog(item)">
                            Edit account details
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-show="!isAccountDefault(item)"
                          @click.native="deleteAccount(item)"
                           v-if="acl.admin || acl.clientAdmin || (aclEx.all[client.code][item.code]
                            && aclEx.all[client.code][item.code].aa)"
                          icon="bi bly-delete">
                            Delete
                          </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
              </div>
            </div>

            <!-- FEATURE LIST -->
            <div class="feature-list" v-if="isTabContentVisible('features')">
              <h4>Added Features</h4>
              <div class="data-list-item"
                v-for="item in clientFeatures"
                :key="item.id">
                  <div class="data-list-item-name">
                    <i class="bi" :class="'bly-'+item.feature.name"></i>
                    {{item.feature.name}}
                  </div>
                  <div class="data-list-item-actions">
                    <el-dropdown trigger="click">
                      <bly-link-button :type="'clear'" class="el-dropdown-link">
                        <i class="bi bly-more bi-rotate-90"></i>
                      </bly-link-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          @click.native="removeClientFeatureMap(item)"
                          icon="bi bly-delete">
                            Remove from client
                          </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
              </div>
              <h4>Avaiable Features</h4>
              <div class="data-list-item"
                v-for="item in notAssignedFeatures"
                :key="item.id">
                  <div class="data-list-item-name">
                    <i class="bi" :class="'bly-'+item.name"></i>
                    {{item.name}}
                  </div>
                  <div class="data-list-item-actions">
                    <bly-link-button :type="'clear'" @click.native="addClientFeatureMap(item)">
                      <i class="bi bly-plus"></i>
                    </bly-link-button>
                  </div>
              </div>
            </div>

            <!-- USER LIST -->
            <div class="user-list" v-if="isTabContentVisible('users')">
              <div class="data-list-item"
                v-for="item in clientUsers"
                :key="item.id">
                  <div class="data-list-item-name">
                    {{item.user.email}} <span class="muted">({{item.user.name}})</span>
                  </div>
                  <div class="data-list-item-actions">
                    <bly-status-tag
                      v-if="!item.user.isApproved"
                      type="red" size="small">INACTIVE</bly-status-tag>
                    <bly-status-tag
                      v-if="item.user.isApproved"
                      type="green" size="small">ACTIVE</bly-status-tag>
                    <el-tooltip content="Assign permissions and activate user"
                      v-if="!item.user.isApproved">
                      <bly-link-button :type="'clear'"
                        @click.native="openClaimEditor(item.user, true)">
                        <i class="bi bly-check"></i>
                      </bly-link-button>
                    </el-tooltip>
                    <el-tooltip content="Manage permissions" v-if="item.user.isApproved">
                      <bly-link-button :type="'copenClaimEditorlear'"
                        @click.native="openClaimEditor(item.user, false)">
                        <i class="bi bly-permissions"></i>
                      </bly-link-button>
                    </el-tooltip>
                    <el-dropdown trigger="click">
                      <bly-link-button :type="'clear'" class="el-dropdown-link">
                        <i class="bi bly-more bi-rotate-90"></i>
                      </bly-link-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          @click.native="deactivateUser(item.user)"
                          icon="bi bly-close">
                            Deactivate user
                          </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- EDIT CLIENT -->
      <el-dialog
        :title="'Editing client'"
        :visible.sync="editClientDialog"
        :close-on-click-modal="false"
        :width="dialogWidth">
        <div class="dialog-form">
          <el-form :model="clientForm" class="form-dark">
            <el-form-item required>
              <el-col :span="6">
                <el-form-item prop="code" label="Code" required >
                  <el-input v-model="clientForm.code" placeholder="Code" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1"> &nbsp; </el-col>
              <el-col :span="17">
                <el-form-item  prop="name" label="Name" required>
                  <el-input v-model="clientForm.name" placeholder="Company name"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item required>
              <el-col :span="12">
                <el-form-item  prop="email" label="Email">
                  <el-input v-model="clientForm.email" placeholder="Email address"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1"> &nbsp; </el-col>
              <el-col :span="11">
                <el-form-item  prop="phoneno" label="Phone">
                  <el-input v-model="clientForm.phoneno"  placeholder="Phone number"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="description" label="Description">
              <el-input v-model="clientForm.description" placeholder="Description"></el-input>
            </el-form-item>
            <el-form-item prop="address" label="Address">
              <el-input v-model="clientForm.address" placeholder="Client address"></el-input>
            </el-form-item>
            <el-form-item prop="website" label="Website URL">
              <el-input v-model="clientForm.website" placeholder="Enter website url"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editClientDialog = false">Cancel</el-button>
          <el-button type="primary" @click="saveClient()">Save</el-button>
        </span>
      </el-dialog>

      <!--
        ------------
        EDIT ACCOUNT
        ------------
      -->
      <el-dialog
        :title="'Editing  account'"
        :visible.sync="editAccountDialog"
        :close-on-click-modal="false"
        :width="dialogWidth">
        <div class="dialog-form">
          <el-form :model="accountForm" class="form-dark">
            <el-form-item required>
              <el-col :span="6">
                <el-form-item prop="code" label="Code" disabled>
                  <el-input v-model="accountForm.code" placeholder="Code" disabled maxlength="3" />
                </el-form-item>
              </el-col>
              <el-col :span="1"> &nbsp; </el-col>
              <el-col :span="17">
                <el-form-item  prop="name" label="Name" required>
                  <el-input v-model="accountForm.name" placeholder="Account name" />
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item required>
              <el-col :span="12">
                <el-form-item  prop="email" label="Email">
                  <el-input v-model="accountForm.email" placeholder="Email address" />
                </el-form-item>
              </el-col>
              <el-col :span="1"> &nbsp; </el-col>
              <el-col :span="11">
                <el-form-item  prop="phoneno" label="Phone">
                  <el-input v-model="accountForm.phoneno"  placeholder="Phone number" />
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="description" label="Description">
              <el-input v-model="accountForm.description" placeholder="Description" />
            </el-form-item>
            <el-form-item prop="address" label="Address">
              <el-input v-model="accountForm.address" placeholder="Address" />
            </el-form-item>
            <el-form-item prop="website" label="Website URL">
              <el-input v-model="accountForm.website" placeholder="Website" />
            </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editAccountDialog = false">Cancel</el-button>
          <el-button type="primary" @click="updateAcc()">Save</el-button>
        </span>
      </el-dialog>

      <!-- ADD ACCOUNT -->
      <el-dialog
        title="Add new accout"
        :visible.sync="createAccountDialog"
        :close-on-click-modal="false"
        :width="dialogWidth">
        <div class="dialog-form">
          <el-form :model="newAccountForm" class="form-dark">
            <el-form-item required>
              <el-col :span="6">
                <el-form-item prop="code" label="Code" required>
                  <el-input v-model="newAccountForm.code" placeholder="Code" maxlength="3" />
                </el-form-item>
              </el-col>
              <el-col :span="1"> &nbsp; </el-col>
              <el-col :span="17">
                <el-form-item  prop="name" label="Name" required>
                  <el-input v-model="newAccountForm.name" placeholder="Account name" />
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item required>
              <el-col :span="12">
                <el-form-item  prop="email" label="Email">
                  <el-input v-model="newAccountForm.email" placeholder="Email address" />
                </el-form-item>
              </el-col>
              <el-col :span="1"> &nbsp; </el-col>
              <el-col :span="11">
                <el-form-item  prop="phoneno" label="Phone">
                  <el-input v-model="newAccountForm.phoneno"  placeholder="Phone number" />
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="description" label="Description">
              <el-input v-model="newAccountForm.description" placeholder="Description" />
            </el-form-item>
            <el-form-item prop="address" label="Address">
              <el-input v-model="newAccountForm.address" placeholder="Address" />
            </el-form-item>
            <el-form-item prop="website" label="Website URL">
              <el-input v-model="newAccountForm.website" placeholder="Website" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="createAccountDialog = false">Cancel</el-button>
          <el-button type="primary" @click="saveAccount()">Save</el-button>
        </span>
      </el-dialog>

      <!--
        ----------------
        EDIT USER CLAIMS
        ----------------
      -->
      <el-dialog
        :title="'Editing permissions'"
        :visible.sync="editUserClaimsDialog"
        :close-on-click-modal="false"
        :width="dialogWidth">
        <div class="dialog-form" v-if="contextItem.user">
          <el-dropdown>
          <span class="el-dropdown-link">
            <el-button type="default" size="mini">
              Load presets<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(profile, index) in claimProfiles.items" :key="'cp' + index"
              @click.native="loadClaimPreset(profile.claims)">
              {{profile.name}}
            </el-dropdown-item>
            <el-dropdown-item
              @click.native="loadClaimPreset(fullAccessClaimPresets)">
              Full Access
            </el-dropdown-item>
          </el-dropdown-menu>
          </el-dropdown>
          <br><br>
          <div class="bly-group" v-for="(feature, id) in contextItem.features" :key="id">
            <el-divider content-position="left">
              <strong>{{feature.feature.name}}</strong>
            </el-divider>
            <div>
              <div
                class="bly-group-claim"
                v-for="(claim, claimId) in feature.claims"
                :key="id + claimId">
                <div
                  @click="
                  contextItem.claims[id+'.'+claimId].value =
                  !contextItem.claims[id+'.'+claimId].value
                  "
                  class="bly-group-claim-name link">
                    {{claim.name}}
                  <small>
                  {{claim.description}}
                  </small>
                </div>
                <el-switch
                :key="id + claimId + 's'"
                  v-model="contextItem.claims[id + '.' + claimId].value">
                </el-switch>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editUserClaimsDialog = false">Cancel</el-button>
          <el-button type="primary" @click="updateUserClaims()">Save</el-button>
        </span>
      </el-dialog>

      <!--
        ----------------
        ASSIGN USER TO CLIENT
        ----------------
      -->
      <el-dialog
        :title="'Assign users'"
        :visible.sync="assignUserDialog"
        :close-on-click-modal="false"
        :width="dialogWidth">
        <div class="dialog-form">
          <el-card
            shadow="hover"
            :body-style="{ padding: '8px' }"
            class="user-card"
            :class="{disabled: user.isUsed}"
            v-for="user in unassignedClientUsers" :key="user.id + user.email">
              <div class="bly-group-name"><strong>{{user.email}}</strong></div>
              <div class="actions">
                <el-button size="mini" type="primary"
                  :disabled="user.isUsed"
                  v-loading="loadingAssignUser"
                  @click.native="assignUserToClient(user)"
                >
                  Assign
                </el-button>
              </div>
          </el-card>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="assignUserDialog = false">Cancel</el-button>
          <el-button type="primary" @click="assignUserDialog = false">OK</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { isMobileOnly } from 'mobile-device-detect';

import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';

export default {
  props: ['id'],
  data() {
    return {
      error: null,
      isMobileOnly,
      activeContext: 'accounts',
      activeFeatureNames: null,
      clientForm: {
        code: '',
        name: '',
        description: '',
        email: '',
        phoneno: '',
        claimsCanAccess: [],
        claimsCanEdit: [],
      },

      featureForm: {
        code: '',
        name: '',
        description: '',
      },

      claimForm: {
        code: '',
        name: '',
        description: '',
        feature: [],
      },

      accountForm: {},

      newAccountForm: {},

      // dialogs
      editClientDialog: false,
      editAccountDialog: false,
      createFeatureDialog: false,
      createClaimDialog: false,
      createAccountDialog: false,
      editUserClaimsDialog: false,
      assignUserDialog: false,
      loadingAssignUser: false,
      contextItem: {},
      newClaims: {},
    };
  },

  computed: {
    ...mapState({
      userId: (state) => state.userAccount.userId,
      aclEx: (state) => state.userAccount.userPermissions,
      isAdmin: (state) => state.userAccount.isAdmin,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      features: (state) => state.admin.features,
      listFeatures: (state) => state.admin.listFeatures.items,
      clients: (state) => state.admin.clients,
      claimProfiles: (state) => state.admin.claimProfiles,
      client: (state) => state.admin.client,
      clientAccounts: (state) => state.admin.clientAccounts,
      account: (state) => state.admin.account,
      users: (state) => state.admin.users,
      listUsers: (state) => state.admin.listUsers,
      allClaims: (state) => state.admin.allClaims,
      acl: (state) => state.userAccount.aclCurrent,
      loading: (state) => state.admin.loading,
    }),

    dialogWidth() {
      return this.isMobileOnly ? '90%' : '420px';
    },

    initials() {
      if (!this.client.name) return '';
      return this.client.name.split(' ').map((n) => n[0]).join('');
    },

    tabClass() {
      return (tab) => {
        const isCurrent = tab === this.activeContext;
        return isCurrent ? 'active' : '';
      };
    },

    isAccountDefault() {
      return (account) => {
        const isDefault = account.code === this.client.code;
        return isDefault;
      };
    },

    isTabContentVisible() {
      return (tab) => {
        const isCurrent = tab === this.activeContext;
        return isCurrent;
      };
    },

    clientUsers() {
      const hasUsers = this.client.users && this.client.users.items;
      return hasUsers ? this.client.users.items : [];
    },

    unassignedClientUsers() {
      const hasUser = this.listUsers.length > 0;
      if (!hasUser) return [];
      return this.listUsers.map((user) => {
        const [isUsed] = this.clientUsers.filter((clientUser) => user.id === clientUser.user.id);
        const newUser = {
          ...user,
          isUsed: !!isUsed,
        };
        return newUser;
      });
    },

    clientFeatures() {
      const hasFeatures = this.client.features && this.client.features.items;
      return hasFeatures ? this.client.features.items : [];
    },

    clientFeaturesMap() {
      const clientFeatures = {};
      this.client.features.items.forEach((item) => {
        clientFeatures[item.feature.code] = this.features[item.feature.code];
      });
      return clientFeatures;
    },

    defaultAccount() {
      const account = this.clientAccounts.filter((item) => {
        const isDefault = item.code === this.client.code;
        return isDefault ? item : null;
      })[0];
      return account;
    },

    notAssignedFeatures() {
      return this.listFeatures.filter((feature) => {
        const isAssigned = !!_.find(this.clientFeatures, ['feature.id', feature.code]);
        return !isAssigned;
      });
    },

    assignedFeatures() {
      return this.listFeatures.filter((feature) => {
        const isAssigned = !!_.find(this.clientFeatures, ['feature.id', feature.code]);
        return isAssigned;
      });
    },

    fullAccessClaimPresets() {
      const fullAccess = [];
      this.assignedFeatures.forEach((feature) => {
        feature.claims.items.forEach((claim) => {
          fullAccess.push(`${feature.id}.${claim.id}`);
        });
      });

      return fullAccess;
    },
  },

  watch: {
    id(newVal) {
      this.setCurrentClientId(newVal);
      this.getClientDetails(newVal);
      this.setAccountId(null);
    },
  },

  mounted() {
    this.setCurrentClientId(this.id);
    this.getClientDetails(this.id);
    if (this.isSysAdmin) this.fetchUserList();
  },

  created() {
    this.listClaimsProfiles();
  },

  methods: {
    ...mapActions({
      updateClient: 'admin/updateClient',
      updateAccount: 'admin/updateAccount',
      deleteAccount: 'admin/deleteAccount',
      createAccount: 'admin/createAccount',
      updateUser: 'admin/updateUser',
      fetchClientDetails: 'admin/fetchClientDetails',
      deleteClientFeatureMap: 'admin/deleteClientFeatureMap',
      mapFeatureToClient: 'admin/mapFeatureToClient',
      createUserClaimMap: 'admin/createUserClaimMap',
      createUserClientMap: 'admin/createUserClientMap',
      listClaimsProfiles: 'admin/listClaimsProfiles',
      fetchUserList: 'admin/fetchUserList',
    }),
    ...mapMutations({
      setCurrentClientId: 'admin/setCurrentClientId',
      setAccountId: 'admin/setAccountId',
      setUserClient: 'userAccount/setClient',
    }),

    changeContext(context) {
      this.activeContext = context;
    },

    async getClientDetails(id) {
      const result = await this.fetchClientDetails(id);
      if (result.error) return;
      this.setUserClient(result);
    },

    getRegisterUrl(clientCode, accountCode) {
      const client = clientCode || this.client.code;
      const doesAccountCodeExist = !!this.account;
      let account = client;
      if (doesAccountCodeExist) account = this.account.code;
      if (accountCode) account = accountCode;

      this.$alert(`${window.location.origin}/#/register/${client}:${account}`, 'Copy url below', {
        confirmButtonText: 'OK',
        type: 'info',
        center: true,
      });
    },

    loadClaimPreset(presets) {
      Object.keys(this.contextItem.claims).forEach((key) => {
        this.contextItem.claims[key].value = false;
      });
      presets.forEach((preset) => {
        this.contextItem.claims[preset].value = true;
      });
    },

    async saveClient() {
      const newClient = {
        id: this.client.id,
        name: this.clientForm.name,
        description: this.clientForm.description,
        email: this.clientForm.email,
        phoneno: this.clientForm.phoneno,
        expectedVersion: this.client.version,
        website: this.clientForm.website,
        address: this.clientForm.address,
      };

      await this.updateClient(newClient);
      this.editClientDialog = false;
    },

    async saveAccount() {
      const accountId = this.newAccountForm.code;
      const clientId = this.client.id;
      const newAccount = {
        ...this.newAccountForm,
        id: accountId.toLowerCase(),
        code: accountId.toLowerCase(),
        claimsCanAccess: [`usr.${accountId}.${clientId}`, `ca.${clientId}.${clientId}`, `ec.${clientId}.${clientId}`],
        claimsCanEdit: [`ca.${clientId}.${clientId}`, `aa.${accountId}.${clientId}`],
        accountClientId: this.client.id,
        accountLastUpdatedById: this.userId,
      };

      await this.createAccount(newAccount);
      this.createAccountDialog = false;
    },

    async updateAcc() {
      await this.updateAccount(this.accountForm);
      this.editAccountDialog = false;
    },

    async updateUserClaims() {
      let singleMutations = _.map(this.contextItem.claims, (claimInfo) => {
        // detect changes
        const hasChanged = claimInfo.value !== claimInfo.defaultValue;
        const toRemove = hasChanged && !!claimInfo.claimMapId;
        const toAdd = hasChanged && !claimInfo.claimMapId;

        // prepere data
        const accountId = this.defaultAccount.id;
        const clientId = this.client.id;
        const userId = this.contextItem.user.id;
        const { claimId } = claimInfo;
        // const { featureId } = claimInfo;
        const { claimMapId } = claimInfo;

        const createUserClaimMapInput = {
          userClaimMapClaimId: claimId,
          userClaimMapUserId: userId,
          userClaimMapLastUpdatedById: this.userId,
          claimsCanAccess: [`usr.${accountId}.${clientId}`],
          claimsCanEdit: [`ca.${accountId}.${clientId}`, `aa.${accountId}.${clientId}`],
        };
        const deleteUserClaimMapInput = {
          id: claimMapId,
        };

        let input = null;
        if (toAdd) input = createUserClaimMapInput;

        if (toRemove) input = deleteUserClaimMapInput;

        return {
          input,
          toRemove,
          toAdd,
        };
      });
      singleMutations = _.filter(singleMutations, (item) => !!item.input);

      await this.createUserClaimMap(singleMutations);

      if (this.contextItem.shouldApprove) {
        this.activateUser(this.contextItem.user);
      }
      Vue.set(this, 'contextItem', { user: {} });
      this.editUserClaimsDialog = false;
    },

    async deactivateUser(user) {
      const newUser = {
        id: user.id,
        name: user.name,
        email: user.email,
        isApproved: false,
        expectedVersion: user.version,
        claimsCanEdit: user.claimsCanEdit,
        claimsCanAccess: user.claimsCanAccess,
      };
      const updatedUser = await this.updateUser(newUser);
      user.isApproved = false;
      user.version = updatedUser.version;
    },

    async activateUser(user) {
      const newUser = {
        id: user.id,
        name: user.name,
        email: user.email,
        isApproved: true,
        expectedVersion: user.version,
        claimsCanEdit: user.claimsCanEdit,
        claimsCanAccess: user.claimsCanAccess,
      };
      const updatedUser = await this.updateUser(newUser);
      user.isApproved = true;
      user.version = updatedUser.version;
    },

    openClaimEditor(user, shouldApprove) {
      this.context = user;

      Vue.set(this, 'contextItem', {});
      Vue.set(this.contextItem, 'claims', JSON.parse(JSON.stringify(this.allClaims)));
      const userFeatures = { ...this.clientFeaturesMap };
      user.claims.items.forEach((claim) => {
        const featureCode = claim.claim.feature.code;
        const claimCode = claim.claim.code;
        if (claim.id) {
          this.contextItem.claims[`${featureCode}.${claimCode}`].defaultValue = true;
          this.contextItem.claims[`${featureCode}.${claimCode}`].value = true;
          this.contextItem.claims[`${featureCode}.${claimCode}`].claimMapId = claim.id;
        }
      });

      Vue.set(this.contextItem, 'user', user);
      Vue.set(this.contextItem, 'shouldApprove', shouldApprove);
      Vue.set(this.contextItem, 'features', userFeatures);
      // this.newClaims
      // this.features.
      this.editUserClaimsDialog = true;
    },

    openAssignUser() {
      this.assignUserDialog = true;
    },

    async assignUserToClient(user) {
      this.loadingAssignUser = true;
      const clientId = this.client.id;
      const accountId = this.defaultAccount.id;
      const newMap = {
        userClientMapUserId: user.id,
        userClientMapClientId: clientId,
        claimsCanAccess: [`usr.${accountId}.${clientId}`, `usr.${clientId}`],
        claimsCanEdit: [`ca.${accountId}.${clientId}`, `aa.${accountId}.${clientId}`],
        userClientMapLastUpdatedById: this.userId,
      };

      await this.createUserClientMap(newMap);
      this.loadingAssignUser = false;
    },

    openEditAccountDialog(account) {
      this.accountForm = {
        id: account.id,
        code: account.code,
        name: account.name,
        description: account.description,
        email: account.email,
        phoneno: account.phoneno,
        expectedVersion: account.version,
        website: account.website,
        address: account.address,
        claimsCanAccess: account.claimsCanAccess,
        claimsCanEdit: account.claimsCanAccess,
      };
      this.editAccountDialog = true;
    },

    openEditClientDialog(client) {
      this.clientForm = {
        id: client.id,
        code: client.code,
        name: client.name,
        description: client.description,
        email: client.email,
        phoneno: client.phoneno,
        expectedVersion: client.version,
        website: client.website,
        address: client.address,
        claimsCanAccess: client.claimsCanAccess,
        claimsCanEdit: client.claimsCanEdit,
      };
      this.editClientDialog = true;
    },

    async addClientFeatureMap(feature) {
      const clientId = this.client.id;
      const featureId = feature.id;
      const accountId = this.defaultAccount.id;
      const createClientFeatureMapInput = {
        clientFeatureMapClientId: clientId,
        clientFeatureMapFeatureId: featureId,
        claimsCanAccess: [`usr.${accountId}.${clientId}`, `usr.${clientId}`],
        claimsCanEdit: [`ca.${accountId}.${clientId}`, `aa.${accountId}.${clientId}`],
        clientFeatureMapLastUpdatedById: this.userId,
      };

      const result = await this.mapFeatureToClient(createClientFeatureMapInput);

      this.displayResult(result, 'Can\'t add feature', 'Feature added');
      return null;
    },

    async removeClientFeatureMap(featureMap) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${featureMap.feature.name}</b> from  <b>${this.client.name}</b>?`);
      if (!isConfirm) return false;
      const result = await this.deleteClientFeatureMap(featureMap.id);
      this.displayResult(result, 'Can\'t remove feature', 'Feature removed');
      return null;
    },

    async showPrompt(confirmationMessage) {
      const confirmation = await this.$confirm(confirmationMessage, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        center: true,
        dangerouslyUseHTMLString: true,
      });

      const isConfirmed = confirmation === 'confirm';
      return isConfirmed;
    },

    displayResult(result, errorMessage, successMessage) {
      if (result.error) {
        this.$message({
          showClose: true,
          message: errorMessage,
          type: 'error',
        });
        return;
      }

      this.$message({
        showClose: true,
        message: successMessage,
        type: 'info',
      });
    },
  },
};
</script>

<style>


.el-loading-spinner svg {
  margin: 0 auto;
}

#admin-client {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding-top: 0;
}
#admin-client .user-card {
  margin-bottom: 8px;
}
#admin-client .user-card .actions {
  position: absolute;
  top: 4px;
  right: 3px;
}
#admin-client .user-card.disabled {
  pointer-events: none;
}
#admin-client .bly-group {
  margin-bottom: 30px;
}
#admin-client .bly-group .el-divider--horizontal {
  margin: 24px 0 16px 0;
}
#admin-client .bly-group .el-divider__text.is-left {
  margin-bottom: 5px;
  color: #000;
}
#admin-client .bly-group-claim {
  display: grid;
  grid-template-columns: auto 40px;
  grid-gap: 24px;
  margin-bottom: 16px;
  align-items: center;
}
#admin-client .bly-group-claim-name {
  color: #000;
  font-weight: 400;
}
#admin-client .bly-group-claim-name small {
  display: block;
  opacity: 0.6;
}
</style>
