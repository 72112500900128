<template>
  <div>
    <h3>Booking Lifecycle</h3>
    <img src="../../assets/schedule-status-legend.svg" alt="" >
    <div v-if="false">
      <div class="status-legend"
        v-for="(status, key) in scheduleUIConfig.eventColorsSimple" :key="key">
        <span class="color"
          :style="{background: status}"></span>
        <span class="name">{{key.replace('_', ' ')}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ScheduleHelp',

  data() {
    return {

    };
  },
};
</script>
