a<template>
  <div id="admin-default">
    Select Client from the list
  </div>
</template>

<script>
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

export default {
  data() {
    return {
      queries,
      mutations,
    };
  },

  computed: {
  },

  created() {
  },

  methods: {

  },
};
</script>

<style>

</style>
