<template>
  <div id="router" class="router flex border-t-[1px] border-gray-950 ml-4">
    <div class="flex-1">
      <bly-mv-box :mvId="'a4bd858e-69e9-4c89-aa03-6e3462591626'" :sourceId="'2934a2af-f16c-488d-a746-8483b13fb6ac'"></bly-mv-box>
    </div>

    <div class="container-with-sidebar flex-1 border-l-[1px] border-gray-950">
      <div class="sidebar"></div>
      <div>
        <splitpanes class="default-theme"
          :push-other-panes="false" :horizontal="isMobileOnly ? false : true">
          <pane min-size="20" max-size="80">
            <div class="pane-wrapper">
              <div class="pane-title blue-light">
                <i class="bi bly-output"></i>
                Sources
                <span class="loading" v-show="loadings.sources">
                  <i class="bi bly-loading red bi-is-spinning"></i>
                </span>
                <div class="title-actions">
                  <el-select
                    size="small"
                    placeholder="Select group"
                    clearable
                    @change="sourceGroupChanged"
                    v-model="selectedSourceGroup" value-key="id">
                    <el-option
                      v-for="group in sourceGroups"
                      :key="group.id"
                      :label="group.name"
                      :value="group">
                    </el-option>
                  </el-select>
                  <el-divider direction="vertical" v-if="!isMobileOnly"></el-divider>
                  <div class="search" v-if="!isMobileOnly">
                    <el-input
                      placeholder="Search sources..."
                      size="small"
                      clearable
                      @change="searchSourceChanged"
                      v-model="searchSource">
                      <i slot="suffix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </div>
                </div>
              </div>
              <div v-bar>
                <div class="pane-bar-wrapper sources">
                  <div class="item-group">
                    <!--<el-divider content-position="left">{{key}}</el-divider>-->
                    <div class="item-wrapper"
                      v-for="(source, index) in filteredSources"
                      :key="source.id">
                      <div class="item"
                        @click="routSource(source, selectedDestination)"
                        :class="{
                          active: selectedSource === source,
                          connected: activeDestinationSource.id === source.id,
                          disabled: !activeDestinationSource
                            && (activeDestinationSource.originRouterId
                            !== source.originRouterId),
                          'show-take': showTakeId === source.id,
                          'show-loading': loadings.source === source.id,
                        }">
                        <div class="changing-route">
                          <i class="bi bly-loading red bi-is-spinning"></i>
                        </div>
                        <div class="take-action"
                          @click="sendRouteCommand(source, selectedDestination)">
                          TAKE
                        </div>
                        <div class="item-avatar"
                          :title="source.originRouterId">
                          <el-avatar shape="square" size="medium"
                          :style="{ background: originRouterColor(source.originRouterId),}"
                          >{{index + 1}}</el-avatar>
                        </div>
                        <div>
                          <div class="item-name">
                            {{source.name}}
                          </div>
                          <div class="item-desc">
                            <div v-if="source.routedDestinations.items">
                              <div
                                class="not-connected"
                                v-if="source.routedDestinations.items.length === 0">
                                not routed
                              </div>
                            </div>
                            <div v-if="source.routedDestinations.items.length > 0">
                              <el-tooltip>
                                <div slot="content">
                                  <div
                                    class="routed"
                                    v-for="d in source.routedDestinations.items"
                                    :key="d.id"
                                    >{{d.name}}</div>
                                </div>
                                <div>
                                  <span class="routed">
                                    {{source.routedDestinations.items[0].name}}
                                  </span>
                                  <span
                                    class="routed"
                                    v-show="source.routedDestinations.items.length > 0"
                                    >... ({{source.routedDestinations.items.length}})
                                  </span>
                                </div>
                              </el-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pane>
          <pane :min-size="paneMinimalSize" :size="paneMinimalSize" class="pane-dest">
            <div class="pane-wrapper">
              <div class="pane-title green">
                <i class="bi bly-input"></i>
                Destinations
                <span class="loading" v-show="loadings.destinations">
                  <i class="bi bly-loading red bi-is-spinning"></i>
                </span>
                <div class="title-actions">
                  <el-select
                    size="small"
                    placeholder="Select group"
                    clearable
                    @change="destGroupChanged"
                    v-model="selectedDestGroup" value-key="id">
                    <el-option
                      v-for="group in destinationGroups"
                      :key="group.id"
                      :label="group.name"
                      :value="group">
                    </el-option>
                  </el-select>
                  <el-divider direction="vertical" v-if="!isMobileOnly"></el-divider>
                  <div class="search" v-if="!isMobileOnly">
                    <el-input
                      placeholder="Search destination..."
                      size="small"
                      clearable
                      @change="searchDestinationChanged"
                      v-model="searchDestination">
                      <i slot="suffix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </div>
                </div>
              </div>
              <div v-bar>
                <div class="pane-bar-wrapper destinations">
                  <div class="item-group">
                    <!--<el-divider content-position="left">{{key}}</el-divider>-->
                    <div class="item-wrapper"
                      v-for="(dest, index) in filteredDestinations"
                      :key="dest.id">
                      <div class="item destination"
                        @click="toggleDest(dest)"
                        :class="{
                          active: selectedDestinationId === dest.id,
                          connected: activeSourceDestinations.indexOf(dest.id) !== -1,
                          disabled: !activeSourceDestinations
                            && (activeSourceDestinations.originRouterId
                            !== dest.originRouterId),
                        }"
                        >
                        <div class="item-avatar"
                          :title="dest.originRouterId"
                          >
                          <el-avatar shape="square" size="medium"
                          :style="{ background: originRouterColor(dest.originRouterId),}">{{index + 1}}</el-avatar>
                          
                        </div>
                        <div>
                          <div class="item-name">
                            {{dest.name}}
                          </div>
                          <div class="item-desc">
                            <span v-if="dest.routedSource">{{dest.routedSource.name}}</span>
                            <!-- <span class="text-red-500 font-bold" v-if="!dest.routedSource">ERROR</span> -->
                          </div>
                        </div>
                        <div class="item-actions"
                          v-show="false" v-if="isAdmin || acl.ro">
                          <el-tooltip
                            v-if="selectedSource
                              && activeSourceDestinations.indexOf(dest.id) === -1">
                            <div slot="content">
                              Route <strong class="blue-light">{{selectedSource.name}}</strong> here
                            </div>
                            <i class="bi bly-route icon-btn blue-light"
                              @click="sendRouteCommand(selectedSource, dest)"></i>
                          </el-tooltip>
                          <el-tooltip
                            v-show="false"
                            v-if="selectedSource
                              && activeSourceDestinations.indexOf(dest.id) !== -1">
                            <div slot="content">
                              Unassign <strong class="blue-light"> {{selectedSource.name}}</strong>
                            </div>
                            <i class="bi bly-close icon-btn red"
                              @click="sendRouteCommand(selectedSource, dest)"></i>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pane>
        </splitpanes>
      </div>
    </div>
    <!-- -------------------------------------------------------------------------------------- -->
    <!-- DILOGS      -------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- NAV OPTIONS -------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->
    <div class="config mv-config">
      <!--<el-checkbox v-model="useTake"
        border
        label="Use take">
      </el-checkbox>
      -->
      <el-tooltip content="Help" placement="right">
        <el-popover
          placement="top-start"
          width="300"
          trigger="hover">
          <el-alert :closable="false">
            Current state refreshes every 15 seconds
          </el-alert>
          <h3><strong>Keybindings</strong></h3>
          <div>
            <div>
              Hold <el-tag size="small">SHIFT</el-tag> to disable scrolling.
            </div>
          </div>
          <i slot="reference" class="bi bly-question icon-btn"></i>
        </el-popover>
      </el-tooltip>
      <!--
      <el-select v-model="seletedRouterGroup" value-key="id" v-if="!isMobileOnly">
        <el-option
          v-for="group in routerGroups.items"
          :key="group.id"
          :label="group.name"
          :value="group">
        </el-option>
      </el-select>
      -->
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import _ from 'lodash';
import { Splitpanes, Pane } from 'splitpanes';
import ColorGenerator from '@/services/ColorGenerator';
import 'splitpanes/dist/splitpanes.css';

export default {
  name: 'router',
  components: { Splitpanes, Pane },
  data() {
    return {
      isMobileOnly,
      activeSource: null,
      loadingInfo: null,
      loadingRoute: null,
      hoveredDest: null,
      hoveredSource: null,
      hoverDest: null,
      useTake: true,
      showTakeId: null,

      seletedRouterGroup: null,
      selectedDestGroup: null,
      selectedSourceGroup: null,

      // routing
      selectedSource: null,
      selectedDestination: null,

      // dialogs
      createRouterDialog: false,
      createRouterSourceDialog: false,
      createRouterDestinationDialog: false,
      createRouterGroupDialog: false,

      // forms
      routerForm: {},
      routerGroupForm: {},
      routerSourceForm: {},
      routerDestinationForm: {},

      keys: {},
      searchDestination: '',
      searchSource: '',
      plp: {
        "id": "b15400dd-121f-4ff3-9498-4cd5da56ad0b",
        "name": "Interview Lines",
        "claimsCanAccess": [
          "usr.ma"
        ],
        "claimsCanEdit": [
          "usr.ma"
        ],
        "version": 1,
        "router": {
          "id": "sto",
          "name": "STO01",
          "description": "Stockholm IP router",
          "claimsCanAccess": [
            "usr.ma",
            "usr.plp.plp"
          ],
          "claimsCanEdit": [
            "usr.ma"
          ],
          "version": 4
        },
        "sources": {
          "items": [
            {
              "id": "40453a19-3ddb-4088-9547-ffb02fe6c169",
              "routerSource": {
                "id": "source-ksd-Sites/IMG/Interview_Lines-105_Delayed",
                "name": "IMG 105 D"
              }
            },
            {
              "id": "1365e4ba-f16a-4db9-96cb-3a8a8b33e5e1",
              "routerSource": {
                "id": "source-ksd-Sites/IMG/Interview_Lines-107_Delayed",
                "name": "IMG 107 D"
              }
            },
            {
              "id": "7b92066b-ccdf-4868-8013-05768929ca70",
              "routerSource": {
                "id": "source-ksd-IMG-IMG 107",
                "name": "IMG 107"
              }
            },
            {
              "id": "ffd46227-8cf4-40c6-9f96-922f2ea75072",
              "routerSource": {
                "id": "source-ksd-IMG-IMG 105",
                "name": "IMG 105"
              }
            },
            {
              "id": "644b88fa-1857-4d05-9087-1e69c280e0b1",
              "routerSource": {
                "id": "source-ksd-Sites/IMG/Interview_Lines-103_Delayed",
                "name": "IMG 103 D"
              }
            },
            {
              "id": "f91dae9d-8073-4353-a0b9-96f40e50aafa",
              "routerSource": {
                "id": "source-ksd-IMG-IMG 109",
                "name": "IMG 109"
              }
            },
            {
              "id": "3132d1de-7ae9-46e9-ba7a-1809c9068d42",
              "routerSource": {
                "id": "source-ksd-Sites/IMG/Interview_Lines-109_Delayed",
                "name": "IMG 109 D"
              }
            },
            {
              "id": "7e932e88-badb-4ff1-bfb1-e3710a6ac2cf",
              "routerSource": {
                "id": "source-ksd-IMG-IMG 103",
                "name": "IMG 103"
              }
            },
            {
              "id": "9ce2e835-f41f-4e70-9d96-c65f6af8f31b",
              "routerSource": {
                "id": "source-ksd-Sites/IMG/Interview_Lines-101_Delayed",
                "name": "IMG 101 D"
              }
            },
            {
              "id": "123553c2-e185-4210-b353-28ab1ea521e2",
              "routerSource": {
                "id": "source-ksd-IMG-IMG 101",
                "name": "IMG 101"
              }
            }
          ],
          "nextToken": null
        },
        "destinations": {
          "items": [],
          "nextToken": null
        }
      },
      plpSources: [
        {
            "id": "source-ksd-IMG-IMG 101",
            "name": "IMG 101",
            "description": "IMG 101",
            "originRouterGroup": "Sites/IMG",
            "originRouterSourceId": "IMG 101",
            "originRouterId": "ksd",
            "claimsCanAccess": [
                "usr.ma.ma",
                "usr.plp.plp"
            ],
            "claimsCanEdit": [
                "usr.ma.ma"
            ],
            "version": 5,
            "lastUpdatedBy": {
                "id": "system"
            },
            "routerGroups": {
                "items": [
                    {
                        "id": "dd1ff557-6230-4356-a8a3-8cac16774a7b",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG-sto",
                            "name": "Sites/IMG"
                        }
                    },
                    {
                        "id": "45d037b5-bf6a-4e92-acb9-d1b87417196c",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG/Interview_Lines-sto",
                            "name": "Sites/IMG/Interview_Lines"
                        }
                    },
                    {
                        "id": "123553c2-e185-4210-b353-28ab1ea521e2",
                        "routerGroup": {
                            "id": "b15400dd-121f-4ff3-9498-4cd5da56ad0b",
                            "name": "Interview Lines"
                        }
                    }
                ],
                "nextToken": null
            },
            "mappedInputs": {
                "items": [
                    {
                        "id": "dddefc11-3cda-4028-96d4-67b4cdcf22b4",
                        "input": {
                            "url": null,
                            "id": "img101",
                            "name": "MOS-IMG-ENC101",
                            "status": null,
                            "requiresPidInfo": null,
                            "frcAvailable": false
                        }
                    },
                    {
                        "id": "1f4d18d0-f36b-45f2-a049-7aaa6ee6edd0",
                        "input": {
                            "url": null,
                            "id": "img101",
                            "name": "MOS-IMG-ENC101",
                            "status": null,
                            "requiresPidInfo": null,
                            "frcAvailable": false
                        }
                    }
                ]
            },
            "routedDestinations": {
                "items": [
                    
                ],
                "nextToken": null
            }
        },
        {
            "id": "source-ksd-Sites/IMG/Interview_Lines-101_Delayed",
            "name": "IMG 101 D",
            "description": "101_Delayed",
            "originRouterGroup": "Sites/IMG/Interview_Lines",
            "originRouterSourceId": "101_Delayed",
            "originRouterId": "ksd",
            "claimsCanAccess": [
                "usr.ma.ma",
                "usr.plp.plp"
            ],
            "claimsCanEdit": [
                "usr.ma.ma"
            ],
            "version": 3,
            "lastUpdatedBy": {
                "id": "system"
            },
            "routerGroups": {
                "items": [
                    {
                        "id": "9ce2e835-f41f-4e70-9d96-c65f6af8f31b",
                        "routerGroup": {
                            "id": "b15400dd-121f-4ff3-9498-4cd5da56ad0b",
                            "name": "Interview Lines"
                        }
                    },
                    {
                        "id": "9d262b16-4595-4d84-8898-2307beb1e388",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG/Interview_Lines-sto",
                            "name": "Sites/IMG/Interview_Lines"
                        }
                    }
                ],
                "nextToken": null
            },
            "mappedInputs": {
                "items": []
            },
            "routedDestinations": {
                "items": [],
                "nextToken": null
            }
        },
        {
            "id": "source-ksd-IMG-IMG 103",
            "name": "IMG 103",
            "description": "IMG 103",
            "originRouterGroup": "Sites/IMG",
            "originRouterSourceId": "IMG 103",
            "originRouterId": "ksd",
            "claimsCanAccess": [
                "usr.ma.ma",
                "usr.plp.plp"
            ],
            "claimsCanEdit": [
                "usr.ma.ma"
            ],
            "version": 4,
            "lastUpdatedBy": {
                "id": "system"
            },
            "routerGroups": {
                "items": [
                    {
                        "id": "6d3ccf1e-89eb-466b-824a-d35174a29a1f",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG-sto",
                            "name": "Sites/IMG"
                        }
                    },
                    {
                        "id": "7e932e88-badb-4ff1-bfb1-e3710a6ac2cf",
                        "routerGroup": {
                            "id": "b15400dd-121f-4ff3-9498-4cd5da56ad0b",
                            "name": "Interview Lines"
                        }
                    },
                    {
                        "id": "7db39ecd-1942-4f6b-b1f7-e730c4b5a6b7",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG/Interview_Lines-sto",
                            "name": "Sites/IMG/Interview_Lines"
                        }
                    }
                ],
                "nextToken": null
            },
            "mappedInputs": {
                "items": [
                    {
                        "id": "e20ca0af-8d99-4088-8125-7ff55fb57af0",
                        "input": {
                            "url": null,
                            "id": "img103",
                            "name": "MOS-IMG-ENC103",
                            "status": null,
                            "requiresPidInfo": null,
                            "frcAvailable": false
                        }
                    },
                    {
                        "id": "5ad08b2a-d8c6-4784-b461-566fe3b61778",
                        "input": {
                            "url": null,
                            "id": "img103",
                            "name": "MOS-IMG-ENC103",
                            "status": null,
                            "requiresPidInfo": null,
                            "frcAvailable": false
                        }
                    }
                ]
            },
            "routedDestinations": {
                "items": [
                    
                ],
                "nextToken": null
            }
        },
        {
            "id": "source-ksd-Sites/IMG/Interview_Lines-103_Delayed",
            "name": "IMG 103 D",
            "description": "103_Delayed",
            "originRouterGroup": "Sites/IMG/Interview_Lines",
            "originRouterSourceId": "103_Delayed",
            "originRouterId": "ksd",
            "claimsCanAccess": [
                "usr.ma.ma",
                "usr.plp.plp"
            ],
            "claimsCanEdit": [
                "usr.ma.ma"
            ],
            "version": 3,
            "lastUpdatedBy": {
                "id": "system"
            },
            "routerGroups": {
                "items": [
                    {
                        "id": "644b88fa-1857-4d05-9087-1e69c280e0b1",
                        "routerGroup": {
                            "id": "b15400dd-121f-4ff3-9498-4cd5da56ad0b",
                            "name": "Interview Lines"
                        }
                    },
                    {
                        "id": "e2317ca9-0bd2-4527-a00e-3bd198a5f4cb",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG/Interview_Lines-sto",
                            "name": "Sites/IMG/Interview_Lines"
                        }
                    }
                ],
                "nextToken": null
            },
            "mappedInputs": {
                "items": []
            },
            "routedDestinations": {
                "items": [],
                "nextToken": null
            }
        },
        {
            "id": "source-ksd-IMG-IMG 105",
            "name": "IMG 105",
            "description": "IMG 105",
            "originRouterGroup": "Sites/IMG",
            "originRouterSourceId": "IMG 105",
            "originRouterId": "ksd",
            "claimsCanAccess": [
                "usr.ma.ma",
                "usr.plp.plp"
            ],
            "claimsCanEdit": [
                "usr.ma.ma"
            ],
            "version": 4,
            "lastUpdatedBy": {
                "id": "system"
            },
            "routerGroups": {
                "items": [
                    {
                        "id": "ffd46227-8cf4-40c6-9f96-922f2ea75072",
                        "routerGroup": {
                            "id": "b15400dd-121f-4ff3-9498-4cd5da56ad0b",
                            "name": "Interview Lines"
                        }
                    },
                    {
                        "id": "0ad898df-2986-4e4f-adeb-0158c3cdea2e",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG/Interview_Lines-sto",
                            "name": "Sites/IMG/Interview_Lines"
                        }
                    },
                    {
                        "id": "813879b4-a478-43d3-bf23-09cc1247f9a9",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG-sto",
                            "name": "Sites/IMG"
                        }
                    }
                ],
                "nextToken": null
            },
            "mappedInputs": {
                "items": [
                    {
                        "id": "d4117f33-a7ef-43c0-a774-8be4f91a02e2",
                        "input": {
                            "url": null,
                            "id": "img105",
                            "name": "MOS-IMG-ENC105",
                            "status": null,
                            "requiresPidInfo": null,
                            "frcAvailable": false
                        }
                    }
                ]
            },
            "routedDestinations": {
                "items": [
                    
                ],
                "nextToken": null
            }
        },
        {
            "id": "source-ksd-Sites/IMG/Interview_Lines-105_Delayed",
            "name": "IMG 105 D",
            "description": "105_Delayed",
            "originRouterGroup": "Sites/IMG/Interview_Lines",
            "originRouterSourceId": "105_Delayed",
            "originRouterId": "ksd",
            "claimsCanAccess": [
                "usr.ma.ma",
                "usr.plp.plp"
            ],
            "claimsCanEdit": [
                "usr.ma.ma"
            ],
            "version": 3,
            "lastUpdatedBy": {
                "id": "system"
            },
            "routerGroups": {
                "items": [
                    {
                        "id": "40453a19-3ddb-4088-9547-ffb02fe6c169",
                        "routerGroup": {
                            "id": "b15400dd-121f-4ff3-9498-4cd5da56ad0b",
                            "name": "Interview Lines"
                        }
                    },
                    {
                        "id": "e1c54cac-f375-4596-8122-e20ffb31459c",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG/Interview_Lines-sto",
                            "name": "Sites/IMG/Interview_Lines"
                        }
                    }
                ],
                "nextToken": null
            },
            "mappedInputs": {
                "items": []
            },
            "routedDestinations": {
                "items": [],
                "nextToken": null
            }
        },
        {
            "id": "source-ksd-IMG-IMG 107",
            "name": "IMG 107",
            "description": "IMG 107",
            "originRouterGroup": "Sites/IMG",
            "originRouterSourceId": "IMG 107",
            "originRouterId": "ksd",
            "claimsCanAccess": [
                "usr.ma.ma",
                "usr.plp.plp"
            ],
            "claimsCanEdit": [
                "usr.ma.ma"
            ],
            "version": 4,
            "lastUpdatedBy": {
                "id": "system"
            },
            "routerGroups": {
                "items": [
                    {
                        "id": "7b92066b-ccdf-4868-8013-05768929ca70",
                        "routerGroup": {
                            "id": "b15400dd-121f-4ff3-9498-4cd5da56ad0b",
                            "name": "Interview Lines"
                        }
                    },
                    {
                        "id": "3dfd9419-8ab2-4979-b9f8-df6ff513b866",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG/Interview_Lines-sto",
                            "name": "Sites/IMG/Interview_Lines"
                        }
                    },
                    {
                        "id": "61236cc9-4c1a-4ea6-b12b-79461f1ab634",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG-sto",
                            "name": "Sites/IMG"
                        }
                    }
                ],
                "nextToken": null
            },
            "mappedInputs": {
                "items": [
                    {
                        "id": "749808a3-bcbe-4e86-ae51-5d5276ce6944",
                        "input": {
                            "url": null,
                            "id": "img107",
                            "name": "MOS-IMG-ENC107",
                            "status": null,
                            "requiresPidInfo": null,
                            "frcAvailable": null
                        }
                    }
                ]
            },
            "routedDestinations": {
                "items": [
                    
                ],
                "nextToken": null
            }
        },
        {
            "id": "source-ksd-Sites/IMG/Interview_Lines-107_Delayed",
            "name": "IMG 107 D",
            "description": "107_Delayed",
            "originRouterGroup": "Sites/IMG/Interview_Lines",
            "originRouterSourceId": "107_Delayed",
            "originRouterId": "ksd",
            "claimsCanAccess": [
                "usr.ma.ma",
                "usr.plp.plp"
            ],
            "claimsCanEdit": [
                "usr.ma.ma"
            ],
            "version": 3,
            "lastUpdatedBy": {
                "id": "system"
            },
            "routerGroups": {
                "items": [
                    {
                        "id": "1365e4ba-f16a-4db9-96cb-3a8a8b33e5e1",
                        "routerGroup": {
                            "id": "b15400dd-121f-4ff3-9498-4cd5da56ad0b",
                            "name": "Interview Lines"
                        }
                    },
                    {
                        "id": "37c41102-0167-4623-a7d5-fde3751f457f",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG/Interview_Lines-sto",
                            "name": "Sites/IMG/Interview_Lines"
                        }
                    }
                ],
                "nextToken": null
            },
            "mappedInputs": {
                "items": []
            },
            "routedDestinations": {
                "items": [],
                "nextToken": null
            }
        },
        {
            "id": "source-ksd-IMG-IMG 109",
            "name": "IMG 109",
            "description": "IMG 109",
            "originRouterGroup": "Sites/IMG",
            "originRouterSourceId": "IMG 109",
            "originRouterId": "ksd",
            "claimsCanAccess": [
                "usr.ma.ma",
                "usr.plp.plp"
            ],
            "claimsCanEdit": [
                "usr.ma.ma"
            ],
            "version": 4,
            "lastUpdatedBy": {
                "id": "system"
            },
            "routerGroups": {
                "items": [
                    {
                        "id": "f91dae9d-8073-4353-a0b9-96f40e50aafa",
                        "routerGroup": {
                            "id": "b15400dd-121f-4ff3-9498-4cd5da56ad0b",
                            "name": "Interview Lines"
                        }
                    },
                    {
                        "id": "7973f835-f12f-4edb-8cb0-21591d412d7b",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG/Interview_Lines-sto",
                            "name": "Sites/IMG/Interview_Lines"
                        }
                    },
                    {
                        "id": "3dc95fca-fe0a-405b-9dce-80799e7f987f",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG-sto",
                            "name": "Sites/IMG"
                        }
                    }
                ],
                "nextToken": null
            },
            "mappedInputs": {
                "items": [
                    {
                        "id": "a8785d26-6c84-4ff7-a627-5b283ab130e5",
                        "input": {
                            "url": null,
                            "id": "img109",
                            "name": "MOS-IMG-ENC109",
                            "status": null,
                            "requiresPidInfo": null,
                            "frcAvailable": null
                        }
                    }
                ]
            },
            "routedDestinations": {
                "items": [
                    
                ],
                "nextToken": null
            }
        },
        {
            "id": "source-ksd-Sites/IMG/Interview_Lines-109_Delayed",
            "name": "IMG 109 D",
            "description": "109_Delayed",
            "originRouterGroup": "Sites/IMG/Interview_Lines",
            "originRouterSourceId": "109_Delayed",
            "originRouterId": "ksd",
            "claimsCanAccess": [
                "usr.ma.ma",
                "usr.plp.plp"
            ],
            "claimsCanEdit": [
                "usr.ma.ma"
            ],
            "version": 3,
            "lastUpdatedBy": {
                "id": "system"
            },
            "routerGroups": {
                "items": [
                    {
                        "id": "f9b12d32-c283-45f6-b498-4df92bb97e59",
                        "routerGroup": {
                            "id": "ksd-Sites/IMG/Interview_Lines-sto",
                            "name": "Sites/IMG/Interview_Lines"
                        }
                    },
                    {
                        "id": "3132d1de-7ae9-46e9-ba7a-1809c9068d42",
                        "routerGroup": {
                            "id": "b15400dd-121f-4ff3-9498-4cd5da56ad0b",
                            "name": "Interview Lines"
                        }
                    }
                ],
                "nextToken": null
            },
            "mappedInputs": {
                "items": []
            },
            "routedDestinations": {
                "items": [],
                "nextToken": null
            }
        }
      ],
    };
  },

  created() {
    this.init();
    this.initInterval();
    window.addEventListener('keyup', this.keyUp);
    window.addEventListener('keydown', this.keyDown);
  },

  beforeDestroy() {
    window.clearInterval(this.interval);
    this.interval = null;
    window.removeEventListener('keyup', this.keyUp);
    window.removeEventListener('keydown', this.keyDown);
  },

  computed: {
    ...mapState({
      aclEx: (state) => state.userAccount.userPermissions,
      acl: (state) => state.userAccount.aclCurrent,
      isAdmin: (state) => state.userAccount.isAdmin,
      client: (state) => state.userAccount.client,
      account: (state) => state.userAccount.account,
      clients: (state) => state.userAccount.clients,
      siteList: (state) => state.router.siteList,
      selection: (state) => state.router.selectedSite,
      loadings: (state) => state.router.loadings,

      // routers: (state) => state.router.routers,
      routerSources: (state) => state.router.routerSources,
      routerDestinations: (state) => state.router.routerDestinations,
      routerGroups: (state) => state.router.routerGroups,

      streamInformation: (state) => state.router.streamInformation,
    }),

    paneMinimalSize() {
      return this.isMobileOnly ? 50 : 25;
    },

    activeSourceDestinations() {
      if (this.hoveredSource) {
        return this.hoveredSource.routedDestinations.items.map((dest) => dest.id);
      }
      if (this.selectedSource) {
        return this.selectedSource.routedDestinations.items.map((dest) => dest.id);
      }
      return [];
    },

    activeDestinationSource() {

      console.log('activeDestinationSource 1');
      if (this.hoveredDest) {
        return this.hoveredDest.routedSource;
      }
      console.log('activeDestinationSource 2');
      if (this.selectedDestination) {
        console.log('activeDestinationSource 3', this.selectedDestination);
        return this.selectedDestination.routedSource || [];
      }
      console.log('activeDestinationSource 3');
      return [];
    },

    filteredSources() {
      const filterBySearchString = this.searchSource.length > 2;
      console.log('router groups: sources', filterBySearchString, !this.selectedSourceGroup);
      if (filterBySearchString) {
        const seachString = this.searchSource.toLowerCase();
        return this.routerSources
          .filter((item) => item.name.toLowerCase().indexOf(seachString) !== -1)
          .sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      if (!this.selectedSourceGroup) {
        return this.routerSources
          .slice(0).sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      let items = this.routerSources
        .filter((item) => {
          const groupIds = item.routerGroups.items.map((group) => group.routerGroup.id);
          return groupIds.indexOf(this.selectedSourceGroup.id) !== -1;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
        // if (this.clientId ==='plp') {
        //   items = this.plpSources;
        // }
        console.log('router groups: sources', items);
      return items;
    },

    filteredDestinations() {
      const filterBySearchString = this.searchDestination.length > 2;
      if (filterBySearchString) {
        const seachString = this.searchDestination.toLowerCase();
        return this.routerDestinations
          .filter((item) => item.name.toLowerCase().indexOf(seachString) !== -1)
          .sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      if (!this.selectedDestGroup) {
        return this.routerDestinations
          .slice(0).sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      return this.routerDestinations
        .filter((item) => {
          const groupIds = item.routerGroups.items.map((group) => group.routerGroup.id);
          return groupIds.indexOf(this.selectedDestGroup.id) !== -1;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    // sourceGroups() {
    //   if (!this.routerGroups) return [];
    //   return this.routerGroups.items.filter((item) => item.sources.items.length > 0)
    //     .sort((a, b) => {
    //       if (a.name < b.name) { return -1; }
    //       if (a.name > b.name) { return 1; }
    //       return 0;
    //     });
    // },
    sourceGroups() {
      if (!this.routerGroups) return [];
      console.log('router groups: ', this.accountId, this.clientId, this.routerGroups);
      const items = this.routerGroups.items.filter((item) => item.sources.items.length > 0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
        // if(this.clientId ==='plp') {
        //   items.push(this.plp);
        // }
      return items;
    },

    destinationGroups() {
      if (!this.routerGroups) return [];
      return this.routerGroups.items.filter((item) => item.destinations.items.length > 0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    groupedSources() {
      const mappedSources = this.routerSources.map((item) => ({
        source: item,
        groups: item.routerGroups.items.map((group) => group.routerGroup.name),
      }));
      return _.groupBy(mappedSources, (item) => item.groups);
    },

    groupedDestinations() {
      const mappedDest = this.routerDestinations.map((item) => ({
        dest: item,
        groups: item.routerGroups.items.map((group) => group.routerGroup.name),
      }));
      return _.groupBy(mappedDest, (item) => item.groups);
    },

    selectedDestinationId() {
      return this.selectedDestination ? this.selectedDestination.id : null;
    },

    accountId() {
      return this.account.code;
    },

    clientId() {
      return this.client.code;
    },

    claimsCanEdit() {
      return [
        `ro.${this.accountId}.${this.clientId}`,
      ];
    },

    claimsCanAccess() {
      return [
        `rv.${this.accountId}.${this.clientId}`,
        `ro.${this.accountId}.${this.clientId}`,
        `mr.${this.accountId}.${this.clientId}`,
      ];
    },

    originRouterColor() {
      return (id) => ColorGenerator.generateNewColor(id);
    },
  },

  watch: {
    seletedRouterGroup() {
      this.selectedSource = null;
      this.selectedDestination = null;
    },
  },

  methods: {
    ...mapActions({
      fetchRouterGroups: 'router/fetchRouterGroups',
      fetchRouterDestinations: 'router/fetchRouterDestinations',
      fetchRouterSources: 'router/fetchRouterSources',
      fetchRouters: 'router/fetchRouters',

      fetchRouter: 'router/fetchRouter',
      fetchRouterDestination: 'router/fetchRouterDestination',
      fetchRouterGroup: 'router/fetchRouterGroup',
      fetchRouterSource: 'router/fetchRouterSource',

      deleteRouter: 'router/deleteRouter',
      deleteRouterDestination: 'router/deleteRouterDestination',
      deleteRouterGroup: 'router/deleteRouterGroup',
      deleteRouterSource: 'router/deleteRouterSource',

      updateRouter: 'router/updateRouter',
      updateRouterDestination: 'router/updateRouterDestination',
      updateRouterGroup: 'router/updateRouterGroup',
      updateRouterSource: 'router/updateRouterSource',

      createRouter: 'router/createRouter',
      createRouterDestination: 'router/createRouterDestination',
      createRouterGroup: 'router/createRouterGroup',
      createRouterSource: 'router/createRouterSource',

      changeRoute: 'router/changeRoute',
    }),
    ...mapMutations({
      setSelectedSite: 'router/setSelectedSite',
    }),

    keyDown(e) {
      this.keys[e.key] = true;
    },

    keyUp(e) {
      this.keys[e.key] = false;
    },

    showTake(source) {
      this.showTakeId = source.id;
    },

    toggleSource(source) {
      if (this.selectedSource === source) {
        this.selectedSource = null;
        return;
      }
      this.selectedSource = source;
      // this.selectedDestination = null;
      if (this.keys.Shift) return;

      this.$forceUpdate();
      setTimeout(() => {
        if (!document.querySelector('.destinations .connected')) return;
        document.querySelector('.destinations').scroll({
          top: document.querySelector('.destinations .connected').offsetTop - 10,
          behavior: 'smooth',
        });
      }, 100);
    },

    toggleDest(dest) {
      console.log('toggled dest', dest, this.selectedDestination);
      this.showTakeId = null;
      if (this.selectedDestinationId === dest.id) {
        this.selectedDestination = null;
        return;
      }
      this.selectedDestination = dest;

      console.log('toggled dest', this.selectedDestination);
      this.selectedSource = null;
      this.$forceUpdate();

      if (this.keys.Shift) return;
      setTimeout(() => {
        if (!document.querySelector('.sources .connected')) return;
        document.querySelector('.sources').scroll({
          top: document.querySelector('.sources .connected').offsetTop - 10,
          behavior: 'smooth',
        });
      }, 100);
    },

    initInterval() {
      this.interval = setInterval(async () => {
        await this.fetchRouterDestinations();
        if (this.selectedDestination) {
          this.filteredDestinations.forEach((dest) => {
            if (dest.id !== this.selectedDestination.id) return;
            this.selectedDestination.routedSource = dest.routedSource;
          });
        }
        await this.fetchRouterSources();
        this.$forceUpdate();
      }, 15000);
    },

    async init() {
      this.searchDestination = localStorage.getItem('router_searchDestination') || '';
      this.searchSource = localStorage.getItem('router_searchSource') || '';

      this.selectedSourceGroup = JSON.parse(localStorage.getItem('router_selectedSourceGroup')) || null;
      this.selectedDestGroup = JSON.parse(localStorage.getItem('router_selectedDestGroup')) || null;
      try {
        // await this.fetchRouters();
        await this.fetchRouterDestinations();
        await this.fetchRouterSources();
        await this.fetchRouterGroups();
      } catch (error) {
        this.$message({
          showClose: true,
          message: 'Oops, something went wrong.',
          type: 'error',
        });
      }
    },

    routSource(source, destination) {
      const hasPermission = this.isAdmin || this.acl.ro;
      console.log('routSource', source, destination, hasPermission, this.isAdmin, this.acl);
      if (!hasPermission) return;
      this.showTakeId = null;
      if (!destination) return;
      if (this.useTake) {
        this.showTakeId = source.id;
        return;
      }

      this.sendRouteCommand(source, destination);
    },

    async sendRouteCommand(source, dest) {
      const command = {
        clientId: this.clientId,
        accountId: this.accountId,
        sourceId: source.id,
        destinationIds: [dest.id],
        // eventId: ID
        claimsCanAccess: this.claimsCanAccess,
        claimsCanEdit: this.claimsCanEdit,
      };
      await this.changeRoute(command);
      this.showTakeId = null;
    },

    // OLD STUFF
    async deleteRouterPrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouter(router);
      return null;
    },
    async deleteRouterSourcePrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouterSource(router.id);
      return null;
    },
    async deleteRouterDestinationPrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouterDestination(router.id);
      return null;
    },

    async showPrompt(confirmationMessage) {
      const confirmation = await this.$confirm(confirmationMessage, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        center: true,
        dangerouslyUseHTMLString: true,
      });
      return confirmation === 'confirm';
    },

    searchDestinationChanged() {
      localStorage.setItem('router_searchDestination', this.searchDestination);
    },
    searchSourceChanged() {
      localStorage.setItem('router_searchSource', this.searchSource);
    },
    sourceGroupChanged() {
      localStorage.setItem('router_selectedSourceGroup', JSON.stringify(this.selectedSourceGroup));
    },
    destGroupChanged() {
      localStorage.setItem('router_selectedDestGroup', JSON.stringify(this.selectedDestGroup));
    },
  },
};
</script>

<style>
.el-loading-spinner svg {
  margin: 0 auto;
}

.green {
  color: #00FF80;
}

.green-dark {
  color: #77ca17;
}

.blue-light {
  color: #00D4FF;
}

#router.router-vh .container-with-sidebar .item-wrapper .item {
  grid-template-columns: 1fr;
  grid-template-rows: 91px auto;
  height: 137px;
  width: 150px;
  text-align: center;
  align-items: start;
  grid-gap: 0;
}
#router.router-vh .container-with-sidebar .item-wrapper .item .item-name {
  margin: 0 10px;
  font-size: 13px;
}
#router.router-vh .container-with-sidebar .item-wrapper .item .item-avatar {
  padding: 0px;
}
#router.router-vh .container-with-sidebar .item-wrapper .item .item-avatar .el-avatar {
  margin: 0 auto;
  width: 120px;
  height: 70px;
  line-height: 75px;
  margin-top: 10px;
}
#router .el-divider .el-divider__text {
  position: absolute;
  background-color: #13191D;
}
#router .container-with-sidebar {
  display: grid;
  grid-template-columns: auto;
}
#router .container-with-sidebar .pane-wrapper {
  height: 100%;
}
#router .container-with-sidebar .pane-wrapper .vb {
  height: 100%;
}
#router .container-with-sidebar .pane-bar-wrapper {
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 50px;
}
#router .container-with-sidebar .pane-title {
  font-size: 14px;
  text-transform: uppercase;
  margin: 10px 0px;
  font-weight: 500;
  letter-spacing: 0.3px;
  position: relative;
}
#router .container-with-sidebar .pane-title .bi {
  position: relative;
  top: 1px;
  margin-right: 8px;
}
#router .container-with-sidebar .pane-title .title-actions {
  position: absolute;
  right: 20px;
  top: -7px;
}
#router .container-with-sidebar .pane-title .title-actions input {
  background: transparent;
  border-color: transparent;
  color: #CDD6EE;
}
#router .container-with-sidebar .pane-title .title-actions .search {
  display: inline-block;
}
#router .container-with-sidebar .item-wrapper {
  display: inline-block;
  margin-bottom: 12px;
  margin-right: 12px;
}
#router .container-with-sidebar .item-wrapper .item-actions {
  position: absolute;
  right: 0;
  top: 0;
}
#router .container-with-sidebar .item-wrapper .item {
  display: grid;
  grid-template-columns: 44px auto;
  grid-gap: 8px;
  width: 252px;
  height: 52px;
  align-items: center;
  overflow: hidden;
  background: #242A33;
  border-radius: 2px;
  position: relative;
}
#router .container-with-sidebar .item-wrapper .item.active, #router .container-with-sidebar .item-wrapper .item:hover {
  outline: 2px solid #00D4FF;
}
#router .container-with-sidebar .item-wrapper .item.active {
  outline: 2px solid #ff9f31 !important;
}
#router .container-with-sidebar .item-wrapper .item.active .item-name {
  color: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.active .el-avatar--square {
  background: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item .take-action, #router .container-with-sidebar .item-wrapper .item .changing-route {
  position: absolute;
  background: #ea0d51;
  top: 5px;
  bottom: 5px;
  grid-column: 1/span 2;
  right: 5px;
  left: 5px;
  max-width: 300px !important;
  color: #fff;
  line-height: 45px;
  text-align: center;
  display: none;
}
#router .container-with-sidebar .item-wrapper .item .changing-route {
  background: rgba(36, 42, 51, 0.78);
}
#router .container-with-sidebar .item-wrapper .item.show-take .take-action {
  display: block;
}
#router .container-with-sidebar .item-wrapper .item.show-loading .changing-route {
  display: block;
  z-index: 999;
}
#router .container-with-sidebar .item-wrapper .item.destination.active, #router .container-with-sidebar .item-wrapper .item.destination:hover {
  outline: 2px solid #00FF80;
}
#router .container-with-sidebar .item-wrapper .item.destination.connected {
  outline: 2px solid #00D4FF;
}
#router .container-with-sidebar .item-wrapper .item.destination.connected .item-name {
  color: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.destination.connected .el-avatar--square {
  background: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.connected-dest {
  outline: 2px solid #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.connected {
  outline: 2px solid #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.connected .item-name {
  color: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.connected .el-avatar--square {
  background: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.disabled {
  opacity: 0.5;
  pointer-events: none;
}
#router .container-with-sidebar .item-wrapper .item .el-avatar--square {
  border-radius: 2px;
}
#router .container-with-sidebar .item-wrapper .item div {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
#router .container-with-sidebar .item-wrapper .item-avatar {
  align-content: center;
  display: grid;
  padding-left: 8px;
}
#router .container-with-sidebar .item-wrapper .item-name {
  color: #CDD6EE;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#router .container-with-sidebar .item-wrapper .item-desc {
  font-size: 12px;
  margin-bottom: -2px;
  line-height: 17px;
}
#router .container-with-sidebar .item-wrapper .item-desc .routed {
  display: inline-block;
  margin-right: 10px;
  color: #00D4FF;
}
#router .container-with-sidebar .item-wrapper .item-desc .not-connected {
  color: #FF5722;
  opacity: 0;
  display: none;
}
#router .container-with-sidebar .item-wrapper .item .item-actions {
  position: absolute;
  right: 0;
  top: 0;
}
#router .container-with-sidebar .item-wrapper .item .item-actions i {
  background: rgba(36, 42, 51, 0.71);
}

.mobile #router {
  overflow-x: scroll;
  margin: 10px 0px;
}
.mobile #router .wrapper {
  padding: 10px 0;
}
.mobile #router .item {
  width: 150px !important;
  grid-template-columns: 0 auto !important;
}
.mobile #router .item-avatar {
  visibility: hidden !important;
}
.mobile #router .default-theme.splitpanes .splitpanes__pane {
  padding: 0 0 0 20px;
}
.mobile #router .container-with-sidebar .pane-title .title-actions {
  right: 0;
  top: 0;
  position: relative;
  left: -16px;
}

.default-theme.splitpanes {
  height: calc(100vh - 60px);
}
.default-theme.splitpanes .splitpanes__pane {
  background: transparent;
  padding: 0 0 0 20px;
}
.default-theme.splitpanes .splitpanes__pane.pane-dest {
  background-color: rgba(0, 0, 0, 0.3);
}
.default-theme.splitpanes .splitpanes__splitter {
  background-color: rgba(0, 0, 0, 0.3);
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
}
.default-theme.splitpanes .splitpanes__splitter:before {
  margin-left: 0px;
  background-color: #66718D;
}
.default-theme.splitpanes .splitpanes__splitter:after {
  background-color: #66718D;
}
</style>
