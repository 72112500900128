<template>
  <div class="bly-button" :class="classes">
    <svg
      :class="svgClass"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;"
      xml:space="preserve">
      <path
        :style="strokeStyle"
        d="M30,5c18.5,0,25,6.5,25,25s-6.5,25-25,25S5,48.5,5,30S11.5,5,30,5z"/>
    </svg>
    <div class="bly-button-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: String,
    stroke: Boolean,
    strokeColor: String,
    size: String,
  },

  computed: {
    strokeStyle() {
      if (!this.stroke) return {};
      return {
        stroke: this.strokeColor ? this.strokeColor : '#1B2127',
        strokeWidth: '4px',
      };
    },

    classes() {
      return `${this.type} ${this.size}`;
    },

    svgClass() {
      return this.stroke ? 'stroke' : '';
    },
  },

  data() {
    return {
    };
  },
};
</script>

<style scoped src="./Button.css"></style>
