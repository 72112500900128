<template>
  <div>
    <div class="bly-live-item bly-live-item-header" v-if="header">
      <div class="start">
        Start
      </div>
      <div class="end">
        End
      </div>
      <div class="source">
        Source
      </div>
      <div class="destination">
        Destination
      </div>
      <div class="endIn">
        <span v-if="live">Ends In</span>
        <span v-if="!live">Starts In</span>
      </div>
    </div>
    <div class="bly-live-item" v-if="!header">
      <div class="fixture pointer"  @click="openPreview(item)">
        {{item.fixture}}
      </div>
      <div class="start pointer" @click="openPreview(item)">
        {{item.startTime | formatDate('HH:mm')}}
      </div>
      <div class="end pointer" @click="openPreview(item)">
        {{item.endTime | formatDate('HH:mm')}}
      </div>
      <div class="source pointer" @click="openPreview(item)">
        <span v-if="!input.url">{{inputName}}</span>
        <a v-if="input.url" :href="input.url">{{inputName}}</a>
        {{input.url}}
      </div>
      <div class="destination pointer" v-html="outputsString" @click="openPreview(item)">
      </div>
      <div class="endIn">
        <div class="mos-checked">
          <el-tooltip :content="item.lastUpdatedBy.name">
             <el-switch v-model="item.mosChecked" @change="updateMos"></el-switch>
          </el-tooltip>
        </div>
        <countdown :time="live ? endsInMs : Math.abs(startsInMs)">
          <template slot-scope="props">{{ props.hours }}:{{ props.minutes | addZero }}:{{ props.seconds | addZero}}</template>
        </countdown>
        <el-tooltip content="Extend Time" v-if="live">
          <el-dropdown trigger="click">
            <i class="bi bly-more icon-btn bi-rotate-90"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="bi bly-plus"
                @click.native="addTime(item, 'tail', 5)">
                  5<span>min</span> </el-dropdown-item>
              <el-dropdown-item icon="bi bly-plus"
                @click.native="addTime(item, 'tail', 15)">
                  15<span>min</span> </el-dropdown-item>
              <el-dropdown-item icon="bi bly-plus"
                @click.native="addTime(item, 'tail', 30)">
                  30<span>min</span> </el-dropdown-item>
              <el-dropdown-item icon="bi bly-plus"
                @click.native="addTime(item, 'tail', 45)">
                  45<span>min</span> </el-dropdown-item>
              <el-dropdown-item icon="bi bly-plus"
                @click.native="openAddTime(item, 'tail')">
                  Custom </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-tooltip>
        <el-tooltip content="Start now" v-if="!live">
          <i class="bi bly-play icon-btn" @click="startEventNow"></i>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapMutations, mapActions } from 'vuex';

export default {
  name: 'LiveOpsItem',
  props: {
    header: {
      type: Boolean,
      default: false,
    },
    live: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      required: true,
    },
    now: {
      type: Object,
    },
  },

  data() {
    return {
      rescheduleForm: {
      },
    };
  },

  computed: {
    status() {
      return this.item.status || '';
    },
    duration() {
      // const format = 'DD/MM/YYYY HH:mm:ss';
      const start = moment(this.item.lineupTime);
      const end = moment(this.item.endTime);
      const ms = end.diff(start);
      const d = moment.duration(ms);
      const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm');
      return s;
    },

    inputName() {
      return this.item && this.item.input && this.item.input.name ? this.item.input.name : '---';
    },
    input() {
      return this.item.input ? this.item.input : {};
    },
    inputEquipment() {
      return this.input && this.input.equipment ? this.input.equipment.items : [];
    },
    outputs() {
      return this.item.outputs.items;
    },
    outputsString() {
      let output = '';
      const t = this.outputs.filter((output) => !!output).forEach((el) => {
        const [outputConfig] = this.item.actions.items
          .filter((action) => action.assignedOutput.id === el.output.id);
        const hasWowzaConfig = outputConfig && outputConfig.configWowza;
        const autostartTitle = hasWowzaConfig && outputConfig.configWowza.autostart ? outputConfig.configWowza.autostartOffsetMin : 0;
        const autostartColor = hasWowzaConfig && outputConfig.configWowza.autostart ? 'green' : 'red';
        const autostartString = `<i class="bi bly-time ${autostartColor}" title="Offset ${autostartTitle} min"></i>`;
        console.log('===> ', el, this.item, outputConfig);
        const hasURL = !!outputConfig.assignedOutput.url;
        output += `
          <div>
            ${hasURL ? `<a href="${outputConfig.assignedOutput.url}">` : ''}
            ${el.output.name} 
            <span class="output-type-tag ${el.output.outputType}">${el.output.outputType}</span>
            ${hasWowzaConfig ? '' : ''}
            <div class="show-ma ${el.output.ottServer}">(${el.output.ottServer} - ${el.output.ottApplication})</div>
            ${hasURL ? `</a><span class="url">${outputConfig.assignedOutput.url}</span>` : ''}
          </div>
        `;
      });
      if (output === '') output = 'not set';
      return output;
    },
    outputEquipment() {
      return this.outputs
        .filter((map) => !!map)
        .map((map) => {
          const { output } = map;
          console.log('dasd', output);
          return output.equipment.items;
        });
    },
    outputEquipmentName() {
      return this.outputEquipment.map((eq) => eq.name);
    },

    endsInMS() {
      const end = moment.utc(this.item.endTime);
      const { now } = this;
      const duration = moment.duration(now.diff(end));
      return duration.asMilliseconds();
    },

    endsIn() {
      const end = moment.utc(this.item.endTime);
      const { now } = this;
      const duration = moment.duration(now.diff(end));
      console.log('duration.asHours()', (new Date(this.item.endTime) - new Date(now)) / 60000, '----', duration.asHours());
      let mm = Math.floor(Math.abs(duration.asMinutes() % 60));
      let hh = Math.floor(Math.abs(duration.asHours()));
      if (mm < 10) mm = `0${mm}`;
      if (hh < 10) hh = `0${hh}`;
      return `${hh}:${mm}`;
    },
    startsInMs() {
      const end = moment.utc(this.item.startTime);
      const { now } = this;
      const duration = moment.duration(now.diff(end));
      return duration.asMilliseconds();
    },
    startsIn() {
      const end = moment.utc(this.item.startTime);
      const { now } = this;
      const duration = moment.duration(now.diff(end));
      console.log('duration.asHours()', (new Date(this.item.startTime) - new Date(now)) / 60000, '----', duration.asHours());
      let ss = Math.floor(Math.abs(duration.asMinutes() % 60));
      let mm = Math.floor(Math.abs(duration.asHours()));
      if (ss < 10) ss = `0${ss}`;
      if (mm < 10) mm = `0${mm}`;
      return `${mm}:${ss}`;
    },
    eventAccount() {
      return this.item ? this.item.account : { name: '' };
    },

    eventClient() {
      return this.item ? this.item.customer : { name: '' };
    },
    serviceStartTimeOffsetMin() {
      return moment(this.item.lineupTime).diff(this.item.serviceStartTime, 'minutes');
    },
  },
  methods: {
    ...mapActions({
      createEvent: 'schedule/createEvent',
      deleteEvent: 'schedule/deleteEvent',
      updateEvent: 'schedule/updateEvent',
      updateEventClean: 'schedule/updateEventClean',
      rescheduleEvent: 'schedule/rescheduleEvent',
      deleteEventAudioChannel: 'schedule/deleteEventAudioChannel',
      createEventAudioChannel: 'schedule/createEventAudioChannel',
      updateEventAudioChannel: 'schedule/updateEventAudioChannel',

      startStreamTarget: 'schedule/startStreamTarget',
      stopStreamTarget: 'schedule/stopStreamTarget',
      restartIncomingStream: 'schedule/restartIncomingStream',
      setStreamKey: 'schedule/setStreamKey',
      eventManualStart: 'schedule/eventManualStart',
      eventManualStop: 'schedule/eventManualStop',

      updateAppSetting: 'appSettings/updateAppSetting',
    }),
    ...mapMutations({
      setSelectedSite: 'router/setSelectedSite',
      setActiveEvent: 'schedule/setActiveEvent',
    }),
    openPreview() {
      this.$emit('openPreview', this.item);
    },
    async addTime(event, type, time) {
      const headTime = type === 'head' ? event.headTimeMin + time : event.headTimeMin;
      const tailTime = type === 'tail' ? event.tailTimeMin + time : event.tailTimeMin;

      const startTime = type === 'head' ? moment(event.startTime).subtract(time, 'minutes') : event.startTime;
      const endTime = type === 'tail' ? moment(event.endTime).add(time, 'minutes') : event.endTime;
      const newTimes = {
        endDate: this.item.endTime.split('T')[0],
        startDate: this.item.startTime.split('T')[0],
        lineupDate: this.item.lineupTime.split('T')[0],
        eventId: this.item.id,
        expectedVersion: this.item.version,
        accountId: this.eventAccount.code,
        clientId: this.eventClient.code,
        lineupTime: event.lineupTime,
        startTime,
        endTime,
        headTimeMin: headTime,
        tailTimeMin: tailTime,
        serviceStartTimeOffsetMin: this.serviceStartTimeOffsetMin,
      };

      delete newTimes.lineupDate;
      delete newTimes.startDate;
      delete newTimes.endDate;
      await this.rescheduleEvent(newTimes);
    },

    async openAddTime(event, type) {
      try {
        const result = await this.$prompt('Please input time in minutes', 'Extend time', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          inputPattern: /^[0-9]/,
          inputErrorMessage: 'Invalid value',
        });

        const { value } = result;
        this.addTime(event, type, parseInt(value, 10));
      } catch (error) {
        if (error === 'cancel') return;
        this.$message.error('Something went wrong!');
      }
    },
    async startEventNow() {
      const confirmation = await this.$confirm('Are you sure you want to start booking now?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.eventManualStart(this.item.id);
    },
    async updateMos(value) {
      console.log('updateMos', value);
      await this.save('mosChecked', value, this.item);
    },

    async save(property, value, event) {
      console.log('ssssss', property, value, event);
      const newEvent = this.getEventObjForUpdate(event);
      newEvent[property] = value;
      if (property === 'fixture') {
        newEvent.name = value;
      }
      const result = await this.updateEventClean(newEvent);
      console.log('result', result);
      this.item.version = result.version;
      this.item.lastUpdatedBy = result.lastUpdatedBy;
    },

    getEventObjForUpdate(event) {
      return {
        bugRequired: event.bugRequired,
        claimsCanAccess: event.claimsCanAccess,
        claimsCanEdit: event.claimsCanEdit,
        description: event.description,
        endTime: event.endTime,
        eventAccountId: event.eventAccountId,
        eventCustomerId: event.eventCustomerId,
        eventInputId: event.eventInputId,
        expectedVersion: event.version,
        fixture: event.fixture,
        headTimeMin: event.headTimeMin,
        startType: event.startType,
        id: event.id,
        attachments: event.attachments,
        lineupTime: event.lineupTime,
        name: event.name,
        property: event.property,
        requestedBitrate: event.requestedBitrate,
        serviceEndTime: event.serviceEndTime,
        serviceStartTime: event.serviceStartTime,
        sport: event.sport,
        startTime: event.startTime,
        status: event.status,
        tailTimeMin: event.tailTimeMin,
        type: event.type,
        eventLastUpdatedById: this.userId,
      };
    },
  },
};
</script>

<style src="./LiveOpsItem.css"></style>
