import axios from 'axios';
import config from '../config';
import awsExports from '../aws-exports';

class ProbesService {
  constructor(options) {
    this.config = config;
    this.options = options;
    this.url = config.url;
    this.svcUrl = config.svcUrl;
    this.apiUrl = config.apiUrl;
    this.awsConfig = awsExports[process.env.VUE_APP_MODE];
    console.log('awdConfig', this.awsConfig)
  }

  async getProbesList(data) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('ma-token'),
      'Client-Id': this.awsConfig.aws_user_pools_web_client_id,
      
    };
    
    // axios.post('https://your-api-url.com/endpoint', data, { headers })
    return axios.get(`${this.svcUrl}probes/all`, { headers });
  }
}

export default new ProbesService();
