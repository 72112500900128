import Vue from 'vue';
import elementUi from 'element-ui';
import VueHotkey from 'v-hotkey';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import { Amplify, Storage } from 'aws-amplify'
// import { AmplifyPlugin } from 'aws-amplify-vue';
import VueQuillEditor from 'vue-quill-editor';
import VueFilterDateFormat from 'vue-filter-date-format';
import Vuebar from 'vuebar';
// import VBar from 'v-bar';
import Affix from 'vue-affix';
import checkView from 'vue-check-view';
import VueCountdown from '@chenfengyuan/vue-countdown';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Sortable from 'vue-sortable';
import draggable from 'vuedraggable';
import VueMobileDetection from "vue-mobile-detection";
// import { Datetime } from 'vue-datetime';
import {  DatetimePicker, Popup, Picker, Locale as localeVant,
} from 'vant';

import enUS from 'vant/lib/locale/lang/en-US';
import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import awsExports from './aws-exports';
import './components';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import './index.css';

library.add(faExchangeAlt);
dom.watch();
Vue.component('font-awesome-icon', FontAwesomeIcon);

locale.use(lang);
localeVant.use('en-US', enUS);
Vue.config.productionTip = false;

Vue.use(elementUi);
Vue.use(Affix);
Vue.use(checkView);
Vue.use(VueHotkey);
Vue.use(Sortable);
Vue.use(draggable);
// Vue.use(AmplifyPlugin);
Vue.use(VueFilterDateFormat);
Vue.use(Vuebar);
// Vue.use(VBar);
Vue.use(VueQuillEditor);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(VueMobileDetection);
// Vue.component('datetime', Datetime);
Vue.component(VueCountdown.name, VueCountdown);
console.log('process.env', process.env);
Amplify.configure(awsExports[process.env.NODE_ENV]);
// Amplify.configure({
//   Auth: {
//     region: 'eu-west-1',
//     aws_cognito_identity_pool_id: 'eu-west-1:3577a0cd-9a89-48be-86c1-b7fe8a9d8a36',
//     aws_cognito_region: 'eu-west-1',
//     userPoolId: 'eu-west-1_CPYIMwbLB',
//     authenticationFlowType: 'USER_PASSWORD_AUTH',
//     userPoolWebClientId: '172en1fqcmvmkme4bflkqinefv',
//     aws_user_files_s3_bucket: 'mos-mg-sync-apac1',
//     aws_user_files_s3_bucket_region: 'ap-southeast-1',
//     aws_content_delivery_bucket: 'mos-mg-sync-apac1',
//     aws_content_delivery_bucket_region: 'ap-southeast-1',
//     aws_upload_bucket: 'console-uploads',
//     aws_appsync_graphqlEndpoint: 'https://b4wz4zyjqzaprijgajuk66sbkq.appsync-api.eu-west-1.amazonaws.com/graphql',
//     aws_appsync_region: 'eu-west-1',
//     aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
//    }
// });
store.state.awsExports = awsExports[process.env.VUE_APP_MODE];
console.log('=>', process.env, awsExports[process.env.VUE_APP_MODE]);

Vue.prototype.$Amplify = Amplify
const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

store.$app = app;
