<template>
  <div class="logout">
    <i class="bi bly-check"></i>
    <h1>Logged out successfully</h1>
    <router-link to="/login">Login again</router-link>
  </div>
</template>
<script>
import AuthenticationService from '@/services/Authentication';

export default {
  methods: {
    login() {
      AuthenticationService.signIn();
    },
  },
};
</script>

<style>
  .logout {
    text-align: center;
    
  }
  .logout i {
    font-size: 50px;
    color: #00FF80;
  }
  .logout  h1 {
    color: #fff;
    font-size: 32px;
    margin-bottom: 10px;
  }
</style>
