import axios from 'axios';
import config from '../config';

class MultiviewService {
  constructor(options) {
    this.config = config;
    this.options = options;
    this.url = config.url;
    this.apiUrl = config.apiUrl;
    this.mvControlUrl = config.mv_control_url;
  }

  async getConfig() {
    console.log('OTTMultiviewers');
    return axios.get(`${this.url}OTTMultiviewers`);
  }

  async setActiveLayout(layout) {
    console.log('setActiveLayout');
    return axios.post(`${this.mvControlUrl}set-active-layout`, {
      id: layout
    });
  }

  async setActivePlayer(player) {
    console.log('setActiveplayer');
    return axios.post(`${this.mvControlUrl}set-active-player`, {
      id: player
    });
  }

  async setActiveAudioChannel(audioChannel, reset = true) {
    console.log('set-active-audio-channel');
    return axios.post(`${this.mvControlUrl}set-active-audio-channel`, {
      id: audioChannel,
      resetPlayer: reset
    });
  }


  async getActiveAudioChannel() {
    console.log('get active-audio-channel');
    return axios.get(`${this.mvControlUrl}active-audio-channel`);
  }

  async getAudioChannelsNo() {
    console.log('get audio-channels-no');
    return axios.get(`${this.mvControlUrl}audio-channels-number`);
  }

  async getPlayersNumber() {
    console.log('get players-number');
    return axios.get(`${this.mvControlUrl}players-number`);
  }
}

export default new MultiviewService();
