import axios from 'axios';
import config from '../config';
import awsExports from '../aws-exports';

class ImportService {
  constructor(options) {
    this.config = config;
    this.options = options;
    this.url = config.url;
    this.svcUrl = config.svcUrl;
    this.apiUrl = config.apiUrl;
    this.awsConfig = awsExports[process.env.VUE_APP_MODE];
    this.wssUrl = this.awsConfig.ws
    this.importInfoUrl = this.awsConfig.server
    this.websocket = null;
  }

  async getLastImportInfo(client) {
    const token = localStorage.getItem('ma-token')
    console.log('awdConfig', this.awsConfig)
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `${token}`,
        'client-id': this.awsConfig.aws_user_pools_web_client_id
      },
    };

    return fetch(`${this.importInfoUrl}/imported?client=${client}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        return result;
      })
      .catch(error => console.log('error', error));
}
  connectToWs(onMessage) {
    this.websocket = new WebSocket(`${this.wssUrl}`);

    // Event listener for successful connection
    this.websocket.addEventListener('open', (event) => {
      console.log('WebSocket connection established:', event);
      // You can add additional logic here if needed
    });

    // Event listener for incoming messages
    this.websocket.addEventListener('message', onMessage);

    // Event listener for connection errors
    this.websocket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      // You can add additional error handling logic here
    });

    // Event listener for connection closure
    this.websocket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed:', event);
      // You can add additional logic here if needed
    });
  }

  send(message) {
    const token = localStorage.getItem('ma-token')
    const newMessage = {
      ...message,
      token,
      clientId: this.awsConfig.aws_user_pools_web_client_id
    }

    this.websocket.send(JSON.stringify(newMessage))
  }

  disconnectFromWs() {
    if (this.websocket) {
      this.websocket.close();
    }
  }

  async sendCommand(command) {
    return axios.post(`${this.url}servercommand`, command);
  }

  async restartApplication(command) {
    return axios.post(`${this.url}WowzaStreamTarget`, command);
  }
  async getOutputsStatus(data) {
    const headers = {
      'Content-Type': 'application/json',
      // Authorization: localStorage.getItem('ma-token'),
      // 'Client-Id': this.awsConfig.aws_user_pools_web_client_id,

    };

    // axios.post('https://your-api-url.com/endpoint', data, { headers })
    return axios.post(`${this.svcUrl}serverStatus`, data, { headers });
  }
}

export default new ImportService();
