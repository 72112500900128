/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const startStreamTarget = `mutation StartStreamTarget($eventId: ID, $streamTargetId: ID) {
  startStreamTarget(eventId: $eventId, streamTargetId: $streamTargetId) {
    success
    message
  }
}
`;
export const stopStreamTarget = `mutation StopStreamTarget($eventId: ID, $streamTargetId: ID) {
  stopStreamTarget(eventId: $eventId, streamTargetId: $streamTargetId) {
    success
    message
  }
}
`;
export const restartEvent = `mutation RestartEvent($eventId: ID) {
  restartEvent(eventId: $eventId) {
    success
    message
  }
}
`;
export const restartIncomingStream = `mutation RestartIncomingStream($eventId: ID) {
  restartIncomingStream(eventId: $eventId) {
    success
    message
  }
}
`;
export const addBooking = `mutation AddBooking($input: AddBookingInput!) {
  addBooking(input: $input) {
    fixture
    id
  }
}
`;

export const createAudioMappingProfile = `mutation CreateAudioMappingProfile($input: CreateAudioMappingProfileInput!) {
  createAudioMappingProfile(input: $input) {
    id
    name
    description
  }
}
`;
export const createAudioMapItem = `mutation CreateAudioMapItem($input: CreateAudioMapItemInput!) {
  createAudioMapItem(input: $input) {
    id
  }
}
`;

export const signDownloadUrl = `mutation SignDownloadUrl($attachmentPath: String!) {
  signDownloadUrl(attachmentPath: $attachmentPath)
}
`;

export const eventManualStart = `mutation EventManualStart($eventId: ID!) {
  eventManualStart(eventId: $eventId) {
    success
    message
  }
}
`;

export const setStreamKey = `mutation SetStreamKey(
  $eventId: ID!
  $streamTargetId: ID!
  $streamKey: String!
) {
  setStreamKey(
    eventId: $eventId
    streamTargetId: $streamTargetId
    streamKey: $streamKey
  ) {
    success
    message
  }
}
`;

export const eventInputChange = `mutation EventInputChange($input: EventInputChangeInput!) {
  eventInputChange(input: $input) {
    success
    message
  }
}
`;

export const eventOutputAdd = `mutation EventOutputAdd($input: EventOutputAddInput!) {
  eventOutputAdd(input: $input) {
    success
    message
  }
}
`;
export const eventOutputRemove = `mutation EventOutputRemove($input: EventOutputRemoveInput!) {
  eventOutputRemove(input: $input) {
    success
    message
  }
}
`;
export const eventOutputProfileChange = `mutation EventOutputProfileChange($input: EventOutputProfileChanegInput!) {
  eventOutputProfileChange(input: $input) {
    success
    message
  }
}
`;
export const createEvent = `mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    id
    name
    description
    type
    mosChecked
    sport
    property
    fixture
    requestedBitrate
    bugRequired
    serviceStartTime
    lineupTime
    startTime
    endTime
    serviceEndTime
    status
    headTimeMin
    tailTimeMin
    inputAudioPid
    inputVideoPid
    claimsCanAccess
    claimsCanEdit
    startType
    version
    commentaryLanguage
    commentaryUser {
      name
      email
      id
    }
    outputStreams {
      items {
        id
        outputStream {
          id
          name
          type
          address
          port
          latencyMs
          talkbackAddress
          talkbackPort
          bufferMinMs
          bufferMaxMs
          encPassphrase
          encKeyLen
          encType
          udpAddress
          udpPort
          udpRtt
        }
      }
    }
    inputFrcEnabled
    inputFrcInputFormat {
        id
        name
        description
      }
      inputFrcOututFormat {
        id
        name
        description
      }
    inputStreams {
      items {
        id
        inputStream {
          id
          name
          description
          type
          attachedTo
          address
          port
          latencyMs
          talkbackAddress
          talkbackPort
          bufferMinMs
          bufferMaxMs
          encPassphrase
          encKeyLen
          encType
          udpAddress
        }
      }
    }
    customer {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      claimsCanAccess
      claimsCanEdit
      version
      features {
        nextToken
      }
      accounts {
        nextToken
      }
      lastUpdatedBy {
        id
        email
        name
        isApproved
        claimsCanAccess
        claimsCanEdit
        version
      }
      users {
        nextToken
      }
    }
    account {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      claimsCanAccess
      claimsCanEdit
      version
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      claims {
        nextToken
      }
      features {
        nextToken
      }
      lastUpdatedBy {
        id
        email
        name
        isApproved
        claimsCanAccess
        claimsCanEdit
        version
      }
      users {
        nextToken
      }
      inputs {
        nextToken
      }
      outputs {
        nextToken
      }
    }
    lastUpdatedBy {
      id
      email
      name
      isApproved
      claimsCanAccess
      claimsCanEdit
      version
      lastUpdatedBy {
        id
        email
        name
        isApproved
        claimsCanAccess
        claimsCanEdit
        version
      }
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
      appSettings {
        nextToken
      }
    }
    outputs {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    audioMapping {
      items {
        id
        channelNo
        nameFieldL
        nameFieldR
        active
        stereo
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    actions {
      items {
        id
        setupOffsetMs
        actionType
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
    input {
      id
      url
      status
      name
      description
      claimsCanAccess
      claimsCanEdit
      requiresPidInfo
      frcAvailable
      version
      lastUpdatedBy {
        id
        email
        name
        isApproved
        claimsCanAccess
        claimsCanEdit
        version
      }
      accounts {
        nextToken
      }
    }
  }
}
`;
export const updateEvent = `mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    id
    name
    description
    type
    sport
    property
    mosChecked
    fixture
    requestedBitrate
    bugRequired
    serviceStartTime
    lineupTime
    startTime
    endTime
    serviceEndTime
    status
    headTimeMin
    tailTimeMin
    inputAudioPid
    inputVideoPid
    claimsCanAccess
    claimsCanEdit
    cancellationMsg
    version
    importSource
    importDate
    startType
    attachments
    groupId
    commentaryLanguage
    commentaryUser {
      name
      email
      id
    }
    outputStreams {
      items {
        id
        outputStream {
          id
          name
          type
          address
          port
          latencyMs
          talkbackAddress
          talkbackPort
          bufferMinMs
          bufferMaxMs
          encPassphrase
          encKeyLen
          encType
          udpAddress
          udpPort
          udpRtt
        }
      }
    }
    inputFrcEnabled
    inputFrcInputFormat {
        id
        name
        description
      }
      inputFrcOututFormat {
        id
        name
        description
      }
    inputStreams {
      items {
        id
        inputStream {
          id
          name
          description
          type
          attachedTo
          address
          port
          latencyMs
          talkbackAddress
          talkbackPort
          bufferMinMs
          bufferMaxMs
          encPassphrase
          encKeyLen
          encType
          udpAddress
        }
      }
    }
    customer {
      id
      code
      name
      description
      version
    }
    account {
      id
      code
      name
      description
      logo
      website
      email
    }
    lastUpdatedBy {
      id
      name
    }
    audioMapping {
      items {
        id
        channelNo
        description
        active
        stereo
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    actions (limit: 1500) {
      items {
        actionType
        configWowza {
          id
          targets {
            items {
              application
              id
              streamKey
              target
              type
              noStremKeyRequired
              claimsCanAccess
              transcoderProfile
              claimsCanEdit
              version
            }
          }
          server
          type
          autostart
          autostartOffsetMin
          claimsCanAccess
          claimsCanEdit
          version
        }
        assignedOutput {
          id
          url
          name
          displayName
          ottPlatform
          outputType
          status
          claimsCanAccess
          claimsCanEdit
        }
      }
      nextToken
    }
    outputs (limit: 1500) {
      items {
        id
        output {
          id
          url
          name
          displayName
          description
          outputType
          status
          ottPlatform
          ottServer
          ottApplication
          outputProfiles {
            items {
              outputProfile {
                id
              }
            }
          }
        }
      }
    }
    inputDownlinkConfig {
      satelliteName
      service
      symbolRate
      satelliteFrequency
      satellitePolarization
      BISS
    }
    input {
      id
      name
      type
      status
      url
      description
      claimsCanAccess
      claimsCanEdit
      requiresPidInfo
      frcAvailable
      downlinkConfig {
        satelliteName
        service
        symbolRate
        satelliteFrequency
        satellitePolarization
        BISS
      }
      version
    }
    inputOverride {
      id
      name
      type
      url
      status
      description
      claimsCanAccess
      claimsCanEdit
      downlinkConfig {
        satelliteName
        service
        symbolRate
        satelliteFrequency
        satellitePolarization
        BISS
      }
      version
    }
  }
}
`;
export const createInputDownlinkConfig = `mutation CreateInputDownlinkConfig($input: CreateInputDownlinkConfigInput!) {
  createInputDownlinkConfig(input: $input) {
    id
  }
}
`;
export const stopEvent = `mutation StopEvent($eventId: ID!) {
  stopEvent(eventId: $eventId) {
    success
    message
  }
}
`;

export const deleteEvent = `mutation DeleteEvent($input: DeleteEventInput!) {
  deleteEvent(input: $input) {
    id
  }
}
`;
export const createEventOutputStreamsMap = `mutation CreateEventOutputStreamsMap($input: CreateEventOutputStreamsMapInput!) {
  createEventOutputStreamsMap(input: $input) {
  id
  claimsCanAccess
  claimsCanEdit
  event {
    id
    name
    description
    type
    mosChecked
    sport
    property
    fixture
    requestedBitrate
    bugRequired
    serviceStartTime
    lineupTime
    startTime
    endTime
    serviceEndTime
    status
    headTimeMin
    tailTimeMin
    inputAudioPid
    inputVideoPid
    claimsCanAccess
    claimsCanEdit
    version
    outputStreams {
      items {
        id
        outputStream {
          id
          name
          type
          address
          port
          latencyMs
          talkbackAddress
          talkbackPort
          bufferMinMs
          bufferMaxMs
          encPassphrase
          encKeyLen
          encType
          udpAddress
          udpPort
          udpRtt
        }
      }
    }
    inputFrcEnabled
    inputFrcInputFormat {
        id
        name
        description
      }
      inputFrcOututFormat {
        id
        name
        description
      }
    inputStreams {
      items {
        id
        inputStream {
          id
          name
          description
          type
          attachedTo
          address
          port
          latencyMs
          talkbackAddress
          talkbackPort
          bufferMinMs
          bufferMaxMs
          encPassphrase
          encKeyLen
          encType
          udpAddress
        }
      }
    }
    customer {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      claimsCanAccess
      claimsCanEdit
      version
    }
    account {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      claimsCanAccess
      claimsCanEdit
      version
    }
    lastUpdatedBy {
      id
      email
      name
      isApproved
      claimsCanAccess
      claimsCanEdit
      version
    }
    outputs {
      nextToken
    }
    audioMapping {
      nextToken
    }
    actions {
      nextToken
    }
    input {
      id
      url
      name
      status
      description
      claimsCanAccess
      claimsCanEdit
      version
      requiresPidInfo
      frcAvailable
    }
  }
  }
  }
  `;
export const createEventInputStreamsMap = `mutation CreateEventInputStreamsMap($input: CreateEventInputStreamsMapInput!) {
    createEventInputStreamsMap(input: $input) {
    id
    claimsCanAccess
    claimsCanEdit
    event {
      id
      name
      description
      type
      mosChecked
      sport
      property
      fixture
      requestedBitrate
      bugRequired
      serviceStartTime
      lineupTime
      startTime
      endTime
      serviceEndTime
      status
      headTimeMin
      tailTimeMin
      inputAudioPid
      inputVideoPid
      claimsCanAccess
      claimsCanEdit
      version
      customer {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      account {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      lastUpdatedBy {
        id
        email
        name
        isApproved
        claimsCanAccess
        claimsCanEdit
        version
      }
      outputs {
        nextToken
      }
      audioMapping {
        nextToken
      }
      actions {
        nextToken
      }
      input {
        id
        url
        name
        status
        description
        claimsCanAccess
        claimsCanEdit
        version
        requiresPidInfo
        frcAvailable
      }
    }
    }
    }
    `;
export const deleteEventInputStreamsMap = `mutation DeleteEventInputStreamsMap($input: DeleteEventInputStreamsMapInput!) {
  deleteEventInputStreamsMap(input: $input) {
    id
    event {
      id
    }
  }
}
`;
export const deleteEventOutputStreamsMap = `mutation DeleteEventOutputStreamsMap($input: DeleteEventOutputStreamsMapInput!) {
  deleteEventOutputStreamsMap(input: $input) {
    id
    event {
      id
    }
  }
}
`;
    
export const createEventOutputMap = `mutation CreateEventOutputMap($input: CreateEventOutputMapInput!) {
createEventOutputMap(input: $input) {
id
claimsCanAccess
claimsCanEdit
event {
  id
  name
  description
  type
  mosChecked
  sport
  property
  fixture
  requestedBitrate
  bugRequired
  serviceStartTime
  lineupTime
  startTime
  endTime
  serviceEndTime
  status
  headTimeMin
  tailTimeMin
  inputAudioPid
  inputVideoPid
  claimsCanAccess
  claimsCanEdit
  version
  outputStreams {
    items {
      id
      outputStream {
        id
        name
        type
        address
        port
        latencyMs
        talkbackAddress
        talkbackPort
        bufferMinMs
        bufferMaxMs
        encPassphrase
        encKeyLen
        encType
        udpAddress
        udpPort
        udpRtt
      }
    }
  }
  inputStreams {
    items {
      id
      inputStream {
        id
        name
        description
        type
        attachedTo
        address
        port
        latencyMs
        talkbackAddress
        talkbackPort
        bufferMinMs
        bufferMaxMs
        encPassphrase
        encKeyLen
        encType
        udpAddress
      }
    }
  }
  customer {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    claimsCanAccess
    claimsCanEdit
    version
  }
  account {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    claimsCanAccess
    claimsCanEdit
    version
  }
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  outputs {
    nextToken
  }
  audioMapping {
    nextToken
  }
  actions {
    nextToken
  }
  input {
    id
    url
    name
    status
    description
    claimsCanAccess
    claimsCanEdit
    version
    requiresPidInfo
    frcAvailable
  }
}
}
}
`;
export const updateEventOutputMap = `mutation UpdateEventOutputMap($input: UpdateEventOutputMapInput!) {
updateEventOutputMap(input: $input) {
id
claimsCanAccess
claimsCanEdit
event {
  id
  name
  mosChecked
  description
  type
  sport
  property
  fixture
  requestedBitrate
  bugRequired
  serviceStartTime
  lineupTime
  startTime
  endTime
  serviceEndTime
  status
  headTimeMin
  tailTimeMin
  inputAudioPid
  inputVideoPid
  claimsCanAccess
  claimsCanEdit
  version
  inputFrcEnabled
  inputFrcInputFormat {
    id
    name
    description
  }
  inputFrcOututFormat {
    id
    name
    description
  }
  outputStreams {
    items {
      id
      outputStream {
        id
        name
        type
        address
        port
        latencyMs
        talkbackAddress
        talkbackPort
        bufferMinMs
        bufferMaxMs
        encPassphrase
        encKeyLen
        encType
        udpAddress
        udpPort
        udpRtt
      }
    }
  }
  inputStreams {
    items {
      id
      inputStream {
        id
        name
        description
        type
        attachedTo
        address
        port
        latencyMs
        talkbackAddress
        talkbackPort
        bufferMinMs
        bufferMaxMs
        encPassphrase
        encKeyLen
        encType
        udpAddress
      }
    }
  }
  customer {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    claimsCanAccess
    claimsCanEdit
    version
  }
  account {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    claimsCanAccess
    claimsCanEdit
    version
  }
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  outputs {
    nextToken
  }
  audioMapping {
    nextToken
  }
  actions {
    nextToken
  }
  input {
    id
    url
    name
    status
    description
    claimsCanAccess
    claimsCanEdit
    version
    requiresPidInfo
    frcAvailable
  }
}
output {
  id
  url
  name
  description
  outputType
  status
  ottPlatform
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  events {
    nextToken
  }
  accounts {
    nextToken
  }
}
}
}
`;
export const deleteEventOutputMap = `mutation DeleteEventOutputMap($input: DeleteEventOutputMapInput!) {
deleteEventOutputMap(input: $input) {
id
}
`;
export const createEventAudioChannel = `mutation CreateEventAudioChannel($input: CreateEventAudioChannelInput!) {
  createEventAudioChannel(input: $input) {
    id
    channelNo
    description
    active
    stereo
    claimsCanAccess
    claimsCanEdit
  }
}
`;
export const updateEventAudioChannel = `mutation UpdateEventAudioChannel(
  $input: UpdateEventAudioChannelInput!
  $condition: ModelEventAudioChannelConditionInput
) {
  updateEventAudioChannel(input: $input, condition: $condition) {
    id
    channelNo
    description
    active
    stereo
  }
}
`;
export const deleteEventAudioChannel = `mutation DeleteEventAudioChannel(
  $input: DeleteEventAudioChannelInput!
  $condition: ModelEventAudioChannelConditionInput
) {
  deleteEventAudioChannel(input: $input, condition: $condition) {
    id
  }
}
`;

export const updateWowzaTarget = `mutation UpdateWowzaTarget($input: UpdateWowzaTargetInput!) {
  updateWowzaTarget(input: $input) {
    id
  }
}
`;

export const createEventAction = `mutation CreateEventAction($input: CreateEventActionInput!) {
createEventAction(input: $input) {
id
setupOffsetMs
actionType
claimsCanAccess
claimsCanEdit
version
lastUpdatedBy {
  id
  email
  name
  isApproved
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  clients {
    nextToken
  }
  accounts {
    nextToken
  }
  claims {
    nextToken
  }
  appSettings {
    nextToken
  }
}
configWowza {
  id
  type
  incomingTieline
  server
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  targets {
    nextToken
  }
}
configRoute {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  source {
    id
    name
    description
    originRouterGroup
    originRouterSourceId
    claimsCanAccess
    claimsCanEdit
    version
  }
  destinations {
    nextToken
  }
}
configPlayout {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
configRecording {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
configDownlink {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
configMaintenance {
  id
  serversToRestart
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
}
}
`;
export const updateEventAction = `mutation UpdateEventAction($input: UpdateEventActionInput!) {
updateEventAction(input: $input) {
id
setupOffsetMs
actionType
claimsCanAccess
claimsCanEdit
version
lastUpdatedBy {
  id
  email
  name
  isApproved
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  clients {
    nextToken
  }
  accounts {
    nextToken
  }
  claims {
    nextToken
  }
  appSettings {
    nextToken
  }
}
configWowza {
  id
  type
  incomingTieline
  server
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  targets {
    nextToken
  }
}
configRoute {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  source {
    id
    name
    description
    originRouterGroup
    originRouterSourceId
    claimsCanAccess
    claimsCanEdit
    version
  }
  destinations {
    nextToken
  }
}
configPlayout {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
configRecording {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
configDownlink {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
configMaintenance {
  id
  serversToRestart
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
}
}
`;
export const deleteEventAction = `mutation DeleteEventAction($input: DeleteEventActionInput!) {
deleteEventAction(input: $input) {
id
setupOffsetMs
actionType
claimsCanAccess
claimsCanEdit
version
lastUpdatedBy {
  id
  email
  name
  isApproved
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  clients {
    nextToken
  }
  accounts {
    nextToken
  }
  claims {
    nextToken
  }
  appSettings {
    nextToken
  }
}
configWowza {
  id
  type
  incomingTieline
  server
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  targets {
    nextToken
  }
}
configRoute {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  source {
    id
    name
    description
    originRouterGroup
    originRouterSourceId
    claimsCanAccess
    claimsCanEdit
    version
  }
  destinations {
    nextToken
  }
}
configPlayout {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
configRecording {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
configDownlink {
  id
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
configMaintenance {
  id
  serversToRestart
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
}
}
}
`;
export const createEventActionConfigWowza = `mutation CreateEventActionConfigWowza(
$input: CreateEventActionConfigWowzaInput!
) {
createEventActionConfigWowza(input: $input) {
id
type
incomingTieline
server
claimsCanAccess
claimsCanEdit
version
lastUpdatedBy {
  id
  email
  name
  isApproved
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  clients {
    nextToken
  }
  accounts {
    nextToken
  }
  claims {
    nextToken
  }
  appSettings {
    nextToken
  }
}
targets {
  items {
    id
    type
    application
    target
    streamKey
    claimsCanAccess
    claimsCanEdit
    version
  }
  nextToken
}
}
}
`;
export const updateEventActionConfigWowza = `mutation UpdateEventActionConfigWowza(
$input: UpdateEventActionConfigWowzaInput!
) {
updateEventActionConfigWowza(input: $input) {
id
type
incomingTieline
server
claimsCanAccess
claimsCanEdit
version
lastUpdatedBy {
  id
}
targets {
  items {
    id
    type
    application
    target
    streamKey
    claimsCanAccess
    claimsCanEdit
    version
  }
  nextToken
}
}
}
`;
export const deleteEventActionConfigWowza = `mutation DeleteEventActionConfigWowza(
$input: DeleteEventActionConfigWowzaInput!
) {
deleteEventActionConfigWowza(input: $input) {
id
type
incomingTieline
server
claimsCanAccess
claimsCanEdit
version
lastUpdatedBy {
  id
  email
  name
  isApproved
  claimsCanAccess
  claimsCanEdit
  version
  lastUpdatedBy {
    id
    email
    name
    isApproved
    claimsCanAccess
    claimsCanEdit
    version
  }
  clients {
    nextToken
  }
  accounts {
    nextToken
  }
  claims {
    nextToken
  }
  appSettings {
    nextToken
  }
}
targets {
  items {
    id
    type
    application
    target
    streamKey
    claimsCanAccess
    claimsCanEdit
    version
  }
  nextToken
}
}
}
`;

export const addEventReport = `mutation AddEventReport($input: AddEventReportInput!) {
  addEventReport(input: $input) {
    id
    ntr
    description
    claimsCanAccess
    claimsCanEdit
    version
  }
}
`;

export const eventReschedule = `mutation EventReschedule($input: EventRescheduleInput!) {
  eventReschedule(input: $input) {
    id
  }
}
`;


export const eventChangeStatus = `mutation EventChangeStatus($input: EventChangeStatusInput!) {
  eventChangeStatus(input: $input) {
    id
  }
}
`;