<template>
  <el-dialog
    title="Add booking"
    :visible.sync="createEventDialog"
    v-show="createEventDialog"
    class="add-booking-dialog"
  >
    <div v-bar>
      <div >
        <div class="dialog-form">
          <el-row :gutter="36">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="flex flex-col">
                <!-- SYS ADMIN -->
                <div>
                  <label class="el-form-item__label clear">Account</label>
                </div>
                <div class="">
                  <el-select v-model="eventFormAccount" value-key="account.id"
                    @change="eventAccountChanged"
                    filterable
                    class="mb-1 mr-3"
                    style="max-width: 300px; display: inline-block"
                    size="small">
                    <el-option
                      v-for="item in avaiableAccounts"
                      :key="item.account.id"
                      :value="item"
                      :label="item.account.name"
                    >
                      <span style="float: left">{{ item.account.name }}</span>
                      <span
                        style="float: right; color: #8492a6; margin-left: 20px; font-size: 12px"
                      >{{ item.client.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="inline-block mb-1">
                    <el-radio-group v-model="eventStatus" size="small">
                      <el-radio-button label="PENDING">Pencil Booking</el-radio-button>
                      <el-radio-button label="CLIENT_CONFIRMED">Client Confirmed Booking</el-radio-button>
                    </el-radio-group>
                  </div>
                </div>
                <br />
                <!-- END SYS ADMIN -->
              </div>
              <!-- BASIC INFORMATIONS -->
              <el-form ref="createEventForm"
                :model="eventForm"
                :rules="formRules"
                label-position="top">
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form-item label="Event booking (required)" prop="fixture">
                      <el-input size="large" v-model="eventForm.fixture" placeholder="Event booking">
                      </el-input>
                    </el-form-item>
                    <el-form-item label="Description" prop="description">
                      <el-input size="small" v-model="eventForm.description"
                        placeholder="Description">
                      </el-input>
                    </el-form-item>
                    <el-form-item size="small" label="Sport" prop="sport">
                      <el-input v-model="eventForm.sport" placeholder="Sport"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="Property" prop="property">
                      <el-input v-model="eventForm.property" placeholder="Property"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="Group ID" prop="groupId" v-if="isSysAdmin">
                      <el-input v-model="eventForm.groupId" placeholder="group ID"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"
                    style="padding-right: 20px">
                    <el-row :gutter="20">
                      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"
                        @click.native="openPicker('lineupDate')">
                        <el-form-item size="small" label="Line-up date (GMT)" prop="lineupDate"
                            :class="{mobileInput: isMobileOnly}">
                          <el-date-picker
                            v-model="eventForm.lineupDate"
                            @change="lineupDateChanged"
                            :picker-options="datePickerOptions"
                            value-format="yyyy-MM-dd"
                            placeholder="Select line-up date"
                          ></el-date-picker>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"
                        @click.native="openPicker('startDate')">
                        <el-form-item size="small" label="Start date (GMT)" prop="startDate"
                          :class="{mobileInput: isMobileOnly}">
                          <el-date-picker
                            v-model="eventForm.startDate"
                            @change="startDateChanged"
                            :picker-options="datePickerOptions"
                            value-format="yyyy-MM-dd"
                            placeholder="Select start date"
                          ></el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"
                        @click.native="openPicker('endDate')">
                        <el-form-item size="small" label="End date (GMT)" prop="endDate"
                            :class="{mobileInput: isMobileOnly}">
                          <el-date-picker
                            v-model="eventForm.endDate"
                            value-format="yyyy-MM-dd"
                            :picker-options="datePickerOptions"
                            placeholder="Select end date"
                          ></el-date-picker>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="16">
                      <el-col :span="8" @click.native="openPicker('lineupTime')">
                        <el-form-item size="small" label="Line-up time (GMT)" prop="lineupTime"
                            :class="{mobileInput: isMobileOnly}">
                          <el-time-picker
                            value-format="HH:mm"
                            v-model="eventForm.lineupTime"
                            type="datetime"
                            format="HH:mm"
                            placeholder="Select line-up Time"
                            @blur="lineupTimeBlur"
                          ></el-time-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" @click.native="openPicker('startTime')">
                        <el-form-item size="small" label="Start Time (GMT)" prop="startTime"
                            :class="{mobileInput: isMobileOnly}">
                          <el-time-picker
                            v-model="eventForm.startTime"
                            value-format="HH:mm"
                            format="HH:mm"
                            @change="startTimeChanged"
                            placeholder="Select start time"
                          ></el-time-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" @click.native="openPicker('endTime')">
                        <el-form-item size="small" label="End time (GMT)" prop="endTime"
                            :class="{mobileInput: isMobileOnly}">
                          <el-time-picker
                            v-model="eventForm.endTime"
                            value-format="HH:mm"
                            format="HH:mm"
                            placeholder="Select end time"
                          ></el-time-picker>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-collapse-transition>
                      <el-alert type="info" v-if="isEndBeforeStartTime">
                        Make sure <strong>End date</strong>
                        is not the same as <strong>Start date</strong>
                      </el-alert>
                    </el-collapse-transition>
                    <el-collapse-transition>
                      <el-alert type="warning" v-if="isCloseToServiceStartTime">
                        Late Event. This event is close to start time needs to be started manually
                      </el-alert>
                    </el-collapse-transition>
                    <el-collapse-transition>
                      <el-alert type="warning" v-if="doesEventStarted">
                        Late Event. This event is after start time needs to be started manually
                      </el-alert>
                    </el-collapse-transition>
                    <!-- INPUTS -->
                    <el-row :gutter="16">
                      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"
                        @click.native="openPicker('input')">
                        <el-form-item size="small" label="Input" prop="inputId"
                          :class="{mobileInput: isMobileOnly}">
                          <el-select
                            filterable
                            clearable
                            @change="outputProfileChanged"
                            v-model="eventForm.inputId">
                            <el-option
                              v-for="input in accountInputs"
                              :key="input.id"
                              :value="input.id"
                              :label="input.name"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"
                        @click.native="openPicker('outputProfile')">
                        <el-form-item size="small" label="Output profile" prop="outputProfileId"
                          :class="{mobileInput: isMobileOnly}">
                          <el-select
                            v-model="eventForm.outputProfileId"
                            @clear="eventForm.outputProfileId = null"
                            @change="outputProfileChanged"
                            clearable
                            filterable
                          >
                            <el-option
                              v-for="input in outputProfilesSorted"
                              :key="input.id"
                              :value="input.id"
                              :label="input.name"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                        <!--
                        <el-form-item size="small" label="Outputs" prop="outputIds" >
                          <el-select
                            v-model="eventForm.outputIds"
                            multiple
                            collapse-tags
                            :disabled="!!eventForm.outputProfileId"
                          >
                            <el-option
                              v-for="input in accountOutputs"
                              :key="input.id"
                              :value="input.id"
                              :label="input.name"
                              class="output-select-option"
                            >
                              <div>{{input.name}} </div>
                              <small>{{input.description}}</small>
                            </el-option>
                          </el-select>
                        </el-form-item>
                        -->
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                        <el-form-item size="small" prop="bugRequired">
                          <label for="bugRequired" class="el-form-item__label">
                            <br />
                            <br />
                            <br />
                          </label>
                          <el-switch v-model="eventForm.bugRequired" active-text="Bug required">
                          </el-switch>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- DOWNLINK CONFIGURATION -->
                    <div v-if="hasDownlinkConfig">
                      <el-row :gutter="16">
                        <el-col :span="24">
                          <h3><strong>Downlink configuration</strong></h3>
                          <div class="text-red-500 text-xs mt-1 mb-1" v-show="fileList.length === 0">When making Satellite booking please attach the booking document with details</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="16">
                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                          <el-form-item size="small"
                            label="Satellite name & position"
                            prop="downlinkConfig.satelliteName"
                            required>
                            <el-input v-model="eventForm.downlinkConfig.satelliteName"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                          <el-form-item size="small" label="BISS"
                            prop="downlinkConfig.BISS"
                            required>
                            <el-input v-model="eventForm.downlinkConfig.BISS"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                          <el-form-item size="small" label="Service ID"
                            prop="downlinkConfig.service"
                            required>
                            <el-input v-model="eventForm.downlinkConfig.service"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="16">
                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                          <el-form-item size="small" label="Satellite polarization"
                            prop="downlinkConfig.satellitePolarization" required>
                            <el-radio-group v-model="eventForm.downlinkConfig.satellitePolarization"
                              size="small">
                              <el-radio-button label="Vertical"></el-radio-button>
                              <el-radio-button label="Horizontal"></el-radio-button>
                            </el-radio-group>
                          </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="6">
                          <el-form-item size="small" label="Satellite frequency (MHz)"
                            prop="downlinkConfig.satelliteFrequency" required>
                            <el-input-number v-model="eventForm.downlinkConfig.satelliteFrequency">
                            </el-input-number>
                          </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="10">
                          <el-form-item
                            size="small"
                            label="Symbol rate (KBaud)"
                            prop="downlinkConfig.symbolRate"
                            required>
                            <el-input
                              placeholder="Choose or enter rate"
                              v-model="eventForm.downlinkConfig.symbolRate">
                              <el-dropdown slot="append" size="small" type="primary">
                                <el-button type="primary">
                                  <i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                  v-for="item in [3400,3600,5000,7000,7120,7200,7500,9850,10000,12500,15000,34000]"
                                  :key="item"
                                  @click.native="eventForm.downlinkConfig.symbolRate = item"
                                  >{{item}}</el-dropdown-item>
                                </el-dropdown-menu>
                              </el-dropdown>
                            </el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>
                    <!-- Input FRC -->
                    <div v-if="frcAvailable" class="mb-2">
                      <el-switch v-model="eventForm.inputFrcEnabled" @change="toggleFRC('inputFrcEnabled', eventForm.inputFrcEnabled)"  active-text="Enable Framerate conversion"
                        style="margin-top: -33px;">
                      </el-switch>
                    </div>
                    <div v-if="eventForm.inputFrcEnabled" class=" mb-4" style="margin-top: -10px">
                      <el-row :gutter="20">
                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"
                          @click.native="openPicker('inputFrcInputFormatId')">
                          <el-form-item size="small" label="Input Format" prop="inputFrcInputFormatId"
                            :class="{mobileInput: isMobileOnly}">
                            <el-select
                              v-model="eventForm.inputFrcInputFormatId"
                              value-key="id"
                            >
                              <el-option
                                v-for="input in pictureFormats"
                                :key="input.id"
                                :value="input.id"
                                :label="input.name"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"
                          @click.native="openPicker('inputFrcOututFormatId')">
                          <el-form-item size="small" label="Output Format" prop="inputFrcOututFormatId"
                            :class="{mobileInput: isMobileOnly}">
                            <el-select
                              v-model="eventForm.inputFrcOututFormatId"
                            >
                              <el-option
                                v-for="input in pictureFormats"
                                :key="input.id"
                                :value="input.id"
                                :label="input.name"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>

                    <!-- input details -->
                    <div v-if="areInputDetailsRequired">
                      <el-form-item label="Input Details" prop="inputDetails" required>
                        <el-input size="small" v-model="eventForm.inputDetails" placeholder="Input Details">
                        </el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
                <!-- MOBILE DATE PICKERS -->
                <van-popup
                  v-model="mobilePopups.inputFrcInputFormatId"
                  round position="bottom">
                  <van-picker
                    show-toolbar
                    :columns="pictureFormats"
                    value-key="name"
                    @cancel="mobilePopups.inputFrcInputFormatId = false"
                    @confirm="oninputFrcInputFormatIdConfirm"
                  />
                </van-popup>
                <van-popup
                  v-model="mobilePopups.inputFrcOututFormatId"
                  round position="bottom">
                  <van-picker
                    show-toolbar
                    :columns="pictureFormats"
                    value-key="name"
                    @cancel="mobilePopups.inputFrcOututFormatId = false"
                    @confirm="oninputFrcOututFormatIdConfirm"
                  />
                </van-popup>
                <van-popup
                  v-model="mobilePopups.lineupDate"
                  round position="bottom">
                  <van-datetime-picker
                    v-model="mobilePopups.lineupDateValue"
                    type="date"
                    :min-date="minDate"
                    @cancel="mobilePopups.lineupDate = false"
                    @confirm="lineupDateChangedMobile()"
                  />
                </van-popup>
                <van-popup
                  v-model="mobilePopups.startDate"
                  round position="bottom">
                  <van-datetime-picker
                    v-model="mobilePopups.startDateValue"
                    type="date"
                    :min-date="minDate"
                    @cancel="mobilePopups.startDate = false"
                    @confirm="startDateChangedMobile()"
                  />
                </van-popup>
                <van-popup
                  v-model="mobilePopups.endDate"
                  round position="bottom">
                  <van-datetime-picker
                    v-model="mobilePopups.endDateValue"
                    type="date"
                    :min-date="minDate"
                    @cancel="mobilePopups.endDate = false"
                    @confirm="endDateChangedMobile()"
                  />
                </van-popup>
                <van-popup
                  v-model="mobilePopups.lineupTime"
                  round position="bottom">
                  <van-datetime-picker
                    v-model="mobilePopups.lineupTimeValue"
                    type="time"
                    :min-date="minDate"
                    @cancel="mobilePopups.lineupTime = false"
                    @confirm="lineupTimeChangedMobile()"
                  />
                </van-popup>
                <van-popup
                  v-model="mobilePopups.startTime"
                  round position="bottom">
                  <van-datetime-picker
                    v-model="mobilePopups.startTimeValue"
                    type="time"
                    :min-date="minDate"
                    @cancel="mobilePopups.startTime = false"
                    @confirm="startTimeChangedMobile()"
                  />
                </van-popup>
                <van-popup
                  v-model="mobilePopups.endTime"
                  round position="bottom">
                  <van-datetime-picker
                    v-model="mobilePopups.endTimeValue"
                    type="time"
                    :min-date="minDate"
                    @cancel="mobilePopups.endTime = false"
                    @confirm="endTimeChangedMobile()"
                  />
                </van-popup>
                <!-- END MOBILE DATE PICKERS -->
                <!-- MOBILE DATA PICKERS -->
                 <van-popup
                  v-model="mobilePopups.input"
                  round position="bottom">
                  <van-picker
                    show-toolbar
                    :columns="accountInputs"
                    value-key="name"
                    @cancel="mobilePopups.input = false"
                    @confirm="onInputConfirm"
                  />
                </van-popup>
                <van-popup
                  v-model="mobilePopups.outputProfile"
                  round position="bottom">
                  <van-picker
                    show-toolbar
                    :columns="outputProfilesSorted"
                    value-key="name"
                    @cancel="mobilePopups.outputProfile = false"
                    @confirm="onOutputProfileConfirm"
                  />
                </van-popup>
                <!-- END MOBILE DATA PICKERS -->
              </el-form>
            </el-col>

            <!--
                    RIGHT COLUMN
                    AUDIO MAPPING
            -->
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <div alt="Source Audio">
                <h3>
                  <strong>Source Audio</strong>
                </h3>
                <div class="audio-channel audio-channel-th">
                  <div class="audio-channel-number">Pair / Channel #</div>
                  <div class="audio-channel-name-left">Description</div>
                  <div class="audio-channel-active">Active</div>
                  <div class="audio-channel-stereo">Stereo / Mono</div>
                  <div class="audio-channel-actions"></div>
                </div>
                <div
                  class="audio-channel"
                  v-for="(audioChannel, index) in eventForm.audioMapping"
                  :key="audioChannel.description"
                >
                  <div class="audio-channel-number">{{audioChannel.channelNo}}</div>
                  <div class="audio-channel-name-left">{{audioChannel.description}}</div>
                  <div class="audio-channel-active">
                    <el-tag size="mini" type="success" v-show="audioChannel.active">ACTIVE</el-tag>
                  </div>
                  <div class="audio-channel-active">
                    <el-tag size="mini" type="danger" v-show="audioChannel.stereo || audioChannel.stereo === null">STEREO</el-tag>
                    <el-tag size="mini" type="info" v-show="!audioChannel.stereo && audioChannel.stereo !== null">MONO</el-tag>
                  </div>
                  <div class="audio-channel-actions">
                    <bly-button
                      type="clear"
                      @click.native="deleteAudioChannel(eventForm.audioMapping, index, false)"
                    >
                      <i class="bi bly-delete"></i>
                    </bly-button>
                  </div>
                </div>
                <el-button
                  size="mini"
                  type="primary"
                  @click="showAudioMappingForm = !showAudioMappingForm"
                >Add audio pair</el-button>
                <el-divider direction="vertical"></el-divider>
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <el-button type="default" size="mini">
                      Load presets
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                  </span>

                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(profile, index) in audioProfilesFiltered"
                      :key="'cp' + index"
                      @click.native="loadAudioProfile(profile)"
                    >{{profile.name}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-tooltip :content="'Create Source Audio preset from current setup'">
                  <el-button
                    style="margin-left: 14px"
                    size="mini" @click="audioDialogFormVisible = true">Create preset</el-button>
                </el-tooltip>
                <br />
                <br />
                <el-card shadow="never" v-show="showAudioMappingForm">
                  <el-form
                    ref="createEventAudioMappingForm"
                    :model="audioMappingForm"
                    label-position="top"
                  >
                    <div class="audio-mapping-form">
                      <el-row :gutter="16">
                        <el-col :span="5">
                          <el-form-item label="Pair / Channel #">
                            <el-input
                              size="mini"
                              v-model="audioMappingForm.channelNo"
                              placeholder="Channel number"
                            ></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="Description" prop="description" required>
                            <el-input
                              size="mini"
                              v-model="audioMappingForm.description"
                              placeholder="Description"
                            ></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="3">
                          <el-form-item label="Active">
                            <el-switch v-model="audioMappingForm.active" class="mt-1"></el-switch>
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="Stereo / Mono">
                            <el-radio-group v-model="audioMappingForm.stereo" size="mini">
                              <el-radio-button :label="true">STEREO</el-radio-button>
                              <el-radio-button :label="false">MONO</el-radio-button>
                            </el-radio-group>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-button
                        size="small"
                        @click="showAudioMappingForm = !showAudioMappingForm"
                      >Cancel</el-button>
                      <el-button
                        size="small"
                        type="primary"
                        @click="addAudioChannel('createEventAudioMappingForm',
                              eventForm.audioMapping, null)"
                      >Add audio pair</el-button>
                    </div>
                  </el-form>
                </el-card>
              </div>
          <div class="upload">
            <h3><strong>Upload Files</strong></h3>
            <div class="upload-file-list">
              <div class="upload-file"
              v-for="(file, index) in fileList" :key="file.uid">
                {{file.name}}
                <div class="file-upload-right">
                  <span  v-if="!file.percentage">
                  {{file.size | prettyBytes}}
                  </span>
                  <span v-if="file.percentage > 0">
                  {{file.percentage}}%
                  </span>
                  <i class="icon-btn bi bly-close" @click="removeFileFromList(index)"></i>
                </div>
                <el-progress
                  v-if="file.percentage > 0"
                  :percentage="file.percentage"
                  :status="file.status"
                  :show-text="false"></el-progress>
              </div>
            </div>
            <el-upload
              class="upload-files"
              drag
              action="uploadAction"
              :auto-upload="false"
              :on-change="handleUploadChange"
              :show-file-list="false"
              :multiple="true"
              :accept="'.png, .jpg, jpeg, .doc, .docx, .pdf, .xls, .xlsx, .csv'"
              :http-request="handleHTTPUpload">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Drop file here or <em>click to select</em></div>
              <div class="el-upload__tip" slot="tip">
                Supported files: png, jpg, doc, pdf, xls, csv
              </div>
            </el-upload>
          </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <el-form>
                <!-- Commentary -->
                <div class="commentary-wrapper" v-if="hasCommentaryAccess">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                      <h3><strong>Commentary</strong>
                      <el-form-item size="small" prop="isEnabled" style="display: inline-block;position: relative;top: -2px;left: 10px;">
                        <el-switch v-model="commentaryForm.isEnabled" @change="enableCommentary" >
                        </el-switch>
                      </el-form-item>
                      </h3>
                    </el-col>
                  </el-row>
                  <div v-if="commentaryForm.isEnabled">
                    <el-row :gutter="12">
                      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                        <el-form-item size="small" label="Language">
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                        <el-form-item size="small" label="Output profile">
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                        <el-form-item size="small" label="User">
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2">
                        Delete
                      </el-col>
                    </el-row>
                    <el-row :gutter="12" v-for="(lang, index) in commentaryForm.languages" :key="lang.language">
                      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                        <el-form-item size="small" prop="lang.languge"
                          :class="{mobileInput: isMobileOnly}">
                          <el-select
                            v-model="lang.language"
                            filterable
                          >
                            <el-option
                              v-for="input in languages"
                              :key="input.code"
                              :value="input.name"
                              :label="input.name"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                        <el-form-item size="small" prop="lang.outputProfileId"
                          :class="{mobileInput: isMobileOnly}">
                          <el-select
                            v-model="lang.outputProfileId"
                            @clear="lang.outputProfileId = null"
                            clearable
                            filterable
                          >
                            <el-option
                              v-for="input in outputProfilesSorted"
                              :key="input.id"
                              :value="input.id"
                              :label="input.name"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                        <el-form-item size="small" prop="lang.user"
                          :class="{mobileInput: isMobileOnly}">
                          <el-select
                            v-model="lang.user"
                            filterable
                            value-key="id"
                          >
                            <el-option
                              v-for="input in clientUsers"
                              :key="input.id"
                              :value="input"
                              :label="input.email"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2">
                        <i @click="commentaryForm.languages.splice(index, 1)" class="bi bly-delete icon-btn" style="top: -5px;position: relative;"></i>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col>
                        <el-button
                          size="mini"
                          type="primary"
                          @click="commentaryForm.languages.push(JSON.parse(JSON.stringify(languageModel)))"
                        >Add language</el-button>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-form>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="createEventDialog = false">Cancel</el-button>
          <el-button type="primary" @click="handleSubmit(false)"
            :disabled="loadingSave"
            v-loading="loadingSave">Save</el-button>
          <el-button type="primary" @click="handleSubmit(true)"
            :disabled="loadingSave"
            v-loading="loadingSave">Save And Add Another</el-button>
        </span>
      </div>
      <el-dialog title="Create preset" append-to-body :visible.sync="audioDialogFormVisible" width="360px">
        <div v-if="audioPresetErrorMessage">
          <el-alert
            :title="audioPresetErrorMessage"
            type="error"
            effect="dark">
          </el-alert>
        </div>
        <el-form :model="audioPresetForm">
          <el-form-item label="Name">
            <el-input v-model="audioPresetForm.name" tabindex="1" autocomplete="off" required></el-input>
          </el-form-item>
          <el-form-item label="Description">
            <el-input v-model="audioPresetForm.description" autocomplete="off" required></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="audioDialogFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="saveAudioProfile" :loading="loadingSavePresets">Save</el-button>
        </span>
      </el-dialog>
    </div>
  </el-dialog>
</template>
<script>
import moment from 'moment';
import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import { Amplify, Storage } from 'aws-amplify'

export default {
  name: 'AgendaAddBooking',
  data() {
    return {
      isMobileOnly,
      loadingSave: false,
      eventForm: {
        bugRequired: false,
        fixture: '',
      },
      audioDialogFormVisible: false,
      audioPresetErrorMessage: false,
      loadingSavePresets: false,
      audioPresetForm: {
        name: '',
        description: '',
      },
      eventStatus: 'CLIENT_CONFIRMED',
      commentaryForm: {
        isEnabled: false,
        languages: [
        ],
      },
      languageModel: {
        language: null,
        user: null,
        outputProfileId: null,
      },
      formRules: {
        fixture: [
          { required: true, message: 'Fixture is required', trigger: 'blur' },
        ],
        lineupDate: [
          { validator: this.validateLineupDate, trigger: 'blur' },
        ],
        startDate: [
          { validator: this.validateStartDate, trigger: 'blur' },
        ],
        endDate: [
          { validator: this.validateEndDate, trigger: 'blur' },
        ],
        lineupTime: [
          { validator: this.validateLineupTime, trigger: 'blur' },
        ],
        startTime: [
          { validator: this.validateStartTime, trigger: 'blur' },
        ],
        endTime: [
          { validator: this.validateEndTime, trigger: 'blur' },
        ],
        'downlinkConfig.symbolRate': [
          { required: true, message: 'Symbol Rate is required' },
        ],
        'downlinkConfig.satelliteName': [
          { required: true, message: 'Name is required' },
        ],
      },
      // event aacount selectable by sys admin
      eventFormAccount: null,

      eventAudioMapping: [
        {
          active: false,
          channelNo: 0,
          description: 'Left',
        },
      ],
      showAudioMappingForm: false,
      showAudioMappingEditForm: false,
      audioMappingForm: {
        active: false,
        channelNo: 1,
        description: null,
        stereo: true,
      },
      mobilePopups: {
        lineupDate: false,
        lineupDateValue: '',
        startDate: false,
        startDateValue: '',
        endDate: false,
        endDateValue: '',
        lineupTime: false,
        lineupTimeValue: '',
        startTime: false,
        startTimeValue: '',
        endTime: false,
        endTimeValue: '',
        input: false,
        outputProfile: false,
        inputFrcInputFormatId: false,
        inputFrcInputFormatIdValue: '',
        inputFrcOututFormatId: false,
        inputFrcOututFormatIdValue: '',
      },
      minDate: new Date(),
      currentDate: new Date(),
      datePickerOptions: {
        disabledDate(time) {
          // if (this.isSysAdmin) return new Date('1897');
          return time.getTime() < new Date(new Date().setDate(new Date().getDate() - 1));
        },
      },

      // upload files
      fileList: [],
      fileUploads: {},
      uploading: false,
      overallUploadProgress: 0,
      languages: [
        {
          code: 'ab',
          name: 'Abkhaz',
        },
        {
          code: 'aa',
          name: 'Afar',
        },
        {
          code: 'af',
          name: 'Afrikaans',
        },
        {
          code: 'ak',
          name: 'Akan',
        },
        {
          code: 'sq',
          name: 'Albanian',
        },
        {
          code: 'am',
          name: 'Amharic',
        },
        {
          code: 'ar',
          name: 'Arabic',
        },
        {
          code: 'an',
          name: 'Aragonese',
        },
        {
          code: 'hy',
          name: 'Armenian',
        },
        {
          code: 'as',
          name: 'Assamese',
        },
        {
          code: 'av',
          name: 'Avaric',
        },
        {
          code: 'ae',
          name: 'Avestan',
        },
        {
          code: 'ay',
          name: 'Aymara',
        },
        {
          code: 'az',
          name: 'Azerbaijani',
        },
        {
          code: 'bm',
          name: 'Bambara',
        },
        {
          code: 'ba',
          name: 'Bashkir',
        },
        {
          code: 'eu',
          name: 'Basque',
        },
        {
          code: 'be',
          name: 'Belarusian',
        },
        {
          code: 'bn',
          name: 'Bengali; Bangla',
        },
        {
          code: 'bh',
          name: 'Bihari',
        },
        {
          code: 'bi',
          name: 'Bislama',
        },
        {
          code: 'bs',
          name: 'Bosnian',
        },
        {
          code: 'br',
          name: 'Breton',
        },
        {
          code: 'bg',
          name: 'Bulgarian',
        },
        {
          code: 'my',
          name: 'Burmese',
        },
        {
          code: 'ca',
          name: 'Catalan; Valencian',
        },
        {
          code: 'ch',
          name: 'Chamorro',
        },
        {
          code: 'ce',
          name: 'Chechen',
        },
        {
          code: 'ny',
          name: 'Chichewa; Chewa; Nyanja',
        },
        {
          code: 'zh',
          name: 'Chinese',
        },
        {
          code: 'cv',
          name: 'Chuvash',
        },
        {
          code: 'kw',
          name: 'Cornish',
        },
        {
          code: 'co',
          name: 'Corsican',
        },
        {
          code: 'cr',
          name: 'Cree',
        },
        {
          code: 'hr',
          name: 'Croatian',
        },
        {
          code: 'cs',
          name: 'Czech',
        },
        {
          code: 'da',
          name: 'Danish',
        },
        {
          code: 'dv',
          name: 'Divehi; Dhivehi; Maldivian;',
        },
        {
          code: 'nl',
          name: 'Dutch',
        },
        {
          code: 'dz',
          name: 'Dzongkha',
        },
        {
          code: 'en',
          name: 'English',
        },
        {
          code: 'eo',
          name: 'Esperanto',
        },
        {
          code: 'et',
          name: 'Estonian',
        },
        {
          code: 'ee',
          name: 'Ewe',
        },
        {
          code: 'fo',
          name: 'Faroese',
        },
        {
          code: 'fj',
          name: 'Fijian',
        },
        {
          code: 'fi',
          name: 'Finnish',
        },
        {
          code: 'fr',
          name: 'French',
        },
        {
          code: 'ff',
          name: 'Fula; Fulah; Pulaar; Pular',
        },
        {
          code: 'gl',
          name: 'Galician',
        },
        {
          code: 'ka',
          name: 'Georgian',
        },
        {
          code: 'de',
          name: 'German',
        },
        {
          code: 'el',
          name: 'Greek, Modern',
        },
        {
          code: 'gn',
          name: 'GuaranÃ­',
        },
        {
          code: 'gu',
          name: 'Gujarati',
        },
        {
          code: 'ht',
          name: 'Haitian; Haitian Creole',
        },
        {
          code: 'ha',
          name: 'Hausa',
        },
        {
          code: 'he',
          name: 'Hebrew (modern)',
        },
        {
          code: 'hz',
          name: 'Herero',
        },
        {
          code: 'hi',
          name: 'Hindi',
        },
        {
          code: 'ho',
          name: 'Hiri Motu',
        },
        {
          code: 'hu',
          name: 'Hungarian',
        },
        {
          code: 'ia',
          name: 'Interlingua',
        },
        {
          code: 'id',
          name: 'Indonesian',
        },
        {
          code: 'ie',
          name: 'Interlingue',
        },
        {
          code: 'ga',
          name: 'Irish',
        },
        {
          code: 'ig',
          name: 'Igbo',
        },
        {
          code: 'ik',
          name: 'Inupiaq',
        },
        {
          code: 'io',
          name: 'Ido',
        },
        {
          code: 'is',
          name: 'Icelandic',
        },
        {
          code: 'it',
          name: 'Italian',
        },
        {
          code: 'iu',
          name: 'Inuktitut',
        },
        {
          code: 'ja',
          name: 'Japanese',
        },
        {
          code: 'jv',
          name: 'Javanese',
        },
        {
          code: 'kl',
          name: 'Kalaallisut, Greenlandic',
        },
        {
          code: 'kn',
          name: 'Kannada',
        },
        {
          code: 'kr',
          name: 'Kanuri',
        },
        {
          code: 'ks',
          name: 'Kashmiri',
        },
        {
          code: 'kk',
          name: 'Kazakh',
        },
        {
          code: 'km',
          name: 'Khmer',
        },
        {
          code: 'ki',
          name: 'Kikuyu, Gikuyu',
        },
        {
          code: 'rw',
          name: 'Kinyarwanda',
        },
        {
          code: 'ky',
          name: 'Kyrgyz',
        },
        {
          code: 'kv',
          name: 'Komi',
        },
        {
          code: 'kg',
          name: 'Kongo',
        },
        {
          code: 'ko',
          name: 'Korean',
        },
        {
          code: 'ku',
          name: 'Kurdish',
        },
        {
          code: 'kj',
          name: 'Kwanyama, Kuanyama',
        },
        {
          code: 'la',
          name: 'Latin',
        },
        {
          code: 'lb',
          name: 'Luxembourgish, Letzeburgesch',
        },
        {
          code: 'lg',
          name: 'Ganda',
        },
        {
          code: 'li',
          name: 'Limburgish, Limburgan, Limburger',
        },
        {
          code: 'ln',
          name: 'Lingala',
        },
        {
          code: 'lo',
          name: 'Lao',
        },
        {
          code: 'lt',
          name: 'Lithuanian',
        },
        {
          code: 'lu',
          name: 'Luba-Katanga',
        },
        {
          code: 'lv',
          name: 'Latvian',
        },
        {
          code: 'gv',
          name: 'Manx',
        },
        {
          code: 'mk',
          name: 'Macedonian',
        },
        {
          code: 'mg',
          name: 'Malagasy',
        },
        {
          code: 'ms',
          name: 'Malay',
        },
        {
          code: 'ml',
          name: 'Malayalam',
        },
        {
          code: 'mt',
          name: 'Maltese',
        },
        {
          code: 'mi',
          name: 'MÄori',
        },
        {
          code: 'mr',
          name: 'Marathi (MarÄá¹­hÄ«)',
        },
        {
          code: 'mh',
          name: 'Marshallese',
        },
        {
          code: 'mn',
          name: 'Mongolian',
        },
        {
          code: 'na',
          name: 'Nauru',
        },
        {
          code: 'nv',
          name: 'Navajo, Navaho',
        },
        {
          code: 'nb',
          name: 'Norwegian BokmÃ¥l',
        },
        {
          code: 'nd',
          name: 'North Ndebele',
        },
        {
          code: 'ne',
          name: 'Nepali',
        },
        {
          code: 'ng',
          name: 'Ndonga',
        },
        {
          code: 'nn',
          name: 'Norwegian Nynorsk',
        },
        {
          code: 'no',
          name: 'Norwegian',
        },
        {
          code: 'ii',
          name: 'Nuosu',
        },
        {
          code: 'nr',
          name: 'South Ndebele',
        },
        {
          code: 'oc',
          name: 'Occitan',
        },
        {
          code: 'oj',
          name: 'Ojibwe, Ojibwa',
        },
        {
          code: 'cu',
          name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
        },
        {
          code: 'om',
          name: 'Oromo',
        },
        {
          code: 'or',
          name: 'Oriya',
        },
        {
          code: 'os',
          name: 'Ossetian, Ossetic',
        },
        {
          code: 'pa',
          name: 'Panjabi, Punjabi',
        },
        {
          code: 'pi',
          name: 'PÄli',
        },
        {
          code: 'fa',
          name: 'Persian (Farsi)',
        },
        {
          code: 'pl',
          name: 'Polish',
        },
        {
          code: 'ps',
          name: 'Pashto, Pushto',
        },
        {
          code: 'pt',
          name: 'Portuguese',
        },
        {
          code: 'qu',
          name: 'Quechua',
        },
        {
          code: 'rm',
          name: 'Romansh',
        },
        {
          code: 'rn',
          name: 'Kirundi',
        },
        {
          code: 'ro',
          name: 'Romanian, [])',
        },
        {
          code: 'ru',
          name: 'Russian',
        },
        {
          code: 'sa',
          name: 'Sanskrit (Saá¹ská¹›ta)',
        },
        {
          code: 'sc',
          name: 'Sardinian',
        },
        {
          code: 'sd',
          name: 'Sindhi',
        },
        {
          code: 'se',
          name: 'Northern Sami',
        },
        {
          code: 'sm',
          name: 'Samoan',
        },
        {
          code: 'sg',
          name: 'Sango',
        },
        {
          code: 'sr',
          name: 'Serbian',
        },
        {
          code: 'gd',
          name: 'Scottish Gaelic; Gaelic',
        },
        {
          code: 'sn',
          name: 'Shona',
        },
        {
          code: 'si',
          name: 'Sinhala, Sinhalese',
        },
        {
          code: 'sk',
          name: 'Slovak',
        },
        {
          code: 'sl',
          name: 'Slovene',
        },
        {
          code: 'so',
          name: 'Somali',
        },
        {
          code: 'st',
          name: 'Southern Sotho',
        },
        {
          code: 'es',
          name: 'Spanish; Castilian',
        },
        {
          code: 'su',
          name: 'Sundanese',
        },
        {
          code: 'sw',
          name: 'Swahili',
        },
        {
          code: 'ss',
          name: 'Swati',
        },
        {
          code: 'sv',
          name: 'Swedish',
        },
        {
          code: 'ta',
          name: 'Tamil',
        },
        {
          code: 'te',
          name: 'Telugu',
        },
        {
          code: 'tg',
          name: 'Tajik',
        },
        {
          code: 'th',
          name: 'Thai',
        },
        {
          code: 'ti',
          name: 'Tigrinya',
        },
        {
          code: 'bo',
          name: 'Tibetan Standard, Tibetan, Central',
        },
        {
          code: 'tk',
          name: 'Turkmen',
        },
        {
          code: 'tl',
          name: 'Tagalog',
        },
        {
          code: 'tn',
          name: 'Tswana',
        },
        {
          code: 'to',
          name: 'Tonga (Tonga Islands)',
        },
        {
          code: 'tr',
          name: 'Turkish',
        },
        {
          code: 'ts',
          name: 'Tsonga',
        },
        {
          code: 'tt',
          name: 'Tatar',
        },
        {
          code: 'tw',
          name: 'Twi',
        },
        {
          code: 'ty',
          name: 'Tahitian',
        },
        {
          code: 'ug',
          name: 'Uyghur, Uighur',
        },
        {
          code: 'uk',
          name: 'Ukrainian',
        },
        {
          code: 'ur',
          name: 'Urdu',
        },
        {
          code: 'uz',
          name: 'Uzbek',
        },
        {
          code: 've',
          name: 'Venda',
        },
        {
          code: 'vi',
          name: 'Vietnamese',
        },
        {
          code: 'vo',
          name: 'VolapÃ¼k',
        },
        {
          code: 'wa',
          name: 'Walloon',
        },
        {
          code: 'cy',
          name: 'Welsh',
        },
        {
          code: 'wo',
          name: 'Wolof',
        },
        {
          code: 'fy',
          name: 'Western Frisian',
        },
        {
          code: 'xh',
          name: 'Xhosa',
        },
        {
          code: 'yi',
          name: 'Yiddish',
        },
        {
          code: 'yo',
          name: 'Yoruba',
        },
        {
          code: 'za',
          name: 'Zhuang, Chuang',
        },
        {
          code: 'zu',
          name: 'Zulu',
        },
      ],
    };
  },

  computed: {
    ...mapState({
      userId: (state) => state.userAccount.userId,
      aclEx: (state) => state.userAccount.userPermissions,
      isAdmin: (state) => state.userAccount.isAdmin,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      listUsers: (state) => state.admin.listUsers,
      acl: (state) => state.userAccount.aclCurrent,
      account: (state) => state.userAccount.account,
      client: (state) => state.userAccount.client,
      clientAccounts: (state) => state.userAccount.clientAccounts,
      listClients: (state) => state.userAccount.listClients,
      showDataFromAllAccounts: (state) => state.userAccount.showDataFromAllAccounts,

      showCreateEventDialog: (state) => state.schedule.showCreateEventDialog,
      selectedEvent: (state) => state.schedule.selectedEvent,
      loading: (state) => state.schedule.loading,
      loadings: (state) => state.schedule.loadings,
      events: (state) => state.schedule.events,
      listEvents: (state) => state.schedule.listEvents,
      eventStatuses: (state) => state.schedule.eventStatuses,
      eventTypes: (state) => state.schedule.eventTypes,
      eventReports: (state) => state.schedule.eventReports,
      inputs: (state) => state.schedule.inputs,
      outputs: (state) => state.schedule.outputs,
      outputProfiles: (state) => state.schedule.outputProfiles,
      audioProfiles: (state) => state.schedule.audioProfiles,

      bookingClone: (state) => state.schedule.bookingClone,
      awsExports: (state) => state.awsExports,

      appSettings: (state) => state.appSettings.settings,

      pictureFormats: (state) => state.input.pictureFormats,
    }),

    selectedInput() {
      return this.accountInputs.find((el) => this.eventForm.inputId === el.id);
    },
    areInputDetailsRequired() {
      return this.selectedInput ? this.selectedInput.requireDetails : false;
    },

    defaultInputPictureFormat() {
      return this.pictureFormats.filter((el) => el.name === '1080i29.97')[0];
    },

    defaultOutputPictureFormat() {
      return this.pictureFormats.filter((el) => el.name === '1080i25')[0];
    },

    clientUsers() {
      return this.eventFormAccount.client.users.items.map((el) => el.user);
    },

    isSaving() {
      return this.loading || this.uploading;
    },

    audioProfilesFiltered() {
      let clientId = this.client.code;
      if (this.eventFormAccount) {
        clientId = this.eventFormAccount.client.code;
      }
      if (!this.audioProfiles || !this.audioProfiles.items) return [];
      return this.audioProfiles.items.filter((item) => {
        return item.claimsCanAccess[0].split('.').indexOf(clientId) !== -1;
      });
    },

    createEventDialog: {
      set(value) {
        this.setShowCreateEventDialog(value);
      },
      get() {
        return this.showCreateEventDialog;
      },
    },

    hasCommentaryAccess() {
      if (!this.eventFormAccount) return false;
      const d = this.eventFormAccount.client.features.items.filter((el) => {
        console.log('commentary access', el);
        return el.feature.code === 'com';
      });
      console.log(d);
      return d.length > 0;
    },

    avaiableAccounts() {
      const accounts = [];
      if (!this.listClients.items) return [];
      this.listClients.items.forEach((client) => {
        client.accounts.items.forEach((account) => {
          const isDefault = account.code === client.code;
          const isSelected = account.code === this.account.code;
          const item = {
            account,
            client,
            isDefault,
          };

          accounts.push(item);
          if (isSelected) this.assignEventFormAccount(item);
        });
      });

      return accounts.splice(0).sort((a, b) => {
        if (a.account.name < b.account.name) { return -1; }
        if (a.account.name > b.account.name) { return 1; }
        return 0;
      });
    },

    clientAccessClaim() {
      return `usr.${this.eventFormAccount.client.code}`;
    },

    accountAccessClaim() {
      const { client, account } = this.eventFormAccount;
      return `usr.${account.code}.${client.code}`;
    },

    accountOutputs() {
      const hasItems = !!this.outputs.items;
      if (!hasItems) return [];
      return this.outputs.items
        .filter((item) => {
          const hasPermission = item.claimsCanAccess
            .indexOf(this.clientAccessClaim) !== -1 || item.claimsCanAccess
            .indexOf(this.accountAccessClaim) !== -1;
          return hasPermission;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },
    frcAvailable() {
      if (!this.eventForm.inputId) return false;
      const accounts = this.accountInputs.filter((el) => {
        console.log('dddd', el);
        return el.id === this.eventForm.inputId;
      }); if (accounts.length === 0) return false;
      return !!accounts[0].frcAvailable;
    },
    accountInputs() {
      const hasItems = !!this.inputs.items;
      if (!hasItems) return [];
      return this.inputs.items
        .filter((item) => {
          const hasPermission = item.claimsCanAccess
            .indexOf(this.clientAccessClaim) !== -1 || item.claimsCanAccess
            .indexOf(this.accountAccessClaim) !== -1;
          return hasPermission;
        }).sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    inputsById() {
      const elements = {};

      this.accountInputs.forEach((item) => {
        elements[item.id] = item;
      });

      return elements;
    },

    isInputDownlink() {
      return !!this.inputsById[this.eventForm.inputId]
        && this.inputsById[this.eventForm.inputId].type === 'DOWNLINK';
    },

    hasDownlinkConfig() {
      return this.isInputDownlink
        && !this.inputsById[this.eventForm.inputId].downlinkConfig;
    },

    outputProfilesSorted() {
      if (!this.outputProfiles.items) return [];
      const profiles = this.outputProfiles.items
        .filter((item) => {
          const hasPermission = item.claimsCanAccess
            .indexOf(this.clientAccessClaim) !== -1 || item.claimsCanAccess
            .indexOf(this.accountAccessClaim) !== -1;
          return hasPermission;
        }).sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      if (this.isMobileOnly) profiles.unshift({ name: 'None', id: -1 });
      return profiles;
    },

    claimsCanEdit() {
      const accountId = this.account.code;
      const clientId = this.client.code;
      return [
        `ee.${accountId}.${clientId}`,
        `eo.${accountId}.${clientId}`,
        `ca.${clientId}.${clientId}`,
        `aa.${accountId}.${clientId}`,
      ];
    },

    claimsCanAccess() {
      const accountId = this.account.code;
      const clientId = this.client.code;
      return [
        `ev.${accountId}.${clientId}`,
        `ee.${accountId}.${clientId}`,
        `eo.${accountId}.${clientId}`,
        `ca.${clientId}.${clientId}`,
        `aa.${accountId}.${clientId}`,
      ];
    },

    bookingClaimsCanAccess() {
      let { claimsCanAccess } = this;
      let accountId = this.account.code;
      let clientId = this.client.code;
      if (this.eventFormAccount) {
        accountId = this.eventFormAccount.account.code;
        clientId = this.eventFormAccount.client.code;

        claimsCanAccess = [
          `ev.${accountId}.${clientId}`,
          `ee.${accountId}.${clientId}`,
          `eo.${accountId}.${clientId}`,
          `ca.${clientId}.${clientId}`,
          `aa.${accountId}.${clientId}`,
          `ec.${clientId}.${clientId}`,
        ];
      }
      return claimsCanAccess;
    },
    bookingClaimsCanEdit() {
      let { claimsCanEdit } = this;
      let accountId = this.account.code;
      let clientId = this.client.code;
      if (this.eventFormAccount) {
        accountId = this.eventFormAccount.account.code;
        clientId = this.eventFormAccount.client.code;

        claimsCanEdit = [
          `ee.${accountId}.${clientId}`,
          `eo.${accountId}.${clientId}`,
          `ca.${clientId}.${clientId}`,
          `aa.${accountId}.${clientId}`,
          `ec.${clientId}.${clientId}`,
        ];
      }
      return claimsCanEdit;
    },

    audioProfileClaimsCanEdit() {
      let { claimsCanEdit } = this;
      let accountId = this.account.code;
      let clientId = this.client.code;
      if (this.eventFormAccount) {
        accountId = this.eventFormAccount.account.code;
        clientId = this.eventFormAccount.client.code;

        claimsCanEdit = [
          `ca.${clientId}.${clientId}`,
          `aa.${accountId}.${clientId}`,
          `ec.${clientId}.${clientId}`,
          `ee.${accountId}.${clientId}`,
        ];
      }
      return claimsCanEdit;
    },

    audioProfileClaimsCanAccess() {
      let { claimsCanAccess } = this;
      // let accountId = this.account.code;
      let clientId = this.client.code;
      if (this.eventFormAccount) {
        // accountId = this.eventFormAccount.account.code;
        clientId = this.eventFormAccount.client.code;

        claimsCanAccess = [
          `usr.${clientId}`,
        ];
      }
      return claimsCanAccess;
    },

    dialogWidth() {
      return (value) => (this.isMobileOnly ? '90%' : value);
    },

    isCloseToServiceStartTime() {
      const format = 'HH:mm:ss';
      const now = moment.utc();
      const before = moment.utc(this.eventForm.startTime, format).subtract('1', 'hours');
      const currentStartTime = moment.utc(this.eventForm.startTime, format);
      const isCurrentDate = moment.utc(this.eventForm.startDate).isSame(new Date(), 'day');
      if (!isCurrentDate) return false;
      return now
        .isBetween(before, currentStartTime, '[]');
    },

    isEndBeforeStartTime() {
      const format = 'HH:mm:ss';
      const currentStartTime = moment.utc(this.eventForm.startTime, format);
      const currentEndTime = moment.utc(this.eventForm.endTime, format);
      return currentEndTime
        .isBefore(currentStartTime);
    },

    doesEventStarted() {
      const format = 'HH:mm:ss';
      const now = moment.utc();
      const currentStartTime = moment.utc(this.eventForm.startTime, format);
      const currentEndTime = moment.utc(this.eventForm.endTime, format);
      const isCurrentDate = moment.utc(this.eventForm.startDate).isSame(new Date(), 'day');
      if (!isCurrentDate) return false;
      return now
        .isBetween(currentStartTime, currentEndTime, '[]');
    },
  },

  watch: {
    createEventDialog() {
      console.log('createEventDialog', this.createEventDialog);
      if (this.bookingClone) {
        console.log('booking', this.bookingClone);
        const {
          input,
          fixture,
          requestedBitrate,
          bugRequired,
          audioMapping,
          outputs,
          property,
          sport,
          description,
          endTime,
          startTime,
          lineupTime,
          account,
          inputFrcEnabled,
          inputFrcInputFormatId,
          inputFrcOututFormatId,
          inputDownlinkConfig,
          attachments
        } = this.bookingClone.extendedProps;
        this.eventForm = {
          endTime: moment.utc(endTime).format('HH:mm'), // moment().add(150, 'minutes').format('HH:mm'),
          startTime: moment.utc(startTime).format('HH:mm'), // moment().format('HH:mm:ss'),
          lineupTime: moment.utc(lineupTime).format('HH:mm'), // moment().subtract(30, 'minutes').format('HH:mm'),
          startDate: moment.utc(startTime).format('YYYY-MM-DD'),
          endDate: moment.utc(endTime).format('YYYY-MM-DD'),
          lineupDate: moment.utc(lineupTime).format('YYYY-MM-DD'),
          bugRequired,
          requestedBitrate,
          property,
          sport,
          description,
          fixture,
          inputId: input ? input.id : null,
          // outputIds: outputs.items ? outputs.items.map(output => output.id) : [],
          outputIds: [],
          audioMapping: audioMapping.items.map((item) => {
            const newItem = item;
            delete newItem.id;
            delete newItem.claimsCanAccess;
            delete newItem.claimsCanEdit;
            return newItem;
          }),
          downlinkConfig: {
            satelliteName: '',
            satelliteFrequency: '',
            satellitePolarization: 'Vertical',
            symbolRate: '',
            BISS: '',
            service: '',
          },
        };
        this.audioMappingForm.channelNo = this.eventForm.audioMapping.length + 1;
        console.log('bookingClone : ', this.eventForm);
        if (input && input.downlinkConfig) {
          this.eventForm.downlinkConfig = input.downlinkConfig;
        }

        if (inputDownlinkConfig) {
          this.eventForm.downlinkConfig = inputDownlinkConfig;
        }
        if(attachments) {
          this.eventForm.attachments = attachments;
        }

        if (inputFrcEnabled) {
          this.eventForm.inputFrcEnabled = inputFrcEnabled;
          this.eventForm.inputFrcInputFormatId = inputFrcInputFormatId;
          this.eventForm.inputFrcOututFormatId = inputFrcOututFormatId;
        }

        this.avaiableAccounts.forEach((item) => {
          const isFromClone = item.account.code === account.code;
          if (isFromClone) this.assignEventFormAccount(item);
        });
        this.setBookingClone(null);
        return;
      }
      this.fetchUserList();
      this.eventForm = {
        endTime: moment().add(150, 'minutes').format('HH:mm'),
        startTime: moment().format('HH:mm:ss'),
        lineupTime: moment().subtract(30, 'minutes').format('HH:mm'),
        bugRequired: false,
        requestedBitrate: 15,
        inputId: null,
        outputIds: [],
        audioMapping: [],
        inputFrcEnabled: false,
        downlinkConfig: {
          satelliteName: '',
          satelliteFrequency: '',
          satellitePolarization: 'Vertical',
          symbolRate: '',
          BISS: '',
          service: '',
        },
      };
      this.commentaryForm = {
        isEnabled: false,
        languages: [
        ],
      };
      this.eventAudioMapping = [
        {
          active: false,
          channelNo: 0,
          description: 'Left',
        },
      ];
      this.audioMappingForm = {
        active: false,
        channelNo: 1,
        description: null,
        stereo: true,
      };
    },
  },
  created() {
    if (this.isSysAdmin) {
      this.datePickerOptions = {};
    }
  },
  mounted() {
    console.log('mounted add booking', this.$Amplify);
  },
  methods: {
    ...mapActions({
      addBooking: 'schedule/addBooking',
      deleteEventAudioChannel: 'schedule/deleteEventAudioChannel',
      createEventAudioChannel: 'schedule/createEventAudioChannel',

      fetchInputs: 'schedule/fetchInputs',
      listAudioMappingProfiles: 'schedule/listAudioMappingProfiles',
      fetchOutputs: 'schedule/fetchOutputs',

      startStreamTarget: 'schedule/startStreamTarget',
      stopStreamTarget: 'schedule/stopStreamTarget',
      restartEvent: 'schedule/restartEvent',
      restartIncomingStream: 'schedule/restartIncomingStream',
      listEventReports: 'schedule/listEventReports',

      updateAppSetting: 'appSettings/updateAppSetting',
      createAudioMappingProfile: 'schedule/createAudioMappingProfile',
      createAudioMapItem: 'schedule/createAudioapItem',
      fetchUserList: 'admin/fetchUserList',
    }),
    ...mapMutations({
      setShowCreateEventDialog: 'schedule/setShowCreateEventDialog',
      setBookingClone: 'schedule/setBookingClone',
    }),

    handleSubmit(addAnother) {
      console.log('save 1');
      this.loadingSave = true;
      console.log('save 1', this.$refs);
      if (this.hasDownlinkConfig && this.fileList.length === 0) { 
        this.loadingSave = false;
        return;
      }
      this.$refs.createEventForm.validate((valid) => {
        console.log('save 1 validate form', valid);
        if (!valid) {
          this.loadingSave = false;
          return;
        }

        const hasCommentary = this.commentaryForm.isEnabled;

        if (hasCommentary) {
          this.commentaryForm.languages.forEach((lang) => {
            if (lang.language) this.saveBooking(addAnother, lang);
          });
          return;
        }

        this.saveBooking(addAnother);
      });
    },
    enableCommentary() {
      console.log(this.commentaryForm.isEnabled);
      if (this.commentaryForm.isEnabled) {
        this.eventForm.outputProfileId = null;
      }
    },

    async saveAudioProfile(details) {
      this.loadingSavePresets = true;
      if (this.audioPresetForm.name === '') {
        this.loadingSavePresets = false;
        this.audioPresetErrorMessage = 'Name is required';
        return;
      }
      const profileInput = {
        name: this.audioPresetForm.name,
        claimsCanAccess: this.audioProfileClaimsCanAccess,
        claimsCanEdit: this.audioProfileClaimsCanEdit,
        audioMappingProfileLastUpdatedById: this.userId,
      };
      if (this.audioPresetForm.description) profileInput.description = this.audioPresetForm.description;
      const resultId = await this.createAudioMappingProfile(profileInput);
      if (resultId.error) {
        this.loadingSavePresets = false;
        return;
      }
      console.log('create profile ', resultId);
      const audioMapItemInput = {
        channelNo: 1,
        description: '',
        active: false,
        stereo: false,
        audioMapItemAudioMappingProfileId: resultId,
        claimsCanAccess: this.audioProfileClaimsCanAccess,
        claimsCanEdit: this.audioProfileClaimsCanEdit,
        audioMapItemLastUpdatedById: this.userId,
      };
      console.log('xxxxx');
      await this.asyncForEach(this.eventForm.audioMapping, async (mapping, index) => {
        console.log(index, mapping, audioMapItemInput);
        audioMapItemInput.channelNo = mapping.channelNo;
        audioMapItemInput.active = mapping.active;
        audioMapItemInput.stereo = mapping.stereo;
        audioMapItemInput.description = mapping.description;
        await this.createAudioMapItem(audioMapItemInput);
      });
      await this.listAudioMappingProfiles();
      this.audioDialogFormVisible = false;
      this.loadingSavePresets = false;
      this.audioPresetForm = {
        name: '',
        description: '',
      };
      this.audioPresetErrorMessage = false;
    },

    async saveBooking(addAnother, commentary) {
      let accountId = this.account.code;
      let clientId = this.client.code;
      console.log('save 3');
      let { claimsCanAccess } = this;
      let { claimsCanEdit } = this;

      if (this.eventFormAccount) {
        accountId = this.eventFormAccount.account.code;
        clientId = this.eventFormAccount.client.code;

        claimsCanAccess = [
          `ev.${accountId}.${clientId}`,
          `ee.${accountId}.${clientId}`,
          `eo.${accountId}.${clientId}`,
          `ca.${clientId}.${clientId}`,
          `aa.${accountId}.${clientId}`,
          `ec.${clientId}.${clientId}`,
        ];

        claimsCanEdit = [
          `ee.${accountId}.${clientId}`,
          `eo.${accountId}.${clientId}`,
          `ca.${clientId}.${clientId}`,
          `aa.${accountId}.${clientId}`,
          `ec.${clientId}.${clientId}`,
        ];
      }

      let { startDate, endDate } = this.eventForm;
      let lineupDate = this.eventForm.lineupDate ? this.eventForm.lineupDate : startDate;

      if (this.isMobileOnly) {
        lineupDate = moment.utc(lineupDate).format('YYYY-MM-DD');
        endDate = moment.utc(endDate).format('YYYY-MM-DD');
        startDate = moment.utc(startDate).format('YYYY-MM-DD');
      }
      // user fixture as a name
      this.eventForm.name = this.eventForm.fixture;
      const newLineupTime = moment.utc(this.eventForm.startTime, ['HH:mm']).subtract(30, 'minutes').format('HH:mm');
      const lineupTime = this.eventForm.lineupTime ? this.eventForm.lineupTime : newLineupTime;
      const newEvent = {
        ...this.eventForm,
        accountId,
        clientId,
        status: this.eventStatus,
        lineupTime: moment.utc(`${lineupDate} ${lineupTime}`)
          .format(),
        startTime: moment.utc(`${startDate} ${this.eventForm.startTime}`)
          .format(),
        endTime: moment.utc(`${endDate} ${this.eventForm.endTime}`)
          .format(),
        claimsCanAccess,
        claimsCanEdit,
        autostart: true,
        autostartOffsetMin: 15,
      };

      if (!this.hasDownlinkConfig) delete newEvent.downlinkConfig;
      delete newEvent.endDate;
      delete newEvent.startDate;
      delete newEvent.lineupDate;

      // if (this.hasDownlinkConfig) {
      //   if (this.appSettings.symbolRate.indexOf(this.eventForm.downlinkConfig.symbolRate) === -1) {
      //     this.appSettings.symbolRate.push(this.eventForm.downlinkConfig.symbolRate);
      //     console.log(this.appSettings.symbolRate);
      //     this.updateAppSetting({ id: 'symbolRate', key: 'symbolRate', value: this.appSettings.symbolRate.join(',') });
      //   }
      // }

      if (!newEvent.outputProfileId) delete newEvent.outputProfileId;

      if (commentary) {
        newEvent.outputProfileId = null;
        if (commentary.outputProfileId) newEvent.outputProfileId = commentary.outputProfileId;
        newEvent.commentaryLanguage = commentary.language;
        newEvent.commentaryUserId = commentary.user.id;
        newEvent.claimsCanAccess.push(`usr.${commentary.user.id}`);
      }
      const attachments = [];
      try {
        this.uploading = true;
        await this.asyncForEach(this.fileList, async (file, index) => {
          // const key = `${file.uid}-${file.name}`;
          const name = file.name.substring(0, file.name.lastIndexOf('.'));
          const extension = file.name.substring(file.name.lastIndexOf('.'));
          // const randomString = this.S4();
          const key = `${moment.utc().format('YYYYmmDD-hhmmss')}-${name}${extension}`;
          this.fileList[index].account = accountId;
          this.fileList[index].client = clientId;
          this.fileList[index].keys = {};
          this.fileList[index].uploads = {};

          attachments.push(key);

          this.fileList[index].status = null;
          this.fileList[index].key = `${clientId}/${accountId}/${key}`;
          console.log('uploading', file, key, `${clientId}/${accountId}/${key}`,  this.$Amplify);
          // Storage.configure({
          //   bucket: this.awsExports.aws_upload_bucket,
          //   level: 'public',
          //   region: this.awsExports.aws_user_files_s3_bucket_region,
          // });
          const result = await Storage.put(`${clientId}/${accountId}/${key}`, file.raw, {
            bucket: this.awsExports.aws_upload_bucket,
            region: 'eu-west-1',
            metadata: {
              account: accountId,
              client: clientId,
            },
          });
        });
        this.uploading = false;
        newEvent.attachments = attachments.join(',');
      } catch (error) {
        this.uploading = false;
        this.loadingSave = false;
        console.log('upload error', error);
        this.$message.error('Can\'t upload selected files');
        return;
      }
      console.log('add booking ::: ', newEvent);
      const result = await this.addBooking(newEvent);
      this.loadingSave = false;
      if (!result.error && !addAnother) this.createEventDialog = false;
      // return result;
    },

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array); // eslint-disable-line
      }
    },

    startTimeChanged(time) {
      const lineupTime = moment.utc(time, ['HH:mm']).subtract(30, 'minutes');
      const endTime = moment.utc(time, ['HH:mm']).add(150, 'minutes');
      if (!this.eventForm.lineupTime) Vue.set(this.eventForm, 'lineupTime', lineupTime.format('HH:mm'));
      if (!this.eventForm.endTime) Vue.set(this.eventForm, 'endTime', endTime.format('HH:mm'));
    },

    lineupTimeBlur() {
      const hasLineupTimeSet = !!this.eventForm.lineupTime;
      const hasStartTimeSet = !!this.eventForm.startTime;
      if (!hasLineupTimeSet && hasStartTimeSet) {
        const time = moment.utc(this.eventForm.startTime, ['HH:mm']).subtract(30, 'minutes').format('HH:mm');
        Vue.set(this.eventForm, 'lineupTime', time);
      }
    },

    startDateChanged(date) {
      const shouldUpdateEndDate = !this.eventForm.endDate
        || moment(this.eventForm.endDate).isBefore(moment(date));
      if (shouldUpdateEndDate) Vue.set(this.eventForm, 'endDate', date);
    },

    lineupDateChanged(date) {
      const shouldUpdateStartDate = !this.eventForm.startDate
        || moment(this.eventForm.endDate).isAfter(moment(date));
      if (shouldUpdateStartDate) {
        Vue.set(this.eventForm, 'endDate', date);
        Vue.set(this.eventForm, 'startDate', date);
      }
    },

    lineupDateChangedMobile() {
      const shouldUpdateStartDate = !this.eventForm.startDate
        || moment(this.eventForm.endDate).isAfter(moment(this.eventForm.lineupDateValue));
      if (shouldUpdateStartDate) {
        Vue.set(this.eventForm, 'endDate', this.mobilePopups.lineupDateValue);
        Vue.set(this.eventForm, 'startDate', this.mobilePopups.lineupDateValue);
      }
      this.mobilePopups.lineupDate = false;
      this.eventForm.lineupDate = this.mobilePopups.lineupDateValue;
    },

    startDateChangedMobile() {
      this.mobilePopups.startDate = false;
      this.eventForm.startDate = this.mobilePopups.startDateValue;
    },

    endDateChangedMobile() {
      this.mobilePopups.endDate = false;
      this.eventForm.endDate = this.mobilePopups.endDateValue;
    },

    lineupTimeChangedMobile() {
      this.mobilePopups.lineupTime = false;
      this.eventForm.lineupTime = this.mobilePopups.lineupTimeValue;
    },

    startTimeChangedMobile() {
      this.mobilePopups.startTime = false;
      this.eventForm.startTime = this.mobilePopups.startTimeValue;
    },

    endTimeChangedMobile() {
      this.mobilePopups.endTime = false;
      this.eventForm.endTime = this.mobilePopups.endTimeValue;
    },

    loadAudioProfile(profile) {
      const newList = profile.channels.items.sort((a, b) => {
        if (a.channelNo < b.channelNo) { return -1; }
        if (a.channelNo > b.channelNo) { return 1; }
        return 0;
      });
      Vue.set(this.eventForm, 'audioMapping', JSON.parse(JSON.stringify(newList)));
    },

    async addAudioChannel(form) {
      const formObject = this.$refs[form];
      const isValid = await formObject.validate();
      if (!isValid) return null;
      const audioMappingForm = {
        active: this.audioMappingForm.active,
        channelNo: this.audioMappingForm.channelNo,
        description: this.audioMappingForm.description,
        stereo: this.audioMappingForm.stereo,
      };
      const doesAudioArrayExist = !!this.eventForm.audioMapping;
      if (!doesAudioArrayExist) this.eventForm.audioMapping = [];
      this.eventForm.audioMapping.push(audioMappingForm);
      this.audioMappingForm.channelNo = this.audioMappingForm.channelNo + 1;
      this.audioMappingForm.description = '';
      this.audioMappingForm.stereo = true;
      return false;
    },

    async deleteAudioChannel(object, index, event) {
      const needsUpdate = !!event.id;
      if (!needsUpdate) {
        object.splice(index, 1);
        return false;
      }

      await this.deleteEventAudioChannel(object[index].id);
      this.$forceUpdate();
      return false;
    },

    onInputConfirm(value) {
      this.eventForm.inputId = value.id;
      this.mobilePopups.input = false;
    },

    onOutputProfileConfirm(value) {
      this.eventForm.outputProfileId = value.name === 'None' ? null : value.id;
      this.mobilePopups.outputProfile = false;
    },

    oninputFrcInputFormatIdConfirm(value) {
      this.eventForm.inputFrcInputFormatId = value.name === 'None' ? null : value.id;
      this.mobilePopups.inputFrcInputFormatId = false;
    },
    oninputFrcOututFormatIdConfirm(value) {
      this.eventForm.inputFrcOututFormatId = value.name === 'None' ? null : value.id;
      this.mobilePopups.inputFrcOututFormatId = false;
    },

    eventAccountChanged() {
      this.eventForm.outputProfileId = null;
      this.eventForm.inputId = null;
      this.eventForm.inputFrcEnabled = false;
      this.commentaryForm.languages = [];
    },

    openPicker(ref) {
      if (!this.isMobileOnly) return;
      this.mobilePopups[ref] = true;
    },

    validateEndDate(rule, value, callback) {
      if (!value || value === '') {
        callback(new Error('Please enter the End date'));
      } else {
        if (this.eventForm.lineupDate && this.eventForm.lineupDate !== '') {
          const isEndBeforeLineup = moment(this.eventForm.lineupDate)
            .isAfter(moment(this.eventForm.endDate));
          if (isEndBeforeLineup) {
            callback(new Error('End date can`t be before line-up date.'));
          }
        }
        if (this.eventForm.startDate && this.eventForm.startDate !== '') {
          const isEndBeforeStart = moment(this.eventForm.startDate)
            .isAfter(moment(this.eventForm.endDate));
          const areDatesTheSame = moment(this.eventForm.startDate)
            .isSame(moment(this.eventForm.endDate));

          if (isEndBeforeStart) {
            callback(new Error('Date can`t be before Start date.'));
          }
          if (areDatesTheSame) {
            this.$refs.createEventForm.validateField('endTime');
          }
        } else {
          callback();
        }
        callback();
      }
    },
    validateLineupDate(rule, value, callback) {
      if (!value || value === '') {
        callback(new Error('Line-up date is required'));
      } else {
        if (this.eventForm.startDate && this.eventForm.startDate !== '') {
          this.$refs.createEventForm.validateField('startDate');
        }
        if (this.eventForm.endDate && this.eventForm.endDate !== '') {
          this.$refs.createEventForm.validateField('endDate');
        }
        callback();
      }
    },
    validateLineupTime(rule, value, callback) {
      if (!value || value === '') {
        callback(new Error('Line-up time is required'));
      } else {
        if (this.eventForm.startTime && this.eventForm.startTime !== '') {
          this.$refs.createEventForm.validateField('startTime');
        }
        if (this.eventForm.endTime && this.eventForm.endTime !== '') {
          this.$refs.createEventForm.validateField('endTime');
        }
        callback();
      }
    },
    validateStartDate(rule, value, callback) {
      if (!value || value === '') {
        callback(new Error('Start date is required'));
      } else {
        if (this.eventForm.lineupDate && this.eventForm.lineupDate !== '') {
          const isBeforeLineup = moment(this.eventForm.lineupDate)
            .isAfter(moment(this.eventForm.startDate));
          if (isBeforeLineup) {
            callback(new Error('Start date can`t be before line-up date.'));
          }
        }
        if (this.eventForm.endTime && this.eventForm.endTime !== '') {
          this.$refs.createEventForm.validateField('endTime');
        }
        callback();
      }
    },

    validateStartTime(rule, value, callback) {
      if (!value || value === '') {
        callback(new Error('Please enter the Start time'));
      } else {
        if (this.eventForm.lineupTime && this.eventForm.lineupTime !== '') {
          // this.$refs.createEventForm.validateField('lineupTime');
          const areDatesTheSame = moment(this.eventForm.startDate)
            .isSame(moment(this.eventForm.lineupDate));
          // const isStartDateBefore = moment(this.eventForm.startDate)
          //   .isBefore(moment(this.eventForm.endDate));
          const isBefore = moment.utc(this.eventForm.startTime, 'HH:mm')
            .isBefore(moment.utc(this.eventForm.lineupTime, 'HH:mm'));
          // console.log('validateStartTime', areDatesTheSame, isBefore, this.eventForm.startDate, this.eventForm.startTime, moment.utc(this.eventForm.lineupTime, 'DD HH:mm'));
          if (areDatesTheSame && isBefore) {
            callback(new Error('Start time can\'t be before Line-up time'));
          } else {
            callback();
          }
        }
        if (this.eventForm.endTime && this.eventForm.endTime !== '') {
          this.$refs.createEventForm.validateField('endTime');
        }
        callback();
      }
    },

    validateEndTime(rule, value, callback) {
      if (!value || value === '') {
        callback(new Error('Please enter the End time'));
      }
      if (this.eventForm.startTime && this.eventForm.startTime !== '') {
        const areDatesTheSame = moment(this.eventForm.startDate)
          .isSame(moment(this.eventForm.endDate));
        const isBefore = moment.utc(this.eventForm.endTime, 'HH:mm')
          .isBefore(moment.utc(this.eventForm.startTime, 'HH:mm'));
        if (areDatesTheSame && isBefore) {
          callback(new Error('Booking can`t end before it starts'));
        } else {
          callback();
        }
      } 
      if (this.eventForm.lineupTime && this.eventForm.lineupTime !== '') {
        const areDatesTheSame = moment(this.eventForm.lineupDate)
          .isSame(moment(this.eventForm.endDate));
        const isBefore = moment.utc(this.eventForm.endTime, 'HH:mm')
          .isBefore(moment.utc(this.eventForm.lineupTime, 'HH:mm'));
        if (areDatesTheSame && isBefore) {
          callback(new Error('End time can\'t be before Line-up time'));
        } else {
          callback();
        }
      } 
      callback();
    },
    assignEventFormAccount(account) {
      console.log('assign account', account);
      Vue.set(this, 'eventFormAccount', account);
    },

    // UPLOAD MEHTODS
    handleUploadChange(file) {
      const newFile = file;
      newFile.status = null;
      this.fileList.push(newFile);
      Vue.set(this, 'fileList', this.fileList);
    },
    handleHTTPUpload() {

    },
    removeFileFromList(index) {
      this.fileList.splice(index, 1);
    },

    uploadFiles() {
      // this.uploadDialogVisible = false;

      let accountId = this.account.id;
      let clientId = this.client.id;

      if (this.eventFormAccount) {
        accountId = this.eventFormAccount.account.id;
        clientId = this.eventFormAccount.client.id;
      }

      this.fileList.forEach((file, index) => {
        // const key = `${file.uid}-${file.name}`;
        const name = file.name.substring(0, file.name.lastIndexOf('.'));
        const extension = file.name.substring(file.name.lastIndexOf('.'));
        const randomString = this.S4();
        const key = `${name}_${randomString}${extension}`;
        this.fileList[index].account = accountId;
        this.fileList[index].client = clientId;
        this.fileList[index].keys = {};
        this.fileList[index].uploads = {};

        this.fileList[index].status = null;
        this.fileList[index].key = `public/${clientId}/${accountId}/${key}`;

        this.$Amplify.Storage.put(key, file.raw, {
          progressCallback: this.fileUploadProgress,
          region: 'eu-west-1',
          metadata: {
            mediaGateways: this.checkedMG.join(','),
          },
        })
          .then((result) => {
            console.log('File uploaded', result);
          })
          .catch((err) => console.log(err));
      });

      this.uploadDialogVisible = false;
    },

    S4() {
      // eslint-disable-next-line
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },

    fileUploadProgress(progress) {
      const percentage = Math.round(100 / progress.total * progress.loaded);
      this.overallUploadProgress = 0;
      let overallSum = 0;
      this.fileList.forEach((file, i) => {
        if (file.key === progress.key) {
          // this.fileList.splice(i, 1);

          // MG File upload progress
          Vue.set(this.fileList[i], 'percentage', percentage);
          // console.log('FILE UPLOAD PROGRESS', percentage);
          if (percentage === 100) {
            this.fileList[i].status = 'success';
            // remove completeFiles
            this.removeCompleteFile(i);
          }

          overallSum += this.fileList[i].percentage;
        }
        this.overallUploadProgress = Math.round(overallSum / this.fileList.length);
      });

      this.$forceUpdate();
    },

    async toggleFRC() {
      const input = this.defaultInputPictureFormat.id;
      const output = this.defaultOutputPictureFormat.id;
      this.eventForm.inputFrcInputFormatId = input;
      this.eventForm.inputFrcOututFormatId = output;
    },
    outputProfileChanged() {
      this.$forceUpdate();
      console.log('output changes');
    },
  },
};
</script>

<style >
  .el-loading-spinner svg {
  margin: 0 auto;
}

.inline-block {
  display: inline-block;
}

.audio-mapping-form .el-switch {
  position: inherit;
  right: auto;
}

.upload .upload-files {
  max-width: 400px;
  margin-bottom: 20px;
}
.upload .upload-file {
  display: grid;
  grid-template-columns: 1fr 200px;
  line-height: 24px;
  height: 24px;
  margin-bottom: 16px;
}
.upload .upload-file .file-upload-right {
  text-align: right;
}
.upload .upload-file .file-upload-right .icon-btn {
  line-height: 24px;
  min-width: 40px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
.upload .upload-file .el-progress {
  grid-column: 1/span 2;
}
.upload .upload {
  margin-top: 20px;
}

.mobileInput .el-form-item__content {
  pointer-events: none;
}
.mobileInput .el-input, .mobileInput .el-select, .mobileInput .el-input__suffix-inner {
  width: 100% !important;
  pointer-events: none;
}
.mobileInput .el-input input, .mobileInput .el-select input, .mobileInput .el-input__suffix-inner input {
  width: 100% !important;
  pointer-events: none;
}

.output-select-option.el-select-dropdown__item {
  height: 48px;
}
.output-select-option.el-select-dropdown__item small {
  margin-top: -10px;
  display: block;
  line-height: 16px;
  color: #8492a6;
  font-size: 12px;
}

.add-booking-dialog > .el-dialog {
  margin: 0 !important;
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.add-booking-dialog > .el-dialog .el-dialog__body {
  padding: 0px 0px;
}
.add-booking-dialog .vb {
  height: calc(100vh - 80px);
  padding: 0 20px;
}
.add-booking-dialog .vb .vb-content {
  padding-bottom: 90px;
}
.add-booking-dialog .vb .wrapper-fullscreen {
  padding: 20px;
  width: auto !important;
}
</style>
