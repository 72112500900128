<template>
  <div class="bly-event-item">
    <div class="bly-event-item-name" :class="status">
      {{item.fixture}}
      <small>{{item.description}}</small>
    </div>
    <div class="bly-event-item-details">
      <div class="event-serviceStart">{{item.serviceStartTime | dateUtc('H:mm')}}</div>
      <div class="event-lineup">{{item.lineupTime | dateUtc('H:mm')}}</div>
      <div class="event-startTime">{{item.startTime | dateUtc('HH:mm')}}</div>
      <div class="event-endTime">{{item.endTime | dateUtc('HH:mm')}}</div>
      <div class="event-serviceEnd">{{item.serviceEndTime | dateUtc('H:mm')}}</div>
      <div class="event-headTimeMin">{{item.headTimeMin}}</div>
      <div class="event-tailTimeMin">{{item.tailTimeMin}}</div>
      <div class="event-duration">{{duration}}</div>
      <div class="event-duration">{{serviceDuration}}</div>
      <div class="event-duration">{{item.property}}</div>
      <div class="event-duration">{{item.sport}}</div>
      <div class="event-input"
        :class="{warning: item.inputName === 'not set'}">
        {{item.inputName}}
        <span class="green" v-show="item.inputFrcEnabled">FRC Enabled</span>
      </div>
      <div class="event-output"
        :class="{warning: item.output === 'not set'}"
        v-html="item.output">
      </div>
    </div>
    <div class="ntr" v-if="txreport">
      <el-tooltip content="TX Report" v-if="reportString !== 'NTR'">
        <i class="bi bly-warning red"></i>
      </el-tooltip>
      <el-tooltip content="Nothing to report" v-if="reportString === 'NTR'">
        <i class="bi bly-check green"></i>
      </el-tooltip>
      <div v-if="reportString === 'NTR'" style="position: relative; top: 4px;">NTR</div>
      <div v-html="reportString" v-if="reportString !== 'NTR'" class="txreportdesc"></div>
    </div>
    <div class="bly-event-item-account">
      <span class="fc-event-account">
        <span v-if="item.commentaryLanguage"
                    class="commentary-language"><i class="fas fa-globe-americas"></i> {{item.commentaryLanguage}}</span>
        {{item.account.name}}
        <el-divider direction="vertical"></el-divider>
        <span class="fc-event-account-client">{{item.customer.name}}</span>
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'EventItem',
  props: {
    txreport: {
      type: Array,
      required: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
    };
  },

  computed: {
    status() {
      return this.item.status || '';
    },
    duration() {
      // const format = 'DD/MM/YYYY HH:mm:ss';
      const start = moment(this.item.lineupTime);
      const end = moment(this.item.endTime);
      const ms = end.diff(start);
      const d = moment.duration(ms);
      const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm');
      return s;
    },
    serviceDuration() {
      const start = moment(this.item.serviceStartTime);
      const end = moment(this.item.serviceEndTime);
      const ms = end.diff(start);
      const d = moment.duration(ms);
      const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm');
      return s;
    },
    reportString() {
      let reportString = '';
      if (!this.txreport) return '--- nothing reported ----';
      this.txreport.forEach((report) => {
        reportString += report.ntr ? 'NTR' : report.description;
      });
      return reportString;
    },
  },
};
</script>

<style src="./EventItem.css"></style>
