<template>
  <div id="resources-view">
   <div class="bg-gray-50 rounded-md border-dashed p-2"  style="min-height: 70px">
     <dl class="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-8" v-show="tooltipData.fixture">
      <div class="sm:col-span-2">
        <dt class="text-xs uppercase font-medium text-gray-500">
          Fixture
        </dt>
        <dd class="text-sm text-gray-900">
          {{tooltipData.fixture}}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-xs uppercase font-medium text-gray-500">
          Service start time
        </dt>
        <dd class=" text-sm text-gray-900">
          {{moment.utc(tooltipData.serviceStartTime).format('YYYY/MM/DD HH:mm')}}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-xs uppercase font-medium text-gray-500">
          Line-up
        </dt>
        <dd class=" text-sm text-gray-900">
          {{moment.utc(tooltipData.lineupTime).format('YYYY/MM/DD HH:mm')}}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-xs uppercase font-medium text-gray-500">
          Kick-off
        </dt>
        <dd class=" text-sm text-gray-900">
          {{moment.utc(tooltipData.startTime).format('YYYY/MM/DD HH:mm')}}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-xs uppercase font-medium text-gray-500">
          End time
        </dt>
        <dd class=" text-sm text-gray-900">
          {{moment.utc(tooltipData.endTime).format('YYYY/MM/DD HH:mm')}}
        </dd>
      </div>
      <div class="sm:col-span-2">
        <dt class="text-xs uppercase font-medium text-gray-500">
          Input
        </dt>
        <dd class=" text-sm text-gray-900">
          {{tooltipData.inputName}} 
          <span class="text-red-500" v-show="overlappingSelectedInputNumber">
            <i class="far fa-exclamation-circle"></i>
            {{overlappingSelectedInputNumber}}
          </span>
        </dd>
      </div>
    </dl>
    <dt class="text-base text-blue-500" v-show="!tooltipData.fixture">
      <i class="far fa-info-circle"></i> Hover over the event to see details
    </dt>
   </div>
   <div id="resourceChart"></div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import moment from 'moment';
// import * as tl from '../d3-timeline';

export default {
  name: 'ResourceView',
  props: ['dataset', 'range', 'overlappingInputs'],
  data() {
    return {
      moment,
      chart: null,
      main: null,
      mini: null,
      chartContainer: null,
      xScale: null,
      xScale2: null,
      yScale: null,
      yScale2: null,
      domain: null,
      xAxis: null,
      xAxisTop: null,
      xAxisTopEl: null,
      yAxis: null,
      brush:null,
      chartOptions: {
        width: 1000,
        height: 600,
        margin: {
          top: 2,
          right: 2,
          bottom: 2,
          left: 2,
        },
        ctrWidth: 0,
        ctrHeight: 0,
      },
      inputs: [],
      mainItems: [],
      mainItemsText: [],
      tooltipData: {},
    };
  },
  computed: {
    overlapingInputsOverallNumber() {
      return this.dataset.filter((event) => {
        return event.overlappingInputs.length > 0;
      })
      .map(event => event.overlappingInputs.length)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },
    overlappingSelectedInputNumber() {
      if (!this.tooltipData.id) return 0;

      return this.tooltipData.overlappingInputs.length;
    },
    processedRange() {
      return {
        start: moment.utc(this.range.start).add(1, 'days').format(),
        end: moment.utc(this.range.end).subtract(1, 'days').endOf('day').format(),
      };
    },
  },

  watch: {
    dataset(val, old) {
      console.log('asdasdasdadaqweqxasdas', val, old);
      if (val && !this.chart)  {
        this.init(val);
        // this.display({selection: [this.xScale(new Date(this.processedRange.start)), this.xScale(new Date(this.processedRange.end))] })
      }
    },
  },

  created() {
    // d3.timeline = tl.timeline;
  },

  mounted() {
    console.log('asdasdasdadaqweqxasdas');
    if (this.dataset && !this.chart)  {
      this.init(this.dataset);
      // this.display({selection: [this.xScale(new Date(this.processedRange.start)), this.xScale(new Date(this.processedRange.end))] })
    }
  },

  methods: {
    async init(dataset) {
      this.inputs = [... new Set(dataset.map(event => event.inputName))].sort((a, b) => a.localeCompare(b));
      console.log('INIT', this.chartOptions, this.inputs, dataset);
      const {top, right, bottom, left } = this.chartOptions.margin;
      
      this.chartOptions.width = document.querySelector('#resources-view').offsetWidth || 1000;
      this.chartOptions.height = this.inputs.length * (44 + 32) + bottom + top;

      this.chartOptions.ctrWidth = this.chartOptions.width - left - right;
      this.chartOptions.ctrHeight = this.chartOptions.height - top - bottom;
      const data = dataset[Object.keys(dataset)[0]];
      const laneLength = this.inputs.length;
      const miniHeight = laneLength * 6;
      const mainHeight = laneLength * 34;

      // const xAccessor = (d) => d.extendedProps.serviceStartTime;
      // const yAccessor = (d) => d.extendedProps.serviceStartTime;

      // scale & domain
      this.xScale = d3.scaleTime()
        .domain([new Date(this.processedRange.start), new Date(this.processedRange.end)])
        .range([0, this.chartOptions.ctrWidth]);
      this.xScale2 = d3.scaleTime()
        .domain([new Date(this.processedRange.start), new Date(this.processedRange.end)])
        .range([0, this.chartOptions.ctrWidth]);

      // left scale
      this.yScale = d3.scaleLinear()
        .domain([0, laneLength])
        .range([0, mainHeight]);

      this.yScale2 = d3.scaleLinear()
        .domain([0, laneLength])
        .range([0, miniHeight]);

      // axis
      this.xAxisTop = d3.axisBottom(this.xScale)
        .tickFormat(d3.timeFormat("%Y-%m-%d %H:%M")).tickSize(12);
      this.xAxis = d3.axisTop(this.xScale)
        .tickFormat(d3.timeFormat("%Y-%m-%d %H:%M")).tickSize(3);
    
      this.yAxis = d3.axisLeft(this.yScale)
        .tickValues(Object.keys(dataset))
        .tickFormat(d => d)
        .tickSize(0).tickPadding(6);

      console.log('INIT', this.xScale(1000));
      // chart
      this.chart = d3.select('#resourceChart')
        .append('svg')
        .style("display", "block")
        .attr("viewBox", `0 0 ${this.chartOptions.width} ${this.chartOptions.height}`)
        .attr("width", this.chartOptions.width)

      this.chart.append("defs").append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", this.chartOptions.width + right + left)
        .attr("height", mainHeight);

      this.main = this.chart.append("g")
        .attr("transform", "translate(" + 0 + "," + 0 + ")")
        .attr("width", '100%')
        .attr("height", mainHeight)
        .attr("class", "main");

      this.mini = this.chart.append("g")
        .attr("transform", "translate(" + 1 + "," + (mainHeight + top + 30) + ")")
        .attr("width", this.chartOptions.width - 10)
        .attr("height", miniHeight)
        .attr("class", "mini");

      

     

      //mini lanes and texts
      // this.mini.append("g").selectAll(".laneLines")
      //   .data(this.inputs)
      //   .enter().append("line")
      //   .attr("x1", 0)
      //   .attr("y1", (d, index) => this.yScale2(index) )
      //   .attr("x2", this.chartOptions.ctrWidth)
      //   .attr("y2", (d, index) => this.yScale2(index) )
      //   .attr("stroke", "lightgray");

      // this.mini.append("g").selectAll(".laneText")
      //   .data(this.inputs)
      //   .enter().append("text")
      //   .text((d) => d)
      //   .attr("x", right)
      //   .attr("y", (d, i) => this.yScale2(i + .5) )
      //   .attr("dy", ".5ex")
      //   .attr("text-anchor", "end")
      //   .attr("class", "laneText");

      this.mini.append("g").selectAll("miniItems")
        .data(dataset)
        .join("rect")
        // .attr("class", (d) => "miniItem resourceItem " + d.status)
        .attr("class", (d) => "miniItem resourceItem ")
        .attr('fill', (d) => {
            if (d.overlappingInputs.length > 0) return 'red';
            return 'lawngreen';
          })
        .attr("x", (d) => this.xScale2(new Date(d.serviceStartTime)))
        .attr("y", (d) => this.yScale2(this.inputs.indexOf(d.inputName)))
        .attr("width", (d) => this.xScale2(moment(d.serviceEndTime)) - this.xScale2((moment(d.serviceStartTime))))
        .attr("height", 5);

      // main items
      this.mainItems = this.main.append("g");
      this.mainItemsText = this.main.append("g").attr('class', 'mainTexts');
      // this.mainItems.selectAll("mainItems")
      //   .data(dataset)
      //   .join("rect")
      //   .attr("class", (d) => "miniItem resourceItem " + d.status)
      //   .attr("x", (d) => this.xScale(new Date(d.serviceStartTime)))
      //   .attr("y", (d) => this.yScale2(this.inputs.indexOf(d.inputName)))
      //   .attr("width", (d) => this.xScale(moment(d.serviceEndTime)) - this.xScale((moment(d.serviceStartTime))))
      //   .attr("height", 5);

      //main lanes and texts
      this.main.append("g").selectAll(".laneLines")
        .data(this.inputs)
        .join('line')
        .attr("x1", 0)
        .attr("y1", (d, index) => this.yScale(index))
        .attr("x2", this.chartOptions.width)
        .attr("y2", (d, index) => this.yScale(index))
        .attr("stroke", "lightgray");
      
      this.main.append("g").selectAll(".laneText")
        .data(this.inputs)
        .join("text")
        .text((d) => d)
        .attr("x", 0)
        .attr("y", (d, i) => this.yScale(i + 0.5))
        .attr("dy", ".5ex")
        .attr("text-anchor", "start")
        .attr("class", "laneText");

      //brush
      this.brush = d3.brushX()
        // .call(this.xAxis)
        .on("brush", this.display);

      this.mini.append("g")
        .attr("class", "x brush")
        .attr("height", miniHeight - 1)
        .call(this.brush)
        .selectAll("rect")
        .attr("y", 1)

      this.mini.append("g")
        .attr("transform", "translate(0," + miniHeight + ")")
        .attr('class', 'xaxis')
        .call(this.xAxis)

      this.xAxisTopEl = this.main.append("g")
        .attr("transform", "translate(0," + mainHeight + ")")
        .attr('class', 'xaxis')
        .call(this.xAxisTop)
    
      var itemRects = this.main.append("g")
        .attr("clip-path", "url(#clip)");
      // this.chartContainer = this.chart.append('g')
      //   .attr(
      //     'transform', 
      //     `translate(${this.chartOptions.margin.left}, ${this.chartOptions.margin.top})`
      //   )
        // .call(this.xAxis)  // create time axis
        // .call(this.yAxis); // create time axis

        
      // var svgGroup = this.chartContainer.append("g").attr("transform","translate(0,"+((2)* this.chartOptions.height)+")");
      // svgGroup.append("text").attr("class","blue").attr("x","240").attr("y",0).attr("alignment-baseline","middle").attr("text-anchor","end").text(axisTypes[p]);
      // svgGroup.append("g").attr("transform","translate(270,0)").attr("class","axis")
        // .call(this.xAxis);

      // this.chartContainer.selectAll('circle')
      //   .data(dataset[Object.keys(dataset)[0]])
      //   .join('rect')
      //   .attr('fill', 'red')
      //   .attr()
      //   .attr('cx', (d) => console.log(d))
      this.display({selection: [this.xScale(new Date(this.processedRange.start)), this.xScale(new Date(this.processedRange.end))] })
    },
    display({selection}) {
      const start = this.xScale.invert(selection[0]);
      const end = this.xScale.invert(selection[1]);
      this.xScale2.domain([start, end]);
      // this.main.select(".xaxis").call(this.xAxisTop);
      this.xAxisTopEl.transition().duration(500).call(d3.axisBottom(this.xScale2));
      const newDataset = this.dataset
        .filter((event) => {
          return moment.utc(start).isBetween(event.serviceStartTime, event.serviceEndTime)
            || moment.utc(end).isBetween(event.serviceStartTime, event.serviceEndTime)
            || moment.utc(event.serviceStartTime).isBetween(start, end)
            || moment.utc(event.serviceEndTime).isBetween(start, end);
        });
      
      console.log('display', newDataset, selection,this.xScale.invert(selection[0]));
      this.mainItems.selectAll("*").remove();
      this.mainItemsText.selectAll("*").remove()
      const groups = this.mainItems.selectAll("mainItem")
        .data(newDataset)
        .join("g")
        .attr('id', (d) => `g${d.id}`)
        .attr("class", (d) => "mainItem resourceItem " + d.status)
        .attr('data-index', (d) => {
          let index = 0;
           if (d.overlappingInputs.length > 0) {
            const group = [...d.overlappingInputs.map((el => el.extendedProps)), d].sort((left, right) => moment.utc(left.serviceStartTime)
            .diff(moment.utc(right.serviceStartTime)));
            index = group.findIndex((el) => el.id === d.id);
            
            console.log('group ==> ', index, group.findIndex((el) => el.id === d.id), d.id, d.serviceStartTime, group);
          }
          return index;
        })
        .attr("transform", (d) => {
          let offset = 0;
          if (d.overlappingInputs.length > 0) {
            const group = [...d.overlappingInputs.map((el => el.extendedProps)), d].sort((left, right) => moment.utc(left.serviceStartTime)
            .diff(moment.utc(right.serviceStartTime)));
            const index = group.findIndex((el) => el.id === d.id);
            if (index >= 1) offset = index * 14 + 2;
            
            console.log('group::: ', offset, group.findIndex((el) => el.id === d.id), d, group);
          } else {
            offset = 8;
          }
          return `translate(${this.xScale2(new Date(d.serviceStartTime))}, ${this.yScale(this.inputs.indexOf(d.inputName)) + offset})`
        })
        .attr("y", (d) => this.yScale(this.inputs.indexOf(d.inputName)))
        .attr("width", (d) => this.xScale2(moment(d.serviceEndTime)) - this.xScale2((moment(d.serviceStartTime))))
        .attr("height", 18)
      
        // white background for rect
      // groups
      //   .append('rect')
      //     // .attr("class", (d) => "resourceItem " + d.status)
      //     .attr("class", (d) => {
      //       return 'resourceItem';
      //     })
      //     .attr('fill', (d) => {
      //       return 'white';
      //     })
      //     .attr("x", (d) => -4)
      //     // .attr("y", (d) => this.yScale(this.inputs.indexOf(d.inputName)) + 8)
      //     .attr("width", (d) => this.xScale2(moment(d.serviceEndTime)) - this.xScale2((moment(d.serviceStartTime))) + 8)
      //     .attr("height", 26)

      groups
        .append("defs").append("clipPath")
        .attr("id", (d) => `${d.id}-clip`)
        .append("rect")
        .attr("width", (d) => this.xScale2(moment(d.serviceEndTime)) - this.xScale2((moment(d.serviceStartTime))))
        .attr("height", 18);
      groups
        .append('rect')
          // .attr("class", (d) => "resourceItem " + d.status)
          .attr("class", (d) => {
            if (d.overlappingInputs.length > 0) return 'resourceItem overlapping';
            return 'resourceItem';
          })
          .attr('fill', (d) => {
            if (d.overlappingInputs.length > 0) return 'red';
            return 'lawngreen';
          })
          .attr('stroke', (d) => {
            return '#fff';
          })
          .attr('stroke-width', (d) => {
            return 3;
          })
          // .attr("x", (d) => this.xScale2(new Date(d.serviceStartTime)))
          // .attr("y", (d) => this.yScale(this.inputs.indexOf(d.inputName)) + 8)
          .attr("width", (d) => this.xScale2(moment(d.serviceEndTime)) - this.xScale2((moment(d.serviceStartTime))))
          .attr("height", 18)
        .on('mouseover', (event, d) => {
          console.log('over', d, event);
          this.tooltipData = d;
        })
    
      groups
        .append('text')
        .text((d) => {
          const width = this.xScale2(moment(d.serviceEndTime)) - this.xScale2((moment(d.serviceStartTime)));
          const maxChar = width / 10;
          return d.fixture.slice(0, maxChar);
        })
        // .attr("x", (d) => this.xScale2(new Date(d.serviceStartTime)) + 4)
        .attr("fill", (d) => '#000')
        .attr("y", (d) => 13)
        .attr("x", (d) => 6)
        .attr("width", (d) => this.xScale2(moment(d.serviceEndTime)) - this.xScale2((moment(d.serviceStartTime))))
        .attr("height", 22)
        
        // this.mainItemsText.selectAll("mainItemText")
        //   .data(newDataset)
        //   .join('text')
        //   .text((d) => d.fixture)
        //   .attr("x", (d) => this.xScale2(new Date(d.serviceStartTime)) + 4)
        //   .attr("y", (d) => this.yScale(this.inputs.indexOf(d.inputName)) + 22)
        //   .attr("width", (d) => this.xScale2(moment(d.serviceEndTime)) - this.xScale2((moment(d.serviceStartTime))))
        //   .attr("height", 22)
        // .on('mouseover', (event, d) => {
        //   console.log('over', d, event);
        //   this.tooltipData = d;
        // })
    },
    // brushended({selection}) {
    //   if (!selection) {
    //     gb.call(brush.move, defaultSelection);
    //   }
    // }
  },
};
</script>
<style>
#timeline3 svg {
  min-height: 300px;
}

.brush .selection {
  stroke: gray;
  fill: dodgerblue;
  fill-opacity: 0.365;
  max-height: 100%;
}

.mini .overlay {
  background: #efefef;
}

.xaxis {
  color: #999;
}

.resourceItem text {
  font-size: 11px;
}
.resourceItem.overlapping {
  filter: contrast(1.5);
  opacity: 0.6;
}
</style>
