<template>
  <div id="servers" class="servers">
    <div></div>
    <div class="wrapper">
      <div class="server-list">
        <!-- DESCRIPTION EDIT DIALOG -->
        <el-dialog :title="'Details for '+ selectedItem.id + ' - ' + selectedItem.name"
          :visible.sync="dialogDetailsVisible"
          :width="uploadDialogWidth">
          <div class="details-dialog">
            <div><strong>Id: </strong>{{selectedItem.id}}</div>
            <div v-show="selectedItem.name">
              <strong>Name: </strong>{{selectedItem.name}}
            </div>
            <div v-show="selectedItem.serialNo">
              <strong>Serial No: </strong>{{selectedItem.serialNo}}
              </div>
            <div v-show="selectedItem.outputAddress">
              <strong>Output Address: </strong>{{selectedItem.outputAddress}}
              </div>
            <div v-show="selectedItem.index">
              <strong>Index: </strong>{{selectedItem.index}}
              </div>
            <div v-show="selectedItem.outputStatus">
              <strong>Output Status: </strong>{{selectedItem.outputStatus}}
              </div>
            <div v-show="selectedItem.type">
              <strong>Type: </strong>{{selectedItem.type}}
              </div>
            <div v-show="selectedItem.outpurMethod">
              <strong>Output Method: </strong>{{selectedItem.outpurMethod}}
              </div>
            <div v-show="selectedItem.videoFormat">
              <strong>Video Format: </strong>{{selectedItem.videoFormat}}
              </div>
            <div v-show="selectedItem.inputActive">
              <strong>Input Active: </strong>{{selectedItem.inputActive}}
              </div>
            <div v-show="selectedItem.detectedVideoFormat">
              <strong>Detected Video Format: </strong>{{selectedItem.detectedVideoFormat}}
              </div>

            <div v-show="selectedItem.bytesIn">
              <div class="details-dialog-item-h"><strong>Network Throughput</strong></div>
              <strong>Bytes In: </strong>
              {{selectedItem.bytesIn | prettyBytes}} @ {{selectedItem.bytesInRate | prettyBytes}}/s
              </div>
            <div v-show="selectedItem.bytesOut || selectedItem.bytesOut === 0">
              <strong>Bytes Out: </strong>
              {{selectedItem.bytesOut | prettyBytes}} @ {{selectedItem.bytesOutRate |prettyBytes}}/s
              <br>
            </div>
            <div v-show="selectedItem.uptime">
              <div class="details-dialog-item-h"><strong>Stream Uptime</strong></div>
              <strong>Stream up since: </strong>
              {{selectedItem.uptime | pastDate | dateFormat('DD MMM YYYY hh:ssA')}}
              <strong>Stream up for about: </strong>
              {{selectedItem.uptime | pastDate | timeAgo}}<br><br>
              </div>
            <div v-show="selectedItem.streamFile">
              <strong>Stream File: </strong>{{selectedItem.streamFile}}
              </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogDetailsVisible = false">Ok</el-button>
          </span>
        </el-dialog>

        <!-- DESCRIPTION EDIT DIALOG -->
        <div class="no-servers-message" v-if="isServerListEmpty">
          <i class="" >i</i>
          <h1>No servers</h1>
        </div>
        <div class="server" v-for="server in serverList" :key="server.id + 's'">
          <div class="server-details">
            <div class="name" @click="setActiveServer(server.id)">
              <el-tooltip
                :content="serverStatusMessage(
                  server.secondsSinceHeartbeat, serverStatus[server.id].status
                )">
                <i class="bi bly-record" :class="serverStatus[server.id].status"/>
              </el-tooltip>
              {{server.name}}
              <span class="description" v-if="isMobileOnly">
                {{server.description | defaultValue('----')}}
              </span>
            </div>
            <div class="details" @click="setActiveServer(server.id)"  v-if="!isMobileOnly">
              <span class="description">{{server.description | defaultValue('----')}}</span>
            </div>

            <!-- MG STATUSES -->
            <div class="additional-info">
              <!-- <el-tooltip :content="serverStatusMessage(server.secondsSinceHeartbeat)">
                <span class="status-tag " :class="serverStatus[server.id].status">
                  {{serverStatus[server.id].status}}
                </span>
              </el-tooltip>
              -->
              <div v-if="server.encoders.length !== 0">
                <el-tooltip content="Inputs (Encoders) status">
                  <span class="tag-extended">
                    INPUTS
                    <span class="grey"
                      v-if="serverStatus[server.id].encoders.grey">
                      {{serverStatus[server.id].encoders.grey}}
                    </span>
                    <span class="amber"
                      v-if="serverStatus[server.id].encoders.amber">
                      {{serverStatus[server.id].encoders.amber}}
                    </span>
                    <span class="red"
                      v-if="serverStatus[server.id].encoders.red">
                      {{serverStatus[server.id].encoders.red}}</span>
                    <span class="green"
                      v-if="serverStatus[server.id].encoders.green">
                      {{serverStatus[server.id].encoders.green}}</span>
                  </span>
                </el-tooltip>
              </div>
              <div v-if="server.decoders.length !== 0">
                <el-tooltip content="Decoders status">
                  <span class="tag-extended">
                    DECODERS
                    <span class="grey"
                      v-if="serverStatus[server.id].decoders.grey">
                      {{serverStatus[server.id].decoders.grey}}
                    </span>
                    <span class="amber"
                      v-if="serverStatus[server.id].decoders.amber">
                      {{serverStatus[server.id].decoders.amber}}
                    </span>
                    <span class="red"
                      v-if="serverStatus[server.id].decoders.red">
                      {{serverStatus[server.id].decoders.red}}</span>
                    <span class="green"
                      v-if="serverStatus[server.id].decoders.green">
                      {{serverStatus[server.id].decoders.green}}</span>
                  </span>
                </el-tooltip>
              </div>
              <div v-if="server.wowzaStreamfiles.length !== 0">
                <el-tooltip content="Outputs status">
                  <span class="tag-extended">
                    OTT OUTPUTS
                    <span class="grey"
                      v-if="serverStatus[server.id].files.grey">
                      {{serverStatus[server.id].files.grey}}
                    </span>
                    <span class="amber"
                      v-if="serverStatus[server.id].files.amber">
                      {{serverStatus[server.id].files.amber}}
                    </span>
                    <span class="red"
                      v-if="serverStatus[server.id].files.red">
                      {{serverStatus[server.id].files.red}}</span>
                    <span class="green"
                      v-if="serverStatus[server.id].files.green">
                      {{serverStatus[server.id].files.green}}</span>
                  </span>
                </el-tooltip>
              </div>
              <div v-if="server.tsPlaybackStatuses.length !== 0">
                <el-tooltip content="PLAYBACK status">
                  <span class="tag-extended">
                    PLAYBACK
                    <span class="grey"
                      v-if="serverStatus[server.id].playbacks.grey">
                      {{serverStatus[server.id].playbacks.grey}}
                    </span>
                    <span class="amber"
                      v-if="serverStatus[server.id].playbacks.amber">
                      {{serverStatus[server.id].playbacks.amber}}
                    </span>
                    <span class="red"
                      v-if="serverStatus[server.id].playbacks.red">
                      {{serverStatus[server.id].playbacks.red}}</span>
                    <span class="green"
                      v-if="serverStatus[server.id].playbacks.green">
                      {{serverStatus[server.id].playbacks.green}}</span>
                  </span>
                </el-tooltip>
              </div>
            </div>

            <!-- MG ACTIONS -->
            <div class="actions">
              <el-popover
                placement="bottom"
                width="200"
                trigger="click">
                <div class="dropdown-item" @click="changeDescription(server, 'server', server)">
                  <i class="bi bly-edit"/> Change description
                </div>
                <div class="dropdown-item" @click="openAdvancedDetails(server, 'server')">
                  <i class="bi bly-info"/> Advanced details
                </div>
                <el-tooltip content="More" slot="reference">
                  <i class="bi bly-more icon-btn"/>
                </el-tooltip>
              </el-popover>
            </div>
          </div>
          <el-collapse-transition>
            <div class="file-list" v-show="activeServer === server.id">
              <!-- ---------------------------------------- -->
              <!-- INPUTS (Encoders) ---------------------- -->
              <!-- ---------------------------------------- -->
              <div class="group-name">INPUTS (encoders)</div>
              <div class="list-item encoder-item"
                v-for="(encoder, index) in server.encoders" :key="encoder.id + 'e' + index">
                <div class="item-name">
                  <span class="index">{{encoder.index}}</span>
                  <i class="bi bly-record" :class="encoderInfo(encoder.index, server.id).all"/>
                  {{encoder.name}}
                  <span class="description"
                    v-if="isMobileOnly">
                      {{encoder.description | defaultValue('----')}}
                  </span>
                </div>
                <div class="details" v-if="!isMobileOnly">
                  <span class="description">{{encoder.description | defaultValue('----')}}</span>
                </div>
                <div class="additional-info">
                  <el-popover
                    placement="bottom"
                    trigger="click"
                    width="220">
                    <div>
                      <div class="dropdown-item"
                        @click="changeSDIPair(encoder, server, 1)">
                        <i class="bi bly-audio-wave"/> SDI Pair 1
                      </div>
                      <div class="dropdown-item"
                        @click="changeSDIPair(encoder, server, 2)">
                        <i class="bi bly-audio-wave"/> SDI Pair 2
                      </div>
                      <div class="dropdown-item"
                        @click="changeSDIPair(encoder, server, 3)">
                        <i class="bi bly-audio-wave"/> SDI Pair 3
                      </div>
                      <div class="dropdown-item"
                        @click="changeSDIPair(encoder, server, 4)">
                        <i class="bi bly-audio-wave"/> SDI Pair 4
                      </div>
                      <div class="dropdown-item"
                        @click="changeSDIPair(encoder, server, 5)">
                        <i class="bi bly-audio-wave"/> SDI Pair 5
                      </div>
                      <div class="dropdown-item"
                        @click="changeSDIPair(encoder, server, 6)">
                        <i class="bi bly-audio-wave"/> SDI Pair 6
                      </div>
                      <div class="dropdown-item"
                        @click="changeSDIPair(encoder, server, 7)">
                        <i class="bi bly-audio-wave"/> SDI Pair 7
                      </div>
                      <div class="dropdown-item"
                        @click="changeSDIPair(encoder, server, 8)">
                        <i class="bi bly-audio-wave"/> SDI Pair 8
                      </div>
                    </div>
                    <el-tooltip content="Select SDI Audio" slot="reference">
                      <span>
                        <i class="bi bly-audio-speaker"/>
                        <span
                          class="file-select selectable"
                          :class="{active: encoder.sdiPair}"
                        >
                          <span v-if="encoder.sdiPair || encoder.sdiPair === 0">SDI Pair </span>
                          {{encoder.sdiPair | defaultValue('Select audio input')}}
                        </span>
                      </span>
                    </el-tooltip>
                  </el-popover>
                  <el-tooltip :content="encoderInfo(encoder.index, server.id).statusMessage">
                    <span class="status-text input-info-status-text"
                      :class="encoderInfo(encoder.index, server.id).status">
                      {{encoderInfo(encoder.index, server.id).statusMessage}}
                    </span>
                  </el-tooltip>
                  <div class="input-output-status">
                    <el-tooltip content="Encoder input status">
                      <span class="encoder-status-tag "
                        :class="encoderInfo(encoder.index, server.id).input">
                        Input <i class="bi bly-input"></i>
                      </span>
                    </el-tooltip>
                    <span class="encoder-status-separator"></span>
                    <el-tooltip content="Encoder output status">
                      <span class="encoder-status-tag "
                        :class="encoderInfo(encoder.index, server.id).output">
                        <i class="bi bly-output"></i> Output
                      </span>
                    </el-tooltip>
                  </div>
                </div>
                <div class="actions">
                  <span class="actions-separator"></span>
                  <el-tooltip content="Restart encoder">
                    <i class="bi bly-refresh icon-btn" @click="restartEncoder(encoder, server)"/>
                  </el-tooltip>
                  <el-tooltip content="Start encoder" v-if="false">
                    <i class="bi bly-play icon-btn" @click="startEncoder(encoder, server)" />
                  </el-tooltip>
                  <el-tooltip content="Stop encoder" v-if="false">
                    <i class="bi bly-stop icon-btn" @click="stopEncoder(encoder, server)"/>
                  </el-tooltip>
                  <span class="actions-separator"></span>
                  <el-popover
                    placement="bottom"
                    width="200"
                    trigger="click">
                    <div class="dropdown-item"
                      @click="changeDescription(encoder, 'device', encoder)">
                      <i class="bi bly-edit"/> Change description
                    </div>
                    <div class="dropdown-item" @click="openAdvancedDetails(encoder, 'encoder')">
                      <i class="bi bly-info"/> Advanced details
                    </div>
                    <el-tooltip content="More" slot="reference">
                      <i class="bi bly-more icon-btn"/>
                    </el-tooltip>
                  </el-popover>
                </div>
              </div>
              <div class="no-transfers-message" v-if="server.encoders.length === 0">
                <i class="" >i</i>
                <h1>No encoders</h1>
              </div>

              <!-- ---------------------------------------- -->
              <!-- DECODERS ------------------------------- -->
              <!-- ---------------------------------------- -->
              <div class="group-name">DECODERS</div>
              <div class="list-item encoder-item"
                v-for="(decoder, index) in server.decoders" :key="decoder.id + 'e' + index">
                <div class="item-name">
                  <i class="bi bly-record" :class="decoderInfo(decoder.index, server.id).all"/>
                  {{decoder.name}}
                </div>
                <div class="details">
                  <span class="description">{{decoder.description | defaultValue('----')}}</span>
                </div>
                <div class="additional-info">
                  <div></div>
                  <el-tooltip>
                    <div slot="content"
                      v-html="decoderInfo(decoder.index, server.id).statusTooltip"></div>
                    <span class="status-text"
                      :class="'green'">
                      {{decoderInfo(decoder.index, server.id).statusMessage}}
                    </span>
                  </el-tooltip>
                  <el-tooltip content="Decoder input status">
                    <span class="encoder-status-tag "
                      :class="decoderInfo(decoder.index, server.id).input">
                      Input <i class="bi bly-input"></i>
                    </span>
                  </el-tooltip>
                  <span class="encoder-status-separator"></span>
                  <el-tooltip content="Decoder output status">
                    <span class="encoder-status-tag "
                      :class="decoderInfo(decoder.index, server.id).output">
                      <i class="bi bly-output"></i> Output
                    </span>
                  </el-tooltip>
                </div>
                <div class="actions">
                  <span class="actions-separator"></span>
                  <el-tooltip content="Restart decoder">
                    <i class="bi bly-refresh icon-btn" @click="restartDecoder(decoder, server)"/>
                  </el-tooltip>
                  <el-tooltip content="Start decoder" v-show="false">
                    <i class="bi bly-play icon-btn" @click="startDecoder(decoder, server)" />
                  </el-tooltip>
                  <el-tooltip content="Stop decoder" v-show="false">
                    <i class="bi bly-stop icon-btn" @click="stopDecoder(decoder, server)"/>
                  </el-tooltip>
                  <span class="actions-separator"></span>
                  <el-popover
                    placement="bottom"
                    width="200"
                    trigger="click">
                    <div class="dropdown-item"
                      @click="changeDescription(decoder, 'device', decoder)">
                      <i class="bi bly-edit"/> Change description
                    </div>
                    <div class="dropdown-item" @click="openAdvancedDetails(decoder, 'decoder')">
                      <i class="bi bly-info"/> Advanced details
                    </div>
                    <el-tooltip content="More" slot="reference">
                      <i class="bi bly-more icon-btn"/>
                    </el-tooltip>
                  </el-popover>
                </div>
              </div>
              <div class="no-transfers-message" v-if="server.decoders.length === 0">
                <i class="" >i</i>
                <h1>No decoders</h1>
              </div>

              <!-- ------------------------------------------ -->
              <!-- OTT OUTPUTS -->
              <!-- ------------------------------------------ -->
              <div class="group-name">OTT OUTPUTS</div>
              <div class="list-item ott-output"
                v-for="(file, index) in server.wowzaStreamfiles" :key="file.id + 'f' + index">
                <div class="item-name">
                  <i class="bi bly-record" :class="serverStatus[server.id].files[index].all"/>
                  {{file.name | defaultValue(file.application)}}
                  <span class="description"
                    v-if="encoderInfo(file.encoderId, server.id) && isMobileOnly">
                    {{encoderInfo(file.encoderId,server.id).encoder.name}} -
                    {{encoderInfo(file.encoderId,server.id).encoder.description | defaultValue()}}
                  </span>
                </div>
                <div class="details">
                  <span class="playback-info c-light-blue">
                    {{file.streamFile | playbackName}}
                  </span>
                  <span class="description"
                    v-if="encoderInfo(file.encoderId, server.id) && !isMobileOnly">
                    {{encoderInfo(file.encoderId,server.id).encoder.name}} -
                    {{encoderInfo(file.encoderId,server.id).encoder.description | defaultValue()}}
                  </span>
                </div>
                <div class="additional-info">
                  <el-tooltip content="Total connections">
                    <span class="badge-tag grey"
                      :class="{green: file.totalConnections > 0}">
                      {{file.totalConnections}}
                    </span>
                  </el-tooltip>
                </div>
                <div class="actions">
                  <el-tooltip content="Select logo">
                    <span class="logo" @click="selectImageFile(server.wowzaLogoFiles,
                      server,
                      file, index, server.wowzaLogoStatuses[index])">
                      <i class="bi bly-image icon-btn"
                        v-if="server.wowzaLogoStatuses[index]
                          && server.wowzaLogoStatuses[index].file === 'blank.png'"/>
                      <amplify-s3-image
                        v-if="server.wowzaLogoStatuses[index]
                            && server.wowzaLogoStatuses[index].file !== 'blank.png'"
                        :imagePath="server.wowzaLogoStatuses[index].file"></amplify-s3-image>
                    </span>
                  </el-tooltip>
                  <el-tooltip content="Restart stream">
                    <i class="bi bly-refresh icon-btn" @click="restartStream(file, server)"/>
                  </el-tooltip>
                  <span class="actions-separator"></span>
                  <el-popover
                    placement="bottom"
                    width="200"
                    trigger="click">
                    <div class="dropdown-item" @click="openAdvancedDetails(file, 'file')">
                      <i class="bi bly-info"/> Advanced details
                    </div>
                    <el-tooltip content="More" slot="reference">
                      <i class="bi bly-more icon-btn"/>
                    </el-tooltip>
                  </el-popover>
                </div>
              </div>
              <div class="no-transfers-message" v-if="server.wowzaStreamfiles.length === 0">
                <i class="" >i</i>
                <h1>No OTT outputs</h1>
              </div>
              <!-- ----------------------------------------------------------------------- -->
              <!-- PLAYOUT -->
              <!-- ----------------------------------------------------------------------- -->
              <div class="group-name">PLAYOUT</div>
              <div class="list-item playout-item"
                v-for="(playback, index) in server.tsPlaybackStatuses"
                :key="playback.index + 'f' + index">
                <div class="item-name">
                  <i class="bi bly-record" :class="serverStatus[server.id].playbacks[index].all"/>
                  PLAYOUT {{playback.index}}
                </div>
                <div class="details">
                  <el-tooltip content="Select file">
                      <span class="file-select selectable" v-show="!playback.running"
                        @click="selectFile(server.tsPlaybackFiles,
                          server, playback,
                          playbackOutputs[playback.index])">
                        {{playback.savedStateFilename | defaultValue('Select file')}}
                    </span>
                  </el-tooltip>
                  <span class="file-select " v-show="playback.running">
                    {{playback.file}}
                  </span>
                  <el-tooltip content="Looped. Click to change."
                    v-show="!playback.running" >
                    <i class="bi bly-loop icon-btn" :class="{active: playback.savedStateLooped}"
                    @click="loopPlayback(playback, server)"/>
                  </el-tooltip>
                  <i class="bi bly-loop" :class="{'c-green': playback.looped}"
                    v-show="playback.running"/>
                </div>

                <!-- -------------------------------------------------------------------------- -->
                <!-- PLAYOUT ADDITIONAL INFO -->
                <!-- -------------------------------------------------------------------------- -->
                <div class="additional-info">
                  <i class="bi bly-arrow-right-long"></i>
                  <el-popover
                    placement="top"
                    width="220">
                    <div class="output-selection">
                      <el-checkbox-group v-model="playback.savedStateTargets">
                        <el-checkbox v-for="file in playback.ottOutputTargetList"
                        :key="file.application"
                        :label="file">{{file.name | defaultValue(file.application)}}</el-checkbox>
                      </el-checkbox-group>
                      <span class="noFiles" v-if="playback.ottOutputTargetList.length === 0">
                        No outputs available
                      </span>
                    </div>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="changeOutputs(playback, server, playback.savedStateTargets)">
                        OK
                        </el-button>
                    </div>
                    <el-tooltip content="Select output" slot="reference">
                      <span v-show="!playback.running">
                        <span class="file-select selectable active"
                          v-show="playback.savedStateTargets.length > 0">
                          <span
                            v-for="target in playback.savedStateTargets" :key="target.application">
                            {{target.name | defaultValue(target.application)}}
                          </span>
                        </span>
                        <span class="file-select selectable"
                          v-show="playback.savedStateTargets.length === 0">
                          {{'Select output'}}
                        </span>
                      </span>
                    </el-tooltip>
                  </el-popover>
                  <span v-show="playback.running">
                    <span class="file-select active"
                      v-show="playback.savedStateTargets.length > 0">
                      <span
                        v-for="target in playback.savedStateTargets" :key="target.application">
                        {{target.name | defaultValue(target.application)}}
                      </span>
                    </span>
                    <span class="file-select"
                      v-show="playback.savedStateTargets.length === 0">
                      No targets selected
                    </span>
                  </span>
                </div>

                <!-- -------------------------------------------------------------------------- -->
                <!-- PLAYBACK ACTIONS --------------------------------------------------------- -->
                <!-- -------------------------------------------------------------------------- -->
                <div class="actions">
                  <el-tooltip content="Start playback">
                    <i class="bi bly-play icon-btn"
                    :disabled="(!playback.savedStateFilename || !playback.savedStateTargets.length)
                      || playback.running"
                    @click="startPlayback(playback, server)" />
                  </el-tooltip>
                  <el-tooltip content="Stop playback">
                    <i class="bi bly-stop icon-btn"
                    :disabled="!playback.running"
                    @click="stopPlayback(playback, server)"/>
                  </el-tooltip>
                  <span class="actions-separator"></span>
                  <el-popover
                    placement="bottom"
                    width="200"
                    trigger="click">
                    <div class="dropdown-item" @click="openAdvancedDetails(playback, 'playback')">
                      <i class="bi bly-info"/> Advanced details
                    </div>
                    <el-tooltip content="More" slot="reference">
                      <i class="bi bly-more icon-btn"/>
                    </el-tooltip>
                  </el-popover>
                </div>
              </div>

              <!-- -------------------------------------------------------------------------- -->
              <!-- LIST EMPTY MESSAGE -->
              <!-- -------------------------------------------------------------------------- -->
              <div class="no-transfers-message" v-if="server.tsPlaybackStatuses.length === 0">
                <i class="" >i</i>
                <h1>No playback</h1>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </div>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- NAV OPTIONS -------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->
    <div class="config mv-config">
      <el-tooltip content="Upload files">
        <i class="bi bly-upload icon-btn" @click="uploadDialogVisible = true"></i>
      </el-tooltip>
      <el-popover
        placement="bottom"
        width="400"
        trigger="click">
         <div class="upload-file-popover-list">
          <div class="upload-file"
            v-for="(file) in fileList" :key="file.uid">
            <div class="file-group">{{file.name}}</div>
            <div class="file-upload-right">
              {{file.percentage}}%
            </div>
            <el-progress
              v-if="file.percentage > 0"
              :percentage="file.percentage"
              :status="file.status"
              :show-text="false"></el-progress>
            <div
              class="upload-file-mg"
              v-for="(f, key) in file.uploads" :key="key">
              {{serversById[f.mg].name}}
              <div>{{f.percentage}}%</div>
            </div>
          </div>
        </div>
        <el-tooltip content="Overall upload progress" slot="reference">
          <span class='pload-progress icon-btn c-green' v-show="overallUploadProgress">
            &nbsp;&nbsp;
            Uploading {{fileList.length}} file
            <span v-show="fileList.length > 1">s</span> - {{overallUploadProgress}}%
            &nbsp;&nbsp;
          </span>
        </el-tooltip>
      </el-popover>
    </div>

    <!-- -------------------------------------------------------------------------- -->
    <!-- UPLOAD FILE DIALOG ------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Upload files"
      :visible.sync="uploadDialogVisible"
      :width="uploadDialogWidth"
      :before-close="handleUploadClose">
      <div class="upload-dialog">
        <div class="servers">
          <div>Destinations</div>
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            border
            size="small"
            @change="handleCheckAllChange">Check all</el-checkbox>
          <el-checkbox-group v-model="checkedMG"
            @change="handleCheckedServersChange"
            size="mini">
            <el-checkbox
              :label="server.id" border
              v-for="server in serverList" :key="server.id + 'ch'">
              {{server.name}}
            </el-checkbox>
          </el-checkbox-group>
        </div>

        <div class="upload">
          <div>Files</div>
          <div class="upload-file-list">
            <div class="upload-file"
            v-for="(file, index) in fileList" :key="file.uid">
              {{file.name}}
              <div class="file-upload-right">
                <span  v-if="!file.percentage">
                {{file.size | prettyBytes}}
                </span>
                <span v-if="file.percentage > 0">
                {{file.percentage}}%
                </span>
                <i class="icon-btn bi bly-close" @click="removeFileFromList(index)"></i>
              </div>
              <el-progress
                v-if="file.percentage > 0"
                :percentage="file.percentage"
                :status="file.status"
                :show-text="false"></el-progress>
            </div>
          </div>
          <el-upload
            class="upload-files"
            drag
            action="uploadAction"
            :auto-upload="false"
            :on-change="handleUploadChange"
            :show-file-list="false"
            :multiple="true"
            :accept="'.png, .ts'"
            :http-request="handleHTTPUpload">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Drop file here or <em>click to select</em></div>
            <div class="el-upload__tip" slot="tip">
              Supported files: .png, .ts
            </div>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="uploadFiles">Start upload and close</el-button>
      </span>
    </el-dialog>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- SELECT FILE DIALOG ------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->
    <el-dialog
      title="Select file"
      :visible.sync="fileDialogVisible"
      :width="uploadDialogWidth">
      <div class="upload-dialog">
        <div class="files">
          <div>Files</div>
          <div class="dropdown-item"
            v-for="(file, index) in dialogFileList" :key="file.name"
            @click="selectedFile = file"
            :class="{
              active: selectedFile && file.name === selectedFile.name,
              processing: file.progress !== '100',
            }">
            {{file.name}}
            <span v-show="file.progress === '100'">
              {{file.size | prettyBytes}}
            </span>
            <span class="file-list-delete"
              v-show="file.progress === '100'"
              @click="deletePlayoutFile(file, selectedServer, selectedPlayback, index)">
              <i class="bi bly-delete" />
            </span>
            <span v-show="file.progress !== '100'">
              Processing {{file.progress}}
            </span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fileDialogVisible = false">Cancel</el-button>
        <el-button type="primary"
          @click="changePlayoutFile(selectedFile, selectedServer, selectedPlayback)">
          Confirm
        </el-button>
      </span>
    </el-dialog>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- SELECT OTT IMAGE DIALOG  ------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->
    <el-dialog
      title="Select file"
      :visible.sync="fileImageDialogVisible"
      :width="uploadDialogWidth">
      <div class="upload-dialog">
        <div class="files">
          <div>Files</div>
          <div class="dropdown-item dropdown-item-image"
            v-for="(file, index) in dialogFileList" :key="file.name"
            @click="selectedFile = file"
            :class="{
              active: selectedFile && file.name === selectedFile.name,
              processing: file.progress !== '100',
            }">
            <div class="file-list-name">
              <amplify-s3-image :imagePath="file.name"></amplify-s3-image> {{file.name}}
            </div>
            <span v-show="file.progress === '100'">
              {{file.size | prettyBytes}}
            </span>
            <span class="file-list-delete"
              v-show="file.progress === '100'"
              @click="deleteOutputLogo(file, selectedServer, selectedOutput, index)">
              <i class="bi bly-delete" />
            </span>
            <span v-show="file.progress !== '100'">
              Processing {{file.progress}}
            </span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div v-if="selectedOutputFile">Current Image: <strong>{{selectedOutputFile.file}}</strong>
        </div>
        <el-button @click="fileImageDialogVisible = false">Cancel</el-button>
        <el-button type="primary"
          v-if="selectedOutputFile && selectedOutputFile.file !== 'blank.png'"
          @click="clearOutputLogo(selectedFile, selectedServer, selectedOutput, selectedOutputFile)"
          >
          Clear Image
        </el-button>
        <el-button type="primary"
          @click="changeOutputImageFile(
            selectedFile, selectedServer, selectedOutput, selectedOutputFile)">
          Confirm
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import { Amplify, Storage } from 'aws-amplify'

export default {
  name: 'servers',
  data() {
    return {
      error: '-------------',
      isMobileOnly,
      activeServer: null,
      interval: null,
      dialogDetailsVisible: false,
      selectedItem: {},
      selectedItemType: null,

      // SELECT FILE DIALOG
      fileDialogVisible: false,
      fileImageDialogVisible: false,
      dialogFileList: [],
      selectedFile: null,
      selectedOutput: null,
      selectedOutputIndex: null,
      selectedIndex: null,
      selectedServer: null,
      selectedPlayback: null,
      selectedOutputFile: null,

      // UPLOAD DIALOG PROPERTIES
      uploadDialogVisible: false,
      checkedMG: [],
      checkAll: false,
      isIndeterminate: false,
      fileList: [],
      fileUploads: {},
      uploading: false,
      overallUploadProgress: 0,

      // POPUPS PROPERTIES
      showOutputPopup: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
      playbackOutputs: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      showEncoderPopup: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
    };
  },

  created() {
    this.init();
    this.initInterval();
  },

  beforeDestroy() {
    window.clearInterval(this.interval);
    this.interval = null;
  },

  computed: {
    ...mapState({
      serverList: (state) => state.servers.serverList,
      serverStatus: (state) => state.servers.serverStatus,
      serversById: (state) => state.servers.serversById,
    }),

    uploadDialogWidth() {
      return this.isMobileOnly ? '90%' : '640px';
    },

    isServerListEmpty() {
      return this.serverList && this.serverList.length === 0;
    },
    encoderInfo() {
      return (encoderId, serverId) => {
        const doesExist = !!this.serverStatus[serverId].encoders[encoderId];
        if (doesExist) return this.serverStatus[serverId].encoders[encoderId];
        return null;
      };
    },

    decoderInfo() {
      return (decoderId, serverId) => {
        const doesExist = !!this.serverStatus[serverId].decoders[decoderId];
        if (doesExist) return this.serverStatus[serverId].decoders[decoderId];
        return null;
      };
    },

    getImage() {
      return (key) => {
        this.$Amplify.Storage.get(key).then((image) => image);
        return '';
      };
    },

    serverListId() {
      return this.serverList.map((server) => server.id);
    },

    serverStatusMessage() {
      return (heartBeat, status) => {
        const isResponsive = heartBeat < 90;
        const isNotRsponsive = heartBeat > 90 && heartBeat < 3600;
        if (isResponsive) return `${status}. Last response: ${Math.round(heartBeat)} seconds ago.`;
        if (isNotRsponsive) {
          return `${status}. Server is not responding.
          Last response: ${Math.round(heartBeat)} seconds ago.`;
        }

        return 'Server not running.';
      };
    },
  },

  methods: {
    ...mapActions({
      fetchServerList: 'servers/fetchServerList',
      sendCommand: 'servers/sendCommand',
      restartApplication: 'servers/restartApplication',
    }),
    handleUploadClose(event) {
      console.log('uploadDialogVisible', event);
      this.uploadDialogVisible = false;
    },

    handleHTTPUpload() {

    },

    removeFileFromList(index) {
      this.fileList.splice(index, 1);
    },

    uploadFiles() {
      const isMGSelected = this.checkedMG.length > 0;

      if (!isMGSelected) {
        this.$message({
          showClose: true,
          message: 'You must select Media Gateway!',
          type: 'warning',
        });
        return;
      }
      // this.uploadDialogVisible = false;

      this.fileList.forEach((file, index) => {
        // const key = `${file.uid}-${file.name}`;
        const name = file.name.substring(0, file.name.lastIndexOf('.'));
        const extension = file.name.substring(file.name.lastIndexOf('.'));
        const randomString = this.S4();
        const key = `${name}_${randomString}${extension}`;
        this.fileList[index].mg = this.checkedMG;
        this.fileList[index].keys = {};
        this.fileList[index].uploads = {};

        this.fileList[index].status = null;
        this.fileList[index].key = `public/${key}`;

        this.$Amplify.Storage.put(key, file.raw, {
          progressCallback: this.fileUploadProgress,
          region: 'eu-west-1',
          metadata: {
            mediaGateways: this.checkedMG.join(','),
          },
        })
          .then((result) => {
            console.log('File uploaded', result);
          })
          .catch((err) => console.log(err));
      });

      // Upload to multiple folders
      /*
      this.checkedMG.forEach((id) => {
        // const toMg = `${id}-MG/`;
        const toMg = '';
        const fileKey = `${toMg}${file.name}-${file.uid}`;
        this.fileList[index].keys[id] = {
          from: key,
          to: `${toMg}${file.name}-${file.uid}`,
          key: fileKey,
          complete: false,
        };
        this.fileList[index].uploads[fileKey] = {
          status: null,
          percentage: 0,
          mg: id,
          key: fileKey,
          originalFile: file,
        };
        this.$Amplify.Storage.put(this.fileList[index].keys[id].to, file.raw, {
          progressCallback: this.fileUploadProgress,
          metadata: {
            mediaGateways: this.checkedMG.join(','),
          },
        })
          .then((result) => {
            console.log('File uploaded', result);
          })
          .catch(err => console.log(err));
        });
      });

      */

      this.uploadDialogVisible = false;
    },

    fileUploadProgress(progress) {
      const percentage = Math.round((100 / progress.total) * progress.loaded);
      this.overallUploadProgress = 0;
      let overallSum = 0;
      this.fileList.forEach((file, i) => {
        if (file.key === progress.key) {
          // this.fileList.splice(i, 1);

          // MG File upload progress
          Vue.set(this.fileList[i], 'percentage', percentage);
          // console.log('FILE UPLOAD PROGRESS', percentage);
          if (percentage === 100) {
            this.fileList[i].status = 'success';
            // remove completeFiles
            this.removeCompleteFile(i);
          }

          overallSum += this.fileList[i].percentage;
        }
        this.overallUploadProgress = Math.round(overallSum / this.fileList.length);
      });

      this.$forceUpdate();
    },

    removeCompleteFile(index) {
      setTimeout(() => {
        this.fileList.splice(index, 1);
        if (this.fileList.length === 0) this.overallUploadProgress = 0;
      }, 2000);
    },

    uploadAction() {
      console.log('uploadAction');
    },

    handleUploadChange(file) {
      const newFile = file;
      newFile.status = null;
      this.fileList.push(newFile);
      Vue.set(this, 'fileList', this.fileList);
    },

    handleCheckAllChange(val) {
      this.checkedMG = val ? this.serverListId : [];
      this.isIndeterminate = false;
    },

    selectFile(fileList, server, playback) {
      this.fileDialogVisible = true;
      this.dialogFileList = fileList;
      this.selectedServer = server;
      this.selectedPlayback = playback;
    },

    selectImageFile(files, server, output, index, currentFile) {
      this.fileImageDialogVisible = true;
      this.dialogFileList = files;
      this.selectedServer = server;
      this.selectedOutput = output;
      this.selectedOutputIndex = index;
      this.selectedOutputFile = currentFile;
    },

    handleCheckedServersChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.serverList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.serverList.length;
    },

    //
    // PLAYOUT ACTIONS
    //

    async changeOutputs(playback, server, outputs) {
      console.log('CHANGE PLAYBACK OUTPUTS', playback, server, outputs);

      const command = {
        id: 'unset',
        serverId: server.id,
        type: 'tsplayback',
        index: playback.index,
        command: 'assign_outputs',
        value: playback.savedStateFilename,
        targets: playback.savedStateTargets,
      };

      console.log('changeOutputs', command);
      this.sendCommandRequest(command, false);
    },

    async changePlayoutFile(file, server, playback) {
      const isFileSelected = !!file;
      if (!isFileSelected) {
        this.$message({
          showClose: true,
          message: 'Please select file.',
          type: 'error',
        });
        return;
      }
      this.fileDialogVisible = false;
      const command = {
        id: 'unset',
        serverId: server.id,
        type: 'tsplayback',
        index: playback.index,
        command: 'assign_outputs',
        value: file.name,
        targets: playback.savedStateTargets,
      };

      this.sendCommandRequest(command, false);
    },

    async deletePlayoutFile(file, server, playback, index) {
      const command = {
        id: 'unset',
        serverId: server.id,
        type: 'tsplayback',
        index: playback.index,
        command: 'delete',
        value: file.name,
        targets: [],
      };
      const message = `Are you sure you want to delete <strong> ${file.name}</strong>?`;
      const confirmation = await this.$confirm(message, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        dangerouslyUseHTMLString: true,
        type: 'warning',
        center: true,
      }).catch((error) => {
        console.log('error', error);
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      console.log('DELETE FILE', command);
      const result = await this.sendCommandRequest(command, false);
      console.log('DELETE RESULT', result);
      if (result) this.dialogFileList.splice(index, 1);
    },

    async startPlayback(playback, server) {
      const command = {
        id: 'unset',
        serverId: server.id,
        type: 'tsplayback',
        index: playback.index,
        command: 'start_to_ott',
        value: playback.file,
      };
      this.sendCommandRequest(command, 'This will start the playback. Continue?');
    },

    async stopPlayback(playback, server) {
      const command = {
        id: 'unset',
        serverId: server.id,
        type: 'tsplayback',
        index: playback.index,
        command: 'stop',
        value: playback.file,
      };
      this.sendCommandRequest(command, 'This will stop the playback. Continue?');
    },

    async loopPlayback(playback, server) {
      const command = {
        id: 'unset',
        serverId: server.id,
        type: 'tsplayback',
        index: playback.index,
        command: 'set_looped',
        value: !playback.savedStateLooped,
      };
      this.sendCommandRequest(command, false);
    },

    //
    // ENCODER ACTIONS
    //

    async startEncoder(encoder, server) {
      const command = {
        id: encoder.id,
        serverId: server.id,
        type: encoder.type,
        index: encoder.index,
        command: 'start',
      };

      this.sendCommandRequest(command, 'This will stop the encoder. Continue?');
    },

    async restartEncoder(encoder, server) {
      const command = {
        id: encoder.id,
        serverId: server.id,
        type: encoder.type,
        index: encoder.index,
        command: 'restart',
      };

      this.sendCommandRequest(command, 'This will restart the encoder. Continue?');
    },

    async stopEncoder(encoder, server) {
      const command = {
        id: encoder.id,
        serverId: server.id,
        type: encoder.type,
        index: encoder.index,
        command: 'stop',
      };

      this.sendCommandRequest(command, 'This will stop the encoder. Continue?');
    },

    async changeSDIPair(encoder, server, pair) {
      const command = {
        id: encoder.id,
        serverId: server.id,
        type: 'obe_encoder_status',
        index: encoder.index,
        command: 'change_audio_pair',
        value: pair,
      };
      this.sendCommandRequest(command, 'This will change audio input. Continue?');
    },

    //
    // DEOCER ACTIONS
    //

    async startDecoder(decoder, server) {
      const command = {
        id: decoder.id,
        serverId: server.id,
        type: 'obe_decoder',
        index: decoder.index,
        command: 'start',
      };

      this.sendCommandRequest(command, 'This will stop the decoder. Continue?');
    },

    async restartDecoder(decoder, server) {
      const command = {
        id: decoder.id,
        serverId: server.id,
        type: 'obe_decoder',
        index: decoder.index,
        command: 'restart',
      };

      this.sendCommandRequest(command, 'This will restart the decoder. Continue?');
    },

    async stopDecoder(decoder, server) {
      const command = {
        id: decoder.id,
        serverId: server.id,
        type: 'obe_decoder',
        index: decoder.index,
        command: 'stop',
      };

      this.sendCommandRequest(command, 'This will stop the decoder. Continue?');
    },

    //
    // OUTPUT ACTIONS
    //
    async restartStream(stream, server) {
      const command = {
        id: 'unset',
        command: 'restart_application',
        application: stream.application,
        serverId: server.id,
        streamKey: stream.streamKey,
        streamFile: stream.restartStreamfile,
        target: 'dummy',
      };

      const confirmation = await this.$confirm(
        'This will restart the stream. Continue?',
        'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          center: true,
        },
      ).catch((error) => {
        console.log('error', error);
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      const result = await this.restartApplication(command);

      const hasFailed = result.error;

      if (hasFailed) {
        this.$message({
          showClose: true,
          message: result.message,
          type: 'error',
        });
        return;
      }

      this.$message({
        showClose: true,
        message: 'Command was sent',
        type: 'success',
      });
    },

    async changeOutputImageFile(file, server, output, selectedOutputFile) {
      const isFileSelected = !!file;
      if (!isFileSelected) {
        this.$message({
          showClose: true,
          message: 'Please select file.',
          type: 'error',
        });
        return;
      }
      this.fileImageDialogVisible = false;

      const command = {
        id: 'unset',
        serverId: server.id,
        type: 'wowza_logo',
        index: selectedOutputFile.index,
        command: 'set',
        value: file.name,
        targets: [],
      };
      this.sendCommandRequest(command, 'This will set the logo for the Output. Continue?');
    },

    async deleteOutputLogo(file, server, output, index) {
      const command = {
        id: 'unset',
        serverId: server.id,
        type: 'tsplayback',
        index: output.index,
        command: 'delete',
        value: file.name,
        targets: [],
      };
      const result = await this.sendCommandRequest(command, false);
      if (result) this.dialogFileList.splice(index, 1);
    },

    async clearOutputLogo(file, server, output, selectedOutputFile) {
      const command = {
        id: 'unset',
        serverId: server.id,
        type: 'wowza_logo',
        index: selectedOutputFile.index,
        command: 'clear',
        value: selectedOutputFile.file,
        targets: [],
      };
      const result = await this.sendCommandRequest(command, false);
      if (result) this.selectedOutputFile.file = 'blank.png';
    },

    async sendCommandRequest(command, confirmationMessage) {
      if (confirmationMessage) {
        const confirmation = await this.$confirm(confirmationMessage, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          center: true,
        }).catch((error) => {
          console.log('error', error);
        });

        const isConfirmed = confirmation === 'confirm';
        if (!isConfirmed) return false;
      }

      const result = await this.sendCommand(command);

      const hasFailed = result.error;

      if (hasFailed) {
        this.$message({
          showClose: true,
          message: result.message,
          type: 'error',
        });
        return false;
      }

      this.$message({
        showClose: true,
        message: 'Command was sent',
        type: 'success',
      });

      return true;
    },

    initInterval() {
      this.interval = setInterval(this.fetchServerList, 15000);
    },

    async init() {
      try {
        await this.fetchServerList();
      } catch (error) {
        this.error = error;
        this.$message({
          showClose: true,
          message: error.responseXML,
          type: 'error',
        });
      }
    },

    setActiveServer(id) {
      const isCurrentlyActive = id === this.activeServer;

      if (isCurrentlyActive) {
        this.activeServer = null;
        return;
      }

      this.activeServer = id;
    },

    async changeDescription(item, type, server) {
      try {
        const confirmResult = await this.$prompt('Please input the description', `Change description for ${item.name}`, {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          inputValue: item.description,
        });

        const command = {
          id: item.id,
          serverId: server.id,
          type: `${type}_description`,
          index: item.index,
          command: confirmResult.value,
        };
        const result = await this.sendCommand(command);
        const hasFailed = result.error;
        if (hasFailed) {
          this.$message({
            showClose: true,
            message: result.message,
            type: 'error',
          });
        }
        console.log('change description', confirmResult);
      } catch (error) {
        console.log('change description', error);
        if (error === 'cancel') return;
        this.$message({
          showClose: true,
          message: 'Oops, something went wrong.',
          type: 'error',
        });
      }

      /*
      .then(({ value }) => {
        const command = {
          id: item.id,
          serverId: server.id,
          type: 'device_description',
          index: item.index,
          command: 'description',
        };
        const result = await this.sendCommand(command);
        const hasFailed = result.error;

        if (hasFailed) {
          this.$message({
            showClose: true,
            message: result.message,
            type: 'error',
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Input canceled',
        });
      });
      */
    },

    openAdvancedDetails(item, type) {
      this.selectedItemType = type;
      this.selectedItem = item;
      this.dialogDetailsVisible = true;
    },

    hideAdvancedDetails() {
      this.dialogDetailsVisible = false;
      this.selectedItemType = null;
      this.selectedItem = null;
    },

    S4() {
      // eslint-disable-next-line
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },
  },
};
</script>

<style>
.c-green {
  color: #00FF80 !important;
}

.c-blue {
  color: #0077FF !important;
}

.c-light-blue {
  color: #00D4FF !important;
}

.upload-files {
  width: 100%;
}
.upload-files .el-upload {
  width: 100%;
}
.upload-files .el-upload .el-upload-dragger {
  width: 100%;
  height: 130px;
}
.upload-files .el-upload .el-upload-dragger .el-icon-upload {
  margin-top: 20px;
}

.upload-file-popover-list .upload-file {
  display: grid;
  grid-template-columns: 1fr 50px;
  margin-bottom: 16px;
}
.upload-file-popover-list .upload-file .file-group {
  font-weight: bold;
}
.upload-file-popover-list .upload-file .file-upload-right {
  text-align: right;
}
.upload-file-popover-list .upload-file .el-progress {
  grid-column: 1/span 2;
  margin-bottom: 10px;
}
.upload-file-popover-list .upload-file .upload-file-mg {
  grid-column: 1/span 2;
  display: grid;
  grid-template-columns: 1fr 50px;
}
.upload-file-popover-list .upload-file .upload-file-mg div {
  text-align: right;
}

.upload-dialog .servers {
  margin-top: -20px;
}
.upload-dialog .servers .el-checkbox {
  margin-right: 8px;
  margin-left: 0px !important;
  margin-bottom: 8px;
}
.upload-dialog .upload-file-list {
  min-height: 200px;
}
.upload-dialog .upload-file {
  display: grid;
  grid-template-columns: 1fr 200px;
  line-height: 24px;
  height: 24px;
  margin-bottom: 16px;
}
.upload-dialog .upload-file .file-upload-right {
  text-align: right;
}
.upload-dialog .upload-file .file-upload-right .icon-btn {
  line-height: 24px;
  min-width: 40px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
.upload-dialog .upload-file .el-progress {
  grid-column: 1/span 2;
}
.upload-dialog .upload {
  margin-top: 20px;
}
.upload-dialog .dropdown-item {
  position: relative;
}
.upload-dialog .dropdown-item.processing {
  pointer-events: none;
  opacity: 0.5;
}
.upload-dialog .dropdown-item.dropdown-item-image {
  height: 50px;
  line-height: 50px;
}
.upload-dialog .dropdown-item .file-list-name {
  float: left;
}
.upload-dialog .dropdown-item .file-list-name div {
  float: left;
  width: 50px;
  background: #efefef;
  height: 50px;
  margin-right: 10px;
  border-radius: 4px;
}
.upload-dialog .dropdown-item .file-list-delete {
  opacity: 0;
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  cursor: pointer;
  right: 0px;
  position: absolute;
  top: 0;
}
.upload-dialog .dropdown-item:hover .file-list-delete {
  opacity: 1;
  pointer-events: auto;
  background: linear-gradient(90deg, rgba(243, 245, 251, 0) 0%, #f3f5fb 50%);
  width: 120px;
  text-align: right;
}

.no-servers-message {
  text-align: center;
  padding: 80px 40px;
}
.no-servers-message h1 {
  color: #66718D;
  font-size: 36px;
}
.no-servers-message i {
  font-size: 50px;
  color: #66718D;
  text-transform: none;
  font-style: normal;
  border: 3px solid #0077FF;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  display: inline-block;
  border-radius: 50px;
  color: #0077FF;
}

.icon-btn {
  line-height: 40px;
  min-width: 40px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
.icon-btn:hover {
  background: #66718D;
  color: #fff !important;
}
.icon-btn:hover.bly-play {
  background: #00FF80;
}
.icon-btn:hover.bly-stop {
  background: #FC487F;
}
.icon-btn .bi {
  vertical-align: middle;
}
.icon-btn[disabled] {
  pointer-events: none;
  opacity: 0.4;
}
.icon-btn.active {
  color: #00FF80;
}

.details-dialog div {
  line-height: 24px;
  padding: 0 8px;
  border-radius: 3px;
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.details-dialog div:hover {
  background: #CFDFFF;
}
.details-dialog div .details-dialog-item-h {
  color: #000;
  margin-top: 11px;
  margin-left: -8px;
}
.details-dialog strong {
  display: inline-block;
  width: 200px;
}

.encoder-status-tag {
  font-size: 13px;
  font-weight: bold;
  text-transform: none;
}
.encoder-status-tag .bi {
  font-size: 16px;
  display: inline-block;
  margin: 0 3px;
}
.encoder-status-tag.green {
  color: #00FF80;
}
.encoder-status-tag.red {
  color: #FC487F;
}
.encoder-status-tag.amber {
  color: #FF9100;
}
.encoder-status-tag.grey {
  color: #66718D;
}

.encoder-status-separator {
  height: 3px;
  background: #66718D;
  width: 30px;
  display: inline-block;
  border-radius: 2px;
  margin: 0 3px;
  position: relative;
  top: -3px;
}

.status-tag, .tag-extended {
  background: #66718D;
  color: #000;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  padding: 0 8px;
  border-radius: 3px;
  margin-right: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.status-tag.green, .tag-extended.green {
  background: #00FF80;
}
.status-tag.red, .tag-extended.red {
  background: #FC487F;
}
.status-tag.amber, .tag-extended.amber {
  background: #FF9100;
}
.status-tag.grey, .tag-extended.grey {
  background: #66718D;
}

.tag-extended {
  background: #12181d;
  color: #66718D;
  width: 100%;
  display: inline-block;
  text-align: left;
  position: relative;
}
.tag-extended span {
  color: #000;
  padding: 0 8px;
  float: right;
}
.tag-extended span.green {
  background: #00FF80;
}
.tag-extended span.red {
  background: #FC487F;
}
.tag-extended span.amber {
  background: #FF9100;
}
.tag-extended span.grey {
  background: #66718D;
}
.tag-extended span:last-child {
  margin-left: 8px;
}
.tag-extended span:first-child {
  margin-right: -8px;
  border-radius: 0 3px 3px 0;
}

.status-text {
  color: #66718D;
  font-size: 13px;
  text-align: center;
  line-height: 24px;
  padding: 0 8px;
  border-radius: 3px;
  margin-right: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.status-text.green {
  color: #00FF80;
}
.status-text.red {
  color: #FC487F;
}
.status-text.amber {
  color: #FF9100;
}

.output-selection label:last-child {
  margin-right: 30px;
}

.badge-tag {
  background: #66718D;
  color: #000;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  margin-right: 16px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 30px;
  min-width: 24px;
  display: inline-block;
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.badge-tag.green {
  background: #00FF80;
}

.muted {
  color: #66718D !important;
  margin: 0px !important;
}

.bi.green {
  color: #00FF80 !important;
}
.bi.red {
  color: #FC487F !important;
}
.bi.amber {
  color: #FF9100 !important;
}

.file-select.active {
  color: #00D4FF !important;
}

#servers {
  font-size: 15px;
}
#servers .dialog-footer div {
  margin-bottom: 20px;
}
#servers .el-dialog .file-list-name div {
  background: #000;
  margin-left: -8px;
}
#servers .el-dialog .file-list-name div .amplify-image {
  max-width: 100%;
  max-height: 100%;
  border: 0px;
  width: auto;
  margin: 0px;
  vertical-align: middle;
}
#servers .wrapper {
  padding: 10px 20px;
}
#servers .additional-info {
  text-align: right;
}
#servers .server-list .bly-more {
  transform: rotate(90deg);
}
#servers .server-list .server .file-list .group-name {
  text-transform: uppercase;
  margin-left: 32px;
  margin-top: 16px;
}
#servers .server-list .server .file-list .list-item {
  background: #232b33;
  margin-bottom: 8px;
  line-height: 40px;
  display: grid;
  grid-template-columns: 300px 1fr 300px 260px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  margin-left: 32px;
  padding: 0 16px;
}
#servers .server-list .server .file-list .list-item.playout-item {
  grid-template-columns: 300px 400px 1fr 260px;
}
#servers .server-list .server .file-list .list-item.playout-item .additional-info {
  text-align: left;
}
#servers .server-list .server .file-list .list-item.playout-item .additional-info .bly-arrow-right-long {
  margin-right: 16px;
}
#servers .server-list .server .file-list .list-item.encoder-item {
  grid-template-columns: 300px 1fr 500px 260px;
}
#servers .server-list .server .file-list .list-item.encoder-item .additional-info {
  display: grid;
  align-items: center;
  grid-template-columns: 171px 100px 207px 90px;
  text-align: center;
}
#servers .server-list .server .file-list .list-item.encoder-item .additional-info .file-select {
  margin-left: 10px;
}
#servers .server-list .server .file-list .list-item.encoder-item .additional-info .file-select.active {
  color: #00D4FF;
}
#servers .server-list .server .file-list .list-item .selectable {
  position: relative;
  cursor: pointer;
  user-select: none;
  color: #66718D;
}
#servers .server-list .server .file-list .list-item .selectable:after {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 1px;
  border-bottom: 1px dashed #66718D;
}
#servers .server-list .server .file-list .list-item i {
  vertical-align: sub;
}
#servers .server-list .server .file-list .list-item .item-name {
  color: #CFDFFF;
}
#servers .server-list .server .file-list .list-item .item-name .bi {
  vertical-align: unset;
  position: relative;
  top: 1px;
  margin-right: 11px;
}
#servers .server-list .server .file-list .list-item .item-name .index {
  margin: 0 5px 0 0;
  color: #5e6e8d;
}
#servers .server-list .server .file-list .list-item .details .description {
  color: #66718D;
}
#servers .server-list .server .file-list .list-item .details span {
  color: #CFDFFF;
  margin-right: 10px;
}
#servers .server-list .server .file-list .list-item .details span strong {
  color: #66718D;
}
#servers .server-list .server .file-list .list-item .actions {
  text-align: right;
}
#servers .server-list .server .file-list .list-item .actions span.actions-separator {
  display: inline-block;
  width: 25px;
}
#servers .server-list .server .file-list .list-item .actions .icon-btn {
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
#servers .server-list .server .file-list .list-item .actions .bly-play {
  color: #00FF80;
}
#servers .server-list .server .file-list .list-item .actions .bly-stop {
  color: #FC487F;
}
#servers .server-list .server .file-list .list-item .actions div {
  display: inline-block;
  text-align: center;
  vertical-align: sub;
  margin-bottom: -15px;
  position: relative;
  top: 3px;
  left: -6px;
  pointer-events: none;
  width: 29px;
  height: 29px;
  background: #000;
}
#servers .server-list .server .file-list .list-item .actions div img {
  border: 0px;
  background: #000;
  margin: 0px;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
#servers .server-list .server .file-list .no-transfers-message {
  text-align: left;
}
#servers .server-list .server .file-list .no-transfers-message h1 {
  color: #66718D;
  display: inline-block;
}
#servers .server-list .server .file-list .no-transfers-message i {
  color: #0077FF;
  text-transform: none;
  font-style: normal;
  border: 1px solid #0077FF;
  width: 20px;
  height: 20px;
  margin-left: 30px;
  text-align: center;
  line-height: 20px;
  display: inline-block;
  border-radius: 50px;
  margin-right: 10px;
  margin-bottom: 30px;
}
#servers .server-list .server .server-details {
  background: #2b343e;
  margin-bottom: 8px;
  line-height: 55px;
  display: grid;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  display: grid;
  grid-template-columns: 300px 1fr 600px 76px;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
#servers .server-list .server .server-details:hover {
  background: rgba(43, 52, 62, 0.4);
}
#servers .server-list .server .server-details .additional-info div {
  width: 155px;
  display: inline-block;
  margin-left: 10px;
}
#servers .server-list .server .server-details .name .bly-warning-2 {
  color: #FF9100;
}
#servers .server-list .server .server-details .name .active {
  color: #00FF80;
}
#servers .server-list .server .server-details .name .description {
  color: #66718D;
}
#servers .server-list .server .server-details .actions {
  text-align: right;
}
#servers .server-list .server .server-details .actions .status {
  display: inline-block;
  font-weight: bold;
  color: #66718D;
}
#servers .server-list .server .server-details .actions .status > div {
  display: inline-block;
}
#servers .server-list .server .server-details .actions .status .progress {
  display: inline-block;
  height: 50px;
  width: 50px;
  overflow: hidden;
  line-height: 50px;
  margin-bottom: -23px;
  margin-left: 16px;
}
#servers .server-list .server .server-details .actions .status .progress .el-progress__text {
  font-size: 18px !important;
  margin-top: -1px;
  color: #66718D;
}
#servers .server-list .server .server-details .actions .status .progress .el-progress-circle__path {
  stroke: #00FF80 !important;
}
#servers .server-list .server .server-details .name {
  cursor: pointer;
  font-weight: bold;
  color: #CFDFFF;
}
#servers .server-list .server .server-details .name i {
  margin-right: 8px;
  color: #CFDFFF;
  vertical-align: sub;
}
#servers .server-list .server .server-details .details {
  cursor: pointer;
}
#servers .server-list .server .server-details .destination i {
  position: relative;
  top: 2px;
  margin-right: 10px;
  color: #0077FF;
}
#servers .server-list .server .server-details .destination i.to {
  transform: rotate(180deg);
  color: #FF9100;
}

.mobile #servers .server-list .server .server-details {
  grid-template-columns: 1fr;
}
.mobile #servers .server-list .server .server-details .actions {
  position: absolute;
  top: 0;
  right: 0;
}
.mobile #servers .server-list .server .server-details .name {
  margin-top: 12px;
  margin-bottom: 12px;
  line-height: 16px;
}
.mobile #servers .server-list .server .server-details .name .description {
  font-weight: 500;
  display: block;
  padding-left: 25px;
  margin-top: 2px;
}
.mobile #servers .server-list .server .server-details .additional-info {
  text-align: left;
  padding-left: 12px;
  line-height: 32px;
  margin-bottom: 16px;
}
.mobile #servers .server-list .server .server-details .additional-info div {
  width: 140px;
}
.mobile #servers .server-list .server .server-details .additional-info .tag-extended {
  font-size: 10px;
  margin-right: 6px;
}
.mobile #servers .server-list .server .file-list .group-name {
  margin-left: 14px;
}
.mobile #servers .server-list .server .file-list .list-item {
  grid-template-columns: 1fr;
  margin-left: 12px;
  overflow: visible;
}
.mobile #servers .server-list .server .file-list .list-item:before {
  display: none;
  content: " ";
  width: 1px;
  position: absolute;
  height: 106%;
  background: #2b343e;
  top: -89%;
  z-index: 1111;
  left: -12px;
}
.mobile #servers .server-list .server .file-list .list-item:after {
  display: none;
  content: " ";
  height: 1px;
  position: absolute;
  background: #2b343e;
  top: 23px;
  z-index: 1111;
  left: -12px;
  width: 10px;
}
.mobile #servers .server-list .server .file-list .list-item .item-name {
  margin-top: 12px;
  margin-bottom: 10px;
  line-height: 16px;
}
.mobile #servers .server-list .server .file-list .list-item .item-name .description {
  font-weight: 500;
  display: block;
  padding-left: 30px;
  color: #66718D;
  margin-top: 7px;
}
.mobile #servers .server-list .server .file-list .list-item.ott-output .additional-info {
  position: absolute;
  top: 0;
  right: 73px;
}
.mobile #servers .server-list .server .file-list .list-item.playout-item {
  grid-template-columns: 1fr 1fr;
}
.mobile #servers .server-list .server .file-list .list-item.playout-item .item-name {
  grid-column: 1/span 2;
}
.mobile #servers .server-list .server .file-list .list-item.playout-item .details {
  background: rgba(0, 0, 0, 0.2);
  margin-left: -16px;
  padding-left: 16px;
}
.mobile #servers .server-list .server .file-list .list-item.playout-item .additional-info {
  background: rgba(0, 0, 0, 0.2);
  margin-right: -16px;
}
.mobile #servers .server-list .server .file-list .list-item.encoder-item {
  grid-template-columns: 1fr;
}
.mobile #servers .server-list .server .file-list .list-item.encoder-item .additional-info {
  grid-template-columns: 1fr;
  text-align: left;
  padding-left: 42px;
}
.mobile #servers .server-list .server .file-list .list-item.encoder-item .additional-info .input-info-status-text {
  position: absolute;
  right: -10px;
  bottom: 8px;
}
.mobile #servers .server-list .server .file-list .list-item.encoder-item .item-name .description {
  padding-left: 44px;
}
.mobile #servers .server-list .server .file-list .list-item .actions {
  position: absolute;
  top: 0;
  right: 0;
}
.mobile #servers .server-list .server .file-list .list-item .actions .actions-separator {
  width: 0;
}
</style>
