import { API, graphqlOperation } from 'aws-amplify';
import Vue from 'vue';
import * as qlqueries from '../graphql/queries';
import * as qlmutations from '../graphql/mutations';

const state = {
  name: 'Stream',
  logs: {},
  streamDestinations: null,
  streamSources: null,
  streamTypes: [
    'SRT',
    'UDP',
    'RIST',
    'RTMP',
    'HLS',
    'DASH',
  ],
  encryptionTypes: [
    'NONE',
    'AES128',
    'AES256',
  ],
  loading: false,
};

const getters = {
};

const mutations = {
  setList: (state, payload) => {
    Vue.set(state, payload.property, payload.data.items);
  },

  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },
};

const actions = {
  async listStreamDestinations({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listStreamDestinations,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listStreamDestinations, property: 'streamDestinations' });
      return data.listStreamDestinations;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listStreamDestinations;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listStreamDestinations, property: 'streamDestinations' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the INPUTS' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t list stream destinations' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async listStreamSources({ commit }) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(qlqueries.listStreamSources,
          {
            limit: 1500,
          }),
      );
      const { data } = result;
      commit('setList', { data: data.listStreamSources, property: 'streamSources' });
      return data.listStreamSources;
    } catch (error) {
      const hasData = !!error.data && !!error.data.listStreamSources;
      if (hasData) {
        const { data } = error;
        commit('setList', { data: data.listStreamSources, property: 'streamSources' });
        this._vm.$message.error(
          { message: 'Oops something went wrong. Can\'t get some of the INPUTS' },
        );
        return { error: true, message: error.errors };
      }
      this._vm.$message.error(
        { message: 'Oops something went wrong. Can\'t list stream sources' },
      );
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createStreamDestination({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createStreamDestination,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Stream Destination created' });
      return data.createStreamDestination;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async createStreamSource({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.createStreamSource,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Stream Source created' });
      return data.createStreamSource;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteStreamDestination({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteStreamDestination,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Stream destination deleted' });
      return data.deleteStreamDestination;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async deleteStreamSource({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.deleteStreamSource,
          { input: payload },
        ),
      );
      const { data } = result;
      this._vm.$message({ message: 'Stream source deleted' });
      return data.deleteStreamSource;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateStreamSource({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateStreamSource,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateStreamSource, property: 'source' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateStreamSource;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },

  async updateStreamDestination({ commit }, payload) {
    commit('setLoading', true);
    try {
      const result = await API.graphql(
        graphqlOperation(
          qlmutations.updateStreamDestination,
          { input: payload },
        ),
      );
      const { data } = result;
      commit('update', { data: data.updateStreamDestination, property: 'destination' });
      this._vm.$message({ message: 'Item updated' });
      return data.updateStreamDestination;
    } catch (error) {
      this._vm.$message.error({ message: 'Something went wrong' });
      return { error: true, message: error.errors };
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
