import { render, staticRenderFns } from "./Multiview.vue?vue&type=template&id=acad5c1c&"
import script from "./Multiview.vue?vue&type=script&lang=js&"
export * from "./Multiview.vue?vue&type=script&lang=js&"
import style0 from "./Multiview.vue?vue&type=style&index=0&id=acad5c1c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports