<template>
  <div class="sidebar-item bly-sidebar-item"
    :class="{active: active}">
    <div class="sidebar-item-name" @click="select(item, contextName)">
      <i class="bi bly-" :class="'bly-' + icon"></i>
      {{name}}
    </div>
    <div class="sidebar-item-actions">
      <slot name="actions"></slot>
      <el-tooltip :content="'Create ' + name" v-if="add">
        <i class="bi icon-btn bly-plus" @click="add(addType)"></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    item: Array,
    contextName: String,
    active: Boolean,
    select: Function,
    add: Function,
    addType: String,
    icon: String,
  },

  computed: {
    classes() {
      return `${this.type} ${this.size}`;
    },
    name() {
      return this.contextName.split(/(?=[A-Z])/).join(' ');
    },
  },

  data() {
    return {
    };
  },
};
</script>

<style scoped src="./SidebarItem.css"></style>
