<template>
  <div id="liveops" class="liveops">
    <div></div>
    <div class="quad-grid">
      <div class="grid-div">
        <div class="pane">
          <div class="pane-title red">
            ON AIR
          </div>
          <bly-live-ops-item
            :header="true" :item="{}"></bly-live-ops-item>
          <div v-bar>
            <div class="pane-content">
            <bly-live-ops-item
              v-for="item in liveEvents"
              :now="now"
              :key="item.id"
              :item="item"></bly-live-ops-item>
          </div>
          </div>
        </div>
      </div>
      <div class="grid-div">
        <div class="pane">
          <div class="pane-title green">
            NEXT
          </div>
          <bly-live-ops-item
            :header="true" :live="false" :item="{}"></bly-live-ops-item>
          <div v-bar>
            <div class="pane-content" >
              <bly-live-ops-item
                @openPreview="showPreviewPanel"
                :now="now"
                :live="false"
                v-for="item in upcoming"
                :key="item.id"
                :item="item"></bly-live-ops-item>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-div">
        <div class="pane">
          <div class="pane-title">
            MULTIVIEWERS
          </div>
          <div v-bar>
            <div class="pane-content">
              <LiveOpsMultiviewerVue />
            </div>
          </div>
        </div>
      </div>
      <div class="grid-div">
        <div class="pane">
          <div class="pane-title">
            ROUTER
          </div>
          <div>
            <div class="pane-content">
              <LiveOpsRouterVue />
            </div>
          </div>
        </div>
      </div>
    </div>
    <SchedulePreviewPanel :showPanel.sync="previewVisible" :event.sync="event"/>
    <div class="config mv-config">
      <el-tooltip content="Help" placement="right">
        <el-popover
          placement="top-start"
          width="300"
          trigger="hover">
          <el-alert :closable="false">
            Current state refreshes every 15 seconds
          </el-alert>
          <h3><strong>Keybindings</strong></h3>
          <div>
            <div>
              Hold <el-tag size="small">SHIFT</el-tag> to disable scrolling.
            </div>
          </div>
          <i slot="reference" class="bi bly-question icon-btn"></i>
        </el-popover>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import _ from 'lodash';
import moment from 'moment';
import 'splitpanes/dist/splitpanes.css';
import LiveOpsRouterVue from './LiveOpsRouter.vue';
import LiveOpsMultiviewerVue from './LiveOpsMultiviewer.vue';
import SchedulePreviewPanel from './LiveOpsSchedulePreviewPanel.vue';

export default {
  name: 'router',
  components: { LiveOpsRouterVue, LiveOpsMultiviewerVue, SchedulePreviewPanel },
  data() {
    return {
      isMobileOnly,
      now: moment.utc(),
      previewVisible: false,
      event: {},
    };
  },

  created() {
    this.init();
    this.initInterval();
  },

  beforeDestroy() {
    window.clearInterval(this.interval);
    this.interval = null;
  },

  computed: {
    ...mapState({
      aclEx: (state) => state.userAccount.userPermissions,
      acl: (state) => state.userAccount.aclCurrent,
      isAdmin: (state) => state.userAccount.isAdmin,
      client: (state) => state.userAccount.client,
      account: (state) => state.userAccount.account,
      clients: (state) => state.userAccount.clients,
      siteList: (state) => state.router.siteList,
      selection: (state) => state.router.selectedSite,
      loadings: (state) => state.router.loadings,

      routers: (state) => state.router.routers,
      routerSources: (state) => state.router.routerSources,
      routerDestinations: (state) => state.router.routerDestinations,
      routerGroups: (state) => state.router.routerGroups,

      streamInformation: (state) => state.router.streamInformation,

      events: (state) => state.schedule.listEvents.items,
    }),

    liveEvents() {
      return this.events.filter((event) => this.now.isBetween(moment.utc(event.startTime), moment.utc(event.endTime)));
    },

    upcoming() {
      return this.events.filter((event) => moment.utc(event.startTime).isAfter(this.now));
    },
  },

  watch: {
    seletedRouterGroup() {
      this.selectedSource = null;
      this.selectedDestination = null;
    },
  },

  methods: {
    ...mapActions({
      fetchRouterGroups: 'router/fetchRouterGroups',
      fetchRouterDestinations: 'router/fetchRouterDestinations',
      fetchRouterSources: 'router/fetchRouterSources',
      fetchRouters: 'router/fetchRouters',

      fetchRouter: 'router/fetchRouter',
      fetchRouterDestination: 'router/fetchRouterDestination',
      fetchRouterGroup: 'router/fetchRouterGroup',
      fetchRouterSource: 'router/fetchRouterSource',

      deleteRouter: 'router/deleteRouter',
      deleteRouterDestination: 'router/deleteRouterDestination',
      deleteRouterGroup: 'router/deleteRouterGroup',
      deleteRouterSource: 'router/deleteRouterSource',

      updateRouter: 'router/updateRouter',
      updateRouterDestination: 'router/updateRouterDestination',
      updateRouterGroup: 'router/updateRouterGroup',
      updateRouterSource: 'router/updateRouterSource',

      createRouter: 'router/createRouter',
      createRouterDestination: 'router/createRouterDestination',
      createRouterGroup: 'router/createRouterGroup',
      createRouterSource: 'router/createRouterSource',

      changeRoute: 'router/changeRoute',

      fetchEventsByDate: 'schedule/fetchEventsByDateExtended',
    }),
    ...mapMutations({
      setSelectedSite: 'router/setSelectedSite',
      setActiveEvent: 'schedule/setActiveEvent',
    }),
    showPreviewPanel(event) {
      this.previewVisible = true;
      this.event = event;
    },

    initInterval() {
      this.interval = setInterval(async () => {
        this.refresh();
        this.$forceUpdate();
      }, 15000);
    },

    async init() {
      const dateRange = {
        start: `${moment.utc(new Date()).subtract(1, 'days').format().split('T')[0]}T00:00:00.000Z`,
        end: `${moment.utc(new Date()).add(2, 'days').format().split('T')[0]}T00:00:00.000Z`,
      };
      this.fetchEventsByDate({...dateRange, showCompleted: true});
    },

    async refresh() {
      this.now = moment.utc();
      this.init();
    },
  },
};
</script>

<style scoped>
.el-loading-spinner svg {
  margin: 0 auto;
}

.green {
  color: #00FF80;
}

.green-dark {
  color: #77ca17;
}

.blue-light {
  color: #00D4FF;
}

#liveops {
  padding: 10px 20px;
  overflow: hidden;
}
#liveops .quad-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(450px, calc(50vh - 50px)) minmax(450px, calc(50vh - 50px));
  gap: 24px;
}
#liveops .quad-grid .vb {
  height: 380px;
}
#liveops .quad-grid .grid-div {
  padding: 8px 8px 2px;
  border: 1px solid #232b33;
  border-radius: 4px;
  height: 100%;
}
#liveops .quad-grid .pane .pane-title {
  margin-bottom: 6px;
  font-weight: bold;
}
#liveops .quad-grid .pane .pane-content {
  height: 50vh;
}
#liveops .red {
  color: #ea0d51;
}
#liveops .green {
  color: #00FF80;
}
#liveops .el-divider .el-divider__text {
  position: absolute;
  background-color: #13191D;
}

@media screen and (max-width: 1110px) {
  #liveops {
    padding: 10px 20px;
  }
  #liveops .quad-grid {
    display: block;
    grid-template-columns: 1fr;
    grid-template-rows: 450px 450px 450px 450px;
    gap: 24px;
    min-height: 2000px;
    overflow: hidden;
  }
  #liveops .quad-grid .grid-div {
    height: 450px;
    margin-bottom: 24px;
  }
}
</style>
